import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import * as VueGoogleMaps from 'vue2-google-maps'
 
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyBonYy_ZH77lxom88j__zIAQ0BS0c2MdGE',
    key: 'AIzaSyDw11yEKBXY_MsuQapZCqRDYso7JgofzAo',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
})

new Vue({
  render: h => h(App),
}).$mount('#app')
