<template>
  <div class="info" v-bind:class="{ hidden: hidden }">
    <div class="container fullh">
      <img class="info-img" src="@/assets/bici.svg" />
      <div class="more-info">
        Este mapa muestra exclusivamente los
        <b
          >aparcabicicletas municipales para el anclaje y candado de
          bicicletas</b
        >
        situados en la vía pública. <br /><br />Información obtenida del
        catálogo de datos del <br />Ayuntamiento de Madrid
      </div>      
      <div class="updated-at">
        Última actualización: 27/10/2020
        <br />
        Desarrollado por:
        <a href="https://sotelo.io" target="_blank">
          <b>sotelo.io</b>
        </a>
      </div>
      <a class="brand-img-cont" href="https://sotelo.io" target="_blank">
        <img class="brand-img" src="@/assets/sotelo.svg" />
      </a>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../event-bus.js";
export default {
  name: "MoreInfo",
  data() {
    return {
      hidden: true,
    };
  },
  mounted() {
    EventBus.$on("show-info", () => {
      this.hidden = !this.hidden;
      console.log("this.hidden", this.hidden);
    });
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.info {
  background: #fff;
  text-align: center;
  color: #020873;
  font-size: 16px;  
}
.info-img {
  padding-top: 200px;
}
.more-info {
  max-width: 600px;
  margin: auto;
  padding-top: 60px;
}
.brand-img {
  position: absolute;
  bottom: 60px;
  right: 15px;
  width: 50px;
  height: 50px;
}
.updated-at {
  position: absolute;
  bottom: 60px;
  left: 0px;
  width: 350px;
  font-size: 14px;
  text-align: left;
  padding-left: 15px;
}
.info a {
  color: #020873;
  text-decoration: none;
}
.fullh{
  height: calc(100vh - 70px);
  position: relative;
}
@media (max-width: 767px) {
  .info-img {
    padding-top: 70px;
  }
  .info {    
    font-size: 14px;  
  }
  .more-info {
    padding-top: 40px;
  }
  .updated-at{
    position: absolute;
    bottom: 130px;    
    width:100%;
    left:0;
    right: auto;
    text-align: center;
    display: block;
    padding: 0;
  }
  .brand-img-cont{
    position: absolute;
    bottom: 0px;
    width:100%;
    left:0;
    text-align: center;
    display: block;
  }
  .brand-img {  
  width: 50px;
  height: 50px;
  
  margin: auto;
  right: auto;
  left:calc(50vw - 25px)
}
}
</style>