<template>
  <div class="header">
    <div class="container">
      <img class="header-img" src="@/assets/logo.svg" />
      <div class="hidden-xs more-info" @click="showInfo">+ Más info</div>
      <div class="visible-xs more-info more-info-xs" @click="showInfo">
        <svg
          v-if="!show"
          xmlns="http://www.w3.org/2000/svg"
          width="25.334"
          height="19.77"
          viewBox="0 0 25.334 19.77"
          stroke-width="2px"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke="#020873"
        >
          <defs></defs>
          <g transform="translate(-621.966 -293.422)" stroke="#020873">
            <line
              class="a"
              x2="23.334"
              transform="translate(622.966 312.192)"
            />
            <line
              class="a"
              x2="23.334"
              transform="translate(622.966 294.422)"
            />
            <line class="a" x2="23.334" transform="translate(622.966 303.3)" />
          </g>
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.328"
          height="19.328"
          viewBox="0 0 19.328 19.328"
          v-if="show"
        >
          <g transform="translate(-309.086 -25.086)"
          stroke-width="2px"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke="#020873">
            <line
              class="a"
              x2="23.334"
              transform="translate(310.5 26.5) rotate(45)"
            />
            <line
              class="a"
              x2="23.334"
              transform="translate(310.5 43) rotate(-45)"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>


<script>
import { EventBus } from "../event-bus.js";

export default {
  name: "Header",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showInfo() {
      EventBus.$emit("show-info", {});
      this.show = !this.show
    },
  },
};
</script>

<style scoped>
.header {
  height: 70px;
  background: #fff;
  text-align: left;
  overflow: hidden;
  padding: 0 -15px;
}
.header-img {
  margin-top: 20px;
}
.more-info {
  float: right;
  margin-top: 27px;
  color: #020873;
  font-size: 14px;
  cursor: pointer;
}
.more-info-xs {
  margin-top: 21px;
  margin-right: 2px;
}
</style>