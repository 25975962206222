<template>
  <div id="app">
    <Header class="app-header" />
    <MoreInfo class="app-moreinfo" />
    <BikesMap class="app-bikesmap" />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import MoreInfo from './components/MoreInfo.vue'
import BikesMap from './components/BikesMap.vue'

export default {
  name: 'App',
  components: {
    BikesMap,
    MoreInfo,
    Header
  }
}
</script>

<style>
#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0;
  width: 100vw; 
  height: 100%
}
body{
  margin: 0px;
  padding: 0;
  overflow: hidden;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
}
@media (min-width: 768px) {
  .container {
    width: 738px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 962px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.app-header{
  position: absolute;
  top:0;
  width: 100%;
  height: 70px;
  box-shadow: rgba(0,0,0,.2) 1px 4px 4px 0;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 20;
}
.app-bikesmap{
  position: relative;  
  position: absolute;
  top:70px;
  z-index: 10;
}

.app-moreinfo{
  position: absolute;
  top:70px;
  height: calc(100vh - 70px);
  width: 100%;
  z-index: 15;
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .visible-xs {
    display: none !important;
  }
}
</style>
