const bikes = [
  {
      "id": "7182544",
      "description": "SITUADO APARCABICICLETAS - CALLE ARTURO SORIA 330",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "159",
      "neighborhoodName": "COSTILLARES",
      "state": "OPERATIVO",
      "installDate": "19/10/2020",
      "addressType": "CALLE",
      "addressStreet": "ARTURO SORIA",
      "addressNumber": "330",
      "addressCode": "11112691",
      "model": "",
      "address": "CALLE ARTURO SORIA 330, COSTILLARES, MADRID",
      "lat": 40.4802881,
      "lng": -3.6664831
  },
  {
      "id": "7182538",
      "description": "SITUADO APARCABICICLETAS - CALLE JULIAN CAMARILLO 21",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "201",
      "neighborhoodName": "SIMANCAS",
      "state": "OPERATIVO",
      "installDate": "16/10/2020",
      "addressType": "CALLE",
      "addressStreet": "JULIAN CAMARILLO",
      "addressNumber": "21",
      "addressCode": "20042304",
      "model": "",
      "address": "CALLE JULIAN CAMARILLO 21, SIMANCAS, MADRID",
      "lat": 40.4343659,
      "lng": -3.6299857
  },
  {
      "id": "7182675",
      "description": "SITUADO APARCABICICLETAS - C/ DEL DOCTOR ESQUERDO 107",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "36",
      "neighborhoodName": "NI�O JESUS",
      "state": "OPERATIVO",
      "installDate": "16/10/2020",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR ESQUERDO",
      "addressNumber": "107",
      "addressCode": "11012040",
      "model": "",
      "address": "CALLE DOCTOR ESQUERDO 107, NI�O JESUS, MADRID",
      "lat": 40.4147353,
      "lng": -3.669281599999999
  },
  {
      "id": "7182682",
      "description": "SITUADO APARCABICICLETAS - C/ DE MENDEZ ALVARO 9",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "27",
      "neighborhoodName": "ATOCHA",
      "state": "OPERATIVO",
      "installDate": "16/10/2020",
      "addressType": "CALLE",
      "addressStreet": "MENDEZ ALVARO",
      "addressNumber": "9",
      "addressCode": "11010542",
      "model": "",
      "address": "CALLE MENDEZ ALVARO 9, ATOCHA, MADRID",
      "lat": 40.4012574,
      "lng": -3.6860159
  },
  {
      "id": "7182623",
      "description": "SITUADO APARCABICICLETAS - C/ DEL OMBU 3. ESTACION MENDEZ ALVARO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "27",
      "neighborhoodName": "ATOCHA",
      "state": "OPERATIVO",
      "installDate": "15/10/2020",
      "addressType": "CALLE",
      "addressStreet": "OMBU",
      "addressNumber": "3",
      "addressCode": "20111980",
      "model": "",
      "address": "CALLE OMBU 3, ATOCHA, MADRID",
      "lat": 40.3953451,
      "lng": -3.676823
  },
  {
      "id": "7182666",
      "description": "SITUADO APARCABICICLETAS - C/ SOLEDAD CAZORLA 3",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "15/10/2020",
      "addressType": "CALLE",
      "addressStreet": "SOLEDAD CAZORLA",
      "addressNumber": "3",
      "addressCode": "20182261",
      "model": "",
      "address": "CALLE SOLEDAD CAZORLA 3, LAS AGUILAS, MADRID",
      "lat": 40.3769199,
      "lng": -3.7753338
  },
  {
      "id": "7181354",
      "description": "SITUADO APARCABICICLETAS - CALLE FRANCISCO VILLAESPESA 16",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "13/10/2020",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO VILLAESPESA",
      "addressNumber": "16",
      "addressCode": "20182469",
      "model": "",
      "address": "CALLE FRANCISCO VILLAESPESA 16, PUEBLO NUEVO, MADRID",
      "lat": 40.4292466,
      "lng": -3.6435574
  },
  {
      "id": "7180222",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCALA 553",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "13/10/2020",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "553",
      "addressCode": "20052900",
      "model": "",
      "address": "CALLE ALCALA 553, EL SALVADOR, MADRID",
      "lat": 40.4435554,
      "lng": -3.6177744
  },
  {
      "id": "7180250",
      "description": "SITUADO APARCABICICLETAS - CALLE MISTERIOS 2",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "153",
      "neighborhoodName": "QUINTANA",
      "state": "OPERATIVO",
      "installDate": "13/10/2020",
      "addressType": "CALLE",
      "addressStreet": "MISTERIOS",
      "addressNumber": "2",
      "addressCode": "11108206",
      "model": "",
      "address": "CALLE MISTERIOS 2, QUINTANA, MADRID",
      "lat": 40.4384813,
      "lng": -3.6388294
  },
  {
      "id": "7180272",
      "description": "SITUADO APARCABICICLETAS - CALLE TORRELAGUNA 56",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "13/10/2020",
      "addressType": "CALLE",
      "addressStreet": "TORRELAGUNA",
      "addressNumber": "56",
      "addressCode": "20151925",
      "model": "",
      "address": "CALLE TORRELAGUNA 56, SAN PASCUAL, MADRID",
      "lat": 40.4450264,
      "lng": -3.6546403
  },
  {
      "id": "7180122",
      "description": "SITUADO APARCABICICLETAS - C/ JOS� ABASCAL N�21",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "13/10/2020",
      "addressType": "CALLE",
      "addressStreet": "JOSE ABASCAL",
      "addressNumber": "21",
      "addressCode": "11041882",
      "model": "",
      "address": "CALLE JOSE ABASCAL 21, RIOS ROSAS, MADRID",
      "lat": 40.43870889999999,
      "lng": -3.6996893
  },
  {
      "id": "7180210",
      "description": "SITUADO APARCABICICLETAS - CARRETERA CANILLAS 46",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "09/10/2020",
      "addressType": "CARRETERA",
      "addressStreet": "CANILLAS",
      "addressNumber": "46",
      "addressCode": "11116433",
      "model": "",
      "address": "CARRETERA CANILLAS 46, CANILLAS, MADRID",
      "lat": 40.4627393,
      "lng": -3.6492347
  },
  {
      "id": "7180216",
      "description": "SITUADO APARCABICICLETAS - CALLE RIBERA DEL SENA 21",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "215",
      "neighborhoodName": "CORRALEJOS",
      "state": "OPERATIVO",
      "installDate": "09/10/2020",
      "addressType": "CALLE",
      "addressStreet": "RIBERA DEL SENA",
      "addressNumber": "21",
      "addressCode": "20058524",
      "model": "",
      "address": "CALLE RIBERA DEL SENA 21, CORRALEJOS, MADRID",
      "lat": 40.4619751,
      "lng": -3.6176719
  },
  {
      "id": "7180045",
      "description": "SITUADO_APARCABICICLETAS - AV DEL DR GARCIA TAPIA 28",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "09/10/2020",
      "addressType": "Avenida de",
      "addressStreet": "Doctor Garc�a Tapia",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "Avenida de Doctor Garc�a Tapia , MEDIA LEGUA, MADRID",
      "lat": 40.4100086,
      "lng": -3.6413044
  },
  {
      "id": "7180048",
      "description": "SITUADO_APARCABICICLETAS - POLIEPORTIVO ANFEVI",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "146",
      "neighborhoodName": "VINATEROS",
      "state": "OPERATIVO",
      "installDate": "09/10/2020",
      "addressType": "Calle de l",
      "addressStreet": "Hacienda de Pavones",
      "addressNumber": "127",
      "addressCode": "11102942",
      "model": "",
      "address": "Calle de l Hacienda de Pavones 127, VINATEROS, MADRID",
      "lat": 40.4034573,
      "lng": -3.6400638
  },
  {
      "id": "7177756",
      "description": "SITUADO APARCABICICLETAS - ESCUELA�TECNICA�SUPERIOR�DE�INGENIERIA�Y�DISE�O�INDUSTRIAL",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "08/10/2020",
      "addressType": "RONDA",
      "addressStreet": "VALENCIA",
      "addressNumber": "3",
      "addressCode": "11010189",
      "model": "",
      "address": "RONDA VALENCIA 3, PALOS DE MOGUER, MADRID",
      "lat": 40.4054093,
      "lng": -3.7006079
  },
  {
      "id": "7179581",
      "description": "SITUADO APARCABICICLETAS - PASEO CASTELLANA N�141",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "08/10/2020",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "141",
      "addressCode": "11030660",
      "model": "",
      "address": "PASEO CASTELLANA 141, CASTILLEJOS, MADRID",
      "lat": 40.4598677,
      "lng": -3.6906837
  },
  {
      "id": "7179792",
      "description": "SITUADO APARCABICICLETAS - C/ ORENSE N�8",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "08/10/2020",
      "addressType": "CALLE",
      "addressStreet": "ORENSE",
      "addressNumber": "8",
      "addressCode": "11029275",
      "model": "",
      "address": "CALLE ORENSE 8, CUATRO CAMINOS, MADRID",
      "lat": 40.448527,
      "lng": -3.695149199999999
  },
  {
      "id": "7180026",
      "description": "SITUADO APARCABICICLETAS - CALLE TIMON 18",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "08/10/2020",
      "addressType": "CALLE",
      "addressStreet": "TIMON",
      "addressNumber": "18",
      "addressCode": "11141244",
      "model": "",
      "address": "CALLE TIMON 18, TIMON, MADRID",
      "lat": 40.4725867,
      "lng": -3.585105299999999
  },
  {
      "id": "7179791",
      "description": "SITUADO APARCABICICLETAS - C/ SAN BERNARDO N�70",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "07/10/2020",
      "addressType": "CALLE",
      "addressStreet": "SAN BERNARDO",
      "addressNumber": "70",
      "addressCode": "20006088",
      "model": "",
      "address": "CALLE SAN BERNARDO 70, UNIVERSIDAD, MADRID",
      "lat": 40.4269861,
      "lng": -3.7064089
  },
  {
      "id": "7179143",
      "description": "SITUADO APARCABICICLETAS - PASEO ALAMEDA DE OSUNA 29",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "06/10/2020",
      "addressType": "PASEO",
      "addressStreet": "ALAMEDA DE OSUNA",
      "addressNumber": "29",
      "addressCode": "31043567",
      "model": "",
      "address": "PASEO ALAMEDA DE OSUNA 29, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.45688,
      "lng": -3.59902
  },
  {
      "id": "7179583",
      "description": "SITUADO APARCABICICLETAS - C/ ROSARIO PINO N�12-14",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "06/10/2020",
      "addressType": "CALLE",
      "addressStreet": "ROSARIO PINO",
      "addressNumber": "12",
      "addressCode": "20149622",
      "model": "",
      "address": "CALLE ROSARIO PINO 12, CASTILLEJOS, MADRID",
      "lat": 40.4622813,
      "lng": -3.6923779
  },
  {
      "id": "7168589",
      "description": "SITUADO APARCABICICLETAS - CALLE SILVANO 75",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "01/10/2020",
      "addressType": "CALLE",
      "addressStreet": "SILVANO",
      "addressNumber": "75",
      "addressCode": "20065737",
      "model": "",
      "address": "CALLE SILVANO 75, CANILLAS, MADRID",
      "lat": 40.462789,
      "lng": -3.637841799999999
  },
  {
      "id": "7161892",
      "description": "SITUADO APARCABICICLETAS - CALLE CARETAS N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "23/09/2020",
      "addressType": "CALLE",
      "addressStreet": "CARRETAS",
      "addressNumber": "1",
      "addressCode": "20006680",
      "model": "",
      "address": "CALLE CARRETAS 1, SOL, MADRID",
      "lat": 40.41658779999999,
      "lng": -3.7031773
  },
  {
      "id": "7161893",
      "description": "SITUADO APARCABICICLETAS - CALLE DOCTOR MATA N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "23/09/2020",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR MATA",
      "addressNumber": "3",
      "addressCode": "11003139",
      "model": "",
      "address": "CALLE DOCTOR MATA 3, EMBAJADORES, MADRID",
      "lat": 40.4092354,
      "lng": -3.6939817
  },
  {
      "id": "7161890",
      "description": "SITUADO APARCABICICLETAS - P� PINTOR ROSALES N�2",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "92",
      "neighborhoodName": "ARGUELLES",
      "state": "OPERATIVO",
      "installDate": "22/09/2020",
      "addressType": "PASEO",
      "addressStreet": "PINTOR ROSALES",
      "addressNumber": "2",
      "addressCode": "11052500",
      "model": "",
      "address": "PASEO PINTOR ROSALES 2, ARGUELLES, MADRID",
      "lat": 40.4254606,
      "lng": -3.7171631
  },
  {
      "id": "7143883",
      "description": "SITUADO APARCABICICLETAS - PSO.�DE�LA�FLORIDA�19",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "17/09/2020",
      "addressType": "PASEO",
      "addressStreet": "FLORIDA",
      "addressNumber": "19",
      "addressCode": "11052096",
      "model": "",
      "address": "PASEO FLORIDA 19, CASA DE CAMPO, MADRID",
      "lat": 40.42171039999999,
      "lng": -3.7222289
  },
  {
      "id": "7143888",
      "description": "SITUADO APARCABICICLETAS - PSO.�DE�LA�FLORIDA�35",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "17/09/2020",
      "addressType": "PASEO",
      "addressStreet": "FLORIDA",
      "addressNumber": "35",
      "addressCode": "11052104",
      "model": "",
      "address": "PASEO FLORIDA 35, CASA DE CAMPO, MADRID",
      "lat": 40.4226506,
      "lng": -3.7232079
  },
  {
      "id": "7143893",
      "description": "SITUADO APARCABICICLETAS - AVDA�DE�VALLADOLID�59",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "17/09/2020",
      "addressType": "AVENIDA",
      "addressStreet": "VALLADOLID",
      "addressNumber": "59",
      "addressCode": "11052026",
      "model": "",
      "address": "AVENIDA VALLADOLID 59, CASA DE CAMPO, MADRID",
      "lat": 40.4308175,
      "lng": -3.7319853
  },
  {
      "id": "7143898",
      "description": "SITUADO APARCABICICLETAS - AVDA.�DE�VALLADOLID�47",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "17/09/2020",
      "addressType": "AVENIDA",
      "addressStreet": "VALLADOLID",
      "addressNumber": "47",
      "addressCode": "20108341",
      "model": "",
      "address": "AVENIDA VALLADOLID 47, CASA DE CAMPO, MADRID",
      "lat": 40.4296762,
      "lng": -3.731013599999999
  },
  {
      "id": "7143903",
      "description": "SITUADO APARCABICICLETAS - AVDA.�DE�VALLADOLID�35",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "17/09/2020",
      "addressType": "AVENIDA",
      "addressStreet": "VALLADOLID",
      "addressNumber": "35",
      "addressCode": "11052082",
      "model": "",
      "address": "AVENIDA VALLADOLID 35, CASA DE CAMPO, MADRID",
      "lat": 40.4277154,
      "lng": -3.7289397
  },
  {
      "id": "7143531",
      "description": "SITUADO APARCABICICLETAS - AVDA/�VALLADOLID�71",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "14/09/2020",
      "addressType": "AVENIDA",
      "addressStreet": "VALLADOLID",
      "addressNumber": "71",
      "addressCode": "11052031",
      "model": "",
      "address": "AVENIDA VALLADOLID 71, CASA DE CAMPO, MADRID",
      "lat": 40.4319875,
      "lng": -3.7326702
  },
  {
      "id": "7129445",
      "description": "SITUADO APARCABICICLETAS - PARROQUA�DEL�DIVINO�PASTOR",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "16/07/2020",
      "addressType": "AVENIDA",
      "addressStreet": "SANTUARIO DE VALVERDE",
      "addressNumber": "37",
      "addressCode": "31004445",
      "model": "",
      "address": "AVENIDA SANTUARIO DE VALVERDE 37, EL GOLOSO, MADRID",
      "lat": 40.50568519999999,
      "lng": -3.698963
  },
  {
      "id": "7122951",
      "description": "SITUADO APARCABICICLETAS - ESCUELA�MADRILE�A�DE�ALTA�MONTA�A",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "09/06/2020",
      "addressType": "AVENIDA",
      "addressStreet": "SALAS DE LOS INFANTES",
      "addressNumber": "1",
      "addressCode": "20103971",
      "model": "",
      "address": "AVENIDA SALAS DE LOS INFANTES 1, VALVERDE, MADRID",
      "lat": 40.48604510000001,
      "lng": -3.6839605
  },
  {
      "id": "7100131",
      "description": "SITUADO APARCABICICLETAS - C/�ABTAO�15",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "25/05/2020",
      "addressType": "CALLE",
      "addressStreet": "ABTAO",
      "addressNumber": "15",
      "addressCode": "11010960",
      "model": "",
      "address": "CALLE ABTAO 15, PACIFICO, MADRID",
      "lat": 40.4044258,
      "lng": -3.6761199
  },
  {
      "id": "7099398",
      "description": "SITUADO APARCABICICLETAS - PZA/�CIBELES�4",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "04/05/2020",
      "addressType": "PLAZA",
      "addressStreet": "CIBELES",
      "addressNumber": "4",
      "addressCode": "11013657",
      "model": "",
      "address": "PLAZA CIBELES 4, RECOLETOS, MADRID",
      "lat": 40.4194633,
      "lng": -3.6933244
  },
  {
      "id": "6823446",
      "description": "SITUADO APARCABICICLETAS - CEIP�ANTONIO�FONTAN",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "13/03/2020",
      "addressType": "AVENIDA",
      "addressStreet": "SANTUARIO DE VALVERDE",
      "addressNumber": "71",
      "addressCode": "31004462",
      "model": "",
      "address": "AVENIDA SANTUARIO DE VALVERDE 71, EL GOLOSO, MADRID",
      "lat": 40.5120567,
      "lng": -3.6938232
  },
  {
      "id": "6741051",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�DE�FOMENTO�LAS�TABLAS�VALVERDE",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "04/03/2020",
      "addressType": "PASEO",
      "addressStreet": "TIERRA DE MELIDE",
      "addressNumber": "24",
      "addressCode": "31030529",
      "model": "",
      "address": "PASEO TIERRA DE MELIDE 24, VALVERDE, MADRID",
      "lat": 40.5069497,
      "lng": -3.663297
  },
  {
      "id": "7179505",
      "description": "SITUADO APARCABICICLETAS - PZA. CONDE DE TORENO N�4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "PLAZA",
      "addressStreet": "CONDE DE TORENO",
      "addressNumber": "4",
      "addressCode": "31037162",
      "model": "",
      "address": "PLAZA CONDE DE TORENO 4, UNIVERSIDAD, MADRID",
      "lat": 40.4279716,
      "lng": -3.7152798
  },
  {
      "id": "7179506",
      "description": "SITUADO APARCABICICLETAS - PZA. CONDE DE TORENO N�5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "PLAZA",
      "addressStreet": "CONDE DE TORENO",
      "addressNumber": "5",
      "addressCode": "11006668",
      "model": "",
      "address": "PLAZA CONDE DE TORENO 5, UNIVERSIDAD, MADRID",
      "lat": 40.4245702,
      "lng": -3.7092828
  },
  {
      "id": "7179507",
      "description": "SITUADO APARCABICICLETAS - CALLE REYES N�7",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "REYES",
      "addressNumber": "7",
      "addressCode": "11006836",
      "model": "",
      "address": "CALLE REYES 7, UNIVERSIDAD, MADRID",
      "lat": 40.4242362,
      "lng": -3.7089193
  },
  {
      "id": "7179508",
      "description": "SITUADO APARCABICICLETAS - CALLE ALAMO N�12",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "ALAMO",
      "addressNumber": "12",
      "addressCode": "20006216",
      "model": "",
      "address": "CALLE ALAMO 12, UNIVERSIDAD, MADRID",
      "lat": 40.4241629,
      "lng": -3.7090105
  },
  {
      "id": "7179509",
      "description": "SITUADO APARCABICICLETAS - CALLE REYES N�13",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "REYES",
      "addressNumber": "13",
      "addressCode": "11006839",
      "model": "",
      "address": "CALLE REYES 13, UNIVERSIDAD, MADRID",
      "lat": 40.424008,
      "lng": -3.7095532
  },
  {
      "id": "7179510",
      "description": "SITUADO APARCABICICLETAS - CALLE REYES N�15",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "REYES",
      "addressNumber": "15",
      "addressCode": "11006840",
      "model": "",
      "address": "CALLE REYES 15, UNIVERSIDAD, MADRID",
      "lat": 40.4239665,
      "lng": -3.7096499
  },
  {
      "id": "7179511",
      "description": "SITUADO APARCABICICLETAS - CALLE ALAMO N�4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "ALAMO",
      "addressNumber": "4",
      "addressCode": "11006788",
      "model": "",
      "address": "CALLE ALAMO 4, UNIVERSIDAD, MADRID",
      "lat": 40.4237726,
      "lng": -3.7089667
  },
  {
      "id": "7179512",
      "description": "SITUADO APARCABICICLETAS - CALLE ALAMO N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "ALAMO",
      "addressNumber": "1",
      "addressCode": "20006218",
      "model": "",
      "address": "CALLE ALAMO 1, UNIVERSIDAD, MADRID",
      "lat": 40.42368889999999,
      "lng": -3.7090963
  },
  {
      "id": "7179495",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�40",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "40",
      "addressCode": "20006130",
      "model": "",
      "address": "CALLE AMANIEL 40, UNIVERSIDAD, MADRID",
      "lat": 40.4286164,
      "lng": -3.7094708
  },
  {
      "id": "7179496",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�40",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "40",
      "addressCode": "20006130",
      "model": "",
      "address": "CALLE AMANIEL 40, UNIVERSIDAD, MADRID",
      "lat": 40.4286164,
      "lng": -3.7094708
  },
  {
      "id": "7179497",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�40",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "40",
      "addressCode": "20006130",
      "model": "",
      "address": "CALLE AMANIEL 40, UNIVERSIDAD, MADRID",
      "lat": 40.4286164,
      "lng": -3.7094708
  },
  {
      "id": "7179498",
      "description": "SITUADO APARCABICICLETAS - CALLE MONTSERRAT N�32",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "MONTSERRAT",
      "addressNumber": "32",
      "addressCode": "11006409",
      "model": "",
      "address": "CALLE MONTSERRAT 32, UNIVERSIDAD, MADRID",
      "lat": 40.4279247,
      "lng": -3.7097255
  },
  {
      "id": "7179499",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�32",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "32",
      "addressCode": "11006359",
      "model": "",
      "address": "CALLE AMANIEL 32, UNIVERSIDAD, MADRID",
      "lat": 40.4273787,
      "lng": -3.7094232
  },
  {
      "id": "7179500",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�32",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "32",
      "addressCode": "11006359",
      "model": "",
      "address": "CALLE AMANIEL 32, UNIVERSIDAD, MADRID",
      "lat": 40.4273787,
      "lng": -3.7094232
  },
  {
      "id": "7179501",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�25",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "25",
      "addressCode": "11006365",
      "model": "",
      "address": "CALLE AMANIEL 25, UNIVERSIDAD, MADRID",
      "lat": 40.42721,
      "lng": -3.709709
  },
  {
      "id": "7179502",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�18",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "18",
      "addressCode": "11006619",
      "model": "",
      "address": "CALLE AMANIEL 18, UNIVERSIDAD, MADRID",
      "lat": 40.42617130000001,
      "lng": -3.7092593
  },
  {
      "id": "7179503",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�6",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "6",
      "addressCode": "11006661",
      "model": "",
      "address": "CALLE AMANIEL 6, UNIVERSIDAD, MADRID",
      "lat": 40.4254306,
      "lng": -3.7091044
  },
  {
      "id": "7179504",
      "description": "SITUADO APARCABICICLETAS - CALLE AMANIEL N�5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "10/01/2020",
      "addressType": "CALLE",
      "addressStreet": "AMANIEL",
      "addressNumber": "5",
      "addressCode": "11006665",
      "model": "",
      "address": "CALLE AMANIEL 5, UNIVERSIDAD, MADRID",
      "lat": 40.4250669,
      "lng": -3.709312999999999
  },
  {
      "id": "5217934",
      "description": "SITUADO APARCABICICLETAS - CALLE SANTA ENGRACIA N�53",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "30/12/2019",
      "addressType": "CALLE",
      "addressStreet": "SANTA ENGRACIA",
      "addressNumber": "53",
      "addressCode": "20013958",
      "model": "",
      "address": "CALLE SANTA ENGRACIA 53, TRAFALGAR, MADRID",
      "lat": 40.4332864,
      "lng": -3.6983615
  },
  {
      "id": "5217929",
      "description": "SITUADO APARCABICICLETASSITUADO APARCABICICLETAS - CALLE NICASIO GALLEGO N�24",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "30/12/2019",
      "addressType": "CALLE",
      "addressStreet": "NICASIO GALLEGO",
      "addressNumber": "24",
      "addressCode": "20014056",
      "model": "",
      "address": "CALLE NICASIO GALLEGO 24, TRAFALGAR, MADRID",
      "lat": 40.4295963,
      "lng": -3.6968933
  },
  {
      "id": "5217949",
      "description": "SITUADO APARCABICICLETAS - CALLE GONZALO DE CORDOBA N�1",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "26/12/2019",
      "addressType": "CALLE",
      "addressStreet": "GONZALO DE CORDOBA",
      "addressNumber": "1",
      "addressCode": "11040088",
      "model": "",
      "address": "CALLE GONZALO DE CORDOBA 1, TRAFALGAR, MADRID",
      "lat": 40.432244,
      "lng": -3.703453699999999
  },
  {
      "id": "5217939",
      "description": "SITUADO APARCABICICLETAS - CALLE PALAFOX N�1",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "26/12/2019",
      "addressType": "CALLE",
      "addressStreet": "PALAFOX",
      "addressNumber": "1",
      "addressCode": "11039892",
      "model": "",
      "address": "CALLE PALAFOX 1, TRAFALGAR, MADRID",
      "lat": 40.4303024,
      "lng": -3.7009126
  },
  {
      "id": "5226572",
      "description": "SITUADO APARCABICICLETAS - CARRERA SAN FRANCISCO 8",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CARRERA",
      "addressStreet": "SAN FRANCISCO",
      "addressNumber": "8",
      "addressCode": "11000966",
      "model": "",
      "address": "CARRERA SAN FRANCISCO 8, PALACIO, MADRID",
      "lat": 40.4110506,
      "lng": -3.7127957
  },
  {
      "id": "5226574",
      "description": "SITUADO APARCABICICLETAS - CARRERA SAN FRANCISCO 2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CARRERA",
      "addressStreet": "SAN FRANCISCO",
      "addressNumber": "2",
      "addressCode": "11000923",
      "model": "",
      "address": "CARRERA SAN FRANCISCO 2, PALACIO, MADRID",
      "lat": 40.4113458,
      "lng": -3.7117756
  },
  {
      "id": "5226577",
      "description": "SITUADO APARCABICICLETAS - CALLE TABERNILLAS 5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "TABERNILLAS",
      "addressNumber": "5",
      "addressCode": "20004066",
      "model": "",
      "address": "CALLE TABERNILLAS 5, PALACIO, MADRID",
      "lat": 40.4111261,
      "lng": -3.7112242
  },
  {
      "id": "5226578",
      "description": "SITUADO APARCABICICLETAS - CALLE TABERNILLAS 7",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "PLAZA",
      "addressStreet": "PUERTA DE MOROS",
      "addressNumber": "7",
      "addressCode": "11000721",
      "model": "",
      "address": "PLAZA PUERTA DE MOROS 7, PALACIO, MADRID",
      "lat": 40.4116373,
      "lng": -3.7109705
  },
  {
      "id": "5226580",
      "description": "SITUADO APARCABICICLETAS - PLAZA PUERTA DE MOROS 4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "PLAZA",
      "addressStreet": "PUERTA DE MOROS",
      "addressNumber": "4",
      "addressCode": "11000866",
      "model": "",
      "address": "PLAZA PUERTA DE MOROS 4, PALACIO, MADRID",
      "lat": 40.4113584,
      "lng": -3.7110637
  },
  {
      "id": "5226581",
      "description": "SITUADO APARCABICICLETAS - PLAZA PUERTA DE MOROS 8",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "PLAZA",
      "addressStreet": "PUERTA DE MOROS",
      "addressNumber": "8",
      "addressCode": "11000722",
      "model": "",
      "address": "PLAZA PUERTA DE MOROS 8, PALACIO, MADRID",
      "lat": 40.4116554,
      "lng": -3.710826699999999
  },
  {
      "id": "5226583",
      "description": "SITUADO APARCABICICLETAS - PLAZA CEBADA 7",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "PLAZA",
      "addressStreet": "CEBADA",
      "addressNumber": "7",
      "addressCode": "11000741",
      "model": "",
      "address": "PLAZA CEBADA 7, PALACIO, MADRID",
      "lat": 40.41147309999999,
      "lng": -3.7096494
  },
  {
      "id": "5226585",
      "description": "SITUADO APARCABICICLETAS - CALLE TOLEDO 56",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "TOLEDO",
      "addressNumber": "56",
      "addressCode": "31008754",
      "model": "",
      "address": "CALLE TOLEDO 56, PALACIO, MADRID",
      "lat": 40.4110831,
      "lng": -3.70901
  },
  {
      "id": "5226586",
      "description": "SITUADO APARCABICICLETAS - CALLE MALDONADAS 11",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "MALDONADAS",
      "addressNumber": "11",
      "addressCode": "11001410",
      "model": "",
      "address": "CALLE MALDONADAS 11, EMBAJADORES, MADRID",
      "lat": 40.4109919,
      "lng": -3.7081638
  },
  {
      "id": "5226588",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN MILLAN 1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "SAN MILLAN",
      "addressNumber": "1",
      "addressCode": "20004258",
      "model": "",
      "address": "CALLE SAN MILLAN 1, EMBAJADORES, MADRID",
      "lat": 40.4113615,
      "lng": -3.7074782
  },
  {
      "id": "5226590",
      "description": "SITUADO APARCABICICLETAS - CALLE DUQUE DE ALBA 12",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "DUQUE DE ALBA",
      "addressNumber": "12",
      "addressCode": "11001496",
      "model": "",
      "address": "CALLE DUQUE DE ALBA 12, EMBAJADORES, MADRID",
      "lat": 40.4117527,
      "lng": -3.7065813
  },
  {
      "id": "5226591",
      "description": "SITUADO APARCABICICLETAS - CALLE DUQUE DE ALBA 6",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "DUQUE DE ALBA",
      "addressNumber": "6",
      "addressCode": "11001493",
      "model": "",
      "address": "CALLE DUQUE DE ALBA 6, EMBAJADORES, MADRID",
      "lat": 40.4118849,
      "lng": -3.7061181
  },
  {
      "id": "5226593",
      "description": "SITUADO APARCABICICLETAS - CALLE DUQUE DE ALBA 2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "DUQUE DE ALBA",
      "addressNumber": "2",
      "addressCode": "20147605",
      "model": "",
      "address": "CALLE DUQUE DE ALBA 2, EMBAJADORES, MADRID",
      "lat": 40.4117932,
      "lng": -3.7067761
  },
  {
      "id": "5226594",
      "description": "SITUADO APARCABICICLETAS - CALLE MAGDALENA 10",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "MAGDALENA",
      "addressNumber": "10",
      "addressCode": "11002523",
      "model": "",
      "address": "CALLE MAGDALENA 10, EMBAJADORES, MADRID",
      "lat": 40.4123476,
      "lng": -3.7021554
  },
  {
      "id": "5226596",
      "description": "SITUADO APARCABICICLETAS - CALLE MAGDALENA 21",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "MAGDALENA",
      "addressNumber": "21",
      "addressCode": "11001636",
      "model": "",
      "address": "CALLE MAGDALENA 21, EMBAJADORES, MADRID",
      "lat": 40.4124358,
      "lng": -3.7010606
  },
  {
      "id": "5226597",
      "description": "SITUADO APARCABICICLETAS - CALLE MAGDALENA 23",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "MAGDALENA",
      "addressNumber": "23",
      "addressCode": "11001637",
      "model": "",
      "address": "CALLE MAGDALENA 23, EMBAJADORES, MADRID",
      "lat": 40.4125832,
      "lng": -3.700802899999999
  },
  {
      "id": "5226599",
      "description": "SITUADO APARCABICICLETAS - CALLE MAGDALENA 40",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "MAGDALENA",
      "addressNumber": "40",
      "addressCode": "11002536",
      "model": "",
      "address": "CALLE MAGDALENA 40, EMBAJADORES, MADRID",
      "lat": 40.4122535,
      "lng": -3.6997092
  },
  {
      "id": "5226579",
      "description": "SITUADO APARCABICICLETAS - PLAZA PUERTA DE MOROS 3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "PLAZA",
      "addressStreet": "PUERTA DE MOROS",
      "addressNumber": "3",
      "addressCode": "11000867",
      "model": "",
      "address": "PLAZA PUERTA DE MOROS 3, PALACIO, MADRID",
      "lat": 40.4113738,
      "lng": -3.7109148
  },
  {
      "id": "5226595",
      "description": "SITUADO APARCABICICLETAS - CALLE MAGDALENA 10",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "MAGDALENA",
      "addressNumber": "10",
      "addressCode": "11002523",
      "model": "",
      "address": "CALLE MAGDALENA 10, EMBAJADORES, MADRID",
      "lat": 40.4123476,
      "lng": -3.7021554
  },
  {
      "id": "5226582",
      "description": "SITUADO APARCABICICLETAS - CALLE HUMILLADERO 2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "HUMILLADERO",
      "addressNumber": "2",
      "addressCode": "11000857",
      "model": "",
      "address": "CALLE HUMILLADERO 2, PALACIO, MADRID",
      "lat": 40.4113354,
      "lng": -3.710639999999999
  },
  {
      "id": "5226571",
      "description": "SITUADO APARCABICICLETAS - PLAZA SAN FRANCISCO 1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "PLAZA",
      "addressStreet": "SAN FRANCISCO",
      "addressNumber": "1",
      "addressCode": "31043703",
      "model": "",
      "address": "PLAZA SAN FRANCISCO 1, PALACIO, MADRID",
      "lat": 40.410933,
      "lng": -3.7139956
  },
  {
      "id": "5226598",
      "description": "SITUADO APARCABICICLETAS - CALLE MAGDALENA 30",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "MAGDALENA",
      "addressNumber": "30",
      "addressCode": "11002531",
      "model": "",
      "address": "CALLE MAGDALENA 30, EMBAJADORES, MADRID",
      "lat": 40.4122453,
      "lng": -3.7005198
  },
  {
      "id": "5226584",
      "description": "SITUADO APARCABICICLETAS - CALLE TOLEDO 54",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "TOLEDO",
      "addressNumber": "54",
      "addressCode": "11000759",
      "model": "",
      "address": "CALLE TOLEDO 54, PALACIO, MADRID",
      "lat": 40.4115667,
      "lng": -3.7083283
  },
  {
      "id": "5226573",
      "description": "SITUADO APARCABICICLETAS - CARRERA SAN FRANCISCO 13",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CARRERA",
      "addressStreet": "SAN FRANCISCO",
      "addressNumber": "13",
      "addressCode": "11000991",
      "model": "",
      "address": "CARRERA SAN FRANCISCO 13, PALACIO, MADRID",
      "lat": 40.41059780000001,
      "lng": -3.71333
  },
  {
      "id": "5226600",
      "description": "SITUADO APARCABICICLETAS - CARRERA DE SAN FRANCISCO 4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CARRERA",
      "addressStreet": "SAN FRANCISCO",
      "addressNumber": "4",
      "addressCode": "20105137",
      "model": "",
      "address": "CARRERA SAN FRANCISCO 4, PALACIO, MADRID",
      "lat": 40.4112075,
      "lng": -3.7122344
  },
  {
      "id": "5226587",
      "description": "SITUADO APARCABICICLETAS - CALLE TOLEDO 59",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "TOLEDO",
      "addressNumber": "59",
      "addressCode": "",
      "model": "",
      "address": "CALLE TOLEDO 59, EMBAJADORES, MADRID",
      "lat": 40.4114243,
      "lng": -3.7080886
  },
  {
      "id": "5226576",
      "description": "SITUADO APARCABICICLETAS - CARRERA SAN FRANCISCO 1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CARRERA",
      "addressStreet": "SAN FRANCISCO",
      "addressNumber": "1",
      "addressCode": "20004062",
      "model": "",
      "address": "CARRERA SAN FRANCISCO 1, PALACIO, MADRID",
      "lat": 40.4111604,
      "lng": -3.7117084
  },
  {
      "id": "5226589",
      "description": "SITUADO APARCABICICLETAS - CALLE DUQUE DE ALBA 14",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "DUQUE DE ALBA",
      "addressNumber": "14",
      "addressCode": "11001497",
      "model": "",
      "address": "CALLE DUQUE DE ALBA 14, EMBAJADORES, MADRID",
      "lat": 40.4116314,
      "lng": -3.7069755
  },
  {
      "id": "5226592",
      "description": "SITUADO APARCABICICLETAS - CALLE DUQUE DE ALBA 8",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CALLE",
      "addressStreet": "DUQUE DE ALBA",
      "addressNumber": "8",
      "addressCode": "11001494",
      "model": "",
      "address": "CALLE DUQUE DE ALBA 8, EMBAJADORES, MADRID",
      "lat": 40.4118341,
      "lng": -3.7062962
  },
  {
      "id": "5226575",
      "description": "SITUADO APARCABICICLETAS - CARRERA SAN FRANCISCO 1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "10/12/2019",
      "addressType": "CARRERA",
      "addressStreet": "SAN FRANCISCO",
      "addressNumber": "1",
      "addressCode": "20004062",
      "model": "",
      "address": "CARRERA SAN FRANCISCO 1, PALACIO, MADRID",
      "lat": 40.4111604,
      "lng": -3.7117084
  },
  {
      "id": "5235703",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA PE�A GORBEA 33",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "132",
      "neighborhoodName": "SAN DIEGO",
      "state": "OPERATIVO",
      "installDate": "05/12/2019",
      "addressType": "CALLE",
      "addressStreet": "PE�A GORBEA",
      "addressNumber": "33",
      "addressCode": "20028268",
      "model": "",
      "address": "CALLE PE�A GORBEA 33, SAN DIEGO, MADRID",
      "lat": 40.3954373,
      "lng": -3.6657502
  },
  {
      "id": "5211162",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ENGRACIA N�43",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "08/11/2019",
      "addressType": "CALLE",
      "addressStreet": "SANTA ENGRACIA",
      "addressNumber": "43",
      "addressCode": "11040614",
      "model": "",
      "address": "CALLE SANTA ENGRACIA 43, TRAFALGAR, MADRID",
      "lat": 40.4326732,
      "lng": -3.6980008
  },
  {
      "id": "5207360",
      "description": "SITUADO APARCABICICLETAS - PARQUE�DE�MAIZ",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "30/10/2019",
      "addressType": "AVENIDA",
      "addressStreet": "AMERICA",
      "addressNumber": "55",
      "addressCode": "11022792",
      "model": "",
      "address": "AVENIDA AMERICA 55, PROSPERIDAD, MADRID",
      "lat": 40.44254,
      "lng": -3.667789999999999
  },
  {
      "id": "5207358",
      "description": "SITUADO APARCABICICLETAS - PARQUE�DE�MAIZ",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "30/10/2019",
      "addressType": "CALLE",
      "addressStreet": "CORAZON DE MARIA",
      "addressNumber": "25",
      "addressCode": "11022809",
      "model": "",
      "address": "CALLE CORAZON DE MARIA 25, PROSPERIDAD, MADRID",
      "lat": 40.4430515,
      "lng": -3.6680484
  },
  {
      "id": "6867589",
      "description": "SITUADO APARCABICICLETAS - CALLE LAS PEDRO�ERAS 10",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "02/10/2019",
      "addressType": "CALLE",
      "addressStreet": "LAS PEDRO�ERAS",
      "addressNumber": "10",
      "addressCode": "11116960",
      "model": "",
      "address": "CALLE LAS PEDRO�ERAS 10, CANILLAS, MADRID",
      "lat": 40.4649638,
      "lng": -3.6302674
  },
  {
      "id": "5197251",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�291",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "01/10/2019",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "289",
      "addressCode": "11036491",
      "model": "",
      "address": "CALLE BRAVO MURILLO 289, VALDEACEDERAS, MADRID",
      "lat": 40.4607958,
      "lng": -3.6984973
  },
  {
      "id": "5203197",
      "description": "SITUADO APARCABICICLETAS - PLAZA LA OROTAVA 6",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "17/09/2019",
      "addressType": "PLAZA",
      "addressStreet": "LA OROTAVA",
      "addressNumber": "6",
      "addressCode": "31015601",
      "model": "",
      "address": "PLAZA LA OROTAVA 6, PUEBLO NUEVO, MADRID",
      "lat": 40.4325012,
      "lng": -3.634700799999999
  },
  {
      "id": "5203198",
      "description": "SITUADO APARCABICICLETAS - PLAZA LA OROTAVA 6",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "17/09/2019",
      "addressType": "PLAZA",
      "addressStreet": "LA OROTAVA",
      "addressNumber": "6",
      "addressCode": "31015601",
      "model": "",
      "address": "PLAZA LA OROTAVA 6, PUEBLO NUEVO, MADRID",
      "lat": 40.4325012,
      "lng": -3.634700799999999
  },
  {
      "id": "5213758",
      "description": "SITUADO APARCABICICLETAS - C.E.I.P.�LEPANTO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "94",
      "neighborhoodName": "VALDEZARZA",
      "state": "OPERATIVO",
      "installDate": "26/08/2019",
      "addressType": "CALLE",
      "addressStreet": "VALDERREY",
      "addressNumber": "9",
      "addressCode": "11054452",
      "model": "",
      "address": "CALLE VALDERREY 9, VALDEZARZA, MADRID",
      "lat": 40.4684437,
      "lng": -3.7210309
  },
  {
      "id": "5204275",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN GREGORIO N�17",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "12/08/2019",
      "addressType": "CALLE",
      "addressStreet": "SAN GREGORIO",
      "addressNumber": "17",
      "addressCode": "11004838",
      "model": "",
      "address": "CALLE SAN GREGORIO 17, JUSTICIA, MADRID",
      "lat": 40.4240012,
      "lng": -3.6972318
  },
  {
      "id": "5204933",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN GREGORIO N�15",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "12/08/2019",
      "addressType": "CALLE",
      "addressStreet": "SAN GREGORIO",
      "addressNumber": "15",
      "addressCode": "11004837",
      "model": "",
      "address": "CALLE SAN GREGORIO 15, JUSTICIA, MADRID",
      "lat": 40.4238986,
      "lng": -3.6973309
  },
  {
      "id": "5182890",
      "description": "",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "143",
      "neighborhoodName": "MARROQUINA",
      "state": "OPERATIVO",
      "installDate": "13/06/2019",
      "addressType": "CALLE",
      "addressStreet": "CAMINO DE LOS VINATEROS",
      "addressNumber": "73",
      "addressCode": "11101667",
      "model": "",
      "address": "CALLE CAMINO DE LOS VINATEROS 73, MARROQUINA, MADRID",
      "lat": 40.4099871,
      "lng": -3.6504029
  },
  {
      "id": "5182899",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL ARROYO DE LA MEDIA LEGUA 31",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "13/06/2019",
      "addressType": "CALLE",
      "addressStreet": "ARROYO DE LA MEDIA LEGUA",
      "addressNumber": "31",
      "addressCode": "31048406",
      "model": "",
      "address": "CALLE ARROYO DE LA MEDIA LEGUA 31, MEDIA LEGUA, MADRID",
      "lat": 40.4110752,
      "lng": -3.6557208
  },
  {
      "id": "5182933",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DE MORATALAZ 185",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "143",
      "neighborhoodName": "MARROQUINA",
      "state": "OPERATIVO",
      "installDate": "13/06/2019",
      "addressType": "AVENIDA",
      "addressStreet": "MORATALAZ",
      "addressNumber": "185",
      "addressCode": "11101443",
      "model": "",
      "address": "AVENIDA MORATALAZ 185, MARROQUINA, MADRID",
      "lat": 40.4089979,
      "lng": -3.6435629
  },
  {
      "id": "4987997",
      "description": "SITUADO_APARCABICICLETAS- RONDA SUR 123",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "03/12/2018",
      "addressType": "RONDA",
      "addressStreet": "SUR",
      "addressNumber": "123",
      "addressCode": "11089955",
      "model": "",
      "address": "RONDA SUR 123, ENTREVIAS, MADRID",
      "lat": 40.37845129999999,
      "lng": -3.6775936
  },
  {
      "id": "4985730",
      "description": "SITUADO APARCABICICLETAS - C/ VALLEHERMOSO Nº26",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "20/11/2018",
      "addressType": "CALLE",
      "addressStreet": "VALLEHERMOSO",
      "addressNumber": "26",
      "addressCode": "",
      "model": "",
      "address": "CALLE VALLEHERMOSO 26, ARAPILES, MADRID",
      "lat": 40.4325967,
      "lng": -3.7086541
  },
  {
      "id": "5072113",
      "description": "SITUADO APARCABICICLETAS - C/ VALLEHERMOSO N�43",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "20/11/2018",
      "addressType": "CALLE",
      "addressStreet": "VALLEHERMOSO",
      "addressNumber": "43 A",
      "addressCode": "20013560",
      "model": "",
      "address": "CALLE VALLEHERMOSO 43 A, ARAPILES, MADRID",
      "lat": 40.4344188,
      "lng": -3.7087737
  },
  {
      "id": "4797631",
      "description": "SITUADO_ APARCABICICLETAS - CALLE DE MONTES DE BARBANZA 25",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "20/08/2018",
      "addressType": "CALLE",
      "addressStreet": "MONTES DE BARBANZA",
      "addressNumber": "25",
      "addressCode": "11131316",
      "model": "",
      "address": "CALLE MONTES DE BARBANZA 25, CASCO H.VALLECAS, MADRID",
      "lat": 40.37677,
      "lng": -3.6196
  },
  {
      "id": "4796651",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTRECHO DE MESINA 1",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "156",
      "neighborhoodName": "SAN JUAN BAUTISTA",
      "state": "OPERATIVO",
      "installDate": "01/08/2018",
      "addressType": "CALLE",
      "addressStreet": "ESTRECHO DE MESINA",
      "addressNumber": "1",
      "addressCode": "11111443",
      "model": "",
      "address": "CALLE ESTRECHO DE MESINA 1, SAN JUAN BAUTISTA, MADRID",
      "lat": 40.452459,
      "lng": -3.6520661
  },
  {
      "id": "4990621",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N� 29",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "29",
      "addressCode": "11004300",
      "model": "",
      "address": "CALLE INFANTAS 29, JUSTICIA, MADRID",
      "lat": 40.4201069,
      "lng": -3.6970542
  },
  {
      "id": "4990619",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS 44",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "44",
      "addressCode": "20005460",
      "model": "",
      "address": "CALLE INFANTAS 44, JUSTICIA, MADRID",
      "lat": 40.4199663,
      "lng": -3.6969408
  },
  {
      "id": "4990623",
      "description": "SITUADO APARCABICICLETAS - PLAZA DEL REY N�5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "PLAZA",
      "addressStreet": "REY",
      "addressNumber": "5",
      "addressCode": "20005506",
      "model": "",
      "address": "PLAZA REY 5, JUSTICIA, MADRID",
      "lat": 40.42,
      "lng": -3.69589
  },
  {
      "id": "4990610",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N� 11",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "11",
      "addressCode": "20005568",
      "model": "",
      "address": "CALLE INFANTAS 11, JUSTICIA, MADRID",
      "lat": 40.4205621,
      "lng": -3.6990381
  },
  {
      "id": "4990607",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N� 10",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "10",
      "addressCode": "11004324",
      "model": "",
      "address": "CALLE INFANTAS 10, JUSTICIA, MADRID",
      "lat": 40.4207447,
      "lng": -3.700320799999999
  },
  {
      "id": "4990374",
      "description": "SITUADO APARCABICICLETAS - C/ HERN�N CORT�S N�15",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "HERNAN CORTES",
      "addressNumber": "15",
      "addressCode": "11004878",
      "model": "",
      "address": "CALLE HERNAN CORTES 15, JUSTICIA, MADRID",
      "lat": 40.4234675,
      "lng": -3.6996299
  },
  {
      "id": "4990372",
      "description": "SITUADO APARCABICICLETAS - C/ HERN�N CORT�S N�23",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "HERNAN CORTES",
      "addressNumber": "23",
      "addressCode": "11004874",
      "model": "",
      "address": "CALLE HERNAN CORTES 23, JUSTICIA, MADRID",
      "lat": 40.4233093,
      "lng": -3.6991296
  },
  {
      "id": "4990378",
      "description": "SITUADO APARCABICICLETAS - C/ HERN�N CORT�S N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "HERNAN CORTES",
      "addressNumber": "1",
      "addressCode": "11004885",
      "model": "",
      "address": "CALLE HERNAN CORTES 1, JUSTICIA, MADRID",
      "lat": 40.4236449,
      "lng": -3.7005158
  },
  {
      "id": "4990382",
      "description": "SITUADO APARCABICICLETAS - C/ FARMACIA N�16",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "FARMACIA",
      "addressNumber": "16",
      "addressCode": "20160608",
      "model": "",
      "address": "CALLE FARMACIA 16, JUSTICIA, MADRID",
      "lat": 40.4237383,
      "lng": -3.6989365
  },
  {
      "id": "4990388",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA BR�GIDA N�14",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SANTA BRIGIDA",
      "addressNumber": "14",
      "addressCode": "31045402",
      "model": "",
      "address": "CALLE SANTA BRIGIDA 14, JUSTICIA, MADRID",
      "lat": 40.4244323,
      "lng": -3.6987432
  },
  {
      "id": "4990385",
      "description": "SITUADO APARCABICICLETAS - C/ FARMACIA N� 2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "FARMACIA",
      "addressNumber": "2",
      "addressCode": "11004888",
      "model": "",
      "address": "CALLE FARMACIA 2, JUSTICIA, MADRID",
      "lat": 40.4239027,
      "lng": -3.7003463
  },
  {
      "id": "4990391",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA BR�GIDA 12A",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SANTA BRIGIDA",
      "addressNumber": "12",
      "addressCode": "31048322",
      "model": "",
      "address": "CALLE SANTA BRIGIDA 12, JUSTICIA, MADRID",
      "lat": 40.4244057,
      "lng": -3.6994418
  },
  {
      "id": "4990393",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA BR�GIDA N�2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SANTA BRIGIDA",
      "addressNumber": "2",
      "addressCode": "11004936",
      "model": "",
      "address": "CALLE SANTA BRIGIDA 2, JUSTICIA, MADRID",
      "lat": 40.424344,
      "lng": -3.7004812
  },
  {
      "id": "4990376",
      "description": "SITUADO APARCABICICLETAS - C/ HERN�N CORT�S N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "HERNAN CORTES",
      "addressNumber": "3",
      "addressCode": "11004884",
      "model": "",
      "address": "CALLE HERNAN CORTES 3, JUSTICIA, MADRID",
      "lat": 40.4236435,
      "lng": -3.7003434
  },
  {
      "id": "4990435",
      "description": "SITUADO APARCABICICLETAS - C/ SAN BARTOLOM� N� 23",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SAN BARTOLOME",
      "addressNumber": "23",
      "addressCode": "11004526",
      "model": "",
      "address": "CALLE SAN BARTOLOME 23, JUSTICIA, MADRID",
      "lat": 40.4221923,
      "lng": -3.6984804
  },
  {
      "id": "4990437",
      "description": "SITUADO APARCABICICLETAS - C/ AUGUSTO FIGUEROA N� 35",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "AUGUSTO FIGUEROA",
      "addressNumber": "35",
      "addressCode": "11004590",
      "model": "",
      "address": "CALLE AUGUSTO FIGUEROA 35, JUSTICIA, MADRID",
      "lat": 40.4222303,
      "lng": -3.6976572
  },
  {
      "id": "4990439",
      "description": "SITUADO APARCABICICLETAS - C/ BARBIERI N� 22",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "BARBIERI",
      "addressNumber": "22",
      "addressCode": "20005608",
      "model": "",
      "address": "CALLE BARBIERI 22, JUSTICIA, MADRID",
      "lat": 40.4219991,
      "lng": -3.6977535
  },
  {
      "id": "4990441",
      "description": "SITUADO APARCABICICLETAS - C/ AUGUSTO FIGUEROA N� 16",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "AUGUSTO FIGUEROA",
      "addressNumber": "16",
      "addressCode": "11004498",
      "model": "",
      "address": "CALLE AUGUSTO FIGUEROA 16, JUSTICIA, MADRID",
      "lat": 40.4224802,
      "lng": -3.698952
  },
  {
      "id": "4990444",
      "description": "SITUADO APARCABICICLETAS - C/ AUGUSTO FIGUEROA N� 34",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "AUGUSTO FIGUEROA",
      "addressNumber": "34",
      "addressCode": "11004667",
      "model": "",
      "address": "CALLE AUGUSTO FIGUEROA 34, JUSTICIA, MADRID",
      "lat": 40.4219327,
      "lng": -3.696624599999999
  },
  {
      "id": "4990446",
      "description": "SITUADO APARCABICICLETAS - C/ AUGUSTO FIGUEROA N� 36",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "AUGUSTO FIGUEROA",
      "addressNumber": "36",
      "addressCode": "20005602",
      "model": "",
      "address": "CALLE AUGUSTO FIGUEROA 36, JUSTICIA, MADRID",
      "lat": 40.4218771,
      "lng": -3.696092
  },
  {
      "id": "4990448",
      "description": "SITUADO APARCABICICLETAS - C/ AUGUSTO FIGUEROA N� 26",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "AUGUSTO FIGUEROA",
      "addressNumber": "26",
      "addressCode": "11004665",
      "model": "",
      "address": "CALLE AUGUSTO FIGUEROA 26, JUSTICIA, MADRID",
      "lat": 40.4219901,
      "lng": -3.697182599999999
  },
  {
      "id": "4990449",
      "description": "SITUADO APARCABICICLETAS - C/ SAN MARCOS N� 41",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SAN MARCOS",
      "addressNumber": "41",
      "addressCode": "11004659",
      "model": "",
      "address": "CALLE SAN MARCOS 41, JUSTICIA, MADRID",
      "lat": 40.4213358,
      "lng": -3.6965195
  },
  {
      "id": "4990457",
      "description": "SOPORTE APARCABICICLETAS - C/ SAN MARCOS N� 9",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SAN MARCOS",
      "addressNumber": "9",
      "addressCode": "11004516",
      "model": "",
      "address": "CALLE SAN MARCOS 9, JUSTICIA, MADRID",
      "lat": 40.4216731,
      "lng": -3.6988668
  },
  {
      "id": "4990461",
      "description": "SITUADO APARCABICICLETAS - C/ SAN MARCOS N� 19",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SAN MARCOS",
      "addressNumber": "19",
      "addressCode": "11004634",
      "model": "",
      "address": "CALLE SAN MARCOS 19, JUSTICIA, MADRID",
      "lat": 40.4214421,
      "lng": -3.6983218
  },
  {
      "id": "4990455",
      "description": "SITUADO APARCABICICLETAS - C/ SAN MARCOS N� 37",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SAN MARCOS",
      "addressNumber": "37",
      "addressCode": "11004660",
      "model": "",
      "address": "CALLE SAN MARCOS 37, JUSTICIA, MADRID",
      "lat": 40.4212523,
      "lng": -3.697190699999999
  },
  {
      "id": "4990452",
      "description": "SITUADO APARCABICICLETAS - C/ SAN MARCOS N�33",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "SAN MARCOS",
      "addressNumber": "33",
      "addressCode": "11004650",
      "model": "",
      "address": "CALLE SAN MARCOS 33, JUSTICIA, MADRID",
      "lat": 40.421343,
      "lng": -3.6976876
  },
  {
      "id": "4990468",
      "description": "SITUADO APARCABICICLETAS - C/ REINA N� 33",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "REINA",
      "addressNumber": "33",
      "addressCode": "11004339",
      "model": "",
      "address": "CALLE REINA 33, JUSTICIA, MADRID",
      "lat": 40.4198661,
      "lng": -3.6983147
  },
  {
      "id": "4990471",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N� 19",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "19",
      "addressCode": "11004284",
      "model": "",
      "address": "CALLE INFANTAS 19, JUSTICIA, MADRID",
      "lat": 40.420411,
      "lng": -3.6982425
  },
  {
      "id": "4990463",
      "description": "SITUADO APARCABICICLETAS - C/ COSTANILLA DE LOS CAPUCHINOS N� 2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "COSTANILLA",
      "addressStreet": "CAPUCHINOS",
      "addressNumber": "2",
      "addressCode": "20005558",
      "model": "",
      "address": "COSTANILLA CAPUCHINOS 2, JUSTICIA, MADRID",
      "lat": 40.4214271,
      "lng": -3.6995238
  },
  {
      "id": "4990480",
      "description": "SITUADO APARCABICICLETAS - C/ REINA N� 15",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "REINA",
      "addressNumber": "15",
      "addressCode": "11004309",
      "model": "",
      "address": "CALLE REINA 15, JUSTICIA, MADRID",
      "lat": 40.420331,
      "lng": -3.6998947
  },
  {
      "id": "4990568",
      "description": "SITUADO APARCABICICLETAS - C/ REINA N� 3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "REINA",
      "addressNumber": "3",
      "addressCode": "11004315",
      "model": "",
      "address": "CALLE REINA 3, JUSTICIA, MADRID",
      "lat": 40.42051660000001,
      "lng": -3.7006181
  },
  {
      "id": "4990572",
      "description": "SITUADO APARCABICICLETAS - C/ BARBIERI N� 12",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "BARBIERI",
      "addressNumber": "12",
      "addressCode": "11004270",
      "model": "",
      "address": "CALLE BARBIERI 12, JUSTICIA, MADRID",
      "lat": 40.4211931,
      "lng": -3.6980245
  },
  {
      "id": "4990578",
      "description": "SITUADO APARCABICICLETAS - C/ BARBIERI N� 4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "BARBIERI",
      "addressNumber": "4",
      "addressCode": "11004265",
      "model": "",
      "address": "CALLE BARBIERI 4, JUSTICIA, MADRID",
      "lat": 40.420652,
      "lng": -3.6981633
  },
  {
      "id": "4990565",
      "description": "SITUADO APARCABICICLETAS - C/ REINA N� 27",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "REINA",
      "addressNumber": "27",
      "addressCode": "11004342",
      "model": "",
      "address": "CALLE REINA 27, JUSTICIA, MADRID",
      "lat": 40.4201099,
      "lng": -3.698956
  },
  {
      "id": "4990581",
      "description": "SITUADO APARCABICICLETAS - C/ BARBIERI N� 18",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "BARBIERI",
      "addressNumber": "18",
      "addressCode": "11004655",
      "model": "",
      "address": "CALLE BARBIERI 18, JUSTICIA, MADRID",
      "lat": 40.4216573,
      "lng": -3.6978065
  },
  {
      "id": "4990585",
      "description": "SITUADO APARCABICICLETAS - C/ LIBERTAD N� 4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "LIBERTAD",
      "addressNumber": "4",
      "addressCode": "11004285",
      "model": "",
      "address": "CALLE LIBERTAD 4, JUSTICIA, MADRID",
      "lat": 40.4203605,
      "lng": -3.6973309
  },
  {
      "id": "4990588",
      "description": "SITUADO APARCABICICLETAS - C/ LIBERTAD N� 20",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "LIBERTAD",
      "addressNumber": "20",
      "addressCode": "20005616",
      "model": "",
      "address": "CALLE LIBERTAD 20, JUSTICIA, MADRID",
      "lat": 40.4214569,
      "lng": -3.6972318
  },
  {
      "id": "4990575",
      "description": "SITUADO APARCABICICLETAS - C/ BARBIERI N� 14",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "BARBIERI",
      "addressNumber": "14",
      "addressCode": "20005606",
      "model": "",
      "address": "CALLE BARBIERI 14, JUSTICIA, MADRID",
      "lat": 40.4213739,
      "lng": -3.697965099999999
  },
  {
      "id": "4990592",
      "description": "SITUADO APARCABICICLETAS - C/ LIBERTAD N� 26",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "LIBERTAD",
      "addressNumber": "26",
      "addressCode": "11004664",
      "model": "",
      "address": "CALLE LIBERTAD 26, JUSTICIA, MADRID",
      "lat": 40.4219017,
      "lng": -3.6972822
  },
  {
      "id": "4990583",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N�27",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "27",
      "addressCode": "11004301",
      "model": "",
      "address": "CALLE INFANTAS 27, JUSTICIA, MADRID",
      "lat": 40.4202425,
      "lng": -3.6972913
  },
  {
      "id": "4990590",
      "description": "SITUADO APARCABICICLETAS - C/ LIBERTAD N� 22",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "LIBERTAD",
      "addressNumber": "22",
      "addressCode": "11004662",
      "model": "",
      "address": "CALLE LIBERTAD 22, JUSTICIA, MADRID",
      "lat": 40.421639,
      "lng": -3.6971327
  },
  {
      "id": "4990605",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N� 6",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "6",
      "addressCode": "20147761",
      "model": "",
      "address": "CALLE INFANTAS 6, JUSTICIA, MADRID",
      "lat": 40.4208568,
      "lng": -3.7004986
  },
  {
      "id": "4990599",
      "description": "SITUADO APARCABICICLETAS - C/ LIBERTAD N� 18",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "LIBERTAD",
      "addressNumber": "18",
      "addressCode": "20005614",
      "model": "",
      "address": "CALLE LIBERTAD 18, JUSTICIA, MADRID",
      "lat": 40.4213195,
      "lng": -3.6973242
  },
  {
      "id": "4990613",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N� 17",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "17",
      "addressCode": "20005570",
      "model": "",
      "address": "CALLE INFANTAS 17, JUSTICIA, MADRID",
      "lat": 40.42045050000001,
      "lng": -3.6984611
  },
  {
      "id": "4990616",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTAS N� 25",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "30/07/2018",
      "addressType": "CALLE",
      "addressStreet": "INFANTAS",
      "addressNumber": "25",
      "addressCode": "11004281",
      "model": "",
      "address": "CALLE INFANTAS 25, JUSTICIA, MADRID",
      "lat": 40.4201804,
      "lng": -3.6976622
  },
  {
      "id": "4796490",
      "description": "SITUADOS_APARCABICICLETAS - PASEO DE PLATA Y CASTA�AR 5",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "27/07/2018",
      "addressType": "Paseo",
      "addressStreet": "Plata y Castanar",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "Paseo Plata y Castanar , VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.350509,
      "lng": -3.7176651
  },
  {
      "id": "4796491",
      "description": "SITUADOS_APARCABICICLETAS - PASEO PLATA Y CASTA�AR 5",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "27/07/2018",
      "addressType": "PASEO",
      "addressStreet": "PLATA Y CASTA�AR",
      "addressNumber": "5",
      "addressCode": "20177287",
      "model": "",
      "address": "PASEO PLATA Y CASTA�AR 5, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3489535,
      "lng": -3.7174207
  },
  {
      "id": "4784618",
      "description": "SITUADOS_APARCABICICLETAS - CALLE SAN ZACARIAS 4",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "24/05/2018",
      "addressType": "Calle",
      "addressStreet": "San Zacar�as",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "Calle San Zacar�as , MOSCARDO, MADRID",
      "lat": 40.3899855,
      "lng": -3.7008198
  },
  {
      "id": "4784552",
      "description": "SITUADO_APARACABICICLETAS-AVENIDA DE LOS ROSALES 121",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "173",
      "neighborhoodName": "BUTARQUE",
      "state": "OPERATIVO",
      "installDate": "23/05/2018",
      "addressType": "AVENIDA",
      "addressStreet": "LOS ROSALES",
      "addressNumber": "121",
      "addressCode": "20176939",
      "model": "",
      "address": "AVENIDA LOS ROSALES 121, BUTARQUE, MADRID",
      "lat": 40.3532459,
      "lng": -3.6785137
  },
  {
      "id": "4782787",
      "description": "SITUADO_APARCABICICLETAS- COLEGIO P�BLICO CONCEPCI�N ARENAL",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "111",
      "neighborhoodName": "COMILLAS",
      "state": "OPERATIVO",
      "installDate": "25/04/2018",
      "addressType": "CALLE",
      "addressStreet": "ANTONIO LOPEZ",
      "addressNumber": "1",
      "addressCode": "11068914",
      "model": "",
      "address": "CALLE ANTONIO LOPEZ 1, COMILLAS, MADRID",
      "lat": 40.397251,
      "lng": -3.7151497
  },
  {
      "id": "4782785",
      "description": "SITUADO_APARCABICICLETAS-COLEGIO P�BLICO PAN BENDITO",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "117",
      "neighborhoodName": "ABRANTES",
      "state": "OPERATIVO",
      "installDate": "25/04/2018",
      "addressType": "CALLE",
      "addressStreet": "CAMINO VIEJO DE LEGANES",
      "addressNumber": "188",
      "addressCode": "31009842",
      "model": "",
      "address": "CALLE CAMINO VIEJO DE LEGANES 188, ABRANTES, MADRID",
      "lat": 40.3772348,
      "lng": -3.734424699999999
  },
  {
      "id": "4782788",
      "description": "SITUADOS_APARCABICICLETAS-CALLE VIA LUSITANA 1",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "117",
      "neighborhoodName": "ABRANTES",
      "state": "OPERATIVO",
      "installDate": "25/04/2018",
      "addressType": "CALLE",
      "addressStreet": "VIA LUSITANA",
      "addressNumber": "1",
      "addressCode": "11078650",
      "model": "",
      "address": "CALLE VIA LUSITANA 1, ABRANTES, MADRID",
      "lat": 40.3835585,
      "lng": -3.7207791
  },
  {
      "id": "4782433",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE ELISA OCHOA 2",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "20/04/2018",
      "addressType": "Calle de",
      "addressStreet": "Elisa Ochoa",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "Calle de Elisa Ochoa , PRADOLONGO, MADRID",
      "lat": 40.3810036,
      "lng": -3.7104834
  },
  {
      "id": "3848681",
      "description": "SITUADO_APARCABICICLETAS COLEGIO SENARA",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "146",
      "neighborhoodName": "VINATEROS",
      "state": "OPERATIVO",
      "installDate": "19/04/2018",
      "addressType": "AVENIDA",
      "addressStreet": "MORATALAZ",
      "addressNumber": "178B",
      "addressCode": "20181694",
      "model": "",
      "address": "AVENIDA MORATALAZ 178B, VINATEROS, MADRID",
      "lat": 40.4085338,
      "lng": -3.6432754
  },
  {
      "id": "4780568",
      "description": "SITUADOS_APARCABICICLETAS - ESCUELA DE F�TBOL DE CARABANCHEL",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "117",
      "neighborhoodName": "ABRANTES",
      "state": "OPERATIVO",
      "installDate": "18/04/2018",
      "addressType": "CALLE",
      "addressStreet": "VIA LUSITANA",
      "addressNumber": "19",
      "addressCode": "20024802",
      "model": "",
      "address": "CALLE VIA LUSITANA 19, ABRANTES, MADRID",
      "lat": 40.3770573,
      "lng": -3.7288408
  },
  {
      "id": "4767421",
      "description": "SITUADOS_APARCABICICLETAS - DENTRO PARQUE PRADOLONGO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "11/04/2018",
      "addressType": "Avenida",
      "addressStreet": "Poblados",
      "addressNumber": "169",
      "addressCode": "",
      "model": "",
      "address": "Avenida Poblados 169, PRADOLONGO, MADRID",
      "lat": 40.3723414,
      "lng": -3.7063673
  },
  {
      "id": "4767422",
      "description": "SITUADOS_APARCABICICLETAS - DENTRO PARQUE PRADOLONGO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "11/04/2018",
      "addressType": "Anillo",
      "addressStreet": "Verde Ciclista",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "Anillo Verde Ciclista , PRADOLONGO, MADRID",
      "lat": 40.3716662,
      "lng": -3.7066736
  },
  {
      "id": "4767423",
      "description": "SITUADOS_APARCABICICLETAS - DENTRO PARQUE PRADOLONGO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "11/04/2018",
      "addressType": "Avenida",
      "addressStreet": "Poblados",
      "addressNumber": "169",
      "addressCode": "",
      "model": "",
      "address": "Avenida Poblados 169, PRADOLONGO, MADRID",
      "lat": 40.3723414,
      "lng": -3.7063673
  },
  {
      "id": "4749607",
      "description": "SITUADOS_APARCABICICLETAS - AVENIDA DE LOS ROSALES 135",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "173",
      "neighborhoodName": "BUTARQUE",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "AVENIDA",
      "addressStreet": "LOS ROSALES",
      "addressNumber": "135",
      "addressCode": "31045500",
      "model": "",
      "address": "AVENIDA LOS ROSALES 135, BUTARQUE, MADRID",
      "lat": 40.3495218,
      "lng": -3.6759147
  },
  {
      "id": "4749612",
      "description": "SITUADOS_APARCABICICLETAS - DENTRO PARQUE PLATA Y CASTA�AR",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "Paseo",
      "addressStreet": "Plata y Castanar",
      "addressNumber": "5",
      "addressCode": "",
      "model": "",
      "address": "Paseo Plata y Castanar 5, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3489535,
      "lng": -3.7174207
  },
  {
      "id": "4749613",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA CANCION DEL OLVIDO 46",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "CALLE",
      "addressStreet": "LA CANCION DEL OLVIDO",
      "addressNumber": "46",
      "addressCode": "11129066",
      "model": "",
      "address": "CALLE LA CANCION DEL OLVIDO 46, LOS ANGELES, MADRID",
      "lat": 40.3606365,
      "lng": -3.6999647
  },
  {
      "id": "4749614",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA CANCION DEL OLVIDO 42",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "CALLE",
      "addressStreet": "LA CANCION DEL OLVIDO",
      "addressNumber": "42",
      "addressCode": "11129064",
      "model": "",
      "address": "CALLE LA CANCION DEL OLVIDO 42, LOS ANGELES, MADRID",
      "lat": 40.3607992,
      "lng": -3.7005393
  },
  {
      "id": "4749616",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE EL LUCERO DEL ALBA 4",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "CALLE",
      "addressStreet": "EL LUCERO DEL ALBA",
      "addressNumber": "4",
      "addressCode": "31052246",
      "model": "",
      "address": "CALLE EL LUCERO DEL ALBA 4, LOS ANGELES, MADRID",
      "lat": 40.3591244,
      "lng": -3.7017002
  },
  {
      "id": "4749618",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA PICARA MOLINERA 36",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "CALLE",
      "addressStreet": "LA PICARA MOLINERA",
      "addressNumber": "36",
      "addressCode": "31048328",
      "model": "",
      "address": "CALLE LA PICARA MOLINERA 36, LOS ANGELES, MADRID",
      "lat": 40.3570165,
      "lng": -3.7022293
  },
  {
      "id": "4749619",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA PICARA MOLINERA 52",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "CALLE",
      "addressStreet": "EL SANTO DE LA ISIDRA",
      "addressNumber": "16",
      "addressCode": "31055464",
      "model": "",
      "address": "CALLE EL SANTO DE LA ISIDRA 16, LOS ANGELES, MADRID",
      "lat": 40.35432309999999,
      "lng": -3.7038648
  },
  {
      "id": "4749620",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA PICARA MOLINERA 72",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "CALLE",
      "addressStreet": "LA PICARA MOLINERA",
      "addressNumber": "72",
      "addressCode": "31043800",
      "model": "",
      "address": "CALLE LA PICARA MOLINERA 72, LOS ANGELES, MADRID",
      "lat": 40.3528647,
      "lng": -3.704114
  },
  {
      "id": "4749626",
      "description": "SITUADOS_APARCABICICLETAS - POLIDEPORTIVO MUNICIPAL IDB EL ESPINILLO 2",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "03/04/2018",
      "addressType": "CALLE",
      "addressStreet": "CONSENSO",
      "addressNumber": "5",
      "addressCode": "20150027",
      "model": "",
      "address": "CALLE CONSENSO 5, LOS ROSALES, MADRID",
      "lat": 40.3606887,
      "lng": -3.6861612
  },
  {
      "id": "4746836",
      "description": "SITUADO_APARCABICICLETAS - CENTRO DE BACHILLERATO VALDECAS",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "15/03/2018",
      "addressType": "CALLE",
      "addressStreet": "JAVIER DE MIGUEL",
      "addressNumber": "32",
      "addressCode": "11094943",
      "model": "",
      "address": "CALLE JAVIER DE MIGUEL 32, PALOMERAS BAJAS, MADRID",
      "lat": 40.38793520000001,
      "lng": -3.6616231
  },
  {
      "id": "4746805",
      "description": "SITUADOS_APARCABICICLETAS - CALLE BOHEMIOS 16",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "14/03/2018",
      "addressType": "CALLE",
      "addressStreet": "LOS BOHEMIOS",
      "addressNumber": "16",
      "addressCode": "31009944",
      "model": "",
      "address": "CALLE LOS BOHEMIOS 16, LOS ANGELES, MADRID",
      "lat": 40.3565566,
      "lng": -3.6968172
  },
  {
      "id": "4747303",
      "description": "SITUADO_APARCABICICLETAS - OFICINA DE ATENCION AL CIUDADANO",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "132",
      "neighborhoodName": "SAN DIEGO",
      "state": "OPERATIVO",
      "installDate": "12/03/2018",
      "addressType": "AVENIDA",
      "addressStreet": "ALBUFERA",
      "addressNumber": "42",
      "addressCode": "11091966",
      "model": "",
      "address": "AVENIDA ALBUFERA 42, SAN DIEGO, MADRID",
      "lat": 40.3965108,
      "lng": -3.6664548
  },
  {
      "id": "4767401",
      "description": "SITUADOS_APARCABICICLETAS - POLIDEPORTIVO MUNICIPAL DE ORCASITAS",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "07/03/2018",
      "addressType": "AVENIDA",
      "addressStreet": "RAFAELA YBARRA",
      "addressNumber": "85",
      "addressCode": "20153855",
      "model": "",
      "address": "AVENIDA RAFAELA YBARRA 85, PRADOLONGO, MADRID",
      "lat": 40.375583,
      "lng": -3.7119542
  },
  {
      "id": "4745164",
      "description": "SITUADOS_APARCABICICLETAS - METRO SAN CRISTOBAL",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "27/02/2018",
      "addressType": "CALLE",
      "addressStreet": "SAN DALMACIO",
      "addressNumber": "1G",
      "addressCode": "31023110",
      "model": "",
      "address": "CALLE SAN DALMACIO 1G, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3423035,
      "lng": -3.6933456
  },
  {
      "id": "4745171",
      "description": "SITUADOS_APARCABICICLETAS - ESTACI�N DE METRO LA PESETA SALIDA CALLE SALVADOR ALLENDE",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "27/02/2018",
      "addressType": "CALLE",
      "addressStreet": "SALVADOR ALLENDE",
      "addressNumber": "28",
      "addressCode": "31004667",
      "model": "",
      "address": "CALLE SALVADOR ALLENDE 28, BUENAVISTA, MADRID",
      "lat": 40.3631898,
      "lng": -3.757955299999999
  },
  {
      "id": "4741982",
      "description": "SITUADO_APARCABICICLETAS - POLIDEPORTIVO MUNICIPAL ALBERTO GARCIA",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "14/02/2018",
      "addressType": "CALLE",
      "addressStreet": "REGUERA DE TOMATEROS",
      "addressNumber": "39B",
      "addressCode": "31012570",
      "model": "",
      "address": "CALLE REGUERA DE TOMATEROS 39B, ENTREVIAS, MADRID",
      "lat": 40.3718598,
      "lng": -3.6568064
  },
  {
      "id": "4741424",
      "description": "SITUADO_APARCABICICLETAS - BIBLIOTECA PUBLICA POZO DEL TIO RAIMUNDO",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "13/02/2018",
      "addressType": "CALLE",
      "addressStreet": "VECINOS DEL POZO",
      "addressNumber": "9",
      "addressCode": "31049971",
      "model": "",
      "address": "CALLE VECINOS DEL POZO 9, ENTREVIAS, MADRID",
      "lat": 40.3751646,
      "lng": -3.6597453
  },
  {
      "id": "4741431",
      "description": "SITUADO_APARCABICICLETAS- ESTACION CERCANIAS ASAMBLEA MADRID-ENTREVIAS",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "132",
      "neighborhoodName": "SAN DIEGO",
      "state": "OPERATIVO",
      "installDate": "12/02/2018",
      "addressType": "CALLE",
      "addressStreet": "PUERTO DE BALBARAN",
      "addressNumber": "2",
      "addressCode": "31049523",
      "model": "",
      "address": "CALLE PUERTO DE BALBARAN 2, SAN DIEGO, MADRID",
      "lat": 40.3785833,
      "lng": -3.6627758
  },
  {
      "id": "3913486",
      "description": "SITUADO APARCABICICLETAS - CALLE FINA DE CALDERON 68",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FINA DE CALDERON",
      "addressNumber": "68",
      "addressCode": "31039808",
      "model": "",
      "address": "CALLE FINA DE CALDERON 68, VALDEFUENTES, MADRID",
      "lat": 40.49774,
      "lng": -3.61781
  },
  {
      "id": "3913489",
      "description": "SITUADO APARCABICICLETAS - CALLE FINA DE CALDERON 34",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FINA DE CALDERON",
      "addressNumber": "34",
      "addressCode": "31043349",
      "model": "",
      "address": "CALLE FINA DE CALDERON 34, VALDEFUENTES, MADRID",
      "lat": 40.4956288,
      "lng": -3.617906899999999
  },
  {
      "id": "3912931",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE ANTONIO CODERCH 59",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JOSE ANTONIO CODERCH",
      "addressNumber": "59",
      "addressCode": "31039798",
      "model": "",
      "address": "CALLE JOSE ANTONIO CODERCH 59, VALDEFUENTES, MADRID",
      "lat": 40.4953285,
      "lng": -3.6216467
  },
  {
      "id": "3912936",
      "description": "SITUADO APARCABICICLETAS - CALLE FELIX CANDELA 14",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FELIX CANDELA",
      "addressNumber": "14",
      "addressCode": "31038954",
      "model": "",
      "address": "CALLE FELIX CANDELA 14, VALDEFUENTES, MADRID",
      "lat": 40.49674,
      "lng": -3.6225
  },
  {
      "id": "3912941",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 15",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "15",
      "addressCode": "31039567",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 15, VALDEFUENTES, MADRID",
      "lat": 40.495701,
      "lng": -3.622565
  },
  {
      "id": "3912945",
      "description": "SITUADO APARCABICICLETAS - CALLE LUIS MARTINEZ FEDUCHI 64",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "LUIS MARTINEZ FEDUCHI",
      "addressNumber": "64",
      "addressCode": "31042500",
      "model": "",
      "address": "CALLE LUIS MARTINEZ FEDUCHI 64, VALDEFUENTES, MADRID",
      "lat": 40.5009219,
      "lng": -3.641770799999999
  },
  {
      "id": "3912950",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA DE LAS MERCEDES DE BORBON 228",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA DE LAS MERCEDES DE BORBON",
      "addressNumber": "228",
      "addressCode": "31045033",
      "model": "",
      "address": "CALLE MARIA DE LAS MERCEDES DE BORBON 228, VALDEFUENTES, MADRID",
      "lat": 40.493071,
      "lng": -3.620959
  },
  {
      "id": "3912955",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 42",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "42",
      "addressCode": "31041155",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 42, VALDEFUENTES, MADRID",
      "lat": 40.4951793,
      "lng": -3.6196127
  },
  {
      "id": "3913492",
      "description": "SITUADO APARCABICICLETAS - CALLE LUIS MARTINEZ FEDUCHI 96",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "LUIS MARTINEZ FEDUCHI",
      "addressNumber": "96",
      "addressCode": "31041752",
      "model": "",
      "address": "CALLE LUIS MARTINEZ FEDUCHI 96, VALDEFUENTES, MADRID",
      "lat": 40.4999307,
      "lng": -3.6438367
  },
  {
      "id": "3913494",
      "description": "SITUADO APARCABICICLETAS - CAMINO HUERTA 25",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CAMINO",
      "addressStreet": "HUERTA",
      "addressNumber": "25",
      "addressCode": "20178427",
      "model": "",
      "address": "CAMINO HUERTA 25, VALDEFUENTES, MADRID",
      "lat": 40.5077576,
      "lng": -3.6440343
  },
  {
      "id": "3913497",
      "description": "SITUADO APARCABICICLETAS - CALLE FINA DE CALDERON 14",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FINA DE CALDERON",
      "addressNumber": "14",
      "addressCode": "31047672",
      "model": "",
      "address": "CALLE FINA DE CALDERON 14, VALDEFUENTES, MADRID",
      "lat": 40.4943899,
      "lng": -3.6170707
  },
  {
      "id": "3913500",
      "description": "SITUADO APARCABICICLETAS - CALLE FINA DE CALDERON 8",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FINA DE CALDERON",
      "addressNumber": "8",
      "addressCode": "31039805",
      "model": "",
      "address": "CALLE FINA DE CALDERON 8, VALDEFUENTES, MADRID",
      "lat": 40.49438480000001,
      "lng": -3.6178965
  },
  {
      "id": "3913503",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA DE LAS MERCEDES DE BORBON 190",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA DE LAS MERCEDES DE BORBON",
      "addressNumber": "190",
      "addressCode": "31052866",
      "model": "",
      "address": "CALLE MARIA DE LAS MERCEDES DE BORBON 190, VALDEFUENTES, MADRID",
      "lat": 40.4929139,
      "lng": -3.6178766
  },
  {
      "id": "3913506",
      "description": "SITUADO APARCABICICLETAS - CALLE LUIS MOYA BLANCO 75",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "LUIS MOYA BLANCO",
      "addressNumber": "75",
      "addressCode": "31039813",
      "model": "",
      "address": "CALLE LUIS MOYA BLANCO 75, VALDEFUENTES, MADRID",
      "lat": 40.4982094,
      "lng": -3.6161521
  },
  {
      "id": "3913509",
      "description": "SITUADO APARCABICICLETAS - CALLE LUIS MOYA BLANCO 78",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "LUIS MOYA BLANCO",
      "addressNumber": "78",
      "addressCode": "31039812",
      "model": "",
      "address": "CALLE LUIS MOYA BLANCO 78, VALDEFUENTES, MADRID",
      "lat": 40.498211,
      "lng": -3.6159356
  },
  {
      "id": "3913511",
      "description": "SITUADO APARCABICICLETAS - AVENIDA FRANCISCO JAVIER SAENZ DE OIZA 162",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "FRANCISCO JAVIER SAENZ DE OIZA",
      "addressNumber": "162",
      "addressCode": "31041492",
      "model": "",
      "address": "AVENIDA FRANCISCO JAVIER SAENZ DE OIZA 162, VALDEFUENTES, MADRID",
      "lat": 40.4983734,
      "lng": -3.615208
  },
  {
      "id": "3913514",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 99",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "99",
      "addressCode": "31039918",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 99, VALDEFUENTES, MADRID",
      "lat": 40.4953647,
      "lng": -3.6138505
  },
  {
      "id": "3913517",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 102",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "102",
      "addressCode": "31042950",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 102, VALDEFUENTES, MADRID",
      "lat": 40.4952885,
      "lng": -3.613853
  },
  {
      "id": "3913520",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSEFINA ALDECOA 8",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JOSEFINA ALDECOA",
      "addressNumber": "8",
      "addressCode": "31039817",
      "model": "",
      "address": "CALLE JOSEFINA ALDECOA 8, VALDEFUENTES, MADRID",
      "lat": 40.4936408,
      "lng": -3.6135572
  },
  {
      "id": "3913524",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA DE LAS MERCEDES DE BORBON 120",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA DE LAS MERCEDES DE BORBON",
      "addressNumber": "120",
      "addressCode": "31050576",
      "model": "",
      "address": "CALLE MARIA DE LAS MERCEDES DE BORBON 120, VALDEFUENTES, MADRID",
      "lat": 40.4931177,
      "lng": -3.6137343
  },
  {
      "id": "3913529",
      "description": "SITUADO APARCABICICLETAS - CALLE LEANDRO SILVA 29",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "LEANDRO SILVA",
      "addressNumber": "29",
      "addressCode": "31038914",
      "model": "",
      "address": "CALLE LEANDRO SILVA 29, VALDEFUENTES, MADRID",
      "lat": 40.4981709,
      "lng": -3.6092706
  },
  {
      "id": "3913526",
      "description": "SITUADO APARCABICICLETAS - AVENIDA FRANCISCO JAVIER SAENZ DE OIZA 242",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "FRANCISCO JAVIER SAENZ DE OIZA",
      "addressNumber": "242",
      "addressCode": "31049466",
      "model": "",
      "address": "AVENIDA FRANCISCO JAVIER SAENZ DE OIZA 242, VALDEFUENTES, MADRID",
      "lat": 40.5000814,
      "lng": -3.6109296
  },
  {
      "id": "3913532",
      "description": "SITUADO APARCABICICLETAS - CALLE LEANDRO SILVA 29",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "LEANDRO SILVA",
      "addressNumber": "29",
      "addressCode": "31038914",
      "model": "",
      "address": "CALLE LEANDRO SILVA 29, VALDEFUENTES, MADRID",
      "lat": 40.4981709,
      "lng": -3.6092706
  },
  {
      "id": "3913535",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 134",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "134",
      "addressCode": "31039692",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 134, VALDEFUENTES, MADRID",
      "lat": 40.49476,
      "lng": -3.61052
  },
  {
      "id": "3913537",
      "description": "SITUADO APARCABICICLETAS - CALLE JORDI SOLE TURA 8",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JORDI SOLE TURA",
      "addressNumber": "8",
      "addressCode": "31039531",
      "model": "",
      "address": "CALLE JORDI SOLE TURA 8, VALDEFUENTES, MADRID",
      "lat": 40.49313,
      "lng": -3.61069
  },
  {
      "id": "3914117",
      "description": "SITUADO APARCABICICLETAS - CALLE JORDI SOLE TURA 26",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JORDI SOLE TURA",
      "addressNumber": "26",
      "addressCode": "31051935",
      "model": "",
      "address": "CALLE JORDI SOLE TURA 26, VALDEFUENTES, MADRID",
      "lat": 40.492647,
      "lng": -3.6095504
  },
  {
      "id": "3914125",
      "description": "SITUADO APARCABICICLETAS - CALLE JULIO CANO LASSO 47",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JULIO CANO LASSO",
      "addressNumber": "47",
      "addressCode": "31040778",
      "model": "",
      "address": "CALLE JULIO CANO LASSO 47, VALDEFUENTES, MADRID",
      "lat": 40.4974986,
      "lng": -3.604517099999999
  },
  {
      "id": "3914138",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 25",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JOSE ANTONIO FERNANDEZ ORDO�EZ",
      "addressNumber": "25",
      "addressCode": "31039111",
      "model": "",
      "address": "CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 25, VALDEFUENTES, MADRID",
      "lat": 40.4914366,
      "lng": -3.6080756
  },
  {
      "id": "3914142",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 35",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JOSE ANTONIO FERNANDEZ ORDO�EZ",
      "addressNumber": "35",
      "addressCode": "31039125",
      "model": "",
      "address": "CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 35, VALDEFUENTES, MADRID",
      "lat": 40.491736,
      "lng": -3.608102
  },
  {
      "id": "3914152",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTANISLAO PEREZ PITA 7",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "ESTANISLAO PEREZ PITA",
      "addressNumber": "7",
      "addressCode": "31039165",
      "model": "",
      "address": "CALLE ESTANISLAO PEREZ PITA 7, VALDEFUENTES, MADRID",
      "lat": 40.4888829,
      "lng": -3.6102171
  },
  {
      "id": "3914164",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTANISLAO PEREZ PITA 56",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "ESTANISLAO PEREZ PITA",
      "addressNumber": "56",
      "addressCode": "31043621",
      "model": "",
      "address": "CALLE ESTANISLAO PEREZ PITA 56, TIMON, MADRID",
      "lat": 40.4887665,
      "lng": -3.6092613
  },
  {
      "id": "3914171",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTANISLAO PEREZ PITA 66",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "ESTANISLAO PEREZ PITA",
      "addressNumber": "66",
      "addressCode": "31039921",
      "model": "",
      "address": "CALLE ESTANISLAO PEREZ PITA 66, TIMON, MADRID",
      "lat": 40.4888984,
      "lng": -3.606942
  },
  {
      "id": "3914181",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 7",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "7",
      "addressCode": "31039242",
      "model": "",
      "address": "CALLE MARIA REICHE 7, VALDEFUENTES, MADRID",
      "lat": 40.4884594,
      "lng": -3.6135665
  },
  {
      "id": "3914193",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 29",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "29",
      "addressCode": "31039266",
      "model": "",
      "address": "CALLE MARIA REICHE 29, TIMON, MADRID",
      "lat": 40.48758,
      "lng": -3.6117434
  },
  {
      "id": "3914209",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 34",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "34",
      "addressCode": "31039279",
      "model": "",
      "address": "CALLE MARIA REICHE 34, TIMON, MADRID",
      "lat": 40.48742,
      "lng": -3.61103
  },
  {
      "id": "3914214",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 48",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "48",
      "addressCode": "31039294",
      "model": "",
      "address": "CALLE MARIA REICHE 48, TIMON, MADRID",
      "lat": 40.48743,
      "lng": -3.60977
  },
  {
      "id": "3914216",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 70",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "70",
      "addressCode": "31039314",
      "model": "",
      "address": "CALLE MARIA REICHE 70, TIMON, MADRID",
      "lat": 40.4874328,
      "lng": -3.6090454
  },
  {
      "id": "3914220",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 81",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "81",
      "addressCode": "31039323",
      "model": "",
      "address": "CALLE MARIA REICHE 81, TIMON, MADRID",
      "lat": 40.4875971,
      "lng": -3.6089267
  },
  {
      "id": "3914227",
      "description": "SITUADO APARCABICICLETAS - CALLE FERNANDO HIGUERAS 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FERNANDO HIGUERAS",
      "addressNumber": "1",
      "addressCode": "31040087",
      "model": "",
      "address": "CALLE FERNANDO HIGUERAS 1, VALDEFUENTES, MADRID",
      "lat": 40.4862813,
      "lng": -3.6124067
  },
  {
      "id": "3914121",
      "description": "SITUADO APARCABICICLETAS - CALLE JORDI SOLE TURA 13",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JORDI SOLE TURA",
      "addressNumber": "13",
      "addressCode": "31039542",
      "model": "",
      "address": "CALLE JORDI SOLE TURA 13, VALDEFUENTES, MADRID",
      "lat": 40.4933263,
      "lng": -3.6101783
  },
  {
      "id": "3914129",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 7",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JOSE ANTONIO FERNANDEZ ORDO�EZ",
      "addressNumber": "7",
      "addressCode": "31039096",
      "model": "",
      "address": "CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 7, VALDEFUENTES, MADRID",
      "lat": 40.4914366,
      "lng": -3.6080756
  },
  {
      "id": "3914134",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 8",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JOSE ANTONIO FERNANDEZ ORDO�EZ",
      "addressNumber": "8",
      "addressCode": "31039920",
      "model": "",
      "address": "CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 8, VALDEFUENTES, MADRID",
      "lat": 40.4914366,
      "lng": -3.6080756
  },
  {
      "id": "3914147",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 65",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "JOSE ANTONIO FERNANDEZ ORDO�EZ",
      "addressNumber": "65",
      "addressCode": "31039158",
      "model": "",
      "address": "CALLE JOSE ANTONIO FERNANDEZ ORDO�EZ 65, TIMON, MADRID",
      "lat": 40.4914385,
      "lng": -3.6071976
  },
  {
      "id": "3914158",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTANISLAO PEREZ PITA 28",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "ESTANISLAO PEREZ PITA",
      "addressNumber": "28",
      "addressCode": "31041163",
      "model": "",
      "address": "CALLE ESTANISLAO PEREZ PITA 28, TIMON, MADRID",
      "lat": 40.4887383,
      "lng": -3.6098451
  },
  {
      "id": "3914176",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTANISLAO PEREZ PITA 71",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "ESTANISLAO PEREZ PITA",
      "addressNumber": "71",
      "addressCode": "31039237",
      "model": "",
      "address": "CALLE ESTANISLAO PEREZ PITA 71, TIMON, MADRID",
      "lat": 40.4888987,
      "lng": -3.606821699999999
  },
  {
      "id": "3914186",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 10",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "10",
      "addressCode": "31043294",
      "model": "",
      "address": "CALLE MARIA REICHE 10, VALDEFUENTES, MADRID",
      "lat": 40.488397,
      "lng": -3.614132799999999
  },
  {
      "id": "3914213",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTANISLAO PEREZ PITA 44",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "ESTANISLAO PEREZ PITA",
      "addressNumber": "44",
      "addressCode": "31041171",
      "model": "",
      "address": "CALLE ESTANISLAO PEREZ PITA 44, TIMON, MADRID",
      "lat": 40.488552,
      "lng": -3.610183
  },
  {
      "id": "3914215",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 69",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "69",
      "addressCode": "31039308",
      "model": "",
      "address": "CALLE MARIA REICHE 69, TIMON, MADRID",
      "lat": 40.4875889,
      "lng": -3.6102463
  },
  {
      "id": "3914223",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA REICHE 86",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "MARIA REICHE",
      "addressNumber": "86",
      "addressCode": "31039330",
      "model": "",
      "address": "CALLE MARIA REICHE 86, TIMON, MADRID",
      "lat": 40.4874332,
      "lng": -3.6089993
  },
  {
      "id": "3914392",
      "description": "SITUADO APARCABICICLETAS - AVENIDA JUAN ANTONIO SAMARANCH 33",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "JUAN ANTONIO SAMARANCH",
      "addressNumber": "33",
      "addressCode": "31042740",
      "model": "",
      "address": "AVENIDA JUAN ANTONIO SAMARANCH 33, VALDEFUENTES, MADRID",
      "lat": 40.4854936,
      "lng": -3.6151201
  },
  {
      "id": "3914398",
      "description": "SITUADO APARCABICICLETAS - CALLE FERNANDO HIGUERAS 69",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FERNANDO HIGUERAS",
      "addressNumber": "69",
      "addressCode": "31042716",
      "model": "",
      "address": "CALLE FERNANDO HIGUERAS 69, TIMON, MADRID",
      "lat": 40.4863091,
      "lng": -3.6074105
  },
  {
      "id": "3914406",
      "description": "SITUADO APARCABICICLETAS - CALLE FERNANDO HIGUERAS 105",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FERNANDO HIGUERAS",
      "addressNumber": "105",
      "addressCode": "31039443",
      "model": "",
      "address": "CALLE FERNANDO HIGUERAS 105, TIMON, MADRID",
      "lat": 40.4860106,
      "lng": -3.6063414
  },
  {
      "id": "3914411",
      "description": "SITUADO APARCABICICLETAS - AVENIDA LAS FUERZAS ARMADAS 5",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "LAS FUERZAS ARMADAS",
      "addressNumber": "5",
      "addressCode": "31035488",
      "model": "",
      "address": "AVENIDA LAS FUERZAS ARMADAS 5, VALDEFUENTES, MADRID",
      "lat": 40.4832326,
      "lng": -3.619564
  },
  {
      "id": "3914421",
      "description": "SITUADO APARCABICICLETAS - AVENIDA LAS FUERZAS ARMADAS 9",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "LAS FUERZAS ARMADAS",
      "addressNumber": "9",
      "addressCode": "31035600",
      "model": "",
      "address": "AVENIDA LAS FUERZAS ARMADAS 9, TIMON, MADRID",
      "lat": 40.4749331,
      "lng": -3.6004132
  },
  {
      "id": "3914394",
      "description": "SITUADO APARCABICICLETAS - CALLE FERNANDO HIGUERAS 69",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FERNANDO HIGUERAS",
      "addressNumber": "69",
      "addressCode": "31042716",
      "model": "",
      "address": "CALLE FERNANDO HIGUERAS 69, TIMON, MADRID",
      "lat": 40.4863091,
      "lng": -3.6074105
  },
  {
      "id": "3914402",
      "description": "SITUADO APARCABICICLETAS - CALLE FERNANDO HIGUERAS 85",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "CALLE",
      "addressStreet": "FERNANDO HIGUERAS",
      "addressNumber": "85",
      "addressCode": "31039415",
      "model": "",
      "address": "CALLE FERNANDO HIGUERAS 85, TIMON, MADRID",
      "lat": 40.4863091,
      "lng": -3.6074105
  },
  {
      "id": "3914416",
      "description": "SITUADO APARCABICICLETAS - AVENIDA ALEJANDRO DE LA SOTA 6",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "ALEJANDRO DE LA SOTA",
      "addressNumber": "6",
      "addressCode": "31042733",
      "model": "",
      "address": "AVENIDA ALEJANDRO DE LA SOTA 6, VALDEFUENTES, MADRID",
      "lat": 40.4783938,
      "lng": -3.6185332
  },
  {
      "id": "3914428",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 80",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/02/2018",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "80",
      "addressCode": "31039636",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 80, VALDEFUENTES, MADRID",
      "lat": 40.49537,
      "lng": -3.615659999999999
  },
  {
      "id": "4740430",
      "description": "SITUADO_ APARCABICICLETAS - AVENIDA DEL PARQUE DE PALOMERAS BAJAS, 10",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "31/01/2018",
      "addressType": "AVENIDA",
      "addressStreet": "PARQUE DE PALOMERAS BAJAS",
      "addressNumber": "10",
      "addressCode": "20080274",
      "model": "",
      "address": "AVENIDA PARQUE DE PALOMERAS BAJAS 10, PALOMERAS BAJAS, MADRID",
      "lat": 40.3812345,
      "lng": -3.6653359
  },
  {
      "id": "4732942",
      "description": "SITUADOS_APARCABICICLETAS - ROC 30 ROC�DROMO DE MADRID",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "113",
      "neighborhoodName": "SAN ISIDRO",
      "state": "OPERATIVO",
      "installDate": "12/01/2018",
      "addressType": "PASEO",
      "addressStreet": "SAN ILLAN",
      "addressNumber": "10",
      "addressCode": "31045280",
      "model": "",
      "address": "PASEO SAN ILLAN 10, SAN ISIDRO, MADRID",
      "lat": 40.4015595,
      "lng": -3.724295199999999
  },
  {
      "id": "3965609",
      "description": "SITUADOS_APARCABICICLETAS - AUTOV�A DEL SUR",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "173",
      "neighborhoodName": "BUTARQUE",
      "state": "OPERATIVO",
      "installDate": "18/12/2017",
      "addressType": "AVENIDA",
      "addressStreet": "LOS ROSALES",
      "addressNumber": "129",
      "addressCode": "31051872",
      "model": "",
      "address": "AVENIDA LOS ROSALES 129, BUTARQUE, MADRID",
      "lat": 40.3517658,
      "lng": -3.6778236
  },
  {
      "id": "3844950",
      "description": "SITUADOS_APARCABICICLETAS - EMPRESA MUNICIPAL DE TRANSPORTES DE MADRID, S.A.",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "07/12/2017",
      "addressType": "AVENIDA",
      "addressStreet": "POBLADOS",
      "addressNumber": "122",
      "addressCode": "31007172",
      "model": "",
      "address": "AVENIDA POBLADOS 122, BUENAVISTA, MADRID",
      "lat": 40.37346720000001,
      "lng": -3.7223247
  },
  {
      "id": "3579662",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL CONGOSTO, 78",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "183",
      "neighborhoodName": "ENSANCHE DE VALLECAS",
      "state": "OPERATIVO",
      "installDate": "17/11/2017",
      "addressType": "AVENIDA",
      "addressStreet": "CERRO MILANO",
      "addressNumber": "3",
      "addressCode": "31029806",
      "model": "",
      "address": "AVENIDA CERRO MILANO 3, ENSANCHE DE VALLECAS, MADRID",
      "lat": 40.3680825,
      "lng": -3.6172448
  },
  {
      "id": "3577914",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE SAN CIPRIANO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "16/11/2017",
      "addressType": "AVENIDA",
      "addressStreet": "DAROCA",
      "addressNumber": "327",
      "addressCode": "31010246",
      "model": "",
      "address": "AVENIDA DAROCA 327, CASCO H.VICALVARO, MADRID",
      "lat": 40.4039975,
      "lng": -3.6093253
  },
  {
      "id": "3575644",
      "description": "SITUADOS_APARCABICICLETAS - CEIP MAESTRO PADILLA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "14/11/2017",
      "addressType": "AVENIDA",
      "addressStreet": "PESETA",
      "addressNumber": "112",
      "addressCode": "31040803",
      "model": "",
      "address": "AVENIDA PESETA 112, BUENAVISTA, MADRID",
      "lat": 40.3625836,
      "lng": -3.74704
  },
  {
      "id": "3575653",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DEL PATRIMONIO DE LA HUMANIDAD 37",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "14/11/2017",
      "addressType": "CALLE",
      "addressStreet": "PATRIMONIO DE LA HUMANIDAD",
      "addressNumber": "37",
      "addressCode": "31034585",
      "model": "",
      "address": "CALLE PATRIMONIO DE LA HUMANIDAD 37, BUENAVISTA, MADRID",
      "lat": 40.3616998,
      "lng": -3.76327
  },
  {
      "id": "3569426",
      "description": "SITUADOS_APARCABICICLETAS - COLEGIO P�BLICO CIUDAD DE LOS �NGELES",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "02/11/2017",
      "addressType": "CALLE",
      "addressStreet": "LA ALEGRIA DE LA HUERTA",
      "addressNumber": "6",
      "addressCode": "11128504",
      "model": "",
      "address": "CALLE LA ALEGRIA DE LA HUERTA 6, LOS ANGELES, MADRID",
      "lat": 40.355622,
      "lng": -3.696122
  },
  {
      "id": "3569427",
      "description": "SITUADOS_APARCABICICLETAS - PASEO DE ALBERTO PALACIOS 15",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "02/11/2017",
      "addressType": "PASEO",
      "addressStreet": "ALBERTO PALACIOS",
      "addressNumber": "15",
      "addressCode": "11124234",
      "model": "",
      "address": "PASEO ALBERTO PALACIOS 15, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3459361,
      "lng": -3.7108885
  },
  {
      "id": "3569428",
      "description": "SITUADOS_APARCABICICLETAS - JUNTA MUNICIPAL DEL DISTRITO DE VILLAVERDE",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "02/11/2017",
      "addressType": "CALLE",
      "addressStreet": "ARROYO BUENO",
      "addressNumber": "53",
      "addressCode": "11124899",
      "model": "",
      "address": "CALLE ARROYO BUENO 53, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.34881,
      "lng": -3.71118
  },
  {
      "id": "3569429",
      "description": "SITUADOS_APARCABICICLETAS - I.E.S. EL ESPINILLO",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "02/11/2017",
      "addressType": "CALLE",
      "addressStreet": "UNANIMIDAD",
      "addressNumber": "13",
      "addressCode": "31018923",
      "model": "",
      "address": "CALLE UNANIMIDAD 13, LOS ROSALES, MADRID",
      "lat": 40.361589,
      "lng": -3.6888765
  },
  {
      "id": "3569430",
      "description": "SITUADO_APARCABICICLETAS - Calle de Puerto Serrano, 3",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "136",
      "neighborhoodName": "NUMANCIA",
      "state": "OPERATIVO",
      "installDate": "02/11/2017",
      "addressType": "CALLE",
      "addressStreet": "MARISMAS",
      "addressNumber": "14B",
      "addressCode": "11099283",
      "model": "",
      "address": "CALLE MARISMAS 14B, NUMANCIA, MADRID",
      "lat": 40.4018308,
      "lng": -3.6544157
  },
  {
      "id": "3575550",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA SIERRA VIEJA 62",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "26/10/2017",
      "addressType": "CALLE",
      "addressStreet": "SIERRA VIEJA",
      "addressNumber": "62",
      "addressCode": "31009833",
      "model": "",
      "address": "CALLE SIERRA VIEJA 62, CASCO H.VALLECAS, MADRID",
      "lat": 40.3784014,
      "lng": -3.623961599999999
  },
  {
      "id": "3569013",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA SILVINA 9",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "123",
      "neighborhoodName": "SAN FERMIN",
      "state": "OPERATIVO",
      "installDate": "24/10/2017",
      "addressType": "CALLE",
      "addressStreet": "SILVINA",
      "addressNumber": "9",
      "addressCode": "20141685",
      "model": "",
      "address": "CALLE SILVINA 9, SAN FERMIN, MADRID",
      "lat": 40.366346,
      "lng": -3.6909609
  },
  {
      "id": "3569014",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE ELISA OCHOA 2",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "24/10/2017",
      "addressType": "CALLE",
      "addressStreet": "ELISA OCHOA",
      "addressNumber": "2",
      "addressCode": "20111866",
      "model": "",
      "address": "CALLE ELISA OCHOA 2, PRADOLONGO, MADRID",
      "lat": 40.3812281,
      "lng": -3.709993099999999
  },
  {
      "id": "3565629",
      "description": "SITUADO APARCABICICLETAS - C/ TRIBULETE N�27",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/07/2017",
      "addressType": "CALLE",
      "addressStreet": "TRIBULETE",
      "addressNumber": "27",
      "addressCode": "11002261",
      "model": "",
      "address": "CALLE TRIBULETE 27, EMBAJADORES, MADRID",
      "lat": 40.4072301,
      "lng": -3.70363
  },
  {
      "id": "3565634",
      "description": "SITUADO APARCABICICLETAS - C/TRIBULETE N�17",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "10/07/2017",
      "addressType": "CALLE",
      "addressStreet": "TRIBULETE",
      "addressNumber": "17",
      "addressCode": "11002256",
      "model": "",
      "address": "CALLE TRIBULETE 17, EMBAJADORES, MADRID",
      "lat": 40.4076075,
      "lng": -3.7027924
  },
  {
      "id": "4745681",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�107",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "61",
      "neighborhoodName": "BELLAS VISTAS",
      "state": "OPERATIVO",
      "installDate": "03/10/2016",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "107",
      "addressCode": "11029075",
      "model": "",
      "address": "CALLE BRAVO MURILLO 107, BELLAS VISTAS, MADRID",
      "lat": 40.4479943,
      "lng": -3.705041
  },
  {
      "id": "4745682",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�107",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "61",
      "neighborhoodName": "BELLAS VISTAS",
      "state": "OPERATIVO",
      "installDate": "03/10/2016",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "107",
      "addressCode": "11029075",
      "model": "",
      "address": "CALLE BRAVO MURILLO 107, BELLAS VISTAS, MADRID",
      "lat": 40.4479943,
      "lng": -3.705041
  },
  {
      "id": "5072125",
      "description": "SITUADO APARCABICICLETAS - C/ VALLEHERMOSO N�83",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "22/02/2016",
      "addressType": "CALLE",
      "addressStreet": "VALLEHERMOSO",
      "addressNumber": "83",
      "addressCode": "20014750",
      "model": "",
      "address": "CALLE VALLEHERMOSO 83, VALLEHERMOSO, MADRID",
      "lat": 40.4391042,
      "lng": -3.70839
  },
  {
      "id": "5176400",
      "description": "SITUADO APARCABICICLETAS - CALLE PE�A DE FRANCIA N�16",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "02/02/2016",
      "addressType": "CALLE",
      "addressStreet": "PE�A DE FRANCIA",
      "addressNumber": "16",
      "addressCode": "11001970",
      "model": "",
      "address": "CALLE PE�A DE FRANCIA 16, EMBAJADORES, MADRID",
      "lat": 40.4065841,
      "lng": -3.7061308
  },
  {
      "id": "5176413",
      "description": "Situado Aparcabicicletas",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "02/02/2016",
      "addressType": "CALLE",
      "addressStreet": "CASINO",
      "addressNumber": "26",
      "addressCode": "31004027",
      "model": "",
      "address": "CALLE CASINO 26, EMBAJADORES, MADRID",
      "lat": 40.406419,
      "lng": -3.7068446
  },
  {
      "id": "5180197",
      "description": "SITUADO APARCABICICLETAS - C/ FELIPE IV",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "15/08/2013",
      "addressType": "CALLE",
      "addressStreet": "FELIPE V",
      "addressNumber": "2",
      "addressCode": "11000279",
      "model": "",
      "address": "CALLE FELIPE V 2, PALACIO, MADRID",
      "lat": 40.41870369999999,
      "lng": -3.710611
  },
  {
      "id": "4704601",
      "description": "SITUADO APARCABICICLETAS - CALLE NEVADO DEL RUIZ 4",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "NEVADO DEL RUIZ",
      "addressNumber": "4",
      "addressCode": "20155744",
      "model": "",
      "address": "CALLE NEVADO DEL RUIZ 4, PINAR DEL REY, MADRID",
      "lat": 40.4700558,
      "lng": -3.6349946
  },
  {
      "id": "4704599",
      "description": "SITUADO APARCABICICLETAS - CALLE LAS PEDRO�ERAS 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "LAS PEDRO?ERAS",
      "addressNumber": "1",
      "addressCode": "20055166",
      "model": "",
      "address": "CALLE LAS PEDRO?ERAS 1, CANILLAS, MADRID",
      "lat": 40.4642048,
      "lng": -3.6304023
  },
  {
      "id": "4704603",
      "description": "SITUADO APARCABICICLETAS - CALLE MAR MENOR 25",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "MAR MENOR",
      "addressNumber": "25",
      "addressCode": "11119370",
      "model": "",
      "address": "CALLE MAR MENOR 25, PINAR DEL REY, MADRID",
      "lat": 40.4757243,
      "lng": -3.644679199999999
  },
  {
      "id": "4704605",
      "description": "SITUADO APARCABICICLETAS - CARRETERA ESTACION DE HORTALEZA 18",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CARRETERA",
      "addressStreet": "ESTACION DE HORTALEZA",
      "addressNumber": "18",
      "addressCode": "31041861",
      "model": "",
      "address": "CARRETERA ESTACION DE HORTALEZA 18, PINAR DEL REY, MADRID",
      "lat": 40.4808186,
      "lng": -3.6502417
  },
  {
      "id": "4704606",
      "description": "SITUADO APARCABICICLETAS - CALLE CARRION DE LOS CONDES 21",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "165",
      "neighborhoodName": "APOSTOL SANTIAGO",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "CARRION DE LOS CONDES",
      "addressNumber": "21",
      "addressCode": "11121086",
      "model": "",
      "address": "CALLE CARRION DE LOS CONDES 21, APOSTOL SANTIAGO, MADRID",
      "lat": 40.4759952,
      "lng": -3.6584225
  },
  {
      "id": "4704607",
      "description": "SITUADO APARCABICICLETAS - CALLE ROCIO JURADO 44",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "ROCIO JURADO",
      "addressNumber": "44",
      "addressCode": "31044007",
      "model": "",
      "address": "CALLE ROCIO JURADO 44, VALDEFUENTES, MADRID",
      "lat": 40.4841133,
      "lng": -3.6304003
  },
  {
      "id": "4704609",
      "description": "SITUADO APARCABICICLETAS - CALLE HERMANOS GASCON 76",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "HERMANOS GASCON",
      "addressNumber": "76",
      "addressCode": "31052128",
      "model": "",
      "address": "CALLE HERMANOS GASCON 76, VALDEFUENTES, MADRID",
      "lat": 40.4833709,
      "lng": -3.6308883
  },
  {
      "id": "4704611",
      "description": "SITUADO APARCABICICLETAS - CALLE JUAN DANIEL FULLAONDO 15",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "JUAN DANIEL FULLAONDO",
      "addressNumber": "15",
      "addressCode": "31039883",
      "model": "",
      "address": "CALLE JUAN DANIEL FULLAONDO 15, VALDEFUENTES, MADRID",
      "lat": 40.48143,
      "lng": -3.62929
  },
  {
      "id": "4704612",
      "description": "SITUADO APARCABICICLETAS - AVENIDA LAS FUERZAS ARMADAS 206",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "LAS FUERZAS ARMADAS",
      "addressNumber": "206",
      "addressCode": "31052130",
      "model": "",
      "address": "AVENIDA LAS FUERZAS ARMADAS 206, VALDEFUENTES, MADRID",
      "lat": 40.483292,
      "lng": -3.627901
  },
  {
      "id": "4704614",
      "description": "SITUADO APARCABICICLETAS - CALLE FRANCISCO UMBRAL 59",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO UMBRAL",
      "addressNumber": "59",
      "addressCode": "31043244",
      "model": "",
      "address": "CALLE FRANCISCO UMBRAL 59, VALDEFUENTES, MADRID",
      "lat": 40.4810461,
      "lng": -3.6261023
  },
  {
      "id": "4704615",
      "description": "SITUADO APARCABICICLETAS - CALLE PROVISIONAL CARCAVAS OESTE UNO 18",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "PROVISIONAL CARCAVAS OESTE UNO",
      "addressNumber": "18",
      "addressCode": "31046860",
      "model": "",
      "address": "CALLE PROVISIONAL CARCAVAS OESTE UNO 18, VALDEFUENTES, MADRID",
      "lat": 40.47843,
      "lng": -3.6261679
  },
  {
      "id": "4704617",
      "description": "SITUADO APARCABICICLETAS - CALLE PROVISIONAL CARCAVAS OESTE UNO 70",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "PROVISIONAL CARCAVAS OESTE UNO",
      "addressNumber": "70",
      "addressCode": "31028245",
      "model": "",
      "address": "CALLE PROVISIONAL CARCAVAS OESTE UNO 70, VALDEFUENTES, MADRID",
      "lat": 40.4768817,
      "lng": -3.6266314
  },
  {
      "id": "4704618",
      "description": "SITUADO APARCABICICLETAS - CALLE PROVISIONAL CARCAVAS OESTE UNO 100",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "PROVISIONAL CARCAVAS OESTE UNO",
      "addressNumber": "100",
      "addressCode": "31028710",
      "model": "",
      "address": "CALLE PROVISIONAL CARCAVAS OESTE UNO 100, VALDEFUENTES, MADRID",
      "lat": 40.475697,
      "lng": -3.6266229
  },
  {
      "id": "4704620",
      "description": "SITUADO APARCABICICLETAS - PASEO DOCE ESTRELLAS 3",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "215",
      "neighborhoodName": "CORRALEJOS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "PASEO",
      "addressStreet": "DOCE ESTRELLAS",
      "addressNumber": "3",
      "addressCode": "20058245",
      "model": "",
      "address": "PASEO DOCE ESTRELLAS 3, CORRALEJOS, MADRID",
      "lat": 40.4617756,
      "lng": -3.6163484
  },
  {
      "id": "4704621",
      "description": "SITUADO APARCABICICLETAS - CALLE BAHIA DE MALAGA 20",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "215",
      "neighborhoodName": "CORRALEJOS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "BAHIA DE MALAGA",
      "addressNumber": "20",
      "addressCode": "31049849",
      "model": "",
      "address": "CALLE BAHIA DE MALAGA 20, CORRALEJOS, MADRID",
      "lat": 40.4678801,
      "lng": -3.5937682
  },
  {
      "id": "4704623",
      "description": "SITUADO APARCABICICLETAS - CALLE CATAMARAN 9",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "CATAMARAN",
      "addressNumber": "9",
      "addressCode": "20047353",
      "model": "",
      "address": "CALLE CATAMARAN 9, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4524478,
      "lng": -3.5948313
  },
  {
      "id": "4704624",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SUR DEL AEROPUERTO DE BARAJAS 36",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "212",
      "neighborhoodName": "AEROPUERTO",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "SUR DEL AEROPUERTO DE BARAJAS",
      "addressNumber": "36",
      "addressCode": "20167907",
      "model": "",
      "address": "AVENIDA SUR DEL AEROPUERTO DE BARAJAS 36, AEROPUERTO, MADRID",
      "lat": 40.4509767,
      "lng": -3.5771559
  },
  {
      "id": "4704626",
      "description": "SITUADO APARCABICICLETAS - PASEO GINEBRA 55",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "PASEO",
      "addressStreet": "GINEBRA",
      "addressNumber": "55",
      "addressCode": "20150424",
      "model": "",
      "address": "PASEO GINEBRA 55, ROSAS, MADRID",
      "lat": 40.423411,
      "lng": -3.6007367
  },
  {
      "id": "4704628",
      "description": "SITUADO APARCABICICLETAS - CALLE VIRGEN DE LA ALEGRIA 18",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "VIRGEN DE LA ALEGRIA",
      "addressNumber": "18",
      "addressCode": "20083532",
      "model": "",
      "address": "CALLE VIRGEN DE LA ALEGRIA 18, CONCEPCION, MADRID",
      "lat": 40.434876,
      "lng": -3.6555556
  },
  {
      "id": "4704629",
      "description": "SITUADO APARCABICICLETAS - CALLE VIRGEN DE NURIA 4",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "VIRGEN DE NURIA",
      "addressNumber": "4",
      "addressCode": "31044468",
      "model": "",
      "address": "CALLE VIRGEN DE NURIA 4, CONCEPCION, MADRID",
      "lat": 40.4368043,
      "lng": -3.6514583
  },
  {
      "id": "4704630",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE DEL HIERRO 1",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "153",
      "neighborhoodName": "QUINTANA",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "JOSE DEL HIERRO",
      "addressNumber": "1",
      "addressCode": "20094442",
      "model": "",
      "address": "CALLE JOSE DEL HIERRO 1, QUINTANA, MADRID",
      "lat": 40.4355455,
      "lng": -3.6519195
  },
  {
      "id": "4704632",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 160",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "160",
      "addressCode": "31022699",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 160, PUEBLO NUEVO, MADRID",
      "lat": 40.42396,
      "lng": -3.6285825
  },
  {
      "id": "4704634",
      "description": "SITUADO APARCABICICLETAS - CALLE VALENTIN BEATO 6",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "201",
      "neighborhoodName": "SIMANCAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "VALENTIN BEATO",
      "addressNumber": "6",
      "addressCode": "11133784",
      "model": "",
      "address": "CALLE VALENTIN BEATO 6, SIMANCAS, MADRID",
      "lat": 40.4402996,
      "lng": -3.6267277
  },
  {
      "id": "4704635",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SEPTIMA 13",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "SEPTIMA",
      "addressNumber": "13",
      "addressCode": "31051332",
      "model": "",
      "address": "AVENIDA SEPTIMA 13, REJAS, MADRID",
      "lat": 40.4484605,
      "lng": -3.5977919
  },
  {
      "id": "4704637",
      "description": "SITUADO APARCABICICLETAS - CALLE EUTERPE 27",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "EUTERPE",
      "addressNumber": "27",
      "addressCode": "31007808",
      "model": "",
      "address": "CALLE EUTERPE 27, REJAS, MADRID",
      "lat": 40.4438178,
      "lng": -3.5923022
  },
  {
      "id": "4704639",
      "description": "SITUADO APARCABICICLETAS - CALLE CAMPEZO 4",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "CAMPEZO",
      "addressNumber": "4",
      "addressCode": "11136499",
      "model": "",
      "address": "CALLE CAMPEZO 4, REJAS, MADRID",
      "lat": 40.4480119,
      "lng": -3.583206999999999
  },
  {
      "id": "4704643",
      "description": "SITUADO APARCABICICLETAS - CALLE FLORENCIO CASTILLO 29",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "162",
      "neighborhoodName": "PIOVERA",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "FLORENCIO CASTILLO",
      "addressNumber": "29",
      "addressCode": "20055058",
      "model": "",
      "address": "CALLE FLORENCIO CASTILLO 29, PIOVERA, MADRID",
      "lat": 40.4597328,
      "lng": -3.6226584
  },
  {
      "id": "4704644",
      "description": "SITUADO APARCABICICLETAS - PLAZA LICEO 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "162",
      "neighborhoodName": "PIOVERA",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "PLAZA",
      "addressStreet": "LICEO",
      "addressNumber": "1",
      "addressCode": "20178837",
      "model": "",
      "address": "PLAZA LICEO 1, PIOVERA, MADRID",
      "lat": 40.4574627,
      "lng": -3.643176
  },
  {
      "id": "4704646",
      "description": "SITUADO APARCABICICLETAS - AVENIDA AZALEAS 28",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "161",
      "neighborhoodName": "PALOMAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "AZALEAS",
      "addressNumber": "28",
      "addressCode": "11113147",
      "model": "",
      "address": "AVENIDA AZALEAS 28, PALOMAS, MADRID",
      "lat": 40.4533725,
      "lng": -3.6172027
  },
  {
      "id": "4704648",
      "description": "SITUADO APARCABICICLETAS - AVENIDA ANDES 52",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "161",
      "neighborhoodName": "PALOMAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "ANDES",
      "addressNumber": "52",
      "addressCode": "31014972",
      "model": "",
      "address": "AVENIDA ANDES 52, PALOMAS, MADRID",
      "lat": 40.4578298,
      "lng": -3.6171521
  },
  {
      "id": "4704600",
      "description": "SITUADO APARCABICICLETAS - CALLE ACONCAGUA 4",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "ACONCAGUA",
      "addressNumber": "4",
      "addressCode": "31017762",
      "model": "",
      "address": "CALLE ACONCAGUA 4, PINAR DEL REY, MADRID",
      "lat": 40.46826799999999,
      "lng": -3.6309617
  },
  {
      "id": "4704602",
      "description": "SITUADO APARCABICICLETAS - CALLE MAR DE LAS ANTILLAS 10",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "MAR DE LAS ANTILLAS",
      "addressNumber": "10",
      "addressCode": "31012936",
      "model": "",
      "address": "CALLE MAR DE LAS ANTILLAS 10, PINAR DEL REY, MADRID",
      "lat": 40.47493180000001,
      "lng": -3.6455053
  },
  {
      "id": "4704604",
      "description": "SITUADO APARCABICICLETAS - CALLE SANTA VIRGILIA 19",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "SANTA VIRGILIA",
      "addressNumber": "19",
      "addressCode": "31050802",
      "model": "",
      "address": "CALLE SANTA VIRGILIA 19, PINAR DEL REY, MADRID",
      "lat": 40.4770361,
      "lng": -3.641137399999999
  },
  {
      "id": "4704608",
      "description": "SITUADO APARCABICICLETAS - AVENIDA LAS FUERZAS ARMADAS 166",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "LAS FUERZAS ARMADAS",
      "addressNumber": "166",
      "addressCode": "31043330",
      "model": "",
      "address": "AVENIDA LAS FUERZAS ARMADAS 166, VALDEFUENTES, MADRID",
      "lat": 40.4843166,
      "lng": -3.629805599999999
  },
  {
      "id": "4704613",
      "description": "SITUADO APARCABICICLETAS - CALLE FRANCISCO UMBRAL 61",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO UMBRAL",
      "addressNumber": "61",
      "addressCode": "31043245",
      "model": "",
      "address": "CALLE FRANCISCO UMBRAL 61, VALDEFUENTES, MADRID",
      "lat": 40.4810461,
      "lng": -3.6261023
  },
  {
      "id": "4704610",
      "description": "SITUADO APARCABICICLETAS - CALLE MAESTRA DOLORES MARCO 10",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "MAESTRA DOLORES MARCO",
      "addressNumber": "10",
      "addressCode": "31039871",
      "model": "",
      "address": "CALLE MAESTRA DOLORES MARCO 10, VALDEFUENTES, MADRID",
      "lat": 40.4826181,
      "lng": -3.628581
  },
  {
      "id": "4704619",
      "description": "SITUADO APARCABICICLETAS - AVENIDA PARTENON 12",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "215",
      "neighborhoodName": "CORRALEJOS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "PARTENON",
      "addressNumber": "12",
      "addressCode": "20058479",
      "model": "",
      "address": "AVENIDA PARTENON 12, CORRALEJOS, MADRID",
      "lat": 40.4635095,
      "lng": -3.6154013
  },
  {
      "id": "4704622",
      "description": "SITUADO APARCABICICLETAS - PLAZA JUBILADO 6",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "213",
      "neighborhoodName": "CASCO H.BARAJAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "PLAZA",
      "addressStreet": "JUBILADO",
      "addressNumber": "6",
      "addressCode": "11143780",
      "model": "",
      "address": "PLAZA JUBILADO 6, CASCO H.BARAJAS, MADRID",
      "lat": 40.474173,
      "lng": -3.57684
  },
  {
      "id": "4704616",
      "description": "SITUADO APARCABICICLETAS - CALLE PROVISIONAL CARCAVAS OESTE UNO 38",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "PROVISIONAL CARCAVAS OESTE UNO",
      "addressNumber": "38",
      "addressCode": "31026279",
      "model": "",
      "address": "CALLE PROVISIONAL CARCAVAS OESTE UNO 38, VALDEFUENTES, MADRID",
      "lat": 40.4777577,
      "lng": -3.6262628
  },
  {
      "id": "4704627",
      "description": "SITUADO APARCABICICLETAS - CALLE VIRGEN DE AFRICA 11",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "VIRGEN DE AFRICA",
      "addressNumber": "11",
      "addressCode": "31015872",
      "model": "",
      "address": "CALLE VIRGEN DE AFRICA 11, SAN PASCUAL, MADRID",
      "lat": 40.4382724,
      "lng": -3.6542729
  },
  {
      "id": "4704625",
      "description": "SITUADO APARCABICICLETAS - PASEO GINEBRA 55",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "PASEO",
      "addressStreet": "GINEBRA",
      "addressNumber": "55",
      "addressCode": "20150424",
      "model": "",
      "address": "PASEO GINEBRA 55, ROSAS, MADRID",
      "lat": 40.423411,
      "lng": -3.6007367
  },
  {
      "id": "4704636",
      "description": "SITUADO APARCABICICLETAS - CALLE EUTERPE 27",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "EUTERPE",
      "addressNumber": "27",
      "addressCode": "31007808",
      "model": "",
      "address": "CALLE EUTERPE 27, REJAS, MADRID",
      "lat": 40.4438178,
      "lng": -3.5923022
  },
  {
      "id": "4704638",
      "description": "SITUADO APARCABICICLETAS - CALLE EUTERPE 5",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "EUTERPE",
      "addressNumber": "5",
      "addressCode": "31007801",
      "model": "",
      "address": "CALLE EUTERPE 5, REJAS, MADRID",
      "lat": 40.44677679999999,
      "lng": -3.5922232
  },
  {
      "id": "4704645",
      "description": "SITUADO APARCABICICLETAS - CARRETERA CANILLAS 2",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CARRETERA",
      "addressStreet": "CANILLAS",
      "addressNumber": "2",
      "addressCode": "20039662",
      "model": "",
      "address": "CARRETERA CANILLAS 2, CANILLAS, MADRID",
      "lat": 40.4627112,
      "lng": -3.657056099999999
  },
  {
      "id": "4704631",
      "description": "SITUADO APARCABICICLETAS - CALLE ASCAO 4",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "ASCAO",
      "addressNumber": "4",
      "addressCode": "20169142",
      "model": "",
      "address": "CALLE ASCAO 4, PUEBLO NUEVO, MADRID",
      "lat": 40.4293396,
      "lng": -3.6416125
  },
  {
      "id": "4704633",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN ROMAN DEL VALLE 8",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "SAN ROMAN DEL VALLE",
      "addressNumber": "8",
      "addressCode": "31017433",
      "model": "",
      "address": "CALLE SAN ROMAN DEL VALLE 8, ARCOS, MADRID",
      "lat": 40.419672,
      "lng": -3.6215428
  },
  {
      "id": "4704647",
      "description": "SITUADO APARCABICICLETAS - CALLE GUADALAJARA 46",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "161",
      "neighborhoodName": "PALOMAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "GUADALAJARA",
      "addressNumber": "46",
      "addressCode": "31052484",
      "model": "",
      "address": "CALLE GUADALAJARA 46, PALOMAS, MADRID",
      "lat": 40.4551571,
      "lng": -3.6146867
  },
  {
      "id": "529252",
      "description": "SITUADO APARCABICICLETAS - CALLE MEQUINENZA 16",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "CALLE",
      "addressStreet": "MEQUINENZA",
      "addressNumber": "16",
      "addressCode": "11137223",
      "model": "",
      "address": "CALLE MEQUINENZA 16, ROSAS, MADRID",
      "lat": 40.4481145,
      "lng": -3.6053096
  },
  {
      "id": "3568803",
      "description": "SITUADO APARCABICICLETAS - AVENIDA NICETO ALCALA ZAMORA 20",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "NICETO ALCALA ZAMORA",
      "addressNumber": "20",
      "addressCode": "31017537",
      "model": "",
      "address": "AVENIDA NICETO ALCALA ZAMORA 20, VALDEFUENTES, MADRID",
      "lat": 40.4940292,
      "lng": -3.6482052
  },
  {
      "id": "3568907",
      "description": "SITUADO APARCABICICLETAS - AVENIDA NICETO ALCALA ZAMORA 12",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "01/03/2013",
      "addressType": "AVENIDA",
      "addressStreet": "NICETO ALCALA ZAMORA",
      "addressNumber": "12",
      "addressCode": "31027986",
      "model": "",
      "address": "AVENIDA NICETO ALCALA ZAMORA 12, VALDEFUENTES, MADRID",
      "lat": 40.4891387,
      "lng": -3.6452552
  },
  {
      "id": "457787",
      "description": "SITUADO APARCABICICLETAS - C/ TAMAYO Y BAUS N�5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TAMAYO Y BAUS",
      "addressNumber": "5",
      "addressCode": "20005408",
      "model": "",
      "address": "CALLE TAMAYO Y BAUS 5, JUSTICIA, MADRID",
      "lat": 40.4229159,
      "lng": -3.693223399999999
  },
  {
      "id": "457790",
      "description": "SITUADO APARCABICICLETAS - P� RECOLETOS N�23",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "RECOLETOS",
      "addressNumber": "23",
      "addressCode": "11004257",
      "model": "",
      "address": "PASEO RECOLETOS 23, JUSTICIA, MADRID",
      "lat": 40.4227242,
      "lng": -3.6923964
  },
  {
      "id": "457794",
      "description": "SITUADO APARCABICICLETAS - C/ MESONERO ROMANOS N�13",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MESONERO ROMANOS",
      "addressNumber": "13",
      "addressCode": "20006522",
      "model": "",
      "address": "CALLE MESONERO ROMANOS 13, UNIVERSIDAD, MADRID",
      "lat": 40.4204818,
      "lng": -3.7040118
  },
  {
      "id": "457798",
      "description": "SITUADO APARCABICICLETAS - C/ MESONERO ROMANOS N�17",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MESONERO ROMANOS",
      "addressNumber": "17",
      "addressCode": "20006452",
      "model": "",
      "address": "CALLE MESONERO ROMANOS 17, UNIVERSIDAD, MADRID",
      "lat": 40.4209187,
      "lng": -3.7041291
  },
  {
      "id": "457801",
      "description": "SITUADO APARCABICICLETAS - Pz. CALLAO N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "CALLAO",
      "addressNumber": "2",
      "addressCode": "20102742",
      "model": "",
      "address": "PLAZA CALLAO 2, SOL, MADRID",
      "lat": 40.4195161,
      "lng": -3.7049295
  },
  {
      "id": "457803",
      "description": "SITUADO APARCABICICLETAS - Pz. SANTO DOMINGO N�19",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "SANTO DOMINGO",
      "addressNumber": "19",
      "addressCode": "20003766",
      "model": "",
      "address": "PLAZA SANTO DOMINGO 19, PALACIO, MADRID",
      "lat": 40.4200133,
      "lng": -3.7077966
  },
  {
      "id": "457804",
      "description": "SITUADO APARCABICICLETAS - Pz. SANTO DOMINGO N�19",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "SANTO DOMINGO",
      "addressNumber": "19",
      "addressCode": "20003766",
      "model": "",
      "address": "PLAZA SANTO DOMINGO 19, PALACIO, MADRID",
      "lat": 40.4200133,
      "lng": -3.7077966
  },
  {
      "id": "457807",
      "description": "SITUADO APARCABICICLETAS - CUESTA SANTO DOMINGO N�14",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CUESTA",
      "addressStreet": "SANTO DOMINGO",
      "addressNumber": "14",
      "addressCode": "11000185",
      "model": "",
      "address": "CUESTA SANTO DOMINGO 14, PALACIO, MADRID",
      "lat": 40.4196904,
      "lng": -3.7091123
  },
  {
      "id": "457808",
      "description": "SITUADO APARCABICICLETAS - Pz. SAN MARTIN N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "SAN MARTIN",
      "addressNumber": "1",
      "addressCode": "11007884",
      "model": "",
      "address": "PLAZA SAN MARTIN 1, SOL, MADRID",
      "lat": 40.418042,
      "lng": -3.7070627
  },
  {
      "id": "457812",
      "description": "SITUADO APARCABICICLETAS - Pz. SAN MARTIN N�5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "SAN MARTIN",
      "addressNumber": "5",
      "addressCode": "11007281",
      "model": "",
      "address": "PLAZA SAN MARTIN 5, SOL, MADRID",
      "lat": 40.4187716,
      "lng": -3.7066805
  },
  {
      "id": "457820",
      "description": "SITUADO APARCABICICLETAS - C/ VERGARA N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VERGARA",
      "addressNumber": "3",
      "addressCode": "31037358",
      "model": "",
      "address": "CALLE VERGARA 3, PALACIO, MADRID",
      "lat": 40.4176745,
      "lng": -3.7102829
  },
  {
      "id": "457823",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA CLARA N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA CLARA",
      "addressNumber": "1",
      "addressCode": "31037355",
      "model": "",
      "address": "CALLE SANTA CLARA 1, PALACIO, MADRID",
      "lat": 40.4168002,
      "lng": -3.7109062
  },
  {
      "id": "457830",
      "description": "SITUADO APARCABICICLETAS - C/ MAYOR N�86",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAYOR",
      "addressNumber": "86",
      "addressCode": "11000048",
      "model": "",
      "address": "CALLE MAYOR 86, PALACIO, MADRID",
      "lat": 40.4152493,
      "lng": -3.7127001
  },
  {
      "id": "457832",
      "description": "SITUADO APARCABICICLETAS - C/ MAYOR N�83",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAYOR",
      "addressNumber": "83",
      "addressCode": "11000040",
      "model": "",
      "address": "CALLE MAYOR 83, PALACIO, MADRID",
      "lat": 40.4148484,
      "lng": -3.714298299999999
  },
  {
      "id": "457833",
      "description": "SITUADO APARCABICICLETAS - C/ SEGOVIA N�75",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SEGOVIA",
      "addressNumber": "75",
      "addressCode": "20007178",
      "model": "",
      "address": "CALLE SEGOVIA 75, PALACIO, MADRID",
      "lat": 40.41397449999999,
      "lng": -3.720948
  },
  {
      "id": "457834",
      "description": "SITUADO APARCABICICLETAS - C/ BAILEN N�41",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BAILEN",
      "addressNumber": "41",
      "addressCode": "11000942",
      "model": "",
      "address": "CALLE BAILEN 41, PALACIO, MADRID",
      "lat": 40.4120074,
      "lng": -3.713624199999999
  },
  {
      "id": "457845",
      "description": "SITUADO APARCABICICLETAS - Pz. DE LA PAJA N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PAJA",
      "addressNumber": "3",
      "addressCode": "11000698",
      "model": "",
      "address": "PLAZA PAJA 3, PALACIO, MADRID",
      "lat": 40.4128515,
      "lng": -3.711316799999999
  },
  {
      "id": "457848",
      "description": "SITUADO APARCABICICLETAS - Pz. CRUZ VERDE N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "CRUZ VERDE",
      "addressNumber": "1",
      "addressCode": "11000578",
      "model": "",
      "address": "PLAZA CRUZ VERDE 1, PALACIO, MADRID",
      "lat": 40.4140369,
      "lng": -3.711980799999999
  },
  {
      "id": "457850",
      "description": "SITUADO APARCABICICLETAS - C/ SEGOVIA N�11",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SEGOVIA",
      "addressNumber": "11",
      "addressCode": "31041185",
      "model": "",
      "address": "CALLE SEGOVIA 11, PALACIO, MADRID",
      "lat": 40.41353,
      "lng": -3.7107
  },
  {
      "id": "457853",
      "description": "SITUADO APARCABICICLETAS - C/ SAN BRUNO N�6",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN BRUNO",
      "addressNumber": "6",
      "addressCode": "31038670",
      "model": "",
      "address": "CALLE SAN BRUNO 6, PALACIO, MADRID",
      "lat": 40.4129914,
      "lng": -3.7082814
  },
  {
      "id": "457854",
      "description": "SITUADO APARCABICICLETAS - C/ SAN BRUNO N�7",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN BRUNO",
      "addressNumber": "7",
      "addressCode": "31032438",
      "model": "",
      "address": "CALLE SAN BRUNO 7, PALACIO, MADRID",
      "lat": 40.4128439,
      "lng": -3.708530799999999
  },
  {
      "id": "457856",
      "description": "SITUADO APARCABICICLETAS - C/ CONDE DE ROMANONES N�2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONDE DE ROMANONES",
      "addressNumber": "2",
      "addressCode": "11001548",
      "model": "",
      "address": "CALLE CONDE DE ROMANONES 2, EMBAJADORES, MADRID",
      "lat": 40.4138688,
      "lng": -3.704947
  },
  {
      "id": "457858",
      "description": "SITUADO APARCABICICLETAS - Pz. PROVINCIA N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PROVINCIA",
      "addressNumber": "3",
      "addressCode": "11007679",
      "model": "",
      "address": "PLAZA PROVINCIA 3, SOL, MADRID",
      "lat": 40.4149818,
      "lng": -3.706408
  },
  {
      "id": "457865",
      "description": "SITUADO APARCABICICLETAS - C/ DOCTOR CORTEZO N�4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR CORTEZO",
      "addressNumber": "4",
      "addressCode": "11001567",
      "model": "",
      "address": "CALLE DOCTOR CORTEZO 4, EMBAJADORES, MADRID",
      "lat": 40.4138642,
      "lng": -3.7038369
  },
  {
      "id": "457870",
      "description": "SITUADO APARCABICICLETAS - Pz. SANTA ANA N�15",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "SANTA ANA",
      "addressNumber": "15",
      "addressCode": "31008866",
      "model": "",
      "address": "PLAZA SANTA ANA 15, CORTES, MADRID",
      "lat": 40.4150337,
      "lng": -3.7016713
  },
  {
      "id": "457871",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ISABEL N�10",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ISABEL",
      "addressNumber": "10",
      "addressCode": "11002560",
      "model": "",
      "address": "CALLE SANTA ISABEL 10, EMBAJADORES, MADRID",
      "lat": 40.411878,
      "lng": -3.6995517
  },
  {
      "id": "457872",
      "description": "SITUADO APARCABICICLETAS - C/ LAVAPIES N�21",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LAVAPIES",
      "addressNumber": "21",
      "addressCode": "11002474",
      "model": "",
      "address": "CALLE LAVAPIES 21, EMBAJADORES, MADRID",
      "lat": 40.4105387,
      "lng": -3.7025228
  },
  {
      "id": "457873",
      "description": "SITUADO APARCABICICLETAS - C/ CARLOS ARNICHES N�5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CARLOS ARNICHES",
      "addressNumber": "5",
      "addressCode": "31008831",
      "model": "",
      "address": "CALLE CARLOS ARNICHES 5, EMBAJADORES, MADRID",
      "lat": 40.4087479,
      "lng": -3.7082195
  },
  {
      "id": "457874",
      "description": "SITUADO APARCABICICLETAS - GTA. PUERTA DE TOLEDO N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "PUERTA DE TOLEDO",
      "addressNumber": "1",
      "addressCode": "20105763",
      "model": "",
      "address": "GLORIETA PUERTA DE TOLEDO 1, EMBAJADORES, MADRID",
      "lat": 40.40723,
      "lng": -3.71099
  },
  {
      "id": "457875",
      "description": "SITUADO APARCABICICLETAS - C/ PALOMA N�39",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PALOMA",
      "addressNumber": "39",
      "addressCode": "20105817",
      "model": "",
      "address": "CALLE PALOMA 39, PALACIO, MADRID",
      "lat": 40.4076461,
      "lng": -3.7115651
  },
  {
      "id": "457876",
      "description": "SITUADO APARCABICICLETAS - GTA. EMBAJADORES N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "EMBAJADORES",
      "addressNumber": "1",
      "addressCode": "20004566",
      "model": "",
      "address": "GLORIETA EMBAJADORES 1, EMBAJADORES, MADRID",
      "lat": 40.4048641,
      "lng": -3.7022627
  },
  {
      "id": "457878",
      "description": "SITUADO APARCABICICLETAS - C/ VALENCIA N�30",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALENCIA",
      "addressNumber": "30",
      "addressCode": "11002358",
      "model": "",
      "address": "CALLE VALENCIA 30, EMBAJADORES, MADRID",
      "lat": 40.4063977,
      "lng": -3.6997955
  },
  {
      "id": "457879",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ISABEL N�57",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ISABEL",
      "addressNumber": "57",
      "addressCode": "20004938",
      "model": "",
      "address": "CALLE SANTA ISABEL 57, EMBAJADORES, MADRID",
      "lat": 40.4083114,
      "lng": -3.6930451
  },
  {
      "id": "457880",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ISABEL N�53",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ISABEL",
      "addressNumber": "53",
      "addressCode": "20004936",
      "model": "",
      "address": "CALLE SANTA ISABEL 53, EMBAJADORES, MADRID",
      "lat": 40.4090099,
      "lng": -3.6945865
  },
  {
      "id": "457881",
      "description": "SITUADO APARCABICICLETAS - RONDA DE ATOCHA N�2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "RONDA",
      "addressStreet": "ATOCHA",
      "addressNumber": "2",
      "addressCode": "20105949",
      "model": "",
      "address": "RONDA ATOCHA 2, EMBAJADORES, MADRID",
      "lat": 40.4076469,
      "lng": -3.6949571
  },
  {
      "id": "457882",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ISABEL N�46",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ISABEL",
      "addressNumber": "46",
      "addressCode": "11002996",
      "model": "",
      "address": "CALLE SANTA ISABEL 46, EMBAJADORES, MADRID",
      "lat": 40.4096348,
      "lng": -3.6965713
  },
  {
      "id": "457883",
      "description": "SITUADO APARCABICICLETAS - C/ HUERTAS N�75",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HUERTAS",
      "addressNumber": "75",
      "addressCode": "31008865",
      "model": "",
      "address": "CALLE HUERTAS 75, CORTES, MADRID",
      "lat": 40.4129258,
      "lng": -3.694369
  },
  {
      "id": "457884",
      "description": "SITUADO APARCABICICLETAS - C/ MARQUES DE CUBAS N�4",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARQUES DE CUBAS",
      "addressNumber": "4",
      "addressCode": "20005286",
      "model": "",
      "address": "CALLE MARQUES DE CUBAS 4, CORTES, MADRID",
      "lat": 40.4174274,
      "lng": -3.695623099999999
  },
  {
      "id": "457885",
      "description": "SITUADO APARCABICICLETAS - C/ ALCALA N�45",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "45",
      "addressCode": "11004356",
      "model": "",
      "address": "CALLE ALCALA 45, JUSTICIA, MADRID",
      "lat": 40.419184,
      "lng": -3.6962013
  },
  {
      "id": "457886",
      "description": "SITUADO APARCABICICLETAS - C/ ALCALA N�42",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "42",
      "addressCode": "11004013",
      "model": "",
      "address": "CALLE ALCALA 42, CORTES, MADRID",
      "lat": 40.41838389999999,
      "lng": -3.6966827
  },
  {
      "id": "457889",
      "description": "SITUADO APARCABICICLETAS - C/ ALCALA N�34",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "34",
      "addressCode": "11004009",
      "model": "",
      "address": "CALLE ALCALA 34, CORTES, MADRID",
      "lat": 40.4180686,
      "lng": -3.6973894
  },
  {
      "id": "457894",
      "description": "SITUADO APARCABICICLETAS - C/ CABALLERO DE GRACIA N�15",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CABALLERO DE GRACIA",
      "addressNumber": "15",
      "addressCode": "20147371",
      "model": "",
      "address": "CALLE CABALLERO DE GRACIA 15, CORTES, MADRID",
      "lat": 40.4191535,
      "lng": -3.6989267
  },
  {
      "id": "457897",
      "description": "SITUADO APARCABICICLETAS - Pz. PUERTA DEL SOL N�13",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PUERTA DEL SOL",
      "addressNumber": "13",
      "addressCode": "11007519",
      "model": "",
      "address": "PLAZA PUERTA DEL SOL 13, SOL, MADRID",
      "lat": 40.4172087,
      "lng": -3.702877099999999
  },
  {
      "id": "528829",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCALA 629",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "629",
      "addressCode": "20052950",
      "model": "",
      "address": "CALLE ALCALA 629, CANILLEJAS, MADRID",
      "lat": 40.448722,
      "lng": -3.6093925
  },
  {
      "id": "457743",
      "description": "SITUADO APARCABICICLETAS - C/ BARCELO N�9",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BARCELO",
      "addressNumber": "9",
      "addressCode": "11005237",
      "model": "",
      "address": "CALLE BARCELO 9, JUSTICIA, MADRID",
      "lat": 40.4268416,
      "lng": -3.7004028
  },
  {
      "id": "457491",
      "description": "SITUADO APARCABICICLETAS - C/ LUIS ESTEBAN N�5",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LUIS ESTEBAN",
      "addressNumber": "5",
      "addressCode": "31040998",
      "model": "",
      "address": "CALLE LUIS ESTEBAN 5, ALMENARA, MADRID",
      "lat": 40.4727513,
      "lng": -3.6885973
  },
  {
      "id": "457493",
      "description": "SITUADO APARCABICICLETAS - C/ GENERAL ARANDA N�98",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL ARANDA",
      "addressNumber": "98",
      "addressCode": "11032550",
      "model": "",
      "address": "CALLE GENERAL ARANDA 98, ALMENARA, MADRID",
      "lat": 40.4729761,
      "lng": -3.6962741
  },
  {
      "id": "457496",
      "description": "SITUADO APARCABICICLETAS - Av. ASTURIAS N�70",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ASTURIAS",
      "addressNumber": "70",
      "addressCode": "31038106",
      "model": "",
      "address": "AVENIDA ASTURIAS 70, ALMENARA, MADRID",
      "lat": 40.4731389,
      "lng": -3.6989758
  },
  {
      "id": "457499",
      "description": "SITUADO APARCABICICLETAS - Av. ASTURIAS N�32",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ASTURIAS",
      "addressNumber": "32",
      "addressCode": "31000231",
      "model": "",
      "address": "AVENIDA ASTURIAS 32, ALMENARA, MADRID",
      "lat": 40.4696597,
      "lng": -3.6953163
  },
  {
      "id": "457504",
      "description": "SITUADO APARCABICICLETAS - Pz. DONOSO N�5",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "DONOSO",
      "addressNumber": "5",
      "addressCode": "11033010",
      "model": "",
      "address": "PLAZA DONOSO 5, ALMENARA, MADRID",
      "lat": 40.469102,
      "lng": -3.6972714
  },
  {
      "id": "457506",
      "description": "SITUADO APARCABICICLETAS - C/ PINOS ALTA N�63",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PINOS ALTA",
      "addressNumber": "63",
      "addressCode": "11033707",
      "model": "",
      "address": "CALLE PINOS ALTA 63, VALDEACEDERAS, MADRID",
      "lat": 40.4680448,
      "lng": -3.6982322
  },
  {
      "id": "457509",
      "description": "SITUADO APARCABICICLETAS - C/ CAPITAN BLANCO ARGIBAY N�14",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAPITAN BLANCO ARGIBAY",
      "addressNumber": "14",
      "addressCode": "11033839",
      "model": "",
      "address": "CALLE CAPITAN BLANCO ARGIBAY 14, VALDEACEDERAS, MADRID",
      "lat": 40.4649946,
      "lng": -3.6966088
  },
  {
      "id": "457511",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�376",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "376",
      "addressCode": "11030949",
      "model": "",
      "address": "CALLE BRAVO MURILLO 376, CASTILLEJOS, MADRID",
      "lat": 40.4651163,
      "lng": -3.6927969
  },
  {
      "id": "457512",
      "description": "SITUADO APARCABICICLETAS - C/ SAN AQUILINO N�2",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN AQUILINO",
      "addressNumber": "2",
      "addressCode": "20057574",
      "model": "",
      "address": "CALLE SAN AQUILINO 2, ALMENARA, MADRID",
      "lat": 40.4675451,
      "lng": -3.6903695
  },
  {
      "id": "457513",
      "description": "SITUADO APARCABICICLETAS - C/ CONDE SERRALLO N�4",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONDE DEL SERRALLO",
      "addressNumber": "4",
      "addressCode": "20056824",
      "model": "",
      "address": "CALLE CONDE DEL SERRALLO 4, ALMENARA, MADRID",
      "lat": 40.4664024,
      "lng": -3.6916041
  },
  {
      "id": "457514",
      "description": "SITUADO APARCABICICLETAS - C/ SAN BENITO N�6",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN BENITO",
      "addressNumber": "6",
      "addressCode": "11031825",
      "model": "",
      "address": "CALLE SAN BENITO 6, ALMENARA, MADRID",
      "lat": 40.46845649999999,
      "lng": -3.6902892
  },
  {
      "id": "457517",
      "description": "SITUADO APARCABICICLETAS - C/ FRANCISCO GERVAS N�26",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO GERVAS",
      "addressNumber": "26",
      "addressCode": "11030792",
      "model": "",
      "address": "CALLE FRANCISCO GERVAS 26, CASTILLEJOS, MADRID",
      "lat": 40.4612386,
      "lng": -3.6952725
  },
  {
      "id": "457518",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�278",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "278",
      "addressCode": "11030453",
      "model": "",
      "address": "CALLE BRAVO MURILLO 278, CASTILLEJOS, MADRID",
      "lat": 40.4610116,
      "lng": -3.6980372
  },
  {
      "id": "457519",
      "description": "SITUADO APARCABICICLETAS - C/ MARQUES DE VIANA N�41",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "66",
      "neighborhoodName": "BERRUGUETE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARQUES DE VIANA",
      "addressNumber": "41",
      "addressCode": "11036732",
      "model": "",
      "address": "CALLE MARQUES DE VIANA 41, BERRUGUETE, MADRID",
      "lat": 40.4621218,
      "lng": -3.7016701
  },
  {
      "id": "457520",
      "description": "SITUADO APARCABICICLETAS - C/ MARQUES DE VIANA N�56",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARQUES DE VIANA",
      "addressNumber": "56",
      "addressCode": "11036029",
      "model": "",
      "address": "CALLE MARQUES DE VIANA 56, VALDEACEDERAS, MADRID",
      "lat": 40.4632323,
      "lng": -3.7027899
  },
  {
      "id": "457521",
      "description": "SITUADO APARCABICICLETAS - C/ VILLAAMIL N�263",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLAAMIL",
      "addressNumber": "263",
      "addressCode": "11054691",
      "model": "",
      "address": "CALLE VILLAAMIL 263, VALDEACEDERAS, MADRID",
      "lat": 40.4680285,
      "lng": -3.7129944
  },
  {
      "id": "457522",
      "description": "SITUADO APARCABICICLETAS - C/ SINESIO DELGADO N�23",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SINESIO DELGADO",
      "addressNumber": "23",
      "addressCode": "31045419",
      "model": "",
      "address": "CALLE SINESIO DELGADO 23, VALDEACEDERAS, MADRID",
      "lat": 40.4607126,
      "lng": -3.7244874
  },
  {
      "id": "457524",
      "description": "SITUADO APARCABICICLETAS - C/ SINESIO DELGADO N�34",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SINESIO DELGADO",
      "addressNumber": "34",
      "addressCode": "20047808",
      "model": "",
      "address": "CALLE SINESIO DELGADO 34, VALDEACEDERAS, MADRID",
      "lat": 40.4719907,
      "lng": -3.7061457
  },
  {
      "id": "457525",
      "description": "SITUADO APARCABICICLETAS - C/ SINESIO DELGADO N�34",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SINESIO DELGADO",
      "addressNumber": "34",
      "addressCode": "20047808",
      "model": "",
      "address": "CALLE SINESIO DELGADO 34, VALDEACEDERAS, MADRID",
      "lat": 40.4719907,
      "lng": -3.7061457
  },
  {
      "id": "457526",
      "description": "SITUADO APARCABICICLETAS - C/ SINESIO DELGADO N�28",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SINESIO DELGADO",
      "addressNumber": "28",
      "addressCode": "20047796",
      "model": "",
      "address": "CALLE SINESIO DELGADO 28, VALDEACEDERAS, MADRID",
      "lat": 40.4720931,
      "lng": -3.704647899999999
  },
  {
      "id": "457527",
      "description": "SITUADO APARCABICICLETAS - P� DE LA DIRECCION N�295",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "DIRECCION",
      "addressNumber": "295",
      "addressCode": "31028420",
      "model": "",
      "address": "PASEO DIRECCION 295, VALDEACEDERAS, MADRID",
      "lat": 40.4700644,
      "lng": -3.7046327
  },
  {
      "id": "457528",
      "description": "SITUADO APARCABICICLETAS - P� DE LA DIRECCION N�295",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "DIRECCION",
      "addressNumber": "295",
      "addressCode": "31028420",
      "model": "",
      "address": "PASEO DIRECCION 295, VALDEACEDERAS, MADRID",
      "lat": 40.4700644,
      "lng": -3.7046327
  },
  {
      "id": "457529",
      "description": "SITUADO APARCABICICLETAS - P� DE LA DIRECCION N�267",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "65",
      "neighborhoodName": "VALDEACEDERAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "DIRECCION",
      "addressNumber": "267",
      "addressCode": "31028366",
      "model": "",
      "address": "PASEO DIRECCION 267, VALDEACEDERAS, MADRID",
      "lat": 40.4696595,
      "lng": -3.7064511
  },
  {
      "id": "457530",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�248",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "248",
      "addressCode": "11030398",
      "model": "",
      "address": "CALLE BRAVO MURILLO 248, CASTILLEJOS, MADRID",
      "lat": 40.4595748,
      "lng": -3.6991934
  },
  {
      "id": "457532",
      "description": "SITUADO APARCABICICLETAS - C/ INFANTA MERCEDES N�47",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "INFANTA MERCEDES",
      "addressNumber": "47",
      "addressCode": "11030336",
      "model": "",
      "address": "CALLE INFANTA MERCEDES 47, CASTILLEJOS, MADRID",
      "lat": 40.4571923,
      "lng": -3.6982624
  },
  {
      "id": "457534",
      "description": "SITUADO APARCABICICLETAS - C/ NTRA. SRA. DEL CARMEN N�3",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "66",
      "neighborhoodName": "BERRUGUETE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NUESTRA SE�ORA DEL CARMEN",
      "addressNumber": "3",
      "addressCode": "20127483",
      "model": "",
      "address": "CALLE NUESTRA SE�ORA DEL CARMEN 3, BERRUGUETE, MADRID",
      "lat": 40.4619746,
      "lng": -3.7050262
  },
  {
      "id": "457535",
      "description": "SITUADO APARCABICICLETAS - C/ HIERBABUENA N�2",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "66",
      "neighborhoodName": "BERRUGUETE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HIERBABUENA",
      "addressNumber": "2",
      "addressCode": "11037992",
      "model": "",
      "address": "CALLE HIERBABUENA 2, BERRUGUETE, MADRID",
      "lat": 40.4591033,
      "lng": -3.7018695
  },
  {
      "id": "457537",
      "description": "SITUADO APARCABICICLETAS - C/ AMBROSIO VALLEJO N�2",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "66",
      "neighborhoodName": "BERRUGUETE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AMBROSIO VALLEJO",
      "addressNumber": "2",
      "addressCode": "20013036",
      "model": "",
      "address": "CALLE AMBROSIO VALLEJO 2, BERRUGUETE, MADRID",
      "lat": 40.4574979,
      "lng": -3.7060938
  },
  {
      "id": "457538",
      "description": "SITUADO APARCABICICLETAS - Av. PABLO IGLESIAS N�96",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "61",
      "neighborhoodName": "BELLAS VISTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PABLO IGLESIAS",
      "addressNumber": "96",
      "addressCode": "11028333",
      "model": "",
      "address": "AVENIDA PABLO IGLESIAS 96, BELLAS VISTAS, MADRID",
      "lat": 40.457117,
      "lng": -3.7115258
  },
  {
      "id": "457541",
      "description": "SITUADO APARCABICICLETAS - C/ ALEJANDRO RODRIGUEZ N�39",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "61",
      "neighborhoodName": "BELLAS VISTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALEJANDRO RODRIGUEZ",
      "addressNumber": "39",
      "addressCode": "11028231",
      "model": "",
      "address": "CALLE ALEJANDRO RODRIGUEZ 39, BELLAS VISTAS, MADRID",
      "lat": 40.4562593,
      "lng": -3.7119513
  },
  {
      "id": "457543",
      "description": "SITUADO APARCABICICLETAS - C/ PAMPLONA N�33",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "61",
      "neighborhoodName": "BELLAS VISTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PAMPLONA",
      "addressNumber": "33",
      "addressCode": "31001838",
      "model": "",
      "address": "CALLE PAMPLONA 33, BELLAS VISTAS, MADRID",
      "lat": 40.4552023,
      "lng": -3.7062941
  },
  {
      "id": "457549",
      "description": "SITUADO APARCABICICLETAS - Av. PABLO IGLESIAS N�65",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "61",
      "neighborhoodName": "BELLAS VISTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PABLO IGLESIAS",
      "addressNumber": "65",
      "addressCode": "20017034",
      "model": "",
      "address": "AVENIDA PABLO IGLESIAS 65, BELLAS VISTAS, MADRID",
      "lat": 40.45194,
      "lng": -3.7117601
  },
  {
      "id": "457552",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�162",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "162",
      "addressCode": "11029812",
      "model": "",
      "address": "CALLE BRAVO MURILLO 162, CUATRO CAMINOS, MADRID",
      "lat": 40.4525812,
      "lng": -3.7029104
  },
  {
      "id": "457555",
      "description": "SITUADO APARCABICICLETAS - Av. GENERAL PERON N�9",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GENERAL PERON",
      "addressNumber": "9",
      "addressCode": "31047944",
      "model": "",
      "address": "AVENIDA GENERAL PERON 9, CUATRO CAMINOS, MADRID",
      "lat": 40.45313429999999,
      "lng": -3.697638
  },
  {
      "id": "457558",
      "description": "SITUADO APARCABICICLETAS - Av. GENERAL PERON N�36",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GENERAL PERON",
      "addressNumber": "36",
      "addressCode": "11029269",
      "model": "",
      "address": "AVENIDA GENERAL PERON 36, CUATRO CAMINOS, MADRID",
      "lat": 40.4520198,
      "lng": -3.6935061
  },
  {
      "id": "457560",
      "description": "SITUADO APARCABICICLETAS - C/ GENERAL MOSCARDO N�24",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL MOSCARDO",
      "addressNumber": "24",
      "addressCode": "20149143",
      "model": "",
      "address": "CALLE GENERAL MOSCARDO 24, CUATRO CAMINOS, MADRID",
      "lat": 40.4506385,
      "lng": -3.696558
  },
  {
      "id": "457562",
      "description": "SITUADO APARCABICICLETAS - C/ ORENSE N�15",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ORENSE",
      "addressNumber": "15",
      "addressCode": "31041819",
      "model": "",
      "address": "CALLE ORENSE 15, CUATRO CAMINOS, MADRID",
      "lat": 40.4487989,
      "lng": -3.6954944
  },
  {
      "id": "457565",
      "description": "SITUADO APARCABICICLETAS - C/ HERNANI N�68",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HERNANI",
      "addressNumber": "68",
      "addressCode": "11029251",
      "model": "",
      "address": "CALLE HERNANI 68, CUATRO CAMINOS, MADRID",
      "lat": 40.44783,
      "lng": -3.696021
  },
  {
      "id": "457567",
      "description": "SITUADO APARCABICICLETAS - C/ AGUSTIN DE BETANCOURT N�15",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AGUSTIN DE BETANCOURT",
      "addressNumber": "15",
      "addressCode": "20014624",
      "model": "",
      "address": "CALLE AGUSTIN DE BETANCOURT 15, RIOS ROSAS, MADRID",
      "lat": 40.4442258,
      "lng": -3.6953637
  },
  {
      "id": "457568",
      "description": "SITUADO APARCABICICLETAS - P� CASTELLANA N�67",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "67",
      "addressCode": "20066514",
      "model": "",
      "address": "PASEO CASTELLANA 67, RIOS ROSAS, MADRID",
      "lat": 40.4435818,
      "lng": -3.6935795
  },
  {
      "id": "457570",
      "description": "SITUADO APARCABICICLETAS - P� CASTELLANA N�65",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "65",
      "addressCode": "11041676",
      "model": "",
      "address": "PASEO CASTELLANA 65, RIOS ROSAS, MADRID",
      "lat": 40.4433937,
      "lng": -3.6936181
  },
  {
      "id": "457572",
      "description": "SITUADO APARCABICICLETAS - Pz. SAN JUAN DE LA CRUZ N�3",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "SAN JUAN DE LA CRUZ",
      "addressNumber": "3",
      "addressCode": "31035629",
      "model": "",
      "address": "PLAZA SAN JUAN DE LA CRUZ 3, RIOS ROSAS, MADRID",
      "lat": 40.4413546,
      "lng": -3.6935727
  },
  {
      "id": "457573",
      "description": "SITUADO APARCABICICLETAS - C/ RIOS ROSAS N�57",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RIOS ROSAS",
      "addressNumber": "57",
      "addressCode": "20014598",
      "model": "",
      "address": "CALLE RIOS ROSAS 57, RIOS ROSAS, MADRID",
      "lat": 40.4418408,
      "lng": -3.695447
  },
  {
      "id": "457575",
      "description": "SITUADO APARCABICICLETAS - C/ RIOS ROSAS N�21",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RIOS ROSAS",
      "addressNumber": "21",
      "addressCode": "11042093",
      "model": "",
      "address": "CALLE RIOS ROSAS 21, RIOS ROSAS, MADRID",
      "lat": 40.442186,
      "lng": -3.700323599999999
  },
  {
      "id": "457579",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ENGRACIA N�125",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ENGRACIA",
      "addressNumber": "125",
      "addressCode": "11041972",
      "model": "",
      "address": "CALLE SANTA ENGRACIA 125, RIOS ROSAS, MADRID",
      "lat": 40.4392262,
      "lng": -3.7008285
  },
  {
      "id": "457580",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ENGRACIA N�142",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ENGRACIA",
      "addressNumber": "142",
      "addressCode": "11042066",
      "model": "",
      "address": "CALLE SANTA ENGRACIA 142, RIOS ROSAS, MADRID",
      "lat": 40.4439985,
      "lng": -3.702442
  },
  {
      "id": "457581",
      "description": "SITUADO APARCABICICLETAS - C/ MAUDES N�14",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAUDES",
      "addressNumber": "14",
      "addressCode": "11042318",
      "model": "",
      "address": "CALLE MAUDES 14, RIOS ROSAS, MADRID",
      "lat": 40.445219,
      "lng": -3.7018728
  },
  {
      "id": "457583",
      "description": "SITUADO APARCABICICLETAS - C/ DULCINEA N�1",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "62",
      "neighborhoodName": "CUATRO CAMINOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DULCINEA",
      "addressNumber": "1",
      "addressCode": "31009760",
      "model": "",
      "address": "CALLE DULCINEA 1, CUATRO CAMINOS, MADRID",
      "lat": 40.4469442,
      "lng": -3.700184999999999
  },
  {
      "id": "457584",
      "description": "SITUADO APARCABICICLETAS - C/ RAIMUNDO FERNANDEZ VILLAVERDE N�32",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RAIMUNDO FERNANDEZ VILLAVERDE",
      "addressNumber": "32",
      "addressCode": "11042290",
      "model": "",
      "address": "CALLE RAIMUNDO FERNANDEZ VILLAVERDE 32, RIOS ROSAS, MADRID",
      "lat": 40.4461314,
      "lng": -3.698974999999999
  },
  {
      "id": "457586",
      "description": "SITUADO APARCABICICLETAS - C/ RAIMUNDO FERNANDEZ VILLAVERDE N�43",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RAIMUNDO FERNANDEZ VILLAVERDE",
      "addressNumber": "43",
      "addressCode": "11029240",
      "model": "",
      "address": "CALLE RAIMUNDO FERNANDEZ VILLAVERDE 43, RIOS ROSAS, MADRID",
      "lat": 40.4467845,
      "lng": -3.6981831
  },
  {
      "id": "457587",
      "description": "SITUADO APARCABICICLETAS - GTA. CUATRO CAMINOS N�1",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "CUATRO CAMINOS",
      "addressNumber": "1",
      "addressCode": "11042383",
      "model": "",
      "address": "GLORIETA CUATRO CAMINOS 1, VALLEHERMOSO, MADRID",
      "lat": 40.446587,
      "lng": -3.7043669
  },
  {
      "id": "457588",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�85",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "85",
      "addressCode": "11042375",
      "model": "",
      "address": "CALLE BRAVO MURILLO 85, VALLEHERMOSO, MADRID",
      "lat": 40.4449104,
      "lng": -3.704604799999999
  },
  {
      "id": "457590",
      "description": "SITUADO APARCABICICLETAS - C/ MARQUES DE LEMA N�7",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARQUES DE LEMA",
      "addressNumber": "7",
      "addressCode": "11042435",
      "model": "",
      "address": "CALLE MARQUES DE LEMA 7, VALLEHERMOSO, MADRID",
      "lat": 40.4455547,
      "lng": -3.7063978
  },
  {
      "id": "457591",
      "description": "SITUADO APARCABICICLETAS - C/ ESQUILACHE N�1",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ESQUILACHE",
      "addressNumber": "1",
      "addressCode": "11042396",
      "model": "",
      "address": "CALLE ESQUILACHE 1, VALLEHERMOSO, MADRID",
      "lat": 40.443146,
      "lng": -3.7052143
  },
  {
      "id": "457593",
      "description": "SITUADO APARCABICICLETAS - Av. FILIPINAS N�11",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "FILIPINAS",
      "addressNumber": "11",
      "addressCode": "31050186",
      "model": "",
      "address": "AVENIDA FILIPINAS 11, VALLEHERMOSO, MADRID",
      "lat": 40.44155,
      "lng": -3.706499999999999
  },
  {
      "id": "457594",
      "description": "SITUADO APARCABICICLETAS - Av. FILIPINAS N�34",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "FILIPINAS",
      "addressNumber": "34",
      "addressCode": "11042492",
      "model": "",
      "address": "AVENIDA FILIPINAS 34, VALLEHERMOSO, MADRID",
      "lat": 40.44064059999999,
      "lng": -3.7076703
  },
  {
      "id": "457596",
      "description": "SITUADO APARCABICICLETAS - P� SAN FRANCISC DE SALES N�42",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "SAN FRANCISCO DE SALES",
      "addressNumber": "42",
      "addressCode": "11042628",
      "model": "",
      "address": "PASEO SAN FRANCISCO DE SALES 42, VALLEHERMOSO, MADRID",
      "lat": 40.4426298,
      "lng": -3.7119193
  },
  {
      "id": "457597",
      "description": "SITUADO APARCABICICLETAS - C/ JUAN VIGON N�8",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN VIGON",
      "addressNumber": "8",
      "addressCode": "11042618",
      "model": "",
      "address": "CALLE JUAN VIGON 8, VALLEHERMOSO, MADRID",
      "lat": 40.4410184,
      "lng": -3.7119788
  },
  {
      "id": "457598",
      "description": "SITUADO APARCABICICLETAS - C/ GUZMAN EL BUENO N�94",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GUZMAN EL BUENO",
      "addressNumber": "94",
      "addressCode": "11042610",
      "model": "",
      "address": "CALLE GUZMAN EL BUENO 94, VALLEHERMOSO, MADRID",
      "lat": 40.440614,
      "lng": -3.712592399999999
  },
  {
      "id": "457599",
      "description": "SITUADO APARCABICICLETAS - C/ JESUS MAESTRO N�3",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JESUS MAESTRO",
      "addressNumber": "3",
      "addressCode": "11042635",
      "model": "",
      "address": "CALLE JESUS MAESTRO 3, VALLEHERMOSO, MADRID",
      "lat": 40.4407952,
      "lng": -3.711503
  },
  {
      "id": "457601",
      "description": "SITUADO APARCABICICLETAS - Av. FILIPINAS N�5",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "FILIPINAS",
      "addressNumber": "5",
      "addressCode": "11042602",
      "model": "",
      "address": "AVENIDA FILIPINAS 5, VALLEHERMOSO, MADRID",
      "lat": 40.4400278,
      "lng": -3.7107991
  },
  {
      "id": "457603",
      "description": "SITUADO APARCABICICLETAS - C/ GENERAL IBA�EZ DE IBERO N�4",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL IBA�EZ DE IBERO",
      "addressNumber": "4",
      "addressCode": "31044157",
      "model": "",
      "address": "CALLE GENERAL IBAÑEZ DE IBERO 4, VALLEHERMOSO, MADRID",
      "lat": 40.444951,
      "lng": -3.7107894
  },
  {
      "id": "457605",
      "description": "SITUADO APARCABICICLETAS - Av. REINA VICTORIA N�56",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "REINA VICTORIA",
      "addressNumber": "56",
      "addressCode": "11053168",
      "model": "",
      "address": "AVENIDA REINA VICTORIA 56, VALLEHERMOSO, MADRID",
      "lat": 40.4465821,
      "lng": -3.7125934
  },
  {
      "id": "457607",
      "description": "SITUADO APARCABICICLETAS - C/ GRAL. ASENSIO CABANILLAS N�15",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL ASENSIO CABANILLAS",
      "addressNumber": "15",
      "addressCode": "11042963",
      "model": "",
      "address": "CALLE GENERAL ASENSIO CABANILLAS 15, VALLEHERMOSO, MADRID",
      "lat": 40.4447191,
      "lng": -3.7155473
  },
  {
      "id": "457611",
      "description": "SITUADO APARCABICICLETAS - C/ ISAAC PERAL N�60",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ISAAC PERAL",
      "addressNumber": "60",
      "addressCode": "11042839",
      "model": "",
      "address": "CALLE ISAAC PERAL 60, VALLEHERMOSO, MADRID",
      "lat": 40.4429899,
      "lng": -3.7182829
  },
  {
      "id": "457615",
      "description": "SITUADO APARCABICICLETAS - C/ JULIAN ROMEA N�20",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JULIAN ROMEA",
      "addressNumber": "20",
      "addressCode": "11042850",
      "model": "",
      "address": "CALLE JULIAN ROMEA 20, VALLEHERMOSO, MADRID",
      "lat": 40.4429424,
      "lng": -3.7173081
  },
  {
      "id": "457625",
      "description": "SITUADO APARCABICICLETAS - C/ JOAQUIN MARIA LOPEZ N�50",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "71",
      "neighborhoodName": "GAZTAMBIDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOAQUIN MARIA LOPEZ",
      "addressNumber": "50",
      "addressCode": "20013348",
      "model": "",
      "address": "CALLE JOAQUIN MARIA LOPEZ 50, GAZTAMBIDE, MADRID",
      "lat": 40.4378415,
      "lng": -3.7151131
  },
  {
      "id": "457626",
      "description": "SITUADO APARCABICICLETAS - C/ GUZMAN EL BUENO N�75",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "71",
      "neighborhoodName": "GAZTAMBIDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GUZMAN EL BUENO",
      "addressNumber": "75",
      "addressCode": "11039033",
      "model": "",
      "address": "CALLE GUZMAN EL BUENO 75, GAZTAMBIDE, MADRID",
      "lat": 40.4368215,
      "lng": -3.7133467
  },
  {
      "id": "457627",
      "description": "SITUADO APARCABICICLETAS - C/ FERNANDO EL CATOLICO N�52",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "71",
      "neighborhoodName": "GAZTAMBIDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FERNANDO EL CATOLICO",
      "addressNumber": "52",
      "addressCode": "20013232",
      "model": "",
      "address": "CALLE FERNANDO EL CATOLICO 52, GAZTAMBIDE, MADRID",
      "lat": 40.4343357,
      "lng": -3.7130134
  },
  {
      "id": "457630",
      "description": "SITUADO APARCABICICLETAS - Pz. MONCLOA N�1",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "92",
      "neighborhoodName": "ARGUELLES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "MONCLOA",
      "addressNumber": "1",
      "addressCode": "31044251",
      "model": "",
      "address": "PLAZA MONCLOA 1, ARGUELLES, MADRID",
      "lat": 40.4353386,
      "lng": -3.7185718
  },
  {
      "id": "457633",
      "description": "SITUADO APARCABICICLETAS - C/ MELENDEZ VALDES N�67",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "71",
      "neighborhoodName": "GAZTAMBIDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MELENDEZ VALDES",
      "addressNumber": "67",
      "addressCode": "11038645",
      "model": "",
      "address": "CALLE MELENDEZ VALDES 67, GAZTAMBIDE, MADRID",
      "lat": 40.4329559,
      "lng": -3.7173911
  },
  {
      "id": "457634",
      "description": "SITUADO APARCABICICLETAS - C/ ROMERO ROBLEDO N�15",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "92",
      "neighborhoodName": "ARGUELLES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROMERO ROBLEDO",
      "addressNumber": "15",
      "addressCode": "20016982",
      "model": "",
      "address": "CALLE ROMERO ROBLEDO 15, ARGUELLES, MADRID",
      "lat": 40.4318548,
      "lng": -3.7204811
  },
  {
      "id": "457635",
      "description": "SITUADO APARCABICICLETAS - P� PINTOR ROSALES N�62",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "92",
      "neighborhoodName": "ARGUELLES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "PINTOR ROSALES",
      "addressNumber": "62",
      "addressCode": "20017012",
      "model": "",
      "address": "PASEO PINTOR ROSALES 62, ARGUELLES, MADRID",
      "lat": 40.4305146,
      "lng": -3.7229596
  },
  {
      "id": "457637",
      "description": "SITUADO APARCABICICLETAS - C/ ALBERTO AGUILERA N�23",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALBERTO AGUILERA",
      "addressNumber": "23",
      "addressCode": "11006483",
      "model": "",
      "address": "CALLE ALBERTO AGUILERA 23, UNIVERSIDAD, MADRID",
      "lat": 40.4295293,
      "lng": -3.7114191
  },
  {
      "id": "457641",
      "description": "SITUADO APARCABICICLETAS - C/ GALILEO N�39",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GALILEO",
      "addressNumber": "39",
      "addressCode": "20013552",
      "model": "",
      "address": "CALLE GALILEO 39, ARAPILES, MADRID",
      "lat": 40.4341684,
      "lng": -3.7103306
  },
  {
      "id": "457644",
      "description": "SITUADO APARCABICICLETAS - C/ ARAPILES N�12",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARAPILES",
      "addressNumber": "12",
      "addressCode": "11039612",
      "model": "",
      "address": "CALLE ARAPILES 12, ARAPILES, MADRID",
      "lat": 40.4332635,
      "lng": -3.7072032
  },
  {
      "id": "457646",
      "description": "SITUADO APARCABICICLETAS - Pz. CONDE DEL VALLE SUCHIL N�6",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "CONDE DEL VALLE DE SUCHIL",
      "addressNumber": "6",
      "addressCode": "11039774",
      "model": "",
      "address": "PLAZA CONDE DEL VALLE DE SUCHIL 6, ARAPILES, MADRID",
      "lat": 40.4305415,
      "lng": -3.7067729
  },
  {
      "id": "457648",
      "description": "SITUADO APARCABICICLETAS - C/ SAN BERNARDO N�93",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN BERNARDO",
      "addressNumber": "93",
      "addressCode": "20013640",
      "model": "",
      "address": "CALLE SAN BERNARDO 93, ARAPILES, MADRID",
      "lat": 40.43009139999999,
      "lng": -3.7058061
  },
  {
      "id": "457651",
      "description": "SITUADO APARCABICICLETAS - C/ FUENCARRAL N�123",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENCARRAL",
      "addressNumber": "123",
      "addressCode": "11039923",
      "model": "",
      "address": "CALLE FUENCARRAL 123, TRAFALGAR, MADRID",
      "lat": 40.4306408,
      "lng": -3.7034155
  },
  {
      "id": "457653",
      "description": "SITUADO APARCABICICLETAS - C/ SAN BERNARDO N�107",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN BERNARDO",
      "addressNumber": "107",
      "addressCode": "11039630",
      "model": "",
      "address": "CALLE SAN BERNARDO 107, ARAPILES, MADRID",
      "lat": 40.4319983,
      "lng": -3.7057148
  },
  {
      "id": "457654",
      "description": "SITUADO APARCABICICLETAS - C/ MAGALLANES N�3",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAGALLANES",
      "addressNumber": "3",
      "addressCode": "11039624",
      "model": "",
      "address": "CALLE MAGALLANES 3, ARAPILES, MADRID",
      "lat": 40.4327042,
      "lng": -3.7055586
  },
  {
      "id": "457656",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�32",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "32",
      "addressCode": "11040240",
      "model": "",
      "address": "CALLE BRAVO MURILLO 32, ARAPILES, MADRID",
      "lat": 40.4370661,
      "lng": -3.7040581
  },
  {
      "id": "457658",
      "description": "SITUADO APARCABICICLETAS - C/ BRAVO MURILLO N�49",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRAVO MURILLO",
      "addressNumber": "49",
      "addressCode": "31044176",
      "model": "",
      "address": "CALLE BRAVO MURILLO 49, VALLEHERMOSO, MADRID",
      "lat": 40.4393401,
      "lng": -3.7043673
  },
  {
      "id": "457661",
      "description": "SITUADO APARCABICICLETAS - C/ CEA BERMUDEZ N�26",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CEA BERMUDEZ",
      "addressNumber": "26",
      "addressCode": "11042545",
      "model": "",
      "address": "CALLE CEA BERMUDEZ 26, VALLEHERMOSO, MADRID",
      "lat": 40.4392124,
      "lng": -3.7088071
  },
  {
      "id": "457678",
      "description": "SITUADO APARCABICICLETAS - C/ GRAL. ALVAREZ DE CASTRO N�46",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL ALVAREZ DE CASTRO",
      "addressNumber": "46",
      "addressCode": "11040310",
      "model": "",
      "address": "CALLE GENERAL ALVAREZ DE CASTRO 46, TRAFALGAR, MADRID",
      "lat": 40.43831,
      "lng": -3.7022263
  },
  {
      "id": "457681",
      "description": "SITUADO APARCABICICLETAS - C/ GRAL. ALVAREZ DE CASTRO N�46",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL ALVAREZ DE CASTRO",
      "addressNumber": "46",
      "addressCode": "11040310",
      "model": "",
      "address": "CALLE GENERAL ALVAREZ DE CASTRO 46, TRAFALGAR, MADRID",
      "lat": 40.43831,
      "lng": -3.7022263
  },
  {
      "id": "457683",
      "description": "SITUADO APARCABICICLETAS - C/ JOSE ABASCAL N�39",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE ABASCAL",
      "addressNumber": "39",
      "addressCode": "11041733",
      "model": "",
      "address": "CALLE JOSE ABASCAL 39, RIOS ROSAS, MADRID",
      "lat": 40.438652,
      "lng": -3.696965999999999
  },
  {
      "id": "457688",
      "description": "SITUADO APARCABICICLETAS - P� CASTELLANA N�53",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "75",
      "neighborhoodName": "RIOS ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "53",
      "addressCode": "31046076",
      "model": "",
      "address": "PASEO CASTELLANA 53, RIOS ROSAS, MADRID",
      "lat": 40.4383317,
      "lng": -3.691547399999999
  },
  {
      "id": "457689",
      "description": "SITUADO APARCABICICLETAS - C/ ALONSO CANO N� 19",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALONSO CANO",
      "addressNumber": "19",
      "addressCode": "20014366",
      "model": "",
      "address": "CALLE ALONSO CANO 19, ALMAGRO, MADRID",
      "lat": 40.4368555,
      "lng": -3.698024
  },
  {
      "id": "457693",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ENGRACIA N�69",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ENGRACIA",
      "addressNumber": "69",
      "addressCode": "11040521",
      "model": "",
      "address": "CALLE SANTA ENGRACIA 69, TRAFALGAR, MADRID",
      "lat": 40.4344572,
      "lng": -3.6987407
  },
  {
      "id": "457706",
      "description": "SITUADO APARCABICICLETAS - P� EDUARDO DATO N�2",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "EDUARDO DATO",
      "addressNumber": "2",
      "addressCode": "31012940",
      "model": "",
      "address": "PASEO EDUARDO DATO 2, ALMAGRO, MADRID",
      "lat": 40.432461,
      "lng": -3.6962013
  },
  {
      "id": "7141904",
      "description": "SITUADO APARCABICICLETAS - C/�PEZ�AUSTRAL�2",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEZ AUSTRAL",
      "addressNumber": "2",
      "addressCode": "11011981",
      "model": "",
      "address": "CALLE PEZ AUSTRAL 2, ESTRELLA, MADRID",
      "lat": 40.4137422,
      "lng": -3.6666217
  },
  {
      "id": "457716",
      "description": "SITUADO APARCABICICLETAS - C/ FERNANDEZ DE LA HOZ N�27",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FERNANDEZ DE LA HOZ",
      "addressNumber": "27",
      "addressCode": "11041298",
      "model": "",
      "address": "CALLE FERNANDEZ DE LA HOZ 27, ALMAGRO, MADRID",
      "lat": 40.4347609,
      "lng": -3.694978299999999
  },
  {
      "id": "457719",
      "description": "SITUADO APARCABICICLETAS - P� GRAL. MARTINEZ CAMPOS N�31",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "GENERAL MARTINEZ CAMPOS",
      "addressNumber": "31",
      "addressCode": "11041370",
      "model": "",
      "address": "PASEO GENERAL MARTINEZ CAMPOS 31, ALMAGRO, MADRID",
      "lat": 40.4354806,
      "lng": -3.6937439
  },
  {
      "id": "457722",
      "description": "SITUADO APARCABICICLETAS - P� GRAL. MARTINEZ CAMPOS N�37",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "GENERAL MARTINEZ CAMPOS",
      "addressNumber": "37",
      "addressCode": "11041557",
      "model": "",
      "address": "PASEO GENERAL MARTINEZ CAMPOS 37, ALMAGRO, MADRID",
      "lat": 40.4354363,
      "lng": -3.6925173
  },
  {
      "id": "457723",
      "description": "SITUADO APARCABICICLETAS - C/ MIGUEL ANGEL N�1",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MIGUEL ANGEL",
      "addressNumber": "1",
      "addressCode": "11041249",
      "model": "",
      "address": "CALLE MIGUEL ANGEL 1, ALMAGRO, MADRID",
      "lat": 40.433482,
      "lng": -3.6916037
  },
  {
      "id": "457725",
      "description": "SITUADO APARCABICICLETAS - C/ FORTUNY N�49",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FORTUNY",
      "addressNumber": "49",
      "addressCode": "20014210",
      "model": "",
      "address": "CALLE FORTUNY 49, ALMAGRO, MADRID",
      "lat": 40.43392550000001,
      "lng": -3.6902493
  },
  {
      "id": "457728",
      "description": "SITUADO APARCABICICLETAS - P� CASTELLANA N�35",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "35",
      "addressCode": "11041192",
      "model": "",
      "address": "PASEO CASTELLANA 35, ALMAGRO, MADRID",
      "lat": 40.4336347,
      "lng": -3.6893051
  },
  {
      "id": "457729",
      "description": "SITUADO APARCABICICLETAS - C/ ZURBARAN N�21",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ZURBARAN",
      "addressNumber": "21",
      "addressCode": "11040978",
      "model": "",
      "address": "CALLE ZURBARAN 21, ALMAGRO, MADRID",
      "lat": 40.4297472,
      "lng": -3.6916028
  },
  {
      "id": "457731",
      "description": "SITUADO APARCABICICLETAS - C/ ALMAGRO N�7",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "74",
      "neighborhoodName": "ALMAGRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALMAGRO",
      "addressNumber": "7",
      "addressCode": "20014094",
      "model": "",
      "address": "CALLE ALMAGRO 7, ALMAGRO, MADRID",
      "lat": 40.4291576,
      "lng": -3.6950915
  },
  {
      "id": "457734",
      "description": "SITUADO APARCABICICLETAS - C/ SANTA ENGRACIA N�15",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA ENGRACIA",
      "addressNumber": "15",
      "addressCode": "11040784",
      "model": "",
      "address": "CALLE SANTA ENGRACIA 15, TRAFALGAR, MADRID",
      "lat": 40.4299764,
      "lng": -3.6968354
  },
  {
      "id": "457737",
      "description": "SITUADO APARCABICICLETAS - C/ FRANCISCO DE ROJAS N�14",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "73",
      "neighborhoodName": "TRAFALGAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO DE ROJAS",
      "addressNumber": "14",
      "addressCode": "31037600",
      "model": "",
      "address": "CALLE FRANCISCO DE ROJAS 14, TRAFALGAR, MADRID",
      "lat": 40.4301795,
      "lng": -3.6995539
  },
  {
      "id": "457741",
      "description": "SITUADO APARCABICICLETAS - C/ SERRANO ANGUITA N�13",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SERRANO ANGUITA",
      "addressNumber": "13",
      "addressCode": "11005281",
      "model": "",
      "address": "CALLE SERRANO ANGUITA 13, JUSTICIA, MADRID",
      "lat": 40.4276989,
      "lng": -3.699074899999999
  },
  {
      "id": "457747",
      "description": "SITUADO APARCABICICLETAS - Pz. DOS DE MAYO N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "DOS DE MAYO",
      "addressNumber": "3",
      "addressCode": "11006142",
      "model": "",
      "address": "PLAZA DOS DE MAYO 3, UNIVERSIDAD, MADRID",
      "lat": 40.4270609,
      "lng": -3.7045232
  },
  {
      "id": "457752",
      "description": "SITUADO APARCABICICLETAS - C/ EVARISTO SAN MIGUEL N�7",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "92",
      "neighborhoodName": "ARGUELLES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EVARISTO SAN MIGUEL",
      "addressNumber": "7",
      "addressCode": "11052385",
      "model": "",
      "address": "CALLE EVARISTO SAN MIGUEL 7, ARGUELLES, MADRID",
      "lat": 40.4267468,
      "lng": -3.7151706
  },
  {
      "id": "457755",
      "description": "SITUADO APARCABICICLETAS - C/ VENTURA RODRIGUEZ N�17",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "92",
      "neighborhoodName": "ARGUELLES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VENTURA RODRIGUEZ",
      "addressNumber": "17",
      "addressCode": "11052307",
      "model": "",
      "address": "CALLE VENTURA RODRIGUEZ 17, ARGUELLES, MADRID",
      "lat": 40.42376660000001,
      "lng": -3.7145164
  },
  {
      "id": "457762",
      "description": "SITUADO APARCABICICLETAS - C/ MARQUES DE LEGANES N�5",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARQUES DE LEGANES",
      "addressNumber": "5",
      "addressCode": "11007091",
      "model": "",
      "address": "CALLE MARQUES DE LEGANES 5, UNIVERSIDAD, MADRID",
      "lat": 40.4220368,
      "lng": -3.7070825
  },
  {
      "id": "457765",
      "description": "SITUADO APARCABICICLETAS - C/ LIBREROS N�16",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LIBREROS",
      "addressNumber": "16",
      "addressCode": "11007082",
      "model": "",
      "address": "CALLE LIBREROS 16, UNIVERSIDAD, MADRID",
      "lat": 40.4219506,
      "lng": -3.706359
  },
  {
      "id": "457767",
      "description": "SITUADO APARCABICICLETAS - Pz. SAN ILDEFONSO N�2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "SAN ILDEFONSO",
      "addressNumber": "2",
      "addressCode": "11005585",
      "model": "",
      "address": "PLAZA SAN ILDEFONSO 2, UNIVERSIDAD, MADRID",
      "lat": 40.4239848,
      "lng": -3.7024146
  },
  {
      "id": "457774",
      "description": "SITUADO APARCABICICLETAS - C/ FUENCARRAL N�35",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENCARRAL",
      "addressNumber": "35",
      "addressCode": "11005641",
      "model": "",
      "address": "CALLE FUENCARRAL 35, UNIVERSIDAD, MADRID",
      "lat": 40.4224281,
      "lng": -3.7010569
  },
  {
      "id": "457776",
      "description": "SITUADO APARCABICICLETAS - C/ FUENCARRAL N�29",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENCARRAL",
      "addressNumber": "29",
      "addressCode": "11005638",
      "model": "",
      "address": "CALLE FUENCARRAL 29, UNIVERSIDAD, MADRID",
      "lat": 40.422028,
      "lng": -3.701136199999999
  },
  {
      "id": "457777",
      "description": "SITUADO APARCABICICLETAS - C/ VALVERDE N�22",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALVERDE",
      "addressNumber": "22",
      "addressCode": "11005658",
      "model": "",
      "address": "CALLE VALVERDE 22, UNIVERSIDAD, MADRID",
      "lat": 40.421893,
      "lng": -3.7017055
  },
  {
      "id": "457780",
      "description": "SITUADO APARCABICICLETAS - C/ FUENCARRAL N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENCARRAL",
      "addressNumber": "3",
      "addressCode": "20006418",
      "model": "",
      "address": "CALLE FUENCARRAL 3, UNIVERSIDAD, MADRID",
      "lat": 40.4204426,
      "lng": -3.7017177
  },
  {
      "id": "457781",
      "description": "SITUADO APARCABICICLETAS - Pz. PEDRO ZEROLO N�6",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PEDRO ZEROLO",
      "addressNumber": "6",
      "addressCode": "11004562",
      "model": "",
      "address": "PLAZA PEDRO ZEROLO 6, JUSTICIA, MADRID",
      "lat": 40.421285,
      "lng": -3.6992644
  },
  {
      "id": "457784",
      "description": "SITUADO APARCABICICLETAS - C/ SAN GREGORIO N�10",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN GREGORIO",
      "addressNumber": "10",
      "addressCode": "31037770",
      "model": "",
      "address": "CALLE SAN GREGORIO 10, JUSTICIA, MADRID",
      "lat": 40.4241122,
      "lng": -3.6969734
  },
  {
      "id": "457785",
      "description": "SITUADO APARCABICICLETAS - C/ BELEN N�7",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BELEN",
      "addressNumber": "7",
      "addressCode": "11005011",
      "model": "",
      "address": "CALLE BELEN 7, JUSTICIA, MADRID",
      "lat": 40.424256,
      "lng": -3.6964093
  },
  {
      "id": "457900",
      "description": "SITUADO APARCABICICLETAS - Pz. PUERTA DEL SOL N�8",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PUERTA DEL SOL",
      "addressNumber": "8",
      "addressCode": "20006804",
      "model": "",
      "address": "PLAZA PUERTA DEL SOL 8, SOL, MADRID",
      "lat": 40.4169343,
      "lng": -3.702345
  },
  {
      "id": "457904",
      "description": "SITUADO APARCABICICLETAS - C/ MAYOR N�8",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAYOR",
      "addressNumber": "8",
      "addressCode": "20007086",
      "model": "",
      "address": "CALLE MAYOR 8, SOL, MADRID",
      "lat": 40.416668,
      "lng": -3.705893199999999
  },
  {
      "id": "457906",
      "description": "SITUADO APARCABICICLETAS - C/ MAYOR N�26",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAYOR",
      "addressNumber": "26",
      "addressCode": "11007812",
      "model": "",
      "address": "CALLE MAYOR 26, SOL, MADRID",
      "lat": 40.4164321,
      "lng": -3.707230999999999
  },
  {
      "id": "457908",
      "description": "SITUADO APARCABICICLETAS - C/ MAYOR N�32",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAYOR",
      "addressNumber": "32",
      "addressCode": "11007815",
      "model": "",
      "address": "CALLE MAYOR 32, SOL, MADRID",
      "lat": 40.4162606,
      "lng": -3.7076848
  },
  {
      "id": "457910",
      "description": "SITUADO APARCABICICLETAS - C/ CAVA DE SAN MIGUEL N�2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAVA DE SAN MIGUEL",
      "addressNumber": "2",
      "addressCode": "20104677",
      "model": "",
      "address": "CALLE CAVA DE SAN MIGUEL 2, SOL, MADRID",
      "lat": 40.4156604,
      "lng": -3.7090292
  },
  {
      "id": "457942",
      "description": "SITUADO APARCABICICLETAS - C/ SAN JUSTO N�1",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN JUSTO",
      "addressNumber": "1",
      "addressCode": "11000634",
      "model": "",
      "address": "CALLE SAN JUSTO 1, PALACIO, MADRID",
      "lat": 40.4138814,
      "lng": -3.709171
  },
  {
      "id": "457963",
      "description": "SITUADO APARCABICICLETAS - C/ JOAQUIN DICENTA N�1",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "64",
      "neighborhoodName": "ALMENARA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOAQUIN DICENTA",
      "addressNumber": "1",
      "addressCode": "11032551",
      "model": "",
      "address": "CALLE JOAQUIN DICENTA 1, ALMENARA, MADRID",
      "lat": 40.4712605,
      "lng": -3.6953072
  },
  {
      "id": "458000",
      "description": "SITUADO APARCABICICLETAS - Av. PABLO IGLESIAS N�46",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "61",
      "neighborhoodName": "BELLAS VISTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PABLO IGLESIAS",
      "addressNumber": "46",
      "addressCode": "11027780",
      "model": "",
      "address": "AVENIDA PABLO IGLESIAS 46, BELLAS VISTAS, MADRID",
      "lat": 40.4496816,
      "lng": -3.709796
  },
  {
      "id": "458013",
      "description": "SITUADO APARCABICICLETAS - Pz. MARQUES DE COMILLAS N�1",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "MARQUES DE COMILLAS",
      "addressNumber": "1",
      "addressCode": "20146709",
      "model": "",
      "address": "PLAZA MARQUES DE COMILLAS 1, VALLEHERMOSO, MADRID",
      "lat": 40.4454842,
      "lng": -3.7192455
  },
  {
      "id": "4737309",
      "description": "SITUADO APARCABICICLETAS - CALLE�JUAN�BRAVO�16",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "46",
      "neighborhoodName": "CASTELLANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN BRAVO",
      "addressNumber": "16",
      "addressCode": "11019596",
      "model": "",
      "address": "CALLE JUAN BRAVO 16, CASTELLANA, MADRID",
      "lat": 40.4326432,
      "lng": -3.6830978
  },
  {
      "id": "4738110",
      "description": "SITUADO_APARCABICICLETAS - ESTACION CERCANIAS EL POZO",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUERTO DE BALBARAN",
      "addressNumber": "10",
      "addressCode": "20126524",
      "model": "",
      "address": "CALLE PUERTO DE BALBARAN 10, ENTREVIAS, MADRID",
      "lat": 40.3762894,
      "lng": -3.6558183
  },
  {
      "id": "528819",
      "description": "SITUADO APARCABICICLETAS - CALLE BAHIA DE POLLENSA 5",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "215",
      "neighborhoodName": "CORRALEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BAHIA DE POLLENSA",
      "addressNumber": "5",
      "addressCode": "31011498",
      "model": "",
      "address": "CALLE BAHIA DE POLLENSA 5, CORRALEJOS, MADRID",
      "lat": 40.464866,
      "lng": -3.593252999999999
  },
  {
      "id": "528822",
      "description": "SITUADO APARCABICICLETAS - CALLE ANGEL LUIS DE LA HERRAN 35",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANGEL LUIS DE LA HERRAN",
      "addressNumber": "35",
      "addressCode": "20089609",
      "model": "",
      "address": "CALLE ANGEL LUIS DE LA HERRAN 35, PINAR DEL REY, MADRID",
      "lat": 40.4710452,
      "lng": -3.647235
  },
  {
      "id": "528824",
      "description": "SITUADO APARCABICICLETAS - CALLE SAMANIEGO 12",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAMANIEGO",
      "addressNumber": "12",
      "addressCode": "11136472",
      "model": "",
      "address": "CALLE SAMANIEGO 12, REJAS, MADRID",
      "lat": 40.4438648,
      "lng": -3.5823765
  },
  {
      "id": "528826",
      "description": "SITUADO APARCABICICLETAS - CALLE AURORA REDONDO 4",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AURORA REDONDO",
      "addressNumber": "4",
      "addressCode": "20167083",
      "model": "",
      "address": "CALLE AURORA REDONDO 4, VALDEFUENTES, MADRID",
      "lat": 40.4799051,
      "lng": -3.6313527
  },
  {
      "id": "528827",
      "description": "SITUADO APARCABICICLETAS - CALLE IQUITOS 20",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "IQUITOS",
      "addressNumber": "20",
      "addressCode": "20043518",
      "model": "",
      "address": "CALLE IQUITOS 20, EL SALVADOR, MADRID",
      "lat": 40.4416519,
      "lng": -3.6287387
  },
  {
      "id": "528828",
      "description": "SITUADO APARCABICICLETAS - CALLE TORRE ARIAS 7",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TORRE ARIAS",
      "addressNumber": "7",
      "addressCode": "20043316",
      "model": "",
      "address": "CALLE TORRE ARIAS 7, CANILLEJAS, MADRID",
      "lat": 40.4464702,
      "lng": -3.6123568
  },
  {
      "id": "528830",
      "description": "SITUADO APARCABICICLETAS - CALLE TELEMACO 11",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TELEMACO",
      "addressNumber": "11",
      "addressCode": "20043588",
      "model": "",
      "address": "CALLE TELEMACO 11, EL SALVADOR, MADRID",
      "lat": 40.4481173,
      "lng": -3.6439445
  },
  {
      "id": "528831",
      "description": "SITUADO APARCABICICLETAS - CALLE JUAN IGNACIO LUCA DE TENA 23",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN IGNACIO LUCA DE TENA",
      "addressNumber": "23",
      "addressCode": "20071600",
      "model": "",
      "address": "CALLE JUAN IGNACIO LUCA DE TENA 23, EL SALVADOR, MADRID",
      "lat": 40.4469713,
      "lng": -3.628573
  },
  {
      "id": "528832",
      "description": "SITUADO APARCABICICLETAS - CALLE TELEMACO 7",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TELEMACO",
      "addressNumber": "7",
      "addressCode": "20043584",
      "model": "",
      "address": "CALLE TELEMACO 7, EL SALVADOR, MADRID",
      "lat": 40.4480157,
      "lng": -3.645055599999999
  },
  {
      "id": "528834",
      "description": "SITUADO APARCABICICLETAS - CALLE ONCE 7",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ONCE",
      "addressNumber": "7",
      "addressCode": "31024847",
      "model": "",
      "address": "CALLE ONCE 7, REJAS, MADRID",
      "lat": 40.4444608,
      "lng": -3.5947038
  },
  {
      "id": "528835",
      "description": "SITUADO APARCABICICLETAS - AVENIDA GUADALAJARA 18",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GUADALAJARA",
      "addressNumber": "18",
      "addressCode": "31044557",
      "model": "",
      "address": "AVENIDA GUADALAJARA 18, ARCOS, MADRID",
      "lat": 40.4205722,
      "lng": -3.617262899999999
  },
  {
      "id": "528836",
      "description": "SITUADO APARCABICICLETAS - CALLE NORAY 11",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NORAY",
      "addressNumber": "11",
      "addressCode": "20062430",
      "model": "",
      "address": "CALLE NORAY 11, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4510561,
      "lng": -3.5959726
  },
  {
      "id": "528837",
      "description": "SITUADO APARCABICICLETAS - CALLE MARTINEZ VILLERGAS 49",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARTINEZ VILLERGAS",
      "addressNumber": "49",
      "addressCode": "11111271",
      "model": "",
      "address": "CALLE MARTINEZ VILLERGAS 49, SAN PASCUAL, MADRID",
      "lat": 40.4446395,
      "lng": -3.6580859
  },
  {
      "id": "528838",
      "description": "SITUADO APARCABICICLETAS - AVENIDA MANOTERAS 49",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MANOTERAS",
      "addressNumber": "49",
      "addressCode": "31020648",
      "model": "",
      "address": "AVENIDA MANOTERAS 49, VALDEFUENTES, MADRID",
      "lat": 40.4876368,
      "lng": -3.6614575
  },
  {
      "id": "528839",
      "description": "SITUADO APARCABICICLETAS - CALLE EL PROVENCIO 20",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EL PROVENCIO",
      "addressNumber": "20",
      "addressCode": "20094724",
      "model": "",
      "address": "CALLE EL PROVENCIO 20, CANILLAS, MADRID",
      "lat": 40.4655682,
      "lng": -3.628256299999999
  },
  {
      "id": "528840",
      "description": "SITUADO APARCABICICLETAS - CALLE CORBETA 12",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CORBETA",
      "addressNumber": "12",
      "addressCode": "11139982",
      "model": "",
      "address": "CALLE CORBETA 12, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4571745,
      "lng": -3.5876129
  },
  {
      "id": "7181774",
      "description": "SITUADO APARCABICILETAS - C/ DE JUAN DUQUE 22",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN DUQUE",
      "addressNumber": "22",
      "addressCode": "20007154",
      "model": "",
      "address": "CALLE JUAN DUQUE 22, IMPERIAL, MADRID",
      "lat": 40.4122242,
      "lng": -3.719575
  },
  {
      "id": "528844",
      "description": "SITUADO APARCABICICLETAS - PLAZA NUESTRA SE�ORA DEL TRANSITO 12",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "NUESTRA SE�ORA DEL TRANSITO",
      "addressNumber": "12",
      "addressCode": "11116399",
      "model": "",
      "address": "PLAZA NUESTRA SE�ORA DEL TRANSITO 12, CANILLAS, MADRID",
      "lat": 40.4600409,
      "lng": -3.6485021
  },
  {
      "id": "528845",
      "description": "SITUADO APARCABICICLETAS - CALLE AMARA 4",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AMARA",
      "addressNumber": "4",
      "addressCode": "11111214",
      "model": "",
      "address": "CALLE AMARA 4, SAN PASCUAL, MADRID",
      "lat": 40.44499620000001,
      "lng": -3.6492941
  },
  {
      "id": "528847",
      "description": "SITUADO APARCABICICLETAS - CALLE MONOVAR 19",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "165",
      "neighborhoodName": "APOSTOL SANTIAGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONOVAR",
      "addressNumber": "19",
      "addressCode": "11122150",
      "model": "",
      "address": "CALLE MONOVAR 19, APOSTOL SANTIAGO, MADRID",
      "lat": 40.4784444,
      "lng": -3.656526
  },
  {
      "id": "528848",
      "description": "SITUADO APARCABICICLETAS - CALLE AGUSTIN CALVO 8 A",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AGUSTIN CALVO",
      "addressNumber": "8:00 AM",
      "addressCode": "20104818",
      "model": "",
      "address": "CALLE AGUSTIN CALVO 8:00 AM, CANILLAS, MADRID",
      "lat": 40.4639358,
      "lng": -3.6507216
  },
  {
      "id": "528849",
      "description": "SITUADO APARCABICICLETAS - CALLE PALERMO 48",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PALERMO",
      "addressNumber": "48",
      "addressCode": "11114868",
      "model": "",
      "address": "CALLE PALERMO 48, CANILLAS, MADRID",
      "lat": 40.4591552,
      "lng": -3.6469776
  },
  {
      "id": "528850",
      "description": "SITUADO APARCABICICLETAS - CALLE INFANTA CATALINA MICAELA 37",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "INFANTA CATALINA MICAELA",
      "addressNumber": "37",
      "addressCode": "31030434",
      "model": "",
      "address": "CALLE INFANTA CATALINA MICAELA 37, VALDEFUENTES, MADRID",
      "lat": 40.4903833,
      "lng": -3.6532836
  },
  {
      "id": "529108",
      "description": "SITUADO APARCABICICLETAS - CALLE EMMA PENELLA 18",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EMMA PENELLA",
      "addressNumber": "18",
      "addressCode": "31043278",
      "model": "",
      "address": "CALLE EMMA PENELLA 18, VALDEFUENTES, MADRID",
      "lat": 40.4812119,
      "lng": -3.6267273
  },
  {
      "id": "529109",
      "description": "SITUADO APARCABICICLETAS - CALLE EMMA PENELLA 2",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EMMA PENELLA",
      "addressNumber": "2",
      "addressCode": "31039876",
      "model": "",
      "address": "CALLE EMMA PENELLA 2, VALDEFUENTES, MADRID",
      "lat": 40.4808833,
      "lng": -3.628299599999999
  },
  {
      "id": "529110",
      "description": "SITUADO APARCABICICLETAS - CALLE JUAN DANIEL FULLAONDO 16 B",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN DANIEL FULLAONDO",
      "addressNumber": "16 B",
      "addressCode": "31043442",
      "model": "",
      "address": "CALLE JUAN DANIEL FULLAONDO 16 B, VALDEFUENTES, MADRID",
      "lat": 40.4805641,
      "lng": -3.6276857
  },
  {
      "id": "529111",
      "description": "SITUADO APARCABICICLETAS - CAMINO VALDEHIGUERA 24",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CAMINO",
      "addressStreet": "VALDEHIGUERA",
      "addressNumber": "24",
      "addressCode": "20126754",
      "model": "",
      "address": "CAMINO VALDEHIGUERA 24, VALDEFUENTES, MADRID",
      "lat": 40.4793509,
      "lng": -3.6267949
  },
  {
      "id": "529112",
      "description": "SITUADO APARCABICICLETAS - AVENIDA NICETO ALCALA ZAMORA 43",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "NICETO ALCALA ZAMORA",
      "addressNumber": "43",
      "addressCode": "31038266",
      "model": "",
      "address": "AVENIDA NICETO ALCALA ZAMORA 43, VALDEFUENTES, MADRID",
      "lat": 40.4950467,
      "lng": -3.6518285
  },
  {
      "id": "529113",
      "description": "SITUADO APARCABICICLETAS - CALLE PRINCIPE CARLOS 36",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRINCIPE CARLOS",
      "addressNumber": "36",
      "addressCode": "31017494",
      "model": "",
      "address": "CALLE PRINCIPE CARLOS 36, VALDEFUENTES, MADRID",
      "lat": 40.4913507,
      "lng": -3.6519673
  },
  {
      "id": "529114",
      "description": "SITUADO APARCABICICLETAS - AVENIDA MANOTERAS 46",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MANOTERAS",
      "addressNumber": "46",
      "addressCode": "20138130",
      "model": "",
      "address": "AVENIDA MANOTERAS 46, VALDEFUENTES, MADRID",
      "lat": 40.4876195,
      "lng": -3.6644629
  },
  {
      "id": "529116",
      "description": "SITUADO APARCABICICLETAS - CALLE PURCHENA 22",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "165",
      "neighborhoodName": "APOSTOL SANTIAGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PURCHENA",
      "addressNumber": "22",
      "addressCode": "20060208",
      "model": "",
      "address": "CALLE PURCHENA 22, APOSTOL SANTIAGO, MADRID",
      "lat": 40.4756845,
      "lng": -3.6639895
  },
  {
      "id": "529117",
      "description": "SITUADO APARCABICICLETAS - CALLE SOMONTIN 26",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "165",
      "neighborhoodName": "APOSTOL SANTIAGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SOMONTIN",
      "addressNumber": "26",
      "addressCode": "11121638",
      "model": "",
      "address": "CALLE SOMONTIN 26, APOSTOL SANTIAGO, MADRID",
      "lat": 40.4755455,
      "lng": -3.6619303
  },
  {
      "id": "529118",
      "description": "SITUADO APARCABICICLETAS - CALLE ABIZANDA 68",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ABIZANDA",
      "addressNumber": "68",
      "addressCode": "20089517",
      "model": "",
      "address": "CALLE ABIZANDA 68, PINAR DEL REY, MADRID",
      "lat": 40.4775554,
      "lng": -3.6511832
  },
  {
      "id": "529119",
      "description": "SITUADO APARCABICICLETAS - CALLE MAR DE ARAL 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAR DE ARAL",
      "addressNumber": "1",
      "addressCode": "11119346",
      "model": "",
      "address": "CALLE MAR DE ARAL 1, PINAR DEL REY, MADRID",
      "lat": 40.4744792,
      "lng": -3.644698099999999
  },
  {
      "id": "529120",
      "description": "SITUADO APARCABICICLETAS - CALLE MAR MENOR 25",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAR MENOR",
      "addressNumber": "25",
      "addressCode": "11119370",
      "model": "",
      "address": "CALLE MAR MENOR 25, PINAR DEL REY, MADRID",
      "lat": 40.4757243,
      "lng": -3.644679199999999
  },
  {
      "id": "529121",
      "description": "SITUADO APARCABICICLETAS - CALLE MAR AMARILLO 12",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAR AMARILLO",
      "addressNumber": "12",
      "addressCode": "11118983",
      "model": "",
      "address": "CALLE MAR AMARILLO 12, PINAR DEL REY, MADRID",
      "lat": 40.4743574,
      "lng": -3.6413074
  },
  {
      "id": "529122",
      "description": "SITUADO APARCABICICLETAS - CALLE AREQUIPA 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AREQUIPA",
      "addressNumber": "1",
      "addressCode": "31049247",
      "model": "",
      "address": "CALLE AREQUIPA 1, CANILLAS, MADRID",
      "lat": 40.4694241,
      "lng": -3.6383243
  },
  {
      "id": "529123",
      "description": "SITUADO APARCABICICLETAS - CALLE JAVIER DEL QUINTO 12",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JAVIER DEL QUINTO",
      "addressNumber": "12",
      "addressCode": "31008306",
      "model": "",
      "address": "CALLE JAVIER DEL QUINTO 12, CANILLAS, MADRID",
      "lat": 40.4666699,
      "lng": -3.6418185
  },
  {
      "id": "529124",
      "description": "SITUADO APARCABICICLETAS - CALLE TORQUEMADA 5",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TORQUEMADA",
      "addressNumber": "5",
      "addressCode": "31008854",
      "model": "",
      "address": "CALLE TORQUEMADA 5, CANILLAS, MADRID",
      "lat": 40.4661914,
      "lng": -3.6405237
  },
  {
      "id": "529125",
      "description": "SITUADO APARCABICICLETAS - CALLE AYACUCHO 30",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AYACUCHO",
      "addressNumber": "30",
      "addressCode": "20139448",
      "model": "",
      "address": "CALLE AYACUCHO 30, PINAR DEL REY, MADRID",
      "lat": 40.4690263,
      "lng": -3.634367499999999
  },
  {
      "id": "529126",
      "description": "SITUADO APARCABICICLETAS - CALLE LAS PEDRO�ERAS 39",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LAS PEDRO�ERAS",
      "addressNumber": "39",
      "addressCode": "11117013",
      "model": "",
      "address": "CALLE LAS PEDRO�ERAS 39, CANILLAS, MADRID",
      "lat": 40.4679057,
      "lng": -3.6316089
  },
  {
      "id": "529127",
      "description": "SITUADO APARCABICICLETAS - CALLE AGUSTIN DE ITURBIDE 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AGUSTIN DE ITURBIDE",
      "addressNumber": "1",
      "addressCode": "31044660",
      "model": "",
      "address": "CALLE AGUSTIN DE ITURBIDE 1, PINAR DEL REY, MADRID",
      "lat": 40.4688312,
      "lng": -3.6487308
  },
  {
      "id": "529128",
      "description": "SITUADO APARCABICICLETAS - CALLE RICARDO SAN JUAN 2",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RICARDO SAN JUAN",
      "addressNumber": "2",
      "addressCode": "31034365",
      "model": "",
      "address": "CALLE RICARDO SAN JUAN 2, CANILLAS, MADRID",
      "lat": 40.463873,
      "lng": -3.6527436
  },
  {
      "id": "529129",
      "description": "SITUADO APARCABICICLETAS - CARRETERA CANILLAS 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CARRETERA",
      "addressStreet": "CANILLAS",
      "addressNumber": "1",
      "addressCode": "31041743",
      "model": "",
      "address": "CARRETERA CANILLAS 1, CANILLAS, MADRID",
      "lat": 40.4638026,
      "lng": -3.6564621
  },
  {
      "id": "529130",
      "description": "SITUADO APARCABICICLETAS - CALLE JAZMIN 15",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "159",
      "neighborhoodName": "COSTILLARES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JAZMIN",
      "addressNumber": "15",
      "addressCode": "11112772",
      "model": "",
      "address": "CALLE JAZMIN 15, COSTILLARES, MADRID",
      "lat": 40.4802539,
      "lng": -3.6655242
  },
  {
      "id": "529131",
      "description": "SITUADO APARCABICICLETAS - CALLE SOMONTIN 59",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "165",
      "neighborhoodName": "APOSTOL SANTIAGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SOMONTIN",
      "addressNumber": "59",
      "addressCode": "20064870",
      "model": "",
      "address": "CALLE SOMONTIN 59, APOSTOL SANTIAGO, MADRID",
      "lat": 40.47772519999999,
      "lng": -3.6602568
  },
  {
      "id": "529132",
      "description": "SITUADO APARCABICICLETAS - CALLE ROSA DE CASTRO 17",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROSA DE CASTRO",
      "addressNumber": "17",
      "addressCode": "20157452",
      "model": "",
      "address": "CALLE ROSA DE CASTRO 17, CANILLAS, MADRID",
      "lat": 40.4657416,
      "lng": -3.6335286
  },
  {
      "id": "529133",
      "description": "SITUADO APARCABICICLETAS - CALLE PEONIAS 2",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "161",
      "neighborhoodName": "PALOMAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEONIAS",
      "addressNumber": "2",
      "addressCode": "11113206",
      "model": "",
      "address": "CALLE PEONIAS 2, PALOMAS, MADRID",
      "lat": 40.4506408,
      "lng": -3.6179092
  },
  {
      "id": "529134",
      "description": "SITUADO APARCABICICLETAS - AVENIDA PARTENON 5",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "215",
      "neighborhoodName": "CORRALEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PARTENON",
      "addressNumber": "5",
      "addressCode": "20058452",
      "model": "",
      "address": "AVENIDA PARTENON 5, CORRALEJOS, MADRID",
      "lat": 40.464228,
      "lng": -3.6131333
  },
  {
      "id": "529135",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SEPTIMA 41",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SEPTIMA",
      "addressNumber": "41",
      "addressCode": "31054048",
      "model": "",
      "address": "AVENIDA SEPTIMA 41, REJAS, MADRID",
      "lat": 40.4498118,
      "lng": -3.6005398
  },
  {
      "id": "529136",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SEXTA 6",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SEXTA",
      "addressNumber": "6",
      "addressCode": "31010276",
      "model": "",
      "address": "AVENIDA SEXTA 6, REJAS, MADRID",
      "lat": 40.4486217,
      "lng": -3.5970194
  },
  {
      "id": "529137",
      "description": "SITUADO APARCABICICLETAS - CALLE PIRRA 14",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PIRRA",
      "addressNumber": "14",
      "addressCode": "31043339",
      "model": "",
      "address": "CALLE PIRRA 14, REJAS, MADRID",
      "lat": 40.4452633,
      "lng": -3.5887831
  },
  {
      "id": "529138",
      "description": "SITUADO APARCABICICLETAS - CALLE ARCAUTE 4",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARCAUTE",
      "addressNumber": "4",
      "addressCode": "31010974",
      "model": "",
      "address": "CALLE ARCAUTE 4, REJAS, MADRID",
      "lat": 40.4430523,
      "lng": -3.5777746
  },
  {
      "id": "529139",
      "description": "SITUADO APARCABICICLETAS - CALLE NAVARIDAS 7",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NAVARIDAS",
      "addressNumber": "7",
      "addressCode": "20090540",
      "model": "",
      "address": "CALLE NAVARIDAS 7, REJAS, MADRID",
      "lat": 40.4453026,
      "lng": -3.5784809
  },
  {
      "id": "529140",
      "description": "SITUADO APARCABICICLETAS - CALLE BALANDRO 1",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BALANDRO",
      "addressNumber": "1",
      "addressCode": "11140143",
      "model": "",
      "address": "CALLE BALANDRO 1, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.451339,
      "lng": -3.5906226
  },
  {
      "id": "529141",
      "description": "SITUADO APARCABICICLETAS - CALLE MEDINA DE POMAR 12",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "212",
      "neighborhoodName": "AEROPUERTO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MEDINA DE POMAR",
      "addressNumber": "12",
      "addressCode": "11140202",
      "model": "",
      "address": "CALLE MEDINA DE POMAR 12, AEROPUERTO, MADRID",
      "lat": 40.4511652,
      "lng": -3.584363799999999
  },
  {
      "id": "529143",
      "description": "SITUADO APARCABICICLETAS - CALLE BERGANTIN 11",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BERGANTIN",
      "addressNumber": "11",
      "addressCode": "11139992",
      "model": "",
      "address": "CALLE BERGANTIN 11, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4586886,
      "lng": -3.585481
  },
  {
      "id": "529145",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN SEVERO 18",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN SEVERO",
      "addressNumber": "18",
      "addressCode": "20152615",
      "model": "",
      "address": "CALLE SAN SEVERO 18, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4622604,
      "lng": -3.5813705
  },
  {
      "id": "529146",
      "description": "SITUADO APARCABICICLETAS - AVENIDA LOGRO�O 70",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "LOGRO�O",
      "addressNumber": "70",
      "addressCode": "11139809",
      "model": "",
      "address": "AVENIDA LOGRO�O 70, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4627914,
      "lng": -3.587122299999999
  },
  {
      "id": "529147",
      "description": "SITUADO APARCABICICLETAS - CALLE TIMON 20",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TIMON",
      "addressNumber": "20",
      "addressCode": "11141287",
      "model": "",
      "address": "CALLE TIMON 20, TIMON, MADRID",
      "lat": 40.4729319,
      "lng": -3.584693
  },
  {
      "id": "529148",
      "description": "SITUADO APARCABICICLETAS - AVENIDA LOGRO�O 365",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "214",
      "neighborhoodName": "TIMON",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "LOGRO�O",
      "addressNumber": "365",
      "addressCode": "31027598",
      "model": "",
      "address": "AVENIDA LOGRO�O 365, TIMON, MADRID",
      "lat": 40.4762396,
      "lng": -3.5822932
  },
  {
      "id": "529149",
      "description": "SITUADO APARCABICICLETAS - CALLE ORION 3",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "213",
      "neighborhoodName": "CASCO H.BARAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ORION",
      "addressNumber": "3",
      "addressCode": "11140690",
      "model": "",
      "address": "CALLE ORION 3, CASCO H.BARAJAS, MADRID",
      "lat": 40.4744149,
      "lng": -3.5780068
  },
  {
      "id": "529150",
      "description": "SITUADO APARCABICICLETAS - CALLE BAHIA DE MALAGA 5",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "215",
      "neighborhoodName": "CORRALEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BAHIA DE MALAGA",
      "addressNumber": "5",
      "addressCode": "20149956",
      "model": "",
      "address": "CALLE BAHIA DE MALAGA 5, CORRALEJOS, MADRID",
      "lat": 40.4671887,
      "lng": -3.5955373
  },
  {
      "id": "529151",
      "description": "SITUADO APARCABICICLETAS - GLORIETA MAR CARIBE 5",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "161",
      "neighborhoodName": "PALOMAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "MAR CARIBE",
      "addressNumber": "5",
      "addressCode": "31049487",
      "model": "",
      "address": "GLORIETA MAR CARIBE 5, PALOMAS, MADRID",
      "lat": 40.4540567,
      "lng": -3.622363999999999
  },
  {
      "id": "529152",
      "description": "SITUADO APARCABICICLETAS - CALLE GENERAL ARANAZ 66",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL ARANAZ",
      "addressNumber": "66",
      "addressCode": "11139194",
      "model": "",
      "address": "CALLE GENERAL ARANAZ 66, EL SALVADOR, MADRID",
      "lat": 40.44617239999999,
      "lng": -3.6431469
  },
  {
      "id": "529154",
      "description": "SITUADO APARCABICICLETAS - CALLE ARTURO SORIA 71",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARTURO SORIA",
      "addressNumber": "71",
      "addressCode": "20039132",
      "model": "",
      "address": "CALLE ARTURO SORIA 71, SAN PASCUAL, MADRID",
      "lat": 40.44490700000001,
      "lng": -3.6470766
  },
  {
      "id": "529155",
      "description": "SITUADO APARCABICICLETAS - CALLE JUAN PEREZ ZU�IGA 36",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN PEREZ ZU�IGA",
      "addressNumber": "36",
      "addressCode": "11110427",
      "model": "",
      "address": "CALLE JUAN PEREZ ZU�IGA 36, CONCEPCION, MADRID",
      "lat": 40.4442017,
      "lng": -3.6467598
  },
  {
      "id": "529156",
      "description": "SITUADO APARCABICICLETAS - CALLE ARTURO SORIA 126",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "156",
      "neighborhoodName": "SAN JUAN BAUTISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARTURO SORIA",
      "addressNumber": "126",
      "addressCode": "20179812",
      "model": "",
      "address": "CALLE ARTURO SORIA 126, SAN JUAN BAUTISTA, MADRID",
      "lat": 40.4499968,
      "lng": -3.6507593
  },
  {
      "id": "529157",
      "description": "SITUADO APARCABICICLETAS - CALLE TORRECILLA DEL PUERTO 1",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "156",
      "neighborhoodName": "SAN JUAN BAUTISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TORRECILLA DEL PUERTO",
      "addressNumber": "1",
      "addressCode": "11111454",
      "model": "",
      "address": "CALLE TORRECILLA DEL PUERTO 1, SAN JUAN BAUTISTA, MADRID",
      "lat": 40.4506661,
      "lng": -3.6509573
  },
  {
      "id": "529158",
      "description": "SITUADO APARCABICICLETAS - CALLE DIEGO AYLLON 8",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "156",
      "neighborhoodName": "SAN JUAN BAUTISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DIEGO AYLLON",
      "addressNumber": "8",
      "addressCode": "31022917",
      "model": "",
      "address": "CALLE DIEGO AYLLON 8, SAN JUAN BAUTISTA, MADRID",
      "lat": 40.45146829999999,
      "lng": -3.6507197
  },
  {
      "id": "529160",
      "description": "SITUADO APARCABICICLETAS - CALLE RAMIREZ DE ARELLANO 30",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "156",
      "neighborhoodName": "SAN JUAN BAUTISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RAMIREZ DE ARELLANO",
      "addressNumber": "30",
      "addressCode": "11111309",
      "model": "",
      "address": "CALLE RAMIREZ DE ARELLANO 30, SAN JUAN BAUTISTA, MADRID",
      "lat": 40.4470586,
      "lng": -3.6589267
  },
  {
      "id": "529161",
      "description": "SITUADO APARCABICICLETAS - CALLE SORZANO 1",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "156",
      "neighborhoodName": "SAN JUAN BAUTISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SORZANO",
      "addressNumber": "1",
      "addressCode": "11111674",
      "model": "",
      "address": "CALLE SORZANO 1, SAN JUAN BAUTISTA, MADRID",
      "lat": 40.4511836,
      "lng": -3.6590123
  },
  {
      "id": "529162",
      "description": "SITUADO APARCABICICLETAS - CALLE LOPEZ DE HOYOS 310",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "157",
      "neighborhoodName": "COLINA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LOPEZ DE HOYOS",
      "addressNumber": "310",
      "addressCode": "20172418",
      "model": "",
      "address": "CALLE LOPEZ DE HOYOS 310, COLINA, MADRID",
      "lat": 40.4555456,
      "lng": -3.661907999999999
  },
  {
      "id": "529163",
      "description": "SITUADO APARCABICICLETAS - CALLE CARRIL DEL CONDE 57",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CARRIL DEL CONDE",
      "addressNumber": "57",
      "addressCode": "11115457",
      "model": "",
      "address": "CALLE CARRIL DEL CONDE 57, CANILLAS, MADRID",
      "lat": 40.4608973,
      "lng": -3.654413699999999
  },
  {
      "id": "529164",
      "description": "SITUADO APARCABICICLETAS - CALLE ANDORRA 30",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANDORRA",
      "addressNumber": "30",
      "addressCode": "20000193",
      "model": "",
      "address": "CALLE ANDORRA 30, CANILLAS, MADRID",
      "lat": 40.4601633,
      "lng": -3.6427623
  },
  {
      "id": "529165",
      "description": "SITUADO APARCABICICLETAS - CALLE LOPEZ DE ARANDA 29",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LOPEZ DE ARANDA",
      "addressNumber": "29",
      "addressCode": "20109179",
      "model": "",
      "address": "CALLE LOPEZ DE ARANDA 29, EL SALVADOR, MADRID",
      "lat": 40.4444813,
      "lng": -3.6347454
  },
  {
      "id": "529166",
      "description": "SITUADO APARCABICICLETAS - CALLE MARIA LOMBILLO 3",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARIA LOMBILLO",
      "addressNumber": "3",
      "addressCode": "11139241",
      "model": "",
      "address": "CALLE MARIA LOMBILLO 3, EL SALVADOR, MADRID",
      "lat": 40.4434762,
      "lng": -3.6385101
  },
  {
      "id": "529167",
      "description": "SITUADO APARCABICICLETAS - CALLE LOPEZ DE ARANDA 5",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LOPEZ DE ARANDA",
      "addressNumber": "5",
      "addressCode": "11110428",
      "model": "",
      "address": "CALLE LOPEZ DE ARANDA 5, CONCEPCION, MADRID",
      "lat": 40.4422492,
      "lng": -3.6399694
  },
  {
      "id": "529168",
      "description": "SITUADO APARCABICICLETAS - CALLE ZIGIA 1",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "153",
      "neighborhoodName": "QUINTANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ZIGIA",
      "addressNumber": "1",
      "addressCode": "11108626",
      "model": "",
      "address": "CALLE ZIGIA 1, QUINTANA, MADRID",
      "lat": 40.4385993,
      "lng": -3.645452
  },
  {
      "id": "529169",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE DEL HIERRO 5",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE DEL HIERRO",
      "addressNumber": "5",
      "addressCode": "20094448",
      "model": "",
      "address": "CALLE JOSE DEL HIERRO 5, CONCEPCION, MADRID",
      "lat": 40.4372497,
      "lng": -3.6484407
  },
  {
      "id": "529171",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE DEL HIERRO 1",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE DEL HIERRO",
      "addressNumber": "1",
      "addressCode": "20094442",
      "model": "",
      "address": "CALLE JOSE DEL HIERRO 1, CONCEPCION, MADRID",
      "lat": 40.4355455,
      "lng": -3.6519195
  },
  {
      "id": "529172",
      "description": "SITUADO APARCABICICLETAS - CALLE DEMETRIO LOPEZ 6",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DEMETRIO LOPEZ",
      "addressNumber": "6",
      "addressCode": "20164578",
      "model": "",
      "address": "CALLE DEMETRIO LOPEZ 6, SAN PASCUAL, MADRID",
      "lat": 40.4373758,
      "lng": -3.6564729
  },
  {
      "id": "529173",
      "description": "SITUADO APARCABICICLETAS - AVENIDA MARQUES DE CORBERA 6",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "151",
      "neighborhoodName": "VENTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MARQUES DE CORBERA",
      "addressNumber": "6",
      "addressCode": "11103497",
      "model": "",
      "address": "AVENIDA MARQUES DE CORBERA 6, VENTAS, MADRID",
      "lat": 40.4221204,
      "lng": -3.656264
  },
  {
      "id": "529174",
      "description": "SITUADO APARCABICICLETAS - CALLE NUESTRA SE�ORA DEL VILLAR 6",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "151",
      "neighborhoodName": "VENTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NUESTRA SE�ORA DEL VILLAR",
      "addressNumber": "6",
      "addressCode": "31014928",
      "model": "",
      "address": "CALLE NUESTRA SE�ORA DEL VILLAR 6, VENTAS, MADRID",
      "lat": 40.4233733,
      "lng": -3.6518285
  },
  {
      "id": "529175",
      "description": "SITUADO APARCABICICLETAS - CALLE SANTA FELICIDAD 39",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "151",
      "neighborhoodName": "VENTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA FELICIDAD",
      "addressNumber": "39",
      "addressCode": "20167532",
      "model": "",
      "address": "CALLE SANTA FELICIDAD 39, VENTAS, MADRID",
      "lat": 40.42434970000001,
      "lng": -3.6501627
  },
  {
      "id": "529176",
      "description": "SITUADO APARCABICICLETAS - CALLE SANTA FELICIDAD 9",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "151",
      "neighborhoodName": "VENTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA FELICIDAD",
      "addressNumber": "9",
      "addressCode": "11103802",
      "model": "",
      "address": "CALLE SANTA FELICIDAD 9, VENTAS, MADRID",
      "lat": 40.4253,
      "lng": -3.64984
  },
  {
      "id": "529177",
      "description": "SITUADO APARCABICICLETAS - AVENIDA MARQUES DE CORBERA 54",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "151",
      "neighborhoodName": "VENTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MARQUES DE CORBERA",
      "addressNumber": "54",
      "addressCode": "11103853",
      "model": "",
      "address": "AVENIDA MARQUES DE CORBERA 54, VENTAS, MADRID",
      "lat": 40.4260475,
      "lng": -3.6508583
  },
  {
      "id": "529178",
      "description": "SITUADO APARCABICICLETAS - AVENIDA DAROCA 96",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "151",
      "neighborhoodName": "VENTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "DAROCA",
      "addressNumber": "96",
      "addressCode": "20117877",
      "model": "",
      "address": "AVENIDA DAROCA 96, VENTAS, MADRID",
      "lat": 40.4190701,
      "lng": -3.6337786
  },
  {
      "id": "529180",
      "description": "SITUADO APARCABICICLETAS - CALLE AQUITANIA 7",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AQUITANIA",
      "addressNumber": "7",
      "addressCode": "20140618",
      "model": "",
      "address": "CALLE AQUITANIA 7, ARCOS, MADRID",
      "lat": 40.41811879999999,
      "lng": -3.620379
  },
  {
      "id": "529181",
      "description": "SITUADO APARCABICICLETAS - AVENIDA GUADALAJARA 4",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GUADALAJARA",
      "addressNumber": "4",
      "addressCode": "31030690",
      "model": "",
      "address": "AVENIDA GUADALAJARA 4, ARCOS, MADRID",
      "lat": 40.4193347,
      "lng": -3.620336
  },
  {
      "id": "529182",
      "description": "SITUADO APARCABICICLETAS - CALLE ARCOS DE JALON 22",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARCOS DE JALON",
      "addressNumber": "22",
      "addressCode": "31024172",
      "model": "",
      "address": "CALLE ARCOS DE JALON 22, ARCOS, MADRID",
      "lat": 40.4205255,
      "lng": -3.6209491
  },
  {
      "id": "529183",
      "description": "SITUADO APARCABICICLETAS - CALLE ARCOS DE JALON 11",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARCOS DE JALON",
      "addressNumber": "11",
      "addressCode": "31032027",
      "model": "",
      "address": "CALLE ARCOS DE JALON 11, ARCOS, MADRID",
      "lat": 40.4204804,
      "lng": -3.6227827
  },
  {
      "id": "529185",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSITUCION LIBRE DE ENSE�ANZA 168",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "168",
      "addressCode": "20182351",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 168, PUEBLO NUEVO, MADRID",
      "lat": 40.4232521,
      "lng": -3.627836
  },
  {
      "id": "529186",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 89",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "203",
      "neighborhoodName": "AMPOSTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "89",
      "addressCode": "11135505",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 89, AMPOSTA, MADRID",
      "lat": 40.4234717,
      "lng": -3.6271078
  },
  {
      "id": "529187",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 160",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "160",
      "addressCode": "31022699",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 160, PUEBLO NUEVO, MADRID",
      "lat": 40.42396,
      "lng": -3.6285825
  },
  {
      "id": "529188",
      "description": "SITUADO APARCABICICLETAS - CALLE FRANCISCO LARGO CABALLERO 4",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO LARGO CABALLERO",
      "addressNumber": "4",
      "addressCode": "31031998",
      "model": "",
      "address": "CALLE FRANCISCO LARGO CABALLERO 4, PUEBLO NUEVO, MADRID",
      "lat": 40.4229811,
      "lng": -3.634522499999999
  },
  {
      "id": "529189",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSITUCION LIBRE DE ENSE�ANZA 70",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "70",
      "addressCode": "11107252",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 70, PUEBLO NUEVO, MADRID",
      "lat": 40.42919,
      "lng": -3.6323115
  },
  {
      "id": "529190",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 68",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "68",
      "addressCode": "11107251",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 68, PUEBLO NUEVO, MADRID",
      "lat": 40.4296237,
      "lng": -3.6323172
  },
  {
      "id": "529191",
      "description": "SITUADO APARCABICICLETAS - CALLE ALBARRACIN 35",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "201",
      "neighborhoodName": "SIMANCAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALBARRACIN",
      "addressNumber": "35",
      "addressCode": "11133416",
      "model": "",
      "address": "CALLE ALBARRACIN 35, SIMANCAS, MADRID",
      "lat": 40.4320003,
      "lng": -3.630290099999999
  },
  {
      "id": "529192",
      "description": "SITUADO APARCABICICLETAS - CALLE JULIAN CAMARILLO 6",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "201",
      "neighborhoodName": "SIMANCAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JULIAN CAMARILLO",
      "addressNumber": "6",
      "addressCode": "20042302",
      "model": "",
      "address": "CALLE JULIAN CAMARILLO 6, SIMANCAS, MADRID",
      "lat": 40.433884,
      "lng": -3.6313007
  },
  {
      "id": "529193",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSITUCION LIBRE DE ENSE�ANZA 17 A",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "201",
      "neighborhoodName": "SIMANCAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "17 A",
      "addressCode": "11139457",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 17 A, SIMANCAS, MADRID",
      "lat": 40.4366316,
      "lng": -3.6367918
  },
  {
      "id": "529195",
      "description": "SITUADO APARCABICICLETAS - AVENIDA VEINTICINCO DE SEPTIEMBRE 1",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "VEINTICINCO DE SEPTIEMBRE",
      "addressNumber": "1",
      "addressCode": "11138895",
      "model": "",
      "address": "AVENIDA VEINTICINCO DE SEPTIEMBRE 1, EL SALVADOR, MADRID",
      "lat": 40.4431022,
      "lng": -3.6245457
  },
  {
      "id": "529196",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN ROMUALDO 17",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "201",
      "neighborhoodName": "SIMANCAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN ROMUALDO",
      "addressNumber": "17",
      "addressCode": "11133898",
      "model": "",
      "address": "CALLE SAN ROMUALDO 17, SIMANCAS, MADRID",
      "lat": 40.4379875,
      "lng": -3.6205929
  },
  {
      "id": "529197",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCALA 558",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "558",
      "addressCode": "20053045",
      "model": "",
      "address": "CALLE ALCALA 558, CANILLEJAS, MADRID",
      "lat": 40.4446567,
      "lng": -3.615334799999999
  },
  {
      "id": "529198",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN MARIANO 10",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN MARIANO",
      "addressNumber": "10",
      "addressCode": "11138066",
      "model": "",
      "address": "CALLE SAN MARIANO 10, CANILLEJAS, MADRID",
      "lat": 40.44440970000001,
      "lng": -3.6135846
  },
  {
      "id": "529199",
      "description": "SITUADO APARCABICICLETAS - CALLE MUSAS 9",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MUSAS",
      "addressNumber": "9",
      "addressCode": "11137360",
      "model": "",
      "address": "CALLE MUSAS 9, CANILLEJAS, MADRID",
      "lat": 40.4377923,
      "lng": -3.6077322
  },
  {
      "id": "529200",
      "description": "SITUADO APARCABICICLETAS - CALLE MUSAS 11",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MUSAS",
      "addressNumber": "11",
      "addressCode": "31036005",
      "model": "",
      "address": "CALLE MUSAS 11, CANILLEJAS, MADRID",
      "lat": 40.4377055,
      "lng": -3.6079696
  },
  {
      "id": "529201",
      "description": "SITUADO APARCABICICLETAS - AVENIDA ARCENTALES 28",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ARCENTALES",
      "addressNumber": "28",
      "addressCode": "31024129",
      "model": "",
      "address": "AVENIDA ARCENTALES 28, ROSAS, MADRID",
      "lat": 40.4343763,
      "lng": -3.609739
  },
  {
      "id": "529202",
      "description": "SITUADO APARCABICICLETAS - CALLE MIGUEL YUSTE 64",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "201",
      "neighborhoodName": "SIMANCAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MIGUEL YUSTE",
      "addressNumber": "64",
      "addressCode": "20042736",
      "model": "",
      "address": "CALLE MIGUEL YUSTE 64, SIMANCAS, MADRID",
      "lat": 40.4343539,
      "lng": -3.6225477
  },
  {
      "id": "529203",
      "description": "SITUADO APARCABICICLETAS - CALLE ARCOS DE JALON 120",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARCOS DE JALON",
      "addressNumber": "120",
      "addressCode": "31025072",
      "model": "",
      "address": "CALLE ARCOS DE JALON 120, ARCOS, MADRID",
      "lat": 40.4256883,
      "lng": -3.6122034
  },
  {
      "id": "529204",
      "description": "SITUADO APARCABICICLETAS - CALLE CONSERVEROS 11",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONSERVEROS",
      "addressNumber": "11",
      "addressCode": "11135732",
      "model": "",
      "address": "CALLE CONSERVEROS 11, ARCOS, MADRID",
      "lat": 40.4254705,
      "lng": -3.6190635
  },
  {
      "id": "529206",
      "description": "SITUADO APARCABICICLETAS - CALLE ARCOS DE JALON 59",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "202",
      "neighborhoodName": "HELLIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARCOS DE JALON",
      "addressNumber": "59",
      "addressCode": "31032030",
      "model": "",
      "address": "CALLE ARCOS DE JALON 59, HELLIN, MADRID",
      "lat": 40.4286096,
      "lng": -3.6132622
  },
  {
      "id": "529207",
      "description": "SITUADO APARCABICICLETAS - AVENIDA CANILLEJAS A VICALVARO 135",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CANILLEJAS A VICALVARO",
      "addressNumber": "135",
      "addressCode": "20145332",
      "model": "",
      "address": "AVENIDA CANILLEJAS A VICALVARO 135, ROSAS, MADRID",
      "lat": 40.42635,
      "lng": -3.610106199999999
  },
  {
      "id": "529209",
      "description": "SITUADO APARCABICICLETAS - CALLE BUDAPEST 11",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BUDAPEST",
      "addressNumber": "11",
      "addressCode": "31025052",
      "model": "",
      "address": "CALLE BUDAPEST 11, ROSAS, MADRID",
      "lat": 40.4271426,
      "lng": -3.6026016
  },
  {
      "id": "529210",
      "description": "SITUADO APARCABICICLETAS - AVENIDA NIZA 8",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "NIZA",
      "addressNumber": "8",
      "addressCode": "31031992",
      "model": "",
      "address": "AVENIDA NIZA 8, ROSAS, MADRID",
      "lat": 40.4276868,
      "lng": -3.6057069
  },
  {
      "id": "529211",
      "description": "SITUADO APARCABICICLETAS - AVENIDA NIZA 9",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "NIZA",
      "addressNumber": "9",
      "addressCode": "20119854",
      "model": "",
      "address": "AVENIDA NIZA 9, ROSAS, MADRID",
      "lat": 40.4275247,
      "lng": -3.6066332
  },
  {
      "id": "529213",
      "description": "SITUADO APARCABICICLETAS - CALLE FRANCISCO RIOJA 14",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO RIOJA",
      "addressNumber": "14",
      "addressCode": "20177032",
      "model": "",
      "address": "CALLE FRANCISCO RIOJA 14, PUEBLO NUEVO, MADRID",
      "lat": 40.4300418,
      "lng": -3.6385201
  },
  {
      "id": "529214",
      "description": "SITUADO APARCABICICLETAS - CALLE CANAL DE PANAMA 8",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CANAL DE PANAMA",
      "addressNumber": "8",
      "addressCode": "11110958",
      "model": "",
      "address": "CALLE CANAL DE PANAMA 8, SAN PASCUAL, MADRID",
      "lat": 40.4404158,
      "lng": -3.6508781
  },
  {
      "id": "529215",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE DEL HIERRO 17",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE DEL HIERRO",
      "addressNumber": "17",
      "addressCode": "11110010",
      "model": "",
      "address": "CALLE JOSE DEL HIERRO 17, CONCEPCION, MADRID",
      "lat": 40.439637,
      "lng": -3.6459481
  },
  {
      "id": "529216",
      "description": "SITUADO APARCABICICLETAS - CALLE MIJANCAS 3",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "206",
      "neighborhoodName": "REJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MIJANCAS",
      "addressNumber": "3",
      "addressCode": "31009183",
      "model": "",
      "address": "CALLE MIJANCAS 3, REJAS, MADRID",
      "lat": 40.44805030000001,
      "lng": -3.5774397
  },
  {
      "id": "7180299",
      "description": "SITUADO APARCABICICLETAS - C/ JUAN DUQUE, 29",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN DUQUE",
      "addressNumber": "29",
      "addressCode": "11008078",
      "model": "",
      "address": "CALLE JUAN DUQUE 29, IMPERIAL, MADRID",
      "lat": 40.4108508,
      "lng": -3.7193143
  },
  {
      "id": "529235",
      "description": "SITUADO APARCABICICLETAS - CALLE JOSE DEL HIERRO 3",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "154",
      "neighborhoodName": "CONCEPCION",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE DEL HIERRO",
      "addressNumber": "3",
      "addressCode": "20094445",
      "model": "",
      "address": "CALLE JOSE DEL HIERRO 3, CONCEPCION, MADRID",
      "lat": 40.4367575,
      "lng": -3.6504415
  },
  {
      "id": "529251",
      "description": "SITUADO APARCABICICLETAS - CALLE A�IL 3",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "161",
      "neighborhoodName": "PALOMAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "A�IL",
      "addressNumber": "3",
      "addressCode": "31013946",
      "model": "",
      "address": "CALLE A�IL 3, PALOMAS, MADRID",
      "lat": 40.4526594,
      "lng": -3.6198863
  },
  {
      "id": "457609",
      "description": "SITUADO APARCABICICLETAS - Av. MONCLOA N�17",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "76",
      "neighborhoodName": "VALLEHERMOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONCLOA",
      "addressNumber": "17",
      "addressCode": "11043015",
      "model": "",
      "address": "AVENIDA MONCLOA 17, VALLEHERMOSO, MADRID",
      "lat": 40.4464517,
      "lng": -3.7181603
  },
  {
      "id": "528841",
      "description": "SITUADO APARCABICICLETAS - CARRETERA ESTACION DE HORTALEZA 11",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "165",
      "neighborhoodName": "APOSTOL SANTIAGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CARRETERA",
      "addressStreet": "ESTACION DE HORTALEZA",
      "addressNumber": "11",
      "addressCode": "11122152",
      "model": "",
      "address": "CARRETERA ESTACION DE HORTALEZA 11, APOSTOL SANTIAGO, MADRID",
      "lat": 40.47889139999999,
      "lng": -3.6528582
  },
  {
      "id": "529115",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SAN LUIS 148",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "159",
      "neighborhoodName": "COSTILLARES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SAN LUIS",
      "addressNumber": "148",
      "addressCode": "11112657",
      "model": "",
      "address": "AVENIDA SAN LUIS 148, COSTILLARES, MADRID",
      "lat": 40.4757092,
      "lng": -3.667231699999999
  },
  {
      "id": "529144",
      "description": "SITUADO APARCABICICLETAS - CALLE GALEON 27",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GALEON",
      "addressNumber": "27",
      "addressCode": "11139950",
      "model": "",
      "address": "CALLE GALEON 27, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4587627,
      "lng": -3.582495199999999
  },
  {
      "id": "529153",
      "description": "SITUADO APARCABICICLETAS - CALLE MARQUES DE PORTUGALETE 13",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARQUES DE PORTUGALETE",
      "addressNumber": "13",
      "addressCode": "11111148",
      "model": "",
      "address": "CALLE MARQUES DE PORTUGALETE 13, SAN PASCUAL, MADRID",
      "lat": 40.4465628,
      "lng": -3.6453552
  },
  {
      "id": "529159",
      "description": "SITUADO APARCABICICLETAS - CALLE TREVIANA 1",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "156",
      "neighborhoodName": "SAN JUAN BAUTISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TREVIANA",
      "addressNumber": "1",
      "addressCode": "11111307",
      "model": "",
      "address": "CALLE TREVIANA 1, SAN JUAN BAUTISTA, MADRID",
      "lat": 40.4485114,
      "lng": -3.6587988
  },
  {
      "id": "529170",
      "description": "SITUADO APARCABICICLETAS - CALLE VIRGEN DEL SAGRARIO 22",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "153",
      "neighborhoodName": "QUINTANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VIRGEN DEL SAGRARIO",
      "addressNumber": "22",
      "addressCode": "11109109",
      "model": "",
      "address": "CALLE VIRGEN DEL SAGRARIO 22, QUINTANA, MADRID",
      "lat": 40.4361162,
      "lng": -3.6485813
  },
  {
      "id": "529179",
      "description": "SITUADO APARCABICICLETAS - CALLE SANTA GENOVEVA 9",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "151",
      "neighborhoodName": "VENTAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA GENOVEVA",
      "addressNumber": "9",
      "addressCode": "20076302",
      "model": "",
      "address": "CALLE SANTA GENOVEVA 9, VENTAS, MADRID",
      "lat": 40.4218097,
      "lng": -3.6517245
  },
  {
      "id": "529184",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 131",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "131",
      "addressCode": "11135544",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 131, ARCOS, MADRID",
      "lat": 40.4196063,
      "lng": -3.6240607
  },
  {
      "id": "529194",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCALA 533",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "533",
      "addressCode": "20053193",
      "model": "",
      "address": "CALLE ALCALA 533, EL SALVADOR, MADRID",
      "lat": 40.4419603,
      "lng": -3.625142599999999
  },
  {
      "id": "529205",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCONERA 2",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "203",
      "neighborhoodName": "AMPOSTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCONERA",
      "addressNumber": "2",
      "addressCode": "11134918",
      "model": "",
      "address": "CALLE ALCONERA 2, AMPOSTA, MADRID",
      "lat": 40.4297112,
      "lng": -3.6176154
  },
  {
      "id": "3568804",
      "description": "SITUADO APARCABICICLETAS - CALLE FINA DE CALDERON 28",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FINA DE CALDERON",
      "addressNumber": "28",
      "addressCode": "31041201",
      "model": "",
      "address": "CALLE FINA DE CALDERON 28, VALDEFUENTES, MADRID",
      "lat": 40.4953533,
      "lng": -3.6177524
  },
  {
      "id": "3568805",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 80",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "80",
      "addressCode": "31039636",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 80, VALDEFUENTES, MADRID",
      "lat": 40.49537,
      "lng": -3.615659999999999
  },
  {
      "id": "3568904",
      "description": "SITUADO APARCABICICLETAS - AVENIDA NIZA 63",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "NIZA",
      "addressNumber": "63",
      "addressCode": "20107847",
      "model": "",
      "address": "AVENIDA NIZA 63, ROSAS, MADRID",
      "lat": 40.43336,
      "lng": -3.60823
  },
  {
      "id": "3568905",
      "description": "SITUADO APARCABICICLETAS - CALLE ESTOCOLMO 59",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ESTOCOLMO",
      "addressNumber": "59",
      "addressCode": "31052864",
      "model": "",
      "address": "CALLE ESTOCOLMO 59, ROSAS, MADRID",
      "lat": 40.4343643,
      "lng": -3.6047295
  },
  {
      "id": "3568906",
      "description": "SITUADO APARCABICICLETAS - AVENIDA BADAJOZ 76",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "155",
      "neighborhoodName": "SAN PASCUAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "BADAJOZ",
      "addressNumber": "76",
      "addressCode": "20089580",
      "model": "",
      "address": "AVENIDA BADAJOZ 76, SAN PASCUAL, MADRID",
      "lat": 40.4436129,
      "lng": -3.6500555
  },
  {
      "id": "3568908",
      "description": "SITUADO APARCABICICLETAS - C/ BARCELO N�2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "14",
      "neighborhoodName": "JUSTICIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BARCELO",
      "addressNumber": "2",
      "addressCode": "11005231",
      "model": "",
      "address": "CALLE BARCELO 2, JUSTICIA, MADRID",
      "lat": 40.4263924,
      "lng": -3.6995308
  },
  {
      "id": "3568910",
      "description": "SITUADO APARCABICICLETAS - CALLE RIOBAMBA 11",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "208",
      "neighborhoodName": "EL SALVADOR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RIOBAMBA",
      "addressNumber": "11",
      "addressCode": "20109446",
      "model": "",
      "address": "CALLE RIOBAMBA 11, EL SALVADOR, MADRID",
      "lat": 40.4400922,
      "lng": -3.6337466
  },
  {
      "id": "3568911",
      "description": "SITUADO APARCABICICLETAS - PASEO ALAMEDA DE OSUNA 46",
      "districtId": "21",
      "districtName": "BARAJAS",
      "neighborhoodId": "211",
      "neighborhoodName": "ALAMEDA DE OSUNA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "ALAMEDA DE OSUNA",
      "addressNumber": "46",
      "addressCode": "31043177",
      "model": "",
      "address": "PASEO ALAMEDA DE OSUNA 46, ALAMEDA DE OSUNA, MADRID",
      "lat": 40.4543676,
      "lng": -3.5996045
  },
  {
      "id": "3568912",
      "description": "SITUADO APARCABICICLETAS - CALLE NEVADO DEL CUMBAL 2",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "164",
      "neighborhoodName": "PINAR DEL REY",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NEVADO DEL CUMBAL",
      "addressNumber": "2",
      "addressCode": "20155841",
      "model": "",
      "address": "CALLE NEVADO DEL CUMBAL 2, PINAR DEL REY, MADRID",
      "lat": 40.4690635,
      "lng": -3.6309901
  },
  {
      "id": "3568913",
      "description": "SITUADO APARCABICICLETAS - GLORIETA MAR DE CRISTAL 1",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "MAR DE CRISTAL",
      "addressNumber": "1",
      "addressCode": "31031862",
      "model": "",
      "address": "GLORIETA MAR DE CRISTAL 1, CANILLAS, MADRID",
      "lat": 40.46927,
      "lng": -3.6389806
  },
  {
      "id": "528289",
      "description": "SITUADOS APARCABICLETAS-CENTRO DEPORTIVO MUNICIPAL ORCASUR",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "122",
      "neighborhoodName": "ORCASUR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MOREJA",
      "addressNumber": "28",
      "addressCode": "11081146",
      "model": "",
      "address": "CALLE MOREJA 28, ORCASUR, MADRID",
      "lat": 40.3683985,
      "lng": -3.6985398
  },
  {
      "id": "528291",
      "description": "SITUADOS_APARCABICICLETAS-AVENIDA DE ORCASUR 7",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "122",
      "neighborhoodName": "ORCASUR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ORCASUR",
      "addressNumber": "7",
      "addressCode": "11081101",
      "model": "",
      "address": "AVENIDA ORCASUR 7, ORCASUR, MADRID",
      "lat": 40.3701847,
      "lng": -3.7006033
  },
  {
      "id": "528334",
      "description": "SITUADOS_APARCABICICLETAS - BIBLIOTECA JOS� HIERRO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "RAFAELA YBARRA",
      "addressNumber": "43",
      "addressCode": "31012599",
      "model": "",
      "address": "AVENIDA RAFAELA YBARRA 43, PRADOLONGO, MADRID",
      "lat": 40.3819025,
      "lng": -3.710472199999999
  },
  {
      "id": "528337",
      "description": "SITUADOS_APARCABICICLETAS - CENTRO CULTURAL ORCASUR",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "122",
      "neighborhoodName": "ORCASUR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PUEBLO",
      "addressNumber": "2",
      "addressCode": "11081298",
      "model": "",
      "address": "PLAZA PUEBLO 2, ORCASUR, MADRID",
      "lat": 40.3666849,
      "lng": -3.701111399999999
  },
  {
      "id": "528338",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE CERECINOS 8",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "126",
      "neighborhoodName": "ZOFIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CERECINOS",
      "addressNumber": "8",
      "addressCode": "11085155",
      "model": "",
      "address": "CALLE CERECINOS 8, ZOFIO, MADRID",
      "lat": 40.3835954,
      "lng": -3.7136103
  },
  {
      "id": "528339",
      "description": "SITUADOS_APARCABICICLETAS - CERCAN�AS ORCASITAS",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAMPOTEJAR",
      "addressNumber": "24",
      "addressCode": "20155613",
      "model": "",
      "address": "CALLE CAMPOTEJAR 24, ORCASITAS, MADRID",
      "lat": 40.3670695,
      "lng": -3.7041753
  },
  {
      "id": "528340",
      "description": "SITUADOS_APARCABICICLETAS - PLAZA DE LA ASOCIACION 1",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "ASOCIACION",
      "addressNumber": "1",
      "addressCode": "31002588",
      "model": "",
      "address": "PLAZA ASOCIACION 1, ORCASITAS, MADRID",
      "lat": 40.3697225,
      "lng": -3.7081405
  },
  {
      "id": "528341",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE TOLOSA 24",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TOLOSA",
      "addressNumber": "24",
      "addressCode": "11080529",
      "model": "",
      "address": "CALLE TOLOSA 24, ORCASITAS, MADRID",
      "lat": 40.3728225,
      "lng": -3.7131186
  },
  {
      "id": "528342",
      "description": "SITUADOS_APARCABICICLETAS - POLIDEPORTIVO MUNICIPAL DE ORCASITAS",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "126",
      "neighborhoodName": "ZOFIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "RAFAELA YBARRA",
      "addressNumber": "52",
      "addressCode": "11085768",
      "model": "",
      "address": "AVENIDA RAFAELA YBARRA 52, ZOFIO, MADRID",
      "lat": 40.3756263,
      "lng": -3.7126159
  },
  {
      "id": "528343",
      "description": "SITUADOS_APARCABICICLETAS - CAMPO DE F�TBOL ZOFIO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "126",
      "neighborhoodName": "ZOFIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RICARDO BELTRAN Y ROZPIDE",
      "addressNumber": "14",
      "addressCode": "20001172",
      "model": "",
      "address": "CALLE RICARDO BELTRAN Y ROZPIDE 14, ZOFIO, MADRID",
      "lat": 40.3803357,
      "lng": -3.7180636
  },
  {
      "id": "528344",
      "description": "SITUADOS_APARCABICICLETAS - METRO HOSPITAL 12 DE OCTUBRE",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "122",
      "neighborhoodName": "ORCASUR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "MALAGA",
      "addressNumber": "11",
      "addressCode": "31051197",
      "model": "",
      "address": "GLORIETA MALAGA 11, ORCASUR, MADRID",
      "lat": 40.3764401,
      "lng": -3.695445
  },
  {
      "id": "528347",
      "description": "SITUADOS_APARCABICICLETAS - INSTALACI�N DEPORTIVA MUNICIPAL LA CHIMENEA",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HERMENEGILDO BIELSA",
      "addressNumber": "2A",
      "addressCode": "31044454",
      "model": "",
      "address": "CALLE HERMENEGILDO BIELSA 2A, MOSCARDO, MADRID",
      "lat": 40.3897535,
      "lng": -3.702400099999999
  },
  {
      "id": "528354",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DEL DODGE 2B",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DODGE",
      "addressNumber": "2B",
      "addressCode": "31001086",
      "model": "",
      "address": "CALLE DODGE 2B, ORCASITAS, MADRID",
      "lat": 40.36598,
      "lng": -3.710309999999999
  },
  {
      "id": "528374",
      "description": "SITUADOS_APARCABICICLETAS - JUNTA MUNICIPAL DEL DISTRITO DE USERA",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "127",
      "neighborhoodName": "PRADOLONGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOLORES BARRANCO",
      "addressNumber": "73",
      "addressCode": "31010491",
      "model": "",
      "address": "CALLE DOLORES BARRANCO 73, PRADOLONGO, MADRID",
      "lat": 40.382676,
      "lng": -3.7110233
  },
  {
      "id": "7180322",
      "description": "SITUADO APARCABICICLETAS - METRO ANTONIO MACHADO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "94",
      "neighborhoodName": "VALDEZARZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALDERRODRIGO",
      "addressNumber": "55",
      "addressCode": "11054575",
      "model": "",
      "address": "CALLE VALDERRODRIGO 55, VALDEZARZA, MADRID",
      "lat": 40.47019,
      "lng": -3.7181408
  },
  {
      "id": "528375",
      "description": "SITUADOS_APARCABICICLETAS - AVENIDA DE ORCASUR 60",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "122",
      "neighborhoodName": "ORCASUR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ORCASUR",
      "addressNumber": "60",
      "addressCode": "31016543",
      "model": "",
      "address": "AVENIDA ORCASUR 60, ORCASUR, MADRID",
      "lat": 40.3679936,
      "lng": -3.7000065
  },
  {
      "id": "528536",
      "description": "SITUADOS_APARCABICICLETAS - COLEGIO P�BLICO MESETA DE ORCASITAS",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "POBLADOS",
      "addressNumber": "165",
      "addressCode": "31007157",
      "model": "",
      "address": "AVENIDA POBLADOS 165, ORCASITAS, MADRID",
      "lat": 40.3728746,
      "lng": -3.7100395
  },
  {
      "id": "528537",
      "description": "SITUADOS_APARCABICICLETAS - METRO ALMENDRALES",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "124",
      "neighborhoodName": "ALMENDRALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CORDOBA",
      "addressNumber": "21",
      "addressCode": "31008329",
      "model": "",
      "address": "AVENIDA CORDOBA 21, ALMENDRALES, MADRID",
      "lat": 40.3836464,
      "lng": -3.6973634
  },
  {
      "id": "528538",
      "description": "SITUADOS_APARCABICICLETAS - C.D.C. MOSCARDO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANDRES ARTEAGA",
      "addressNumber": "3",
      "addressCode": "20026196",
      "model": "",
      "address": "CALLE ANDRES ARTEAGA 3, MOSCARDO, MADRID",
      "lat": 40.3891737,
      "lng": -3.7060245
  },
  {
      "id": "528539",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE SAN MAXIMO 4",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "123",
      "neighborhoodName": "SAN FERMIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN MAXIMO",
      "addressNumber": "4",
      "addressCode": "20075892",
      "model": "",
      "address": "CALLE SAN MAXIMO 4, SAN FERMIN, MADRID",
      "lat": 40.3763472,
      "lng": -3.6936904
  },
  {
      "id": "528540",
      "description": "SITUADOS_APARCABICICLETAS - CAMINO DE PERALES 90",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "123",
      "neighborhoodName": "SAN FERMIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CAMINO",
      "addressStreet": "PERALES",
      "addressNumber": "90",
      "addressCode": "31006054",
      "model": "",
      "address": "CAMINO PERALES 90, SAN FERMIN, MADRID",
      "lat": 40.3778411,
      "lng": -3.6904719
  },
  {
      "id": "528541",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE VILLABONA 4",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLABONA",
      "addressNumber": "4",
      "addressCode": "11080136",
      "model": "",
      "address": "CALLE VILLABONA 4, ORCASITAS, MADRID",
      "lat": 40.3687209,
      "lng": -3.7145957
  },
  {
      "id": "528542",
      "description": "SITUADOS_APARCABICICLETAS - COLEGIO P�BLICO PUERTO RICO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GAINZA",
      "addressNumber": "22",
      "addressCode": "31012140",
      "model": "",
      "address": "CALLE GAINZA 22, ORCASITAS, MADRID",
      "lat": 40.3683596,
      "lng": -3.718979799999999
  },
  {
      "id": "528543",
      "description": "SITUADOS_APARCABICICLETAS - POLIDEPORTIVO MUNICIPAL SAN FERM�N",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "123",
      "neighborhoodName": "SAN FERMIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ESTAFETA",
      "addressNumber": "2",
      "addressCode": "20025246",
      "model": "",
      "address": "CALLE ESTAFETA 2, SAN FERMIN, MADRID",
      "lat": 40.37440429999999,
      "lng": -3.6925278
  },
  {
      "id": "528544",
      "description": "SITUADOS_APARCABICICLETAS - CENTRO SOCIO CULTURAL SAN FERMIN",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "123",
      "neighborhoodName": "SAN FERMIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SAN FERMIN",
      "addressNumber": "5",
      "addressCode": "11081679",
      "model": "",
      "address": "AVENIDA SAN FERMIN 5, SAN FERMIN, MADRID",
      "lat": 40.3713388,
      "lng": -3.6929349
  },
  {
      "id": "528546",
      "description": "SITUADOS_APARCABICICLETAS - PLAZA DE FERNANDEZ LADREDA 1",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "FERNANDEZ LADREDA",
      "addressNumber": "1",
      "addressCode": "31054307",
      "model": "",
      "address": "PLAZA FERNANDEZ LADREDA 1, MOSCARDO, MADRID",
      "lat": 40.385143,
      "lng": -3.7171788
  },
  {
      "id": "528547",
      "description": "SITUADOS_APARCABICICLETAS - METRO USERA",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AMPARO USERA",
      "addressNumber": "47",
      "addressCode": "20148884",
      "model": "",
      "address": "CALLE AMPARO USERA 47, MOSCARDO, MADRID",
      "lat": 40.3868575,
      "lng": -3.7067937
  },
  {
      "id": "529218",
      "description": "SITUADO_APARCABICICLETAS - PABELL�N JES�S ROLLAN",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "126",
      "neighborhoodName": "ZOFIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AVENA",
      "addressNumber": "11",
      "addressCode": "31018932",
      "model": "",
      "address": "CALLE AVENA 11, ZOFIO, MADRID",
      "lat": 40.3761622,
      "lng": -3.7156119
  },
  {
      "id": "528335",
      "description": "SITUADOS_APARCABICICLETAS - METRO USERA",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MIRASIERRA",
      "addressNumber": "35",
      "addressCode": "20113222",
      "model": "",
      "address": "CALLE MIRASIERRA 35, MOSCARDO, MADRID",
      "lat": 40.3887136,
      "lng": -3.7039705
  },
  {
      "id": "461496",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO SAGRADA FAMILIA",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "143",
      "neighborhoodName": "MARROQUINA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "OBERON",
      "addressNumber": "6",
      "addressCode": "20091560",
      "model": "",
      "address": "CALLE OBERON 6, MARROQUINA, MADRID",
      "lat": 40.4061715,
      "lng": -3.6353844
  },
  {
      "id": "528277",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO PUBLICO MENENDEZ PIDAL",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "145",
      "neighborhoodName": "FONTARRON",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ENCOMIENDA DE PALACIOS",
      "addressNumber": "105",
      "addressCode": "11102759",
      "model": "",
      "address": "CALLE ENCOMIENDA DE PALACIOS 105, FONTARRON, MADRID",
      "lat": 40.4005823,
      "lng": -3.6407036
  },
  {
      "id": "528581",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO PUBLICO FONTARRON",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARROYO FONTARRON",
      "addressNumber": "24",
      "addressCode": "20089612",
      "model": "",
      "address": "CALLE ARROYO FONTARRON 24, PAVONES, MADRID",
      "lat": 40.39484059999999,
      "lng": -3.6335361
  },
  {
      "id": "528583",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO GREDOS SAN DIEGO MORATALAZ",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LUIS DE HOYOS SAINZ",
      "addressNumber": "170",
      "addressCode": "20131818",
      "model": "",
      "address": "CALLE LUIS DE HOYOS SAINZ 170, PAVONES, MADRID",
      "lat": 40.4033849,
      "lng": -3.6286672
  },
  {
      "id": "528588",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA HACIENDA DE PAVONES 223",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HACIENDA DE PAVONES",
      "addressNumber": "223",
      "addressCode": "11101305",
      "model": "",
      "address": "CALLE HACIENDA DE PAVONES 223, PAVONES, MADRID",
      "lat": 40.4021313,
      "lng": -3.634407
  },
  {
      "id": "528596",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL CAMINO DE LOS VINATEROS 119",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "146",
      "neighborhoodName": "VINATEROS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAMINO DE LOS VINATEROS",
      "addressNumber": "119",
      "addressCode": "11103241",
      "model": "",
      "address": "CALLE CAMINO DE LOS VINATEROS 119, VINATEROS, MADRID",
      "lat": 40.4070053,
      "lng": -3.6438297
  },
  {
      "id": "528598",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL CAMINO DE LOS VINATEROS 1",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAMINO DE LOS VINATEROS",
      "addressNumber": "1",
      "addressCode": "20105644",
      "model": "",
      "address": "CALLE CAMINO DE LOS VINATEROS 1, MEDIA LEGUA, MADRID",
      "lat": 40.412163,
      "lng": -3.6605531
  },
  {
      "id": "528599",
      "description": "SITUADO_APARCABICICLETAS - Junta Municipal",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTE CARRANTONA",
      "addressNumber": "8",
      "addressCode": "20098134",
      "model": "",
      "address": "CALLE FUENTE CARRANTONA 8, PAVONES, MADRID",
      "lat": 40.3986642,
      "lng": -3.636945299999999
  },
  {
      "id": "528609",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE MARIO CABRE 2",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "143",
      "neighborhoodName": "MARROQUINA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARIO CABRE",
      "addressNumber": "2",
      "addressCode": "20158447",
      "model": "",
      "address": "CALLE MARIO CABRE 2, MARROQUINA, MADRID",
      "lat": 40.4059706,
      "lng": -3.6384748
  },
  {
      "id": "528610",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "145",
      "neighborhoodName": "FONTARRON",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "ENCUENTRO",
      "addressNumber": "4",
      "addressCode": "11102128",
      "model": "",
      "address": "PLAZA ENCUENTRO 4, FONTARRON, MADRID",
      "lat": 40.4047888,
      "lng": -3.6523433
  },
  {
      "id": "",
      "description": "IES Carlos Mar�a Rodr�guez de Valc�rcel",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528614",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE BRUJAS 32",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRUJAS",
      "addressNumber": "34",
      "addressCode": "31045387",
      "model": "",
      "address": "CALLE BRUJAS 34, PAVONES, MADRID",
      "lat": 40.4048858,
      "lng": -3.6258906
  },
  {
      "id": "528619",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL ALCALDE GARRIDO JUARISTI 13",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALDE GARRIDO JUARISTI",
      "addressNumber": "4",
      "addressCode": "20128801",
      "model": "",
      "address": "CALLE ALCALDE GARRIDO JUARISTI 4, MEDIA LEGUA, MADRID",
      "lat": 40.4141864,
      "lng": -3.6568857
  },
  {
      "id": "528623",
      "description": "SITUADO_APARCABICICLETAS -AVENIDA DE MORATALAZ 51",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MORATALAZ",
      "addressNumber": "51",
      "addressCode": "11101914",
      "model": "",
      "address": "AVENIDA MORATALAZ 51, MEDIA LEGUA, MADRID",
      "lat": 40.40738959999999,
      "lng": -3.6573758
  },
  {
      "id": "528624",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL ARROYO DE LA MEDIA LEGUA 51",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARROYO DE LA MEDIA LEGUA",
      "addressNumber": "51",
      "addressCode": "31022818",
      "model": "",
      "address": "CALLE ARROYO DE LA MEDIA LEGUA 51, MEDIA LEGUA, MADRID",
      "lat": 40.4130096,
      "lng": -3.6551788
  },
  {
      "id": "529221",
      "description": "SITUADO_APARCABICICLETAS - IES FELIPE",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALDEBERNARDO",
      "addressNumber": "1",
      "addressCode": "11101287",
      "model": "",
      "address": "CALLE VALDEBERNARDO 1, PAVONES, MADRID",
      "lat": 40.3976024,
      "lng": -3.634248699999999
  },
  {
      "id": "528597",
      "description": "SITUADO_APARCABICICLETAS - Colegio P�blico Francisco de Luis",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CORREGIDOR RODRIGO RODRIGUEZ",
      "addressNumber": "5",
      "addressCode": "31003481",
      "model": "",
      "address": "CALLE CORREGIDOR RODRIGO RODRIGUEZ 5, MEDIA LEGUA, MADRID",
      "lat": 40.4088428,
      "lng": -3.6602827
  },
  {
      "id": "528607",
      "description": "SITUADO_APARCABICICLETAS - IES MORATALAZ",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CORREGIDOR DIEGO DE VALDERRABANO",
      "addressNumber": "35",
      "addressCode": "20105401",
      "model": "",
      "address": "CALLE CORREGIDOR DIEGO DE VALDERRABANO 35, MEDIA LEGUA, MADRID",
      "lat": 40.412829,
      "lng": -3.6584027
  },
  {
      "id": "528618",
      "description": "SITUADO_APARCABICICLETAS - Colegio P�blico Real Armada",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "145",
      "neighborhoodName": "FONTARRON",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CORREGIDOR JUAN FRANCISCO DE LUJAN",
      "addressNumber": "108",
      "addressCode": "11102295",
      "model": "",
      "address": "CALLE CORREGIDOR JUAN FRANCISCO DE LUJAN 108, FONTARRON, MADRID",
      "lat": 40.40169170000001,
      "lng": -3.646151
  },
  {
      "id": "7180329",
      "description": "SITUADO APARCABICICLETAS - C/ ADANERO, 3",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "105",
      "neighborhoodName": "CAMPAMENTO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ADANERO",
      "addressNumber": "3",
      "addressCode": "20136248",
      "model": "",
      "address": "CALLE ADANERO 3, CAMPAMENTO, MADRID",
      "lat": 40.3938694,
      "lng": -3.7794183
  },
  {
      "id": "7180366",
      "description": "SITUADO APARCABICICLETAS - METRO ALTO EXTREMADURA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "102",
      "neighborhoodName": "PUERTA DEL ANGEL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "EXTREMADURA",
      "addressNumber": "158",
      "addressCode": "11062515",
      "model": "",
      "address": "PASEO EXTREMADURA 158, PUERTA DEL ANGEL, MADRID",
      "lat": 40.4098693,
      "lng": -3.7391605
  },
  {
      "id": "225577",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(C/�DE�PORTOMARIN)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PORTOMARIN",
      "addressNumber": "21",
      "addressCode": "31050947",
      "model": "",
      "address": "CALLE PORTOMARIN 21, VALVERDE, MADRID",
      "lat": 40.5105362,
      "lng": -3.6664475
  },
  {
      "id": "229683",
      "description": "SITUADO APARCABICICLETAS - I.E.S�LEONARDO�DA�VINCI",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BLAS CABRERA",
      "addressNumber": "90",
      "addressCode": "11068588",
      "model": "",
      "address": "CALLE BLAS CABRERA 90, LAS AGUILAS, MADRID",
      "lat": 40.3780305,
      "lng": -3.7639447
  },
  {
      "id": "233750",
      "description": "SITUADO APARCABICICLETAS - PARQUE�ALUCHE",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALMOJADO",
      "addressNumber": "173",
      "addressCode": "11065168",
      "model": "",
      "address": "CALLE VALMOJADO 173, ALUCHE, MADRID",
      "lat": 40.39016730000001,
      "lng": -3.7582893
  },
  {
      "id": "236287",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CULTURAL�FERNANDEZ�DE�LOS�RIOS",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAMARENA",
      "addressNumber": "57",
      "addressCode": "11065037",
      "model": "",
      "address": "CALLE CAMARENA 57, ALUCHE, MADRID",
      "lat": 40.3947426,
      "lng": -3.7551742
  },
  {
      "id": "176608",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�MPAL�GALLUR",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "101",
      "neighborhoodName": "LOS CARMENES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GALLUR",
      "addressNumber": "1",
      "addressCode": "11059060",
      "model": "",
      "address": "CALLE GALLUR 1, LOS CARMENES, MADRID",
      "lat": 40.3982611,
      "lng": -3.7355155
  },
  {
      "id": "176854",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�GALLUR",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "101",
      "neighborhoodName": "LOS CARMENES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GALLUR",
      "addressNumber": "2",
      "addressCode": "20019104",
      "model": "",
      "address": "CALLE GALLUR 2, LOS CARMENES, MADRID",
      "lat": 40.3988833,
      "lng": -3.735337
  },
  {
      "id": "3569262",
      "description": "SITUADO APARCABICICLETAS - PARQUE�DE�LA�CU�A�VERDE�(C/ CONCEJAL FCO JOSE JIMENEZ MARTIN 174)",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "103",
      "neighborhoodName": "LUCERO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONCEJAL FRANCISCO JOSE JIMENEZ MARTIN",
      "addressNumber": "174",
      "addressCode": "20086599",
      "model": "",
      "address": "CALLE CONCEJAL FRANCISCO JOSE JIMENEZ MARTIN 174, LUCERO, MADRID",
      "lat": 40.4007026,
      "lng": -3.75527
  },
  {
      "id": "178375",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CULTURAL�JOSE�LUIS�SAMPREDO",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "103",
      "neighborhoodName": "LUCERO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CASTROSERNA",
      "addressNumber": "4",
      "addressCode": "20086999",
      "model": "",
      "address": "CALLE CASTROSERNA 4, LUCERO, MADRID",
      "lat": 40.4017918,
      "lng": -3.7491542
  },
  {
      "id": "179215",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CONCERTADO�COLEGIO�LOURDES",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "103",
      "neighborhoodName": "LUCERO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN ROBERTO",
      "addressNumber": "8",
      "addressCode": "11064205",
      "model": "",
      "address": "CALLE SAN ROBERTO 8, LUCERO, MADRID",
      "lat": 40.40291,
      "lng": -3.75966
  },
  {
      "id": "179635",
      "description": "SITUADO APARCABICICLETAS - C/SAN�LEON�3",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "103",
      "neighborhoodName": "LUCERO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN JUAN DE MATA",
      "addressNumber": "21",
      "addressCode": "11064128",
      "model": "",
      "address": "CALLE SAN JUAN DE MATA 21, LUCERO, MADRID",
      "lat": 40.4036437,
      "lng": -3.7576139
  },
  {
      "id": "181120",
      "description": "SITUADO APARCABICICLETAS - C.P�SAN�JOSE�DE�CALASANZ",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "102",
      "neighborhoodName": "PUERTA DEL ANGEL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARIA DEL CARMEN",
      "addressNumber": "62",
      "addressCode": "31052000",
      "model": "",
      "address": "CALLE MARIA DEL CARMEN 62, PUERTA DEL ANGEL, MADRID",
      "lat": 40.4060384,
      "lng": -3.7328683
  },
  {
      "id": "181202",
      "description": "SITUADO APARCABICICLETAS - CENTRO�COMERCIAL�LA�ERMITA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "101",
      "neighborhoodName": "LOS CARMENES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "ERMITA DEL SANTO",
      "addressNumber": "66",
      "addressCode": "20075161",
      "model": "",
      "address": "PASEO ERMITA DEL SANTO 66, LOS CARMENES, MADRID",
      "lat": 40.4059998,
      "lng": -3.724674
  },
  {
      "id": "182236",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�BOLIVIA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "103",
      "neighborhoodName": "LUCERO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLAVALIENTE",
      "addressNumber": "29",
      "addressCode": "11064051",
      "model": "",
      "address": "CALLE VILLAVALIENTE 29, LUCERO, MADRID",
      "lat": 40.40725,
      "lng": -3.75103
  },
  {
      "id": "182617",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�DIVINO�MAESTRO",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "103",
      "neighborhoodName": "LUCERO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "EXTREMADURA",
      "addressNumber": "165",
      "addressCode": "11063043",
      "model": "",
      "address": "PASEO EXTREMADURA 165, LUCERO, MADRID",
      "lat": 40.4080616,
      "lng": -3.7430747
  },
  {
      "id": "182945",
      "description": "SITUADO APARCABICICLETAS - FRENTE�AL�PARQUE�CARAMUEL",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "102",
      "neighborhoodName": "PUERTA DEL ANGEL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CARAMUEL",
      "addressNumber": "58",
      "addressCode": "11061206",
      "model": "",
      "address": "CALLE CARAMUEL 58, PUERTA DEL ANGEL, MADRID",
      "lat": 40.4085998,
      "lng": -3.7297251
  },
  {
      "id": "184345",
      "description": "SITUADO APARCABICICLETAS - SALA�DE�LECTURA�JUNTA�MUNICIPAL�DE�LATINA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "102",
      "neighborhoodName": "PUERTA DEL ANGEL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "EXTREMADURA",
      "addressNumber": "128",
      "addressCode": "11061831",
      "model": "",
      "address": "PASEO EXTREMADURA 128, PUERTA DEL ANGEL, MADRID",
      "lat": 40.4111331,
      "lng": -3.7357863
  },
  {
      "id": "186977",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�JOSE�MARIA�CAGIGAL",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA FE",
      "addressNumber": "4",
      "addressCode": "11051984",
      "model": "",
      "address": "CALLE SANTA FE 4, CASA DE CAMPO, MADRID",
      "lat": 40.4320616,
      "lng": -3.735932
  },
  {
      "id": "187018",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�LA�BOMBILLA",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SENDA DEL REY",
      "addressNumber": "8",
      "addressCode": "31026240",
      "model": "",
      "address": "CALLE SENDA DEL REY 8, CASA DE CAMPO, MADRID",
      "lat": 40.4299614,
      "lng": -3.7295528
  },
  {
      "id": "194340",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CULTURAL�JUAN�GRIS",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "94",
      "neighborhoodName": "VALDEZARZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCOS RODRIGUEZ",
      "addressNumber": "100",
      "addressCode": "11055490",
      "model": "",
      "address": "CALLE FRANCOS RODRIGUEZ 100, VALDEZARZA, MADRID",
      "lat": 40.4584993,
      "lng": -3.7120581
  },
  {
      "id": "196061",
      "description": "SITUADO APARCABICICLETAS - PUENTE�DE�SAN�FERNANDO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CARRETERA",
      "addressStreet": "EL PARDO",
      "addressNumber": "19",
      "addressCode": "31052037",
      "model": "",
      "address": "CARRETERA EL PARDO 19, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.4449666,
      "lng": -3.7384834
  },
  {
      "id": "3535252",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�NUESTRA�SE�ORA�DE�LAS�DELICIAS",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "DELICIAS",
      "addressNumber": "65 A",
      "addressCode": "11009575",
      "model": "",
      "address": "PASEO DELICIAS 65 A, DELICIAS, MADRID",
      "lat": 40.3986575,
      "lng": -3.6939817
  },
  {
      "id": "3536142",
      "description": "SITUADO APARCABICICLETAS - CERCANO�A�LA�ESTACION�DE�METRO�(MANUEL�BECERRA)",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "42",
      "neighborhoodName": "GOYA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "MANUEL BECERRA",
      "addressNumber": "17",
      "addressCode": "11015391",
      "model": "",
      "address": "PLAZA MANUEL BECERRA 17, GOYA, MADRID",
      "lat": 40.4274384,
      "lng": -3.6688587
  },
  {
      "id": "3536439",
      "description": "SITUADO APARCABICICLETAS - ENTRADA�PARQUE�DEL�BRONCE",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANTRACITA",
      "addressNumber": "32",
      "addressCode": "20170760",
      "model": "",
      "address": "CALLE ANTRACITA 32, LEGAZPI, MADRID",
      "lat": 40.3912902,
      "lng": -3.6868539
  },
  {
      "id": "3538010",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CONCERTADO�DE�EDUCACION�SAN�JOSE",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAZARREDO",
      "addressNumber": "11",
      "addressCode": "31052131",
      "model": "",
      "address": "CALLE MAZARREDO 11, IMPERIAL, MADRID",
      "lat": 40.41378419999999,
      "lng": -3.7183839
  },
  {
      "id": "3540328",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�EVA�DUARTE",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR GOMEZ ULLA",
      "addressNumber": "11",
      "addressCode": "11017103",
      "model": "",
      "address": "CALLE DOCTOR GOMEZ ULLA 11, GUINDALERA, MADRID",
      "lat": 40.4299151,
      "lng": -3.6675692
  },
  {
      "id": "3540487",
      "description": "SITUADO APARCABICICLETAS - C/V�AVENIDA�DE�MENENDEZ�PELAYO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "34",
      "neighborhoodName": "IBIZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "O'DONNELL",
      "addressNumber": "4",
      "addressCode": "11012679",
      "model": "",
      "address": "CALLE O'DONNELL 4, IBIZA, MADRID",
      "lat": 40.4215396,
      "lng": -3.6794251
  },
  {
      "id": "3540610",
      "description": "SITUADO APARCABICICLETAS - FRENTE�AL�PARQUE�DEL�RETIRO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "34",
      "neighborhoodName": "IBIZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCE DE OCTUBRE",
      "addressNumber": "1",
      "addressCode": "11012364",
      "model": "",
      "address": "CALLE DOCE DE OCTUBRE 1, IBIZA, MADRID",
      "lat": 40.415615,
      "lng": -3.6774985
  },
  {
      "id": "3541227",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�ANDRES�SEGOVIA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRINCIPE DE VERGARA",
      "addressNumber": "142",
      "addressCode": "20009834",
      "model": "",
      "address": "CALLE PRINCIPE DE VERGARA 142, CIUDAD JARDIN, MADRID",
      "lat": 40.4448867,
      "lng": -3.6780089
  },
  {
      "id": "3541694",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�RODRIGO�URIA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "51",
      "neighborhoodName": "EL VISO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRINCIPE DE VERGARA",
      "addressNumber": "187",
      "addressCode": "11021652",
      "model": "",
      "address": "CALLE PRINCIPE DE VERGARA 187, EL VISO, MADRID",
      "lat": 40.447723,
      "lng": -3.6786852
  },
  {
      "id": "3542121",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�DAOIZ�Y�VELARDE",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "DAOIZ Y VELARDE",
      "addressNumber": "5",
      "addressCode": "31052306",
      "model": "",
      "address": "PLAZA DAOIZ Y VELARDE 5, PACIFICO, MADRID",
      "lat": 40.4020327,
      "lng": -3.6776657
  },
  {
      "id": "3542152",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�DAOIZ�Y�VELARDE",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TELLEZ",
      "addressNumber": "31",
      "addressCode": "20088240",
      "model": "",
      "address": "CALLE TELLEZ 31, PACIFICO, MADRID",
      "lat": 40.4011324,
      "lng": -3.6786852
  },
  {
      "id": "3542222",
      "description": "SITUADO APARCABICICLETAS - ENTRADA�PLAZA�DAOIZ�Y�VELARDE",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CIUDAD DE BARCELONA",
      "addressNumber": "156",
      "addressCode": "31051005",
      "model": "",
      "address": "AVENIDA CIUDAD DE BARCELONA 156, PACIFICO, MADRID",
      "lat": 40.4025951,
      "lng": -3.6775388
  },
  {
      "id": "3542367",
      "description": "SITUADO APARCABICICLETAS - C.P.�JOAQUIN�COSTA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "PONTONES",
      "addressNumber": "8",
      "addressCode": "11008162",
      "model": "",
      "address": "PASEO PONTONES 8, IMPERIAL, MADRID",
      "lat": 40.4055918,
      "lng": -3.7145902
  },
  {
      "id": "3542809",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�PACIFICO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CIUDAD DE BARCELONA",
      "addressNumber": "79",
      "addressCode": "11011222",
      "model": "",
      "address": "AVENIDA CIUDAD DE BARCELONA 79, PACIFICO, MADRID",
      "lat": 40.401574,
      "lng": -3.6748943
  },
  {
      "id": "3543363",
      "description": "SITUADO APARCABICICLETAS - JUNTO�A�ENTRADA�A�GARAJE",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "VIRGEN DEL PUERTO",
      "addressNumber": "33",
      "addressCode": "11008052",
      "model": "",
      "address": "PASEO VIRGEN DEL PUERTO 33, IMPERIAL, MADRID",
      "lat": 40.4110456,
      "lng": -3.7210719
  },
  {
      "id": "3543425",
      "description": "SITUADO APARCABICICLETAS - EN�LA�REAL�FABRICA�DE�TAPICES",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTERRABIA",
      "addressNumber": "2",
      "addressCode": "11010631",
      "model": "",
      "address": "CALLE FUENTERRABIA 2, PACIFICO, MADRID",
      "lat": 40.4060552,
      "lng": -3.6824933
  },
  {
      "id": "3543463",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MARQUES�DE�SAMARANCH",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "IMPERIAL",
      "addressNumber": "20",
      "addressCode": "11008145",
      "model": "",
      "address": "PASEO IMPERIAL 20, IMPERIAL, MADRID",
      "lat": 40.4076236,
      "lng": -3.7173636
  },
  {
      "id": "3544877",
      "description": "SITUADO APARCABICICLETAS - I.E.S.�JUAN�DE�LA�CIERVA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "22",
      "neighborhoodName": "ACACIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAOBA",
      "addressNumber": "6",
      "addressCode": "20129132",
      "model": "",
      "address": "CALLE CAOBA 6, ACACIAS, MADRID",
      "lat": 40.4023105,
      "lng": -3.706834
  },
  {
      "id": "3546898",
      "description": "SITUADO APARCABICICLETAS - C/V�PASEO�DE�LA�CASTELLANA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FELIX BOIX",
      "addressNumber": "2",
      "addressCode": "20010132",
      "model": "",
      "address": "CALLE FELIX BOIX 2, NUEVA ESPA�A, MADRID",
      "lat": 40.4632534,
      "lng": -3.6887082
  },
  {
      "id": "3546923",
      "description": "SITUADO APARCABICICLETAS - EMBAJADA DE SANTA SEDE (NUNCIATURA APOSTOLICA)",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "56",
      "neighborhoodName": "CASTILLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PIO XII",
      "addressNumber": "46",
      "addressCode": "11027052",
      "model": "",
      "address": "AVENIDA PIO XII 46, CASTILLA, MADRID",
      "lat": 40.4680715,
      "lng": -3.673703199999999
  },
  {
      "id": "3547243",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�CHAMARTIN",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PIO XII",
      "addressNumber": "11",
      "addressCode": "11026265",
      "model": "",
      "address": "AVENIDA PIO XII 11, NUEVA ESPA�A, MADRID",
      "lat": 40.46155,
      "lng": -3.67656
  },
  {
      "id": "3547448",
      "description": "SITUADO APARCABICICLETAS - EN�LA�ENTRADA�A�PLAZA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DRACENA",
      "addressNumber": "29",
      "addressCode": "11026872",
      "model": "",
      "address": "CALLE DRACENA 29, NUEVA ESPA�A, MADRID",
      "lat": 40.4643964,
      "lng": -3.669097599999999
  },
  {
      "id": "3547483",
      "description": "SITUADO APARCABICICLETAS - C/V�C.�VICTOR�DE�LA�SERNA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "COSTA RICA",
      "addressNumber": "32",
      "addressCode": "11025091",
      "model": "",
      "address": "CALLE COSTA RICA 32, HISPANOAMERICA, MADRID",
      "lat": 40.458126,
      "lng": -3.6713953
  },
  {
      "id": "3547888",
      "description": "SITUADO APARCABICICLETAS - EMPRESA�MUNICIPAL�DE�TRANSPORTES",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "32",
      "neighborhoodName": "ADELFAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CERRO DE LA PLATA",
      "addressNumber": "4",
      "addressCode": "20118541",
      "model": "",
      "address": "CALLE CERRO DE LA PLATA 4, ADELFAS, MADRID",
      "lat": 40.4002126,
      "lng": -3.6751621
  },
  {
      "id": "3548012",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CULTURAL�LUIS�PEIDRO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "32",
      "neighborhoodName": "ADELFAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LUIS PEIDRO",
      "addressNumber": "2",
      "addressCode": "31037836",
      "model": "",
      "address": "CALLE LUIS PEIDRO 2, ADELFAS, MADRID",
      "lat": 40.3973112,
      "lng": -3.6737723
  },
  {
      "id": "3549651",
      "description": "SITUADO APARCABICICLETAS - I.E.S�SANTAMARCA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUERTO RICO",
      "addressNumber": "34",
      "addressCode": "11025158",
      "model": "",
      "address": "CALLE PUERTO RICO 34, HISPANOAMERICA, MADRID",
      "lat": 40.4558796,
      "lng": -3.6741251
  },
  {
      "id": "3549858",
      "description": "SITUADO APARCABICICLETAS - EMBAJADA�DE�VIETNAM",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALFONSO XIII",
      "addressNumber": "56",
      "addressCode": "11024496",
      "model": "",
      "address": "AVENIDA ALFONSO XIII 56, HISPANOAMERICA, MADRID",
      "lat": 40.4531178,
      "lng": -3.6671434
  },
  {
      "id": "3550833",
      "description": "SITUADO APARCABICICLETAS - I.E.S.�CLARA�DEL�REY",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PADRE CLARET",
      "addressNumber": "9",
      "addressCode": "11027465",
      "model": "",
      "address": "CALLE PADRE CLARET 9, PROSPERIDAD, MADRID",
      "lat": 40.446013,
      "lng": -3.6650799
  },
  {
      "id": "3551602",
      "description": "SITUADO APARCABICICLETAS - CERCANO�A OFICINA DE EMPLEO PROSPERIDAD",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CORAZON DE MARIA",
      "addressNumber": "25",
      "addressCode": "11022809",
      "model": "",
      "address": "CALLE CORAZON DE MARIA 25, PROSPERIDAD, MADRID",
      "lat": 40.4430515,
      "lng": -3.6680484
  },
  {
      "id": "3551687",
      "description": "SITUADO APARCABICICLETAS - JUNTA�MUNICIPAL�FUENCARRAL�EL�PARDO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONFORTE DE LEMOS",
      "addressNumber": "38",
      "addressCode": "11045935",
      "model": "",
      "address": "AVENIDA MONFORTE DE LEMOS 38, EL PILAR, MADRID",
      "lat": 40.478613,
      "lng": -3.7094293
  },
  {
      "id": "3553745",
      "description": "SITUADO APARCABICICLETAS - ROTONDA�PUERTA�DE�ALCALA",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "INDEPENDENCIA",
      "addressNumber": "9",
      "addressCode": "11012758",
      "model": "",
      "address": "PLAZA INDEPENDENCIA 9, LOS JERONIMOS, MADRID",
      "lat": 40.4193747,
      "lng": -3.6892241
  },
  {
      "id": "3553892",
      "description": "Situado Aparcabicicletas FRENTE A LA AGENCIA ESPA�OLA DE CONSUMO, SEGURIDAD ALIMENTARIA Y NUTRICI�N",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "54",
      "addressCode": "11012723",
      "model": "",
      "address": "CALLE ALCALA 54, LOS JERONIMOS, MADRID",
      "lat": 40.4193967,
      "lng": -3.6908559
  },
  {
      "id": "3553943",
      "description": "SITUADO APARCABICICLETAS - C/V�CALLE�MONTALBAN",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALFONSO XII",
      "addressNumber": "12",
      "addressCode": "11012744",
      "model": "",
      "address": "CALLE ALFONSO XII 12, LOS JERONIMOS, MADRID",
      "lat": 40.4182309,
      "lng": -3.6890627
  },
  {
      "id": "3553949",
      "description": "SITUADO APARCABICICLETAS - HOTEL�PALACIO�DEL�RETIRO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALFONSO XII",
      "addressNumber": "14",
      "addressCode": "11012745",
      "model": "",
      "address": "CALLE ALFONSO XII 14, LOS JERONIMOS, MADRID",
      "lat": 40.41781599999999,
      "lng": -3.6892258
  },
  {
      "id": "3554248",
      "description": "SITUADO APARCABICICLETAS - I.T.�PRINCIPE�PIO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "FLORIDA",
      "addressNumber": "4",
      "addressCode": "20106554",
      "model": "",
      "address": "PASEO FLORIDA 4, CASA DE CAMPO, MADRID",
      "lat": 40.4222321,
      "lng": -3.722264099999999
  },
  {
      "id": "3554257",
      "description": "SITUADO APARCABICICLETAS - I.T.�PRINCIPE�PIO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "FLORIDA",
      "addressNumber": "4",
      "addressCode": "20106554",
      "model": "",
      "address": "PASEO FLORIDA 4, CASA DE CAMPO, MADRID",
      "lat": 40.4222321,
      "lng": -3.722264099999999
  },
  {
      "id": "3554428",
      "description": "SITUADO APARCABICICLETAS - I.T.�PRINCIPE�PIO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "FLORIDA",
      "addressNumber": "2",
      "addressCode": "20147325",
      "model": "",
      "address": "PASEO FLORIDA 2, CASA DE CAMPO, MADRID",
      "lat": 40.4213154,
      "lng": -3.7193638
  },
  {
      "id": "3554461",
      "description": "SITUADO APARCABICICLETAS - MUSEO�NACIONAL�DE�ANTROPOLOGIA",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "INFANTA ISABEL",
      "addressNumber": "11",
      "addressCode": "11012987",
      "model": "",
      "address": "PASEO INFANTA ISABEL 11, LOS JERONIMOS, MADRID",
      "lat": 40.4078089,
      "lng": -3.6894617
  },
  {
      "id": "3554462",
      "description": "SITUADO APARCABICICLETAS - AECOM�(ASOCIACION DE EMPRESAS DE LA CONSTRUCCION DE MADRID)",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALFONSO XII",
      "addressNumber": "64",
      "addressCode": "11012964",
      "model": "",
      "address": "CALLE ALFONSO XII 64, LOS JERONIMOS, MADRID",
      "lat": 40.408204,
      "lng": -3.6889088
  },
  {
      "id": "3554495",
      "description": "SITUADO APARCABICICLETAS - CONSEJO�SUPERIOR�DE�INVESTIGACIONES",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CLAUDIO MOYANO",
      "addressNumber": "1",
      "addressCode": "11012968",
      "model": "",
      "address": "CALLE CLAUDIO MOYANO 1, LOS JERONIMOS, MADRID",
      "lat": 40.4097622,
      "lng": -3.6894019
  },
  {
      "id": "3554625",
      "description": "SITUADO APARCABICICLETAS - HOTEL�RITZ",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "LEALTAD",
      "addressNumber": "5",
      "addressCode": "11012872",
      "model": "",
      "address": "PLAZA LEALTAD 5, LOS JERONIMOS, MADRID",
      "lat": 40.4154065,
      "lng": -3.6924756
  },
  {
      "id": "3554928",
      "description": "SITUADO APARCABICICLETAS - EN�LA�ENTRADA�DEL�AYUNTAMIENTO�DE�MADRID",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONTALBAN",
      "addressNumber": "3",
      "addressCode": "11012789",
      "model": "",
      "address": "CALLE MONTALBAN 3, LOS JERONIMOS, MADRID",
      "lat": 40.4182794,
      "lng": -3.6910379
  },
  {
      "id": "3555626",
      "description": "SITUADO APARCABICICLETAS - COLONIA�DEL�MANZANARES",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "COMANDANTE FORTEA",
      "addressNumber": "66",
      "addressCode": "11051727",
      "model": "",
      "address": "PASEO COMANDANTE FORTEA 66, CASA DE CAMPO, MADRID",
      "lat": 40.4289564,
      "lng": -3.7337027
  },
  {
      "id": "3555722",
      "description": "SITUADO APARCABICICLETAS - DENTRO�DEL�AREA�INFANTIL",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "POETA ESTEBAN DE VILLEGAS",
      "addressNumber": "1",
      "addressCode": "31014460",
      "model": "",
      "address": "CALLE POETA ESTEBAN DE VILLEGAS 1, LOS JERONIMOS, MADRID",
      "lat": 40.4089749,
      "lng": -3.682459
  },
  {
      "id": "3556112",
      "description": "SITUADO APARCABICICLETAS - TRIBUNAL�DE�CUENTAS",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "45",
      "neighborhoodName": "LISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARTIRES CONCEPCIONISTAS",
      "addressNumber": "23",
      "addressCode": "11019214",
      "model": "",
      "address": "CALLE MARTIRES CONCEPCIONISTAS 23, LISTA, MADRID",
      "lat": 40.4294229,
      "lng": -3.6707102
  },
  {
      "id": "3556362",
      "description": "SITUADO APARCABICICLETAS - DIRECCION�SERVICIOS�PROTECCION�CIVIL�Y�BOMBEROS",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PRINCIPAL",
      "addressNumber": "2",
      "addressCode": "20173896",
      "model": "",
      "address": "AVENIDA PRINCIPAL 2, CASA DE CAMPO, MADRID",
      "lat": 40.4119093,
      "lng": -3.7390549
  },
  {
      "id": "3556665",
      "description": "SITUADO APARCABICICLETAS - PARQUE�DE�FRANCOS�RODRIGUEZ�-�RESIDENCIA�MILITAR�DON�QUIJOTE",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "DOCTOR FEDERICO RUBIO Y GALI",
      "addressNumber": "82",
      "addressCode": "20010642",
      "model": "",
      "address": "AVENIDA DOCTOR FEDERICO RUBIO Y GALI 82, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.45355199999999,
      "lng": -3.7132388
  },
  {
      "id": "3556929",
      "description": "SITUADO APARCABICICLETAS - C.E.I.P.�SAN FRANCISCO DE ASIS",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "51",
      "neighborhoodName": "EL VISO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CONCHA ESPINA",
      "addressNumber": "32",
      "addressCode": "11021069",
      "model": "",
      "address": "AVENIDA CONCHA ESPINA 32, EL VISO, MADRID",
      "lat": 40.45143909999999,
      "lng": -3.6811617
  },
  {
      "id": "3557017",
      "description": "SITUADO APARCABICICLETAS - CARRETERA DE LA DEHESA DE LA VILLA�(INSTITUTO VIRGEN DE LA PALOMA)",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCOS RODRIGUEZ",
      "addressNumber": "83",
      "addressCode": "20000008",
      "model": "",
      "address": "CALLE FRANCOS RODRIGUEZ 83, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.4594554,
      "lng": -3.7188842
  },
  {
      "id": "3557020",
      "description": "SITUADO APARCABICICLETAS - MUSEO�ARQUEOLOGICO�NACIONAL",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLANUEVA",
      "addressNumber": "12",
      "addressCode": "11013761",
      "model": "",
      "address": "CALLE VILLANUEVA 12, RECOLETOS, MADRID",
      "lat": 40.4225376,
      "lng": -3.6888167
  },
  {
      "id": "3557113",
      "description": "SITUADO APARCABICICLETAS - ROTONDA�PUERTA�DE�ALCALA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "INDEPENDENCIA",
      "addressNumber": "2",
      "addressCode": "11013678",
      "model": "",
      "address": "PLAZA INDEPENDENCIA 2, RECOLETOS, MADRID",
      "lat": 40.4205254,
      "lng": -3.6894888
  },
  {
      "id": "3557117",
      "description": "SITUADO APARCABICICLETAS - ROTONDA�PUERTA�DE�ALCALA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "INDEPENDENCIA",
      "addressNumber": "4",
      "addressCode": "11013680",
      "model": "",
      "address": "PLAZA INDEPENDENCIA 4, RECOLETOS, MADRID",
      "lat": 40.4206929,
      "lng": -3.6889989
  },
  {
      "id": "3557426",
      "description": "SITUADO APARCABICICLETAS - CALLE�CONDE�DE�ARANDA�1",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONDE DE ARANDA",
      "addressNumber": "1",
      "addressCode": "11013814",
      "model": "",
      "address": "CALLE CONDE DE ARANDA 1, RECOLETOS, MADRID",
      "lat": 40.4219077,
      "lng": -3.6880568
  },
  {
      "id": "3562088",
      "description": "SITUADO APARCABICICLETAS - C/V�C.�SERRANO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HERMOSILLA",
      "addressNumber": "12",
      "addressCode": "11013990",
      "model": "",
      "address": "CALLE HERMOSILLA 12, RECOLETOS, MADRID",
      "lat": 40.426413,
      "lng": -3.6873269
  },
  {
      "id": "3562264",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(INST. DEPORTIVA CLUB DE CAMPO VILLA DE MADRID)",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CARRETERA",
      "addressStreet": "CASTILLA",
      "addressNumber": "12",
      "addressCode": "20183945",
      "model": "",
      "address": "CARRETERA CASTILLA 12, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.434742,
      "lng": -3.7383779
  },
  {
      "id": "3562530",
      "description": "SITUADO APARCABICICLETAS - ZONA�COMERCIAL",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NU�EZ DE BALBOA",
      "addressNumber": "25",
      "addressCode": "11014488",
      "model": "",
      "address": "CALLE NU�EZ DE BALBOA 25, RECOLETOS, MADRID",
      "lat": 40.4240553,
      "lng": -3.6830438
  },
  {
      "id": "3562592",
      "description": "SITUADO APARCABICICLETAS - TORRE�SERRANO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "46",
      "neighborhoodName": "CASTELLANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARQUES DE VILLAMAGNA",
      "addressNumber": "3",
      "addressCode": "11019723",
      "model": "",
      "address": "CALLE MARQUES DE VILLAMAGNA 3, CASTELLANA, MADRID",
      "lat": 40.4297078,
      "lng": -3.6877991
  },
  {
      "id": "3563464",
      "description": "SITUADO APARCABICICLETAS - SAN�ANTONIO�MARIA�CLARAF",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CORAZON DE MARIA",
      "addressNumber": "1",
      "addressCode": "11022758",
      "model": "",
      "address": "CALLE CORAZON DE MARIA 1, PROSPERIDAD, MADRID",
      "lat": 40.4403979,
      "lng": -3.672113
  },
  {
      "id": "3563498",
      "description": "SITUADO APARCABICICLETAS - SAN�FRANCISCO�DE�BORJA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "46",
      "neighborhoodName": "CASTELLANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SERRANO",
      "addressNumber": "102",
      "addressCode": "11019873",
      "model": "",
      "address": "CALLE SERRANO 102, CASTELLANA, MADRID",
      "lat": 40.4338348,
      "lng": -3.6862658
  },
  {
      "id": "3564587",
      "description": "SITUADO APARCABICICLETAS - INST. DEPORTIVA BASICA ESTANQUE DE LAS TORMENTAS",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ENEBRO",
      "addressNumber": "21",
      "addressCode": "11058366",
      "model": "",
      "address": "CALLE ENEBRO 21, ARAVACA, MADRID",
      "lat": 40.4512499,
      "lng": -3.7706414
  },
  {
      "id": "3564777",
      "description": "SITUADO APARCABICICLETAS - C.P.�ROSA�DE�LUXEMBURGO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BARDEGUERAL",
      "addressNumber": "16",
      "addressCode": "20140585",
      "model": "",
      "address": "CALLE BARDEGUERAL 16, ARAVACA, MADRID",
      "lat": 40.4500987,
      "lng": -3.7714632
  },
  {
      "id": "3557945",
      "description": "SITUADO APARCABICICLETAS - CEIP�CIUDAD�DE�ROMA",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN ESPLANDIU",
      "addressNumber": "2:00 AM",
      "addressCode": "11013634",
      "model": "",
      "address": "CALLE JUAN ESPLANDIU 2:00 AM, ESTRELLA, MADRID",
      "lat": 40.4158864,
      "lng": -3.6644091
  },
  {
      "id": "3557946",
      "description": "SITUADO APARCABICICLETAS - CEIP�CIUDAD�DE�ROMA",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN ESPLANDIU",
      "addressNumber": "1",
      "addressCode": "11012057",
      "model": "",
      "address": "CALLE JUAN ESPLANDIU 1, ESTRELLA, MADRID",
      "lat": 40.41549200000001,
      "lng": -3.6663685
  },
  {
      "id": "3558139",
      "description": "SITUADO APARCABICICLETAS - PARROQUIA�NUESTRA�SE�ORA�DE�HENAR",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AGUSTIN DURAN",
      "addressNumber": "33",
      "addressCode": "11018667",
      "model": "",
      "address": "CALLE AGUSTIN DURAN 33, GUINDALERA, MADRID",
      "lat": 40.4365277,
      "lng": -3.6737271
  },
  {
      "id": "3531545",
      "description": "SITUADO APARCABICICLETAS - PARROQUIA�DE�SAN�HILARIO�DE�POLIERS",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NAVIA",
      "addressNumber": "27",
      "addressCode": "20137018",
      "model": "",
      "address": "CALLE NAVIA 27, LAS AGUILAS, MADRID",
      "lat": 40.3850971,
      "lng": -3.7652123
  },
  {
      "id": "3532967",
      "description": "SITUADO APARCABICICLETAS - C.P.�AMADOR�DE�LOS�RIOS",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "43",
      "neighborhoodName": "FUENTE DEL BERRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "MARQUES DE ZAFRA",
      "addressNumber": "16",
      "addressCode": "11016537",
      "model": "",
      "address": "PASEO MARQUES DE ZAFRA 16, FUENTE DEL BERRO, MADRID",
      "lat": 40.4258382,
      "lng": -3.6663048
  },
  {
      "id": "3535627",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�MONTECARMELO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONASTERIO DE SILOS",
      "addressNumber": "61",
      "addressCode": "31045237",
      "model": "",
      "address": "AVENIDA MONASTERIO DE SILOS 61, EL GOLOSO, MADRID",
      "lat": 40.5065174,
      "lng": -3.694867299999999
  },
  {
      "id": "3537003",
      "description": "SITUADO APARCABICICLETAS - AVENIDA�SANTO�DOMINGO�DE�LA�CALZADA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BERDUN",
      "addressNumber": "2",
      "addressCode": "31029489",
      "model": "",
      "address": "CALLE BERDUN 2, VALVERDE, MADRID",
      "lat": 40.5065689,
      "lng": -3.6791607
  },
  {
      "id": "3540394",
      "description": "SITUADO APARCABICICLETAS - CALLE�DE�JORGE�JUAN�CV�CALLE�SERRANO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JORGE JUAN",
      "addressNumber": "4",
      "addressCode": "11013994",
      "model": "",
      "address": "CALLE JORGE JUAN 4, RECOLETOS, MADRID",
      "lat": 40.4249556,
      "lng": -3.6899006
  },
  {
      "id": "3540442",
      "description": "SITUADO APARCABICICLETAS - ZONA�COMERCIAL",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CLAUDIO COELLO",
      "addressNumber": "26",
      "addressCode": "11013897",
      "model": "",
      "address": "CALLE CLAUDIO COELLO 26, RECOLETOS, MADRID",
      "lat": 40.42350680000001,
      "lng": -3.6867235
  },
  {
      "id": "3542074",
      "description": "SITUADO APARCABICICLETAS - C.P.�MARQUES�DE�MARCENADO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LINNEO",
      "addressNumber": "31",
      "addressCode": "11008086",
      "model": "",
      "address": "CALLE LINNEO 31, IMPERIAL, MADRID",
      "lat": 40.4103431,
      "lng": -3.720266
  },
  {
      "id": "3542435",
      "description": "SITUADO APARCABICICLETAS - ENTRADA�DEL�JARDIN�DEL�MAESTRO�PADILLA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "VIRGEN DEL PUERTO",
      "addressNumber": "65",
      "addressCode": "11008212",
      "model": "",
      "address": "PASEO VIRGEN DEL PUERTO 65, IMPERIAL, MADRID",
      "lat": 40.4048409,
      "lng": -3.7219523
  },
  {
      "id": "3543608",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�NACIONES",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALFONSO XIII",
      "addressNumber": "90",
      "addressCode": "11024512",
      "model": "",
      "address": "AVENIDA ALFONSO XIII 90, HISPANOAMERICA, MADRID",
      "lat": 40.4556337,
      "lng": -3.6666613
  },
  {
      "id": "3544531",
      "description": "SITUADO APARCABICICLETAS - FRENTE�A�AYUNTAMIENTO�DE�MADRID",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BUSTAMANTE",
      "addressNumber": "20",
      "addressCode": "31001897",
      "model": "",
      "address": "CALLE BUSTAMANTE 20, DELICIAS, MADRID",
      "lat": 40.4005533,
      "lng": -3.6893783
  },
  {
      "id": "3545009",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�BMX",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PARQUE",
      "addressStreet": "MADRID RIO",
      "addressNumber": "200 EX",
      "addressCode": "31049409",
      "model": "",
      "address": "PARQUE MADRID RIO 200 EX, LEGAZPI, MADRID",
      "lat": 40.4130932,
      "lng": -3.7234566
  },
  {
      "id": "3545079",
      "description": "SITUADO APARCABICICLETAS - ENTRADA�A�PARQUE�(CENTRO�DEPORTIVO�BMX)",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EMBAJADORES",
      "addressNumber": "316 A",
      "addressCode": "31040642",
      "model": "",
      "address": "CALLE EMBAJADORES 316 A, LEGAZPI, MADRID",
      "lat": 40.38486100000001,
      "lng": -3.688471
  },
  {
      "id": "3545395",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�ARGANZUELA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PALOS DE LA FRONTERA",
      "addressNumber": "40",
      "addressCode": "20107719",
      "model": "",
      "address": "CALLE PALOS DE LA FRONTERA 40, PALOS DE MOGUER, MADRID",
      "lat": 40.4029675,
      "lng": -3.6953948
  },
  {
      "id": "3545781",
      "description": "SITUADO APARCABICICLETAS - MATADERO�DE�MADRID",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "23",
      "neighborhoodName": "CHOPERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CHOPERA",
      "addressNumber": "53",
      "addressCode": "11009026",
      "model": "",
      "address": "PASEO CHOPERA 53, CHOPERA, MADRID",
      "lat": 40.3928831,
      "lng": -3.6971407
  },
  {
      "id": "3546014",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�ARGANZUELA�C/ ALICANTE 14",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALICANTE",
      "addressNumber": "14",
      "addressCode": "20131941",
      "model": "",
      "address": "CALLE ALICANTE 14, DELICIAS, MADRID",
      "lat": 40.3943307,
      "lng": -3.6912615
  },
  {
      "id": "3566462",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(CTRO�COMERCIAL�MONTECARMELO)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONASTERIOS DE SUSO Y YUSO",
      "addressNumber": "73",
      "addressCode": "31007852",
      "model": "",
      "address": "CALLE MONASTERIOS DE SUSO Y YUSO 73, EL GOLOSO, MADRID",
      "lat": 40.50909619999999,
      "lng": -3.6910102
  },
  {
      "id": "3566463",
      "description": "SITUADO APARCABICICLETAS - CARRIL�BICI�(CERRO�DE�LAS�TABLAS�)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SIERRA DE ATAPUERCA",
      "addressNumber": "49",
      "addressCode": "31030555",
      "model": "",
      "address": "CALLE SIERRA DE ATAPUERCA 49, VALVERDE, MADRID",
      "lat": 40.5074071,
      "lng": -3.6653533
  },
  {
      "id": "3566494",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�FERNANDO�MARTIN",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "94",
      "neighborhoodName": "VALDEZARZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SANTO ANGEL DE LA GUARDA",
      "addressNumber": "6",
      "addressCode": "31009640",
      "model": "",
      "address": "AVENIDA SANTO ANGEL DE LA GUARDA 6, VALDEZARZA, MADRID",
      "lat": 40.4609267,
      "lng": -3.7117078
  },
  {
      "id": "3566640",
      "description": "SITUADO APARCABICICLETAS - MERCADO�SANTA�MARIA�DE�LA�CABEZA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "SANTA MARIA DE LA CABEZA",
      "addressNumber": "39",
      "addressCode": "11010285",
      "model": "",
      "address": "PASEO SANTA MARIA DE LA CABEZA 39, PALOS DE MOGUER, MADRID",
      "lat": 40.4030323,
      "lng": -3.6970931
  },
  {
      "id": "3566672",
      "description": "SITUADO APARCABICICLETAS - C/�ARROYOFRESNO�17�ANILLO�VERDE�CICLISTA",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARROYOFRESNO",
      "addressNumber": "15",
      "addressCode": "20017220",
      "model": "",
      "address": "CALLE ARROYOFRESNO 15, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.4739415,
      "lng": -3.7441494
  },
  {
      "id": "3569266",
      "description": "SITUADO APARCABICICLETAS - C/LUIS�LARRAINZA.�C.P.�PADRE�POVEDA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LUIS LARRAINZA",
      "addressNumber": "57",
      "addressCode": "11023438",
      "model": "",
      "address": "CALLE LUIS LARRAINZA 57, CIUDAD JARDIN, MADRID",
      "lat": 40.451025,
      "lng": -3.6682063
  },
  {
      "id": "3569267",
      "description": "SITUADO APARCABICICLETAS - C/LUIS�LARRAINZA.�C.P.�PADRE�POVEDA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALFONSO XIII",
      "addressNumber": "21",
      "addressCode": "11023058",
      "model": "",
      "address": "AVENIDA ALFONSO XIII 21, CIUDAD JARDIN, MADRID",
      "lat": 40.4508435,
      "lng": -3.6679884
  },
  {
      "id": "3569268",
      "description": "SITUADO APARCABICICLETAS - C/LUIS�LARRAINZA.�C.P.�PADRE�POVEDA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LUIS LARRAINZA",
      "addressNumber": "57",
      "addressCode": "11023438",
      "model": "",
      "address": "CALLE LUIS LARRAINZA 57, CIUDAD JARDIN, MADRID",
      "lat": 40.451025,
      "lng": -3.6682063
  },
  {
      "id": "3569269",
      "description": "SITUADO APARCABICICLETAS - C/JOSE�VASCONCELOS�C/CASTELLANA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "56",
      "neighborhoodName": "CASTILLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE VASCONCELOS",
      "addressNumber": "2",
      "addressCode": "20105700",
      "model": "",
      "address": "CALLE JOSE VASCONCELOS 2, CASTILLA, MADRID",
      "lat": 40.4710313,
      "lng": -3.6868622
  },
  {
      "id": "3569270",
      "description": "SITUADO APARCABICICLETAS - JUNTO�A�CARRIL�BICI",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "SANTA MARIA DE LA CABEZA",
      "addressNumber": "4",
      "addressCode": "11009880",
      "model": "",
      "address": "GLORIETA SANTA MARIA DE LA CABEZA 4, PALOS DE MOGUER, MADRID",
      "lat": 40.4012874,
      "lng": -3.6986629
  },
  {
      "id": "3569272",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�LAS�CRUCES",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "POBLADOS",
      "addressNumber": "72",
      "addressCode": "31034963",
      "model": "",
      "address": "AVENIDA POBLADOS 72, LAS AGUILAS, MADRID",
      "lat": 40.3788628,
      "lng": -3.7566821
  },
  {
      "id": "172981",
      "description": "SITUADO APARCABICICLETAS - CERCA�ESTACION�DE�METRO�PLAZA�DE�CASTILLA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "CASTILLA",
      "addressNumber": "9",
      "addressCode": "20131087",
      "model": "",
      "address": "PLAZA CASTILLA 9, NUEVA ESPA�A, MADRID",
      "lat": 40.465563,
      "lng": -3.6882827
  },
  {
      "id": "173548",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�ARQUITECTO�GAUDI",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROSA JARDON",
      "addressNumber": "6",
      "addressCode": "20102934",
      "model": "",
      "address": "CALLE ROSA JARDON 6, NUEVA ESPA�A, MADRID",
      "lat": 40.4655303,
      "lng": -3.6694955
  },
  {
      "id": "132190",
      "description": "SITUADO APARCABICICLETAS - COLEGIO NUESTRA SE�ORA DEL RECUERDO",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "56",
      "neighborhoodName": "CASTILLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "DUQUE DE PASTRANA",
      "addressNumber": "4",
      "addressCode": "11027130",
      "model": "",
      "address": "PLAZA DUQUE DE PASTRANA 4, CASTILLA, MADRID",
      "lat": 40.4679309,
      "lng": -3.6781611
  },
  {
      "id": "149445",
      "description": "SITUADO APARCABICICLETAS - C/PADRE�FRANCISCO�PALAU�Y�QUER",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "56",
      "neighborhoodName": "CASTILLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "272",
      "addressCode": "11027310",
      "model": "",
      "address": "PASEO CASTELLANA 272, CASTILLA, MADRID",
      "lat": 40.4737713,
      "lng": -3.6866103
  },
  {
      "id": "151223",
      "description": "SITUADO APARCABICICLETAS - FRENTE�EDIFICIO�DE�COFIDES",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "56",
      "neighborhoodName": "CASTILLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "278",
      "addressCode": "11027331",
      "model": "",
      "address": "PASEO CASTELLANA 278, CASTILLA, MADRID",
      "lat": 40.4754078,
      "lng": -3.6861068
  },
  {
      "id": "156151",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�HERMINIO�MINGUEZ",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "56",
      "neighborhoodName": "CASTILLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONDE DE TORRALBA",
      "addressNumber": "23",
      "addressCode": "20103420",
      "model": "",
      "address": "CALLE CONDE DE TORRALBA 23, CASTILLA, MADRID",
      "lat": 40.48024789999999,
      "lng": -3.6844672
  },
  {
      "id": "162245",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�LEGAZPI",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "LEGAZPI",
      "addressNumber": "1",
      "addressCode": "11009434",
      "model": "",
      "address": "PLAZA LEGAZPI 1, DELICIAS, MADRID",
      "lat": 40.3914721,
      "lng": -3.6944197
  },
  {
      "id": "165831",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�ARGANZUELA�PLANETARIO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BRONCE",
      "addressNumber": "1:00 AM",
      "addressCode": "31025852",
      "model": "",
      "address": "CALLE BRONCE 1:00 AM, LEGAZPI, MADRID",
      "lat": 40.3925451,
      "lng": -3.687886799999999
  },
  {
      "id": "166022",
      "description": "SITUADO APARCABICICLETAS - EN�EL�PLANETARIO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PLANETARIO",
      "addressNumber": "16",
      "addressCode": "20064507",
      "model": "",
      "address": "AVENIDA PLANETARIO 16, LEGAZPI, MADRID",
      "lat": 40.39257140000001,
      "lng": -3.685262899999999
  },
  {
      "id": "170048",
      "description": "SITUADO APARCABICICLETAS - JUNTO�A�PLAZA�RUTILIO�GARCIS",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "23",
      "neighborhoodName": "CHOPERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GUILLERMO DE OSMA",
      "addressNumber": "10",
      "addressCode": "11008976",
      "model": "",
      "address": "CALLE GUILLERMO DE OSMA 10, CHOPERA, MADRID",
      "lat": 40.3940201,
      "lng": -3.697046
  },
  {
      "id": "172923",
      "description": "SITUADO APARCABICICLETAS - EN�EL�PARQUE",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RAMIREZ DE PRADO",
      "addressNumber": "6",
      "addressCode": "11009534",
      "model": "",
      "address": "CALLE RAMIREZ DE PRADO 6, DELICIAS, MADRID",
      "lat": 40.3992315,
      "lng": -3.6881108
  },
  {
      "id": "173321",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�PE�UELAS",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "22",
      "neighborhoodName": "ACACIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARGANDA",
      "addressNumber": "42",
      "addressCode": "20065138",
      "model": "",
      "address": "CALLE ARGANDA 42, ACACIAS, MADRID",
      "lat": 40.399162,
      "lng": -3.7055862
  },
  {
      "id": "173669",
      "description": "SITUADO APARCABICICLETAS - BIBLIOTECA�PUBLICA�MUNICIPAL�PIO�BAROJA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "22",
      "neighborhoodName": "ACACIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARGANDA",
      "addressNumber": "10 C",
      "addressCode": "31050694",
      "model": "",
      "address": "CALLE ARGANDA 10 C, ACACIAS, MADRID",
      "lat": 40.4002235,
      "lng": -3.7080004
  },
  {
      "id": "173919",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�CERCANIAS�DELICIAS",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "DELICIAS",
      "addressNumber": "61 A",
      "addressCode": "11009550",
      "model": "",
      "address": "PASEO DELICIAS 61 A, DELICIAS, MADRID",
      "lat": 40.40028580000001,
      "lng": -3.6937929
  },
  {
      "id": "173964",
      "description": "SITUADO APARCABICICLETAS - AYUNTAMIENTO�DE�MADRID",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VARA DE REY",
      "addressNumber": "11",
      "addressCode": "31032623",
      "model": "",
      "address": "CALLE VARA DE REY 11, DELICIAS, MADRID",
      "lat": 40.4004067,
      "lng": -3.6894223
  },
  {
      "id": "175086",
      "description": "SITUADO APARCABICICLETAS - JUNTO�A�CARRIL�BICI",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "SANTA MARIA DE LA CABEZA",
      "addressNumber": "2",
      "addressCode": "20097258",
      "model": "",
      "address": "GLORIETA SANTA MARIA DE LA CABEZA 2, PALOS DE MOGUER, MADRID",
      "lat": 40.4013237,
      "lng": -3.6985811
  },
  {
      "id": "175640",
      "description": "SITUADO APARCABICICLETAS - ESCUELA�INFANTIL�EL�ALBA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "22",
      "neighborhoodName": "ACACIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TOLEDO",
      "addressNumber": "181",
      "addressCode": "20002162",
      "model": "",
      "address": "CALLE TOLEDO 181, ACACIAS, MADRID",
      "lat": 40.4015289,
      "lng": -3.7136441
  },
  {
      "id": "175788",
      "description": "SITUADO APARCABICICLETAS - I.E.S.�GRAN�CAPITAN",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "MELANCOLICOS",
      "addressNumber": "51",
      "addressCode": "11008217",
      "model": "",
      "address": "PASEO MELANCOLICOS 51, IMPERIAL, MADRID",
      "lat": 40.4036501,
      "lng": -3.7201604
  },
  {
      "id": "120718",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�SAN�EUGENIO�Y�SAN�ISIDRO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "22",
      "neighborhoodName": "ACACIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PE�UELAS",
      "addressNumber": "29",
      "addressCode": "20007398",
      "model": "",
      "address": "CALLE PE�UELAS 29, ACACIAS, MADRID",
      "lat": 40.4025658,
      "lng": -3.7034626
  },
  {
      "id": "121719",
      "description": "SITUADO APARCABICICLETAS - BIBLIOTECA�RETIRO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "32",
      "neighborhoodName": "ADELFAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR ESQUERDO",
      "addressNumber": "189",
      "addressCode": "11011582",
      "model": "",
      "address": "CALLE DOCTOR ESQUERDO 189, ADELFAS, MADRID",
      "lat": 40.4034693,
      "lng": -3.6721789
  },
  {
      "id": "123078",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�LEGADO�CRESPO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "22",
      "neighborhoodName": "ACACIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "ACACIAS",
      "addressNumber": "2",
      "addressCode": "11008437",
      "model": "",
      "address": "PASEO ACACIAS 2, ACACIAS, MADRID",
      "lat": 40.404894,
      "lng": -3.7039189
  },
  {
      "id": "123106",
      "description": "SITUADO APARCABICICLETAS - AL�LADO�DE�AGENCIA�PARA�EL�EMPLEO�DE�MADRID",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "PONTONES",
      "addressNumber": "10",
      "addressCode": "20138350",
      "model": "",
      "address": "PASEO PONTONES 10, IMPERIAL, MADRID",
      "lat": 40.4052096,
      "lng": -3.7156558
  },
  {
      "id": "123753",
      "description": "SITUADO APARCABICICLETAS - UPM:�ESCUELA�TENICA�SUPERIOR�DE�INGENIERIA�Y�DISE�O�INSDUSTRIAL",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BERNARDINO OBREGON",
      "addressNumber": "1",
      "addressCode": "20008190",
      "model": "",
      "address": "CALLE BERNARDINO OBREGON 1, PALOS DE MOGUER, MADRID",
      "lat": 40.4053058,
      "lng": -3.7007315
  },
  {
      "id": "123802",
      "description": "SITUADO APARCABICICLETAS - ESCUELA�MUNICIPAL�DE�MUSICA�ALMUDENA�CANO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASAJE",
      "addressStreet": "TORTOSA",
      "addressNumber": "6",
      "addressCode": "20105955",
      "model": "",
      "address": "PASAJE TORTOSA 6, PALOS DE MOGUER, MADRID",
      "lat": 40.4060584,
      "lng": -3.6926288
  },
  {
      "id": "124711",
      "description": "SITUADO APARCABICICLETAS - EN�LA�GLORIETA",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "CONDE DE CASAL",
      "addressNumber": "5",
      "addressCode": "11011778",
      "model": "",
      "address": "PLAZA CONDE DE CASAL 5, ESTRELLA, MADRID",
      "lat": 40.4069835,
      "lng": -3.669731399999999
  },
  {
      "id": "124995",
      "description": "SITUADO APARCABICICLETAS - DIRECCION�GENERAL�DE�AGRICULTURA�DE�LA�COMUNIDAD�DE�MADRID",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "RONDA",
      "addressStreet": "ATOCHA",
      "addressNumber": "17",
      "addressCode": "11010412",
      "model": "",
      "address": "RONDA ATOCHA 17, PALOS DE MOGUER, MADRID",
      "lat": 40.406735,
      "lng": -3.695651
  },
  {
      "id": "128692",
      "description": "SITUADO APARCABICICLETAS - AL�LADO�DEL�CEIP�CIUDAD�DE�ROMA,�EN�LA�ENTRADA�DEL�PARQUE",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN ESPLANDIU",
      "addressNumber": "1",
      "addressCode": "11012057",
      "model": "",
      "address": "CALLE JUAN ESPLANDIU 1, ESTRELLA, MADRID",
      "lat": 40.41549200000001,
      "lng": -3.6663685
  },
  {
      "id": "130122",
      "description": "SITUADO APARCABICICLETAS - EN�LA�PARTE�DE�LAS�OFICINAS",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN ESPLANDIU",
      "addressNumber": "13",
      "addressCode": "11012086",
      "model": "",
      "address": "CALLE JUAN ESPLANDIU 13, ESTRELLA, MADRID",
      "lat": 40.41802759999999,
      "lng": -3.6645463
  },
  {
      "id": "130831",
      "description": "SITUADO APARCABICICLETAS - AL�LADO�DE�CASA DE AMERICA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "53",
      "addressCode": "11013649",
      "model": "",
      "address": "CALLE ALCALA 53, RECOLETOS, MADRID",
      "lat": 40.41977929999999,
      "lng": -3.6920138
  },
  {
      "id": "131013",
      "description": "SITUADO APARCABICICLETAS - ROTONDA�PUERTA�DE�ALCALA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "INDEPENDENCIA",
      "addressNumber": "6",
      "addressCode": "11013824",
      "model": "",
      "address": "PLAZA INDEPENDENCIA 6, RECOLETOS, MADRID",
      "lat": 40.4205648,
      "lng": -3.6877991
  },
  {
      "id": "131263",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�RETIRO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LAGASCA",
      "addressNumber": "2",
      "addressCode": "11013863",
      "model": "",
      "address": "CALLE LAGASCA 2, RECOLETOS, MADRID",
      "lat": 40.4208894,
      "lng": -3.6857161
  },
  {
      "id": "131337",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�FUENTE�DEL�BERRO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "43",
      "neighborhoodName": "FUENTE DEL BERRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ELVIRA",
      "addressNumber": "31",
      "addressCode": "20182773",
      "model": "",
      "address": "CALLE ELVIRA 31, FUENTE DEL BERRO, MADRID",
      "lat": 40.4211332,
      "lng": -3.664962399999999
  },
  {
      "id": "132154",
      "description": "SITUADO APARCABICICLETAS - CERCANO�A�LA�CASA�ARABE",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "O'DONNELL",
      "addressNumber": "7",
      "addressCode": "11014619",
      "model": "",
      "address": "CALLE O'DONNELL 7, RECOLETOS, MADRID",
      "lat": 40.4219046,
      "lng": -3.6806664
  },
  {
      "id": "133618",
      "description": "SITUADO APARCABICICLETAS - PALACIO�DE�LOS�DEPORTES",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "42",
      "neighborhoodName": "GOYA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LOMBIA",
      "addressNumber": "18",
      "addressCode": "11015545",
      "model": "",
      "address": "CALLE LOMBIA 18, GOYA, MADRID",
      "lat": 40.4235029,
      "lng": -3.6727896
  },
  {
      "id": "135605",
      "description": "SITUADO APARCABICICLETAS - I.E.S�BEATRIZ�GALINDO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GOYA",
      "addressNumber": "10",
      "addressCode": "11013902",
      "model": "",
      "address": "CALLE GOYA 10, RECOLETOS, MADRID",
      "lat": 40.4249957,
      "lng": -3.6859762
  },
  {
      "id": "138273",
      "description": "SITUADO APARCABICICLETAS - UNIVERSIDAD REY JUAN CARLOS CAMPUS DE MADRID - MANUEL BECERRA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "43",
      "neighborhoodName": "FUENTE DEL BERRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "MANUEL BECERRA",
      "addressNumber": "15",
      "addressCode": "11016812",
      "model": "",
      "address": "PLAZA MANUEL BECERRA 15, FUENTE DEL BERRO, MADRID",
      "lat": 40.427779,
      "lng": -3.6671565
  },
  {
      "id": "138853",
      "description": "SITUADO APARCABICICLETAS - EN�LA�PLAZA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "MANUEL BECERRA",
      "addressNumber": "8",
      "addressCode": "31026268",
      "model": "",
      "address": "PLAZA MANUEL BECERRA 8, GUINDALERA, MADRID",
      "lat": 40.4287737,
      "lng": -3.6687219
  },
  {
      "id": "139324",
      "description": "SITUADO APARCABICICLETAS - C/V�C.�SERRANO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DON RAMON DE LA CRUZ",
      "addressNumber": "2",
      "addressCode": "11014069",
      "model": "",
      "address": "CALLE DON RAMON DE LA CRUZ 2, RECOLETOS, MADRID",
      "lat": 40.42913960000001,
      "lng": -3.6868023
  },
  {
      "id": "141419",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�TOROS�DE�LAS�VENTAS",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "237",
      "addressCode": "11017699",
      "model": "",
      "address": "CALLE ALCALA 237, GUINDALERA, MADRID",
      "lat": 40.4315811,
      "lng": -3.6628632
  },
  {
      "id": "142259",
      "description": "SITUADO APARCABICICLETAS - BIBLIOTECA PUBLICA MUNICIPAL BUENAVISTA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "TOREROS",
      "addressNumber": "5",
      "addressCode": "11017387",
      "model": "",
      "address": "AVENIDA TOREROS 5, GUINDALERA, MADRID",
      "lat": 40.4320796,
      "lng": -3.6706822
  },
  {
      "id": "142279",
      "description": "SITUADO APARCABICICLETAS - EN�LA�ESTACION�DE�METRO�DIEGO�DE�LEON",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "45",
      "neighborhoodName": "LISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN BRAVO",
      "addressNumber": "62",
      "addressCode": "11019099",
      "model": "",
      "address": "CALLE JUAN BRAVO 62, LISTA, MADRID",
      "lat": 40.4321202,
      "lng": -3.6737921
  },
  {
      "id": "143432",
      "description": "SITUADO APARCABICICLETAS - MUSEO DE ARTE PUBLICO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "46",
      "neighborhoodName": "CASTELLANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "40",
      "addressCode": "11019748",
      "model": "",
      "address": "PASEO CASTELLANA 40, CASTELLANA, MADRID",
      "lat": 40.4332398,
      "lng": -3.6876128
  },
  {
      "id": "145954",
      "description": "SITUADO APARCABICICLETAS - C/V�CALLE�DE�MEJICO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO SILVELA",
      "addressNumber": "66",
      "addressCode": "11018853",
      "model": "",
      "address": "CALLE FRANCISCO SILVELA 66, GUINDALERA, MADRID",
      "lat": 40.4356527,
      "lng": -3.6751969
  },
  {
      "id": "147565",
      "description": "SITUADO APARCABICICLETAS - C.P.�GUINDALERA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CAMILO JOSE CELA",
      "addressNumber": "6",
      "addressCode": "31041250",
      "model": "",
      "address": "AVENIDA CAMILO JOSE CELA 6, GUINDALERA, MADRID",
      "lat": 40.44044,
      "lng": -3.66514
  },
  {
      "id": "148185",
      "description": "SITUADO APARCABICICLETAS - AGENCIA ESTATAL DE ADMINISTRACION TRIBUTARIA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "46",
      "neighborhoodName": "CASTELLANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NU�EZ DE BALBOA",
      "addressNumber": "125",
      "addressCode": "11020044",
      "model": "",
      "address": "CALLE NU�EZ DE BALBOA 125, CASTELLANA, MADRID",
      "lat": 40.4373826,
      "lng": -3.6819034
  },
  {
      "id": "148326",
      "description": "SITUADO APARCABICICLETAS - EMBAJADA�DE�ALBANIA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "45",
      "neighborhoodName": "LISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARIA DE MOLINA",
      "addressNumber": "62",
      "addressCode": "11018938",
      "model": "",
      "address": "CALLE MARIA DE MOLINA 62, LISTA, MADRID",
      "lat": 40.4372569,
      "lng": -3.678506899999999
  },
  {
      "id": "148778",
      "description": "SITUADO APARCABICICLETAS - CENTRO�MUNICIPAL�MOSCARDO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PILAR DE ZARAGOZA",
      "addressNumber": "93",
      "addressCode": "11018900",
      "model": "",
      "address": "CALLE PILAR DE ZARAGOZA 93, GUINDALERA, MADRID",
      "lat": 40.4379005,
      "lng": -3.6745053
  },
  {
      "id": "150558",
      "description": "SITUADO APARCABICICLETAS - ESCUELA TECNICA SUPERIOR DE INGENIEROS INDUSTRIALES UPM",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "51",
      "neighborhoodName": "EL VISO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEDRO DE VALDIVIA",
      "addressNumber": "6",
      "addressCode": "11020541",
      "model": "",
      "address": "CALLE PEDRO DE VALDIVIA 6, EL VISO, MADRID",
      "lat": 40.4391976,
      "lng": -3.6899073
  },
  {
      "id": "154330",
      "description": "SITUADO APARCABICICLETAS - I.E.S.�RAMIRO�DE�MAEZTU",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "51",
      "neighborhoodName": "EL VISO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SERRANO",
      "addressNumber": "131",
      "addressCode": "31048666",
      "model": "",
      "address": "CALLE SERRANO 131, EL VISO, MADRID",
      "lat": 40.442982,
      "lng": -3.686083
  },
  {
      "id": "155190",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�BASICA�SANTA�HORTENSIA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA HORTENSIA",
      "addressNumber": "34",
      "addressCode": "31025357",
      "model": "",
      "address": "CALLE SANTA HORTENSIA 34, PROSPERIDAD, MADRID",
      "lat": 40.4437903,
      "lng": -3.6649972
  },
  {
      "id": "155947",
      "description": "SITUADO APARCABICICLETAS - FRENTE�A�IBM",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA HORTENSIA",
      "addressNumber": "31",
      "addressCode": "11027467",
      "model": "",
      "address": "CALLE SANTA HORTENSIA 31, PROSPERIDAD, MADRID",
      "lat": 40.4442767,
      "lng": -3.6667241
  },
  {
      "id": "155979",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�PROSPERIDAD",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PROSPERIDAD",
      "addressNumber": "3",
      "addressCode": "31008456",
      "model": "",
      "address": "PLAZA PROSPERIDAD 3, CIUDAD JARDIN, MADRID",
      "lat": 40.4443147,
      "lng": -3.6749957
  },
  {
      "id": "156740",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�PINTOR�ROSALES",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "51",
      "neighborhoodName": "EL VISO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRINCIPE DE VERGARA",
      "addressNumber": "139",
      "addressCode": "11021619",
      "model": "",
      "address": "CALLE PRINCIPE DE VERGARA 139, EL VISO, MADRID",
      "lat": 40.44476969999999,
      "lng": -3.679081399999999
  },
  {
      "id": "157887",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�ANDRES�SEGOVIA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "ANDRES SEGOVIA",
      "addressNumber": "2",
      "addressCode": "20120191",
      "model": "",
      "address": "PLAZA ANDRES SEGOVIA 2, CIUDAD JARDIN, MADRID",
      "lat": 40.4455245,
      "lng": -3.6777615
  },
  {
      "id": "157999",
      "description": "SITUADO APARCABICICLETAS - AL�LADO�DE�LA�FUENTE�DE�LOS�DELFINES",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "51",
      "neighborhoodName": "EL VISO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "DOCTOR ARCE",
      "addressNumber": "1",
      "addressCode": "20009078",
      "model": "",
      "address": "AVENIDA DOCTOR ARCE 1, EL VISO, MADRID",
      "lat": 40.445594,
      "lng": -3.6849031
  },
  {
      "id": "158867",
      "description": "SITUADO APARCABICICLETAS - CERCA�DE�ESTACION�DE�METRO�NUEVOS�MINISTERIOS",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "51",
      "neighborhoodName": "EL VISO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "110",
      "addressCode": "11020485",
      "model": "",
      "address": "PASEO CASTELLANA 110, EL VISO, MADRID",
      "lat": 40.4456432,
      "lng": -3.6904564
  },
  {
      "id": "159396",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�RODOLFO�Y�ERNESTO�HALFFTER",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SUERO DE QUI�ONES",
      "addressNumber": "32",
      "addressCode": "11023997",
      "model": "",
      "address": "CALLE SUERO DE QUI�ONES 32, CIUDAD JARDIN, MADRID",
      "lat": 40.4465303,
      "lng": -3.6770994
  },
  {
      "id": "161602",
      "description": "SITUADO APARCABICICLETAS - JARDINES�DE�PROSPERO�SOYNARD",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EUGENIO SALAZAR",
      "addressNumber": "55",
      "addressCode": "31050976",
      "model": "",
      "address": "CALLE EUGENIO SALAZAR 55, CIUDAD JARDIN, MADRID",
      "lat": 40.4481486,
      "lng": -3.6756267
  },
  {
      "id": "161723",
      "description": "SITUADO APARCABICICLETAS - ESCUELA DE DANZA BROADWAY",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "52",
      "neighborhoodName": "PROSPERIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PADRE CLARET",
      "addressNumber": "23",
      "addressCode": "11022999",
      "model": "",
      "address": "CALLE PADRE CLARET 23, PROSPERIDAD, MADRID",
      "lat": 40.4488036,
      "lng": -3.6645227
  },
  {
      "id": "161936",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO MUNICIPAL PRADILLO",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRADILLO",
      "addressNumber": "25",
      "addressCode": "20094374",
      "model": "",
      "address": "CALLE PRADILLO 25, CIUDAD JARDIN, MADRID",
      "lat": 40.4490153,
      "lng": -3.672352499999999
  },
  {
      "id": "163739",
      "description": "SITUADO APARCABICICLETAS - C.P.�PADRE�POVEDA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALFONSO XIII",
      "addressNumber": "23",
      "addressCode": "11023059",
      "model": "",
      "address": "AVENIDA ALFONSO XIII 23, CIUDAD JARDIN, MADRID",
      "lat": 40.4511664,
      "lng": -3.6678299
  },
  {
      "id": "164052",
      "description": "SITUADO APARCABICICLETAS - ENTRADA�PARQUE�BERLIN",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "RAMON Y CAJAL",
      "addressNumber": "5",
      "addressCode": "11024265",
      "model": "",
      "address": "AVENIDA RAMON Y CAJAL 5, CIUDAD JARDIN, MADRID",
      "lat": 40.4519288,
      "lng": -3.6770013
  },
  {
      "id": "166611",
      "description": "SITUADO APARCABICICLETAS - AL�LADO�DEL�AYUNTAMIENTO",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HERREROS DE TEJADA",
      "addressNumber": "7",
      "addressCode": "31033511",
      "model": "",
      "address": "CALLE HERREROS DE TEJADA 7, HISPANOAMERICA, MADRID",
      "lat": 40.4553488,
      "lng": -3.6819145
  },
  {
      "id": "167171",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�PADRE�DAMIAN",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PADRE DAMIAN",
      "addressNumber": "24",
      "addressCode": "31042940",
      "model": "",
      "address": "CALLE PADRE DAMIAN 24, HISPANOAMERICA, MADRID",
      "lat": 40.4566416,
      "lng": -3.6861676
  },
  {
      "id": "167650",
      "description": "SITUADO APARCABICICLETAS - ESCUELA�UNIVERSITARIA�DE�DISE�O",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALFONSO XIII",
      "addressNumber": "97",
      "addressCode": "11025068",
      "model": "",
      "address": "AVENIDA ALFONSO XIII 97, HISPANOAMERICA, MADRID",
      "lat": 40.457057,
      "lng": -3.667513
  },
  {
      "id": "167812",
      "description": "SITUADO APARCABICICLETAS - JUNTO�A�ZONA�AJARDINADA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN RAMON JIMENEZ",
      "addressNumber": "9",
      "addressCode": "11025560",
      "model": "",
      "address": "CALLE JUAN RAMON JIMENEZ 9, HISPANOAMERICA, MADRID",
      "lat": 40.45723570000001,
      "lng": -3.6872311
  },
  {
      "id": "168626",
      "description": "SITUADO APARCABICICLETAS - MINISTERIO�DE�HACIENDA.�METRO�CUZCO",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "CASTELLANA",
      "addressNumber": "162",
      "addressCode": "11025623",
      "model": "",
      "address": "PASEO CASTELLANA 162, HISPANOAMERICA, MADRID",
      "lat": 40.4579287,
      "lng": -3.6888691
  },
  {
      "id": "168705",
      "description": "SITUADO APARCABICICLETAS - FRENTE�A�EDIFICIO�COMERCIAL",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALBERTO ALCOCER",
      "addressNumber": "10",
      "addressCode": "11025619",
      "model": "",
      "address": "AVENIDA ALBERTO ALCOCER 10, HISPANOAMERICA, MADRID",
      "lat": 40.4586451,
      "lng": -3.6870682
  },
  {
      "id": "168988",
      "description": "SITUADO APARCABICICLETAS - PLAZA�REPUBLICA�DOMINICANA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRINCIPE DE VERGARA",
      "addressNumber": "283",
      "addressCode": "31021821",
      "model": "",
      "address": "CALLE PRINCIPE DE VERGARA 283, NUEVA ESPA�A, MADRID",
      "lat": 40.4591672,
      "lng": -3.6769896
  },
  {
      "id": "169252",
      "description": "SITUADO APARCABICICLETAS - CERCANO�A�COLEGIO�SAGRADO�CORAZON",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PARAGUAY",
      "addressNumber": "8",
      "addressCode": "11026450",
      "model": "",
      "address": "CALLE PARAGUAY 8, NUEVA ESPA�A, MADRID",
      "lat": 40.4591506,
      "lng": -3.6714939
  },
  {
      "id": "169332",
      "description": "SITUADO APARCABICICLETAS - CENTRO�MEDICO�MILENIUM�COSTA�RICA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "JOSE MARIA SOLER",
      "addressNumber": "7",
      "addressCode": "31021839",
      "model": "",
      "address": "PLAZA JOSE MARIA SOLER 7, NUEVA ESPA�A, MADRID",
      "lat": 40.4592957,
      "lng": -3.6689589
  },
  {
      "id": "169946",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�NUESTRA�SE�ORA�DE�LA�MERCED",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALFONSO XIII",
      "addressNumber": "165",
      "addressCode": "11026353",
      "model": "",
      "address": "AVENIDA ALFONSO XIII 165, NUEVA ESPA�A, MADRID",
      "lat": 40.4598921,
      "lng": -3.6795965
  },
  {
      "id": "170099",
      "description": "SITUADO APARCABICICLETAS - JUNTO�A�JARDIN�DE�GLORIA�FUERTES",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALFONSO XIII",
      "addressNumber": "180",
      "addressCode": "31013778",
      "model": "",
      "address": "AVENIDA ALFONSO XIII 180, NUEVA ESPA�A, MADRID",
      "lat": 40.4604955,
      "lng": -3.6760244
  },
  {
      "id": "170181",
      "description": "SITUADO APARCABICICLETAS - EMBAJADA�DE�COSTA�RICA",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "55",
      "neighborhoodName": "NUEVA ESPA�A",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN HURTADO DE MENDOZA",
      "addressNumber": "1",
      "addressCode": "31008516",
      "model": "",
      "address": "CALLE JUAN HURTADO DE MENDOZA 1, NUEVA ESPA�A, MADRID",
      "lat": 40.460622,
      "lng": -3.689007999999999
  },
  {
      "id": "205202",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�VEREDA�DE�GANAPANES",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BECERREA",
      "addressNumber": "4",
      "addressCode": "20097696",
      "model": "",
      "address": "CALLE BECERREA 4, EL PILAR, MADRID",
      "lat": 40.4713258,
      "lng": -3.7130393
  },
  {
      "id": "205468",
      "description": "SITUADO APARCABICICLETAS - CENTRO�EDUCACION�PRIMARIA�GUATEMALA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CELANOVA",
      "addressNumber": "2",
      "addressCode": "31022383",
      "model": "",
      "address": "CALLE CELANOVA 2, EL PILAR, MADRID",
      "lat": 40.4718043,
      "lng": -3.7142022
  },
  {
      "id": "207288",
      "description": "SITUADO APARCABICICLETAS - I.E.S�GREGORIO�MARA�ON",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "EL FERROL",
      "addressNumber": "43",
      "addressCode": "20104396",
      "model": "",
      "address": "AVENIDA EL FERROL 43, EL PILAR, MADRID",
      "lat": 40.4743129,
      "lng": -3.7100893
  },
  {
      "id": "207342",
      "description": "SITUADO APARCABICICLETAS - CUATRO�TORRES�BUSINESS�AREA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "85",
      "neighborhoodName": "LA PAZ",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SINESIO DELGADO",
      "addressNumber": "2",
      "addressCode": "11046911",
      "model": "",
      "address": "CALLE SINESIO DELGADO 2, LA PAZ, MADRID",
      "lat": 40.4743818,
      "lng": -3.6879808
  },
  {
      "id": "208440",
      "description": "SITUADO APARCABICICLETAS - C.P.�BREOGAN",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "85",
      "neighborhoodName": "LA PAZ",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GINZO DE LIMIA",
      "addressNumber": "2",
      "addressCode": "31022711",
      "model": "",
      "address": "CALLE GINZO DE LIMIA 2, LA PAZ, MADRID",
      "lat": 40.4763604,
      "lng": -3.7025823
  },
  {
      "id": "209788",
      "description": "SITUADO APARCABICICLETAS - INST.�DEPORTIVA�BASICA�ISLA�DE�TABARCA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTIAGO DE COMPOSTELA",
      "addressNumber": "104",
      "addressCode": "20097783",
      "model": "",
      "address": "CALLE SANTIAGO DE COMPOSTELA 104, PE�A GRANDE, MADRID",
      "lat": 40.4768178,
      "lng": -3.7208449
  },
  {
      "id": "211592",
      "description": "SITUADO APARCABICICLETAS - PARQUE�DEL�POBLADO�SAN�FRANCISCO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "82",
      "neighborhoodName": "FUENTELARREINA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRESNEDILLAS",
      "addressNumber": "8:00 AM",
      "addressCode": "20148701",
      "model": "",
      "address": "CALLE FRESNEDILLAS 8:00 AM, FUENTELARREINA, MADRID",
      "lat": 40.4785597,
      "lng": -3.740909
  },
  {
      "id": "211608",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�VICENTE�DEL�BOSQUE",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "85",
      "neighborhoodName": "LA PAZ",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONFORTE DE LEMOS",
      "addressNumber": "13",
      "addressCode": "11046919",
      "model": "",
      "address": "AVENIDA MONFORTE DE LEMOS 13, LA PAZ, MADRID",
      "lat": 40.4787123,
      "lng": -3.693340099999999
  },
  {
      "id": "212414",
      "description": "SITUADO APARCABICICLETAS - IES�PRINCIPE�FELIPE",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "85",
      "neighborhoodName": "LA PAZ",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONFORTE DE LEMOS",
      "addressNumber": "28",
      "addressCode": "20095316",
      "model": "",
      "address": "AVENIDA MONFORTE DE LEMOS 28, LA PAZ, MADRID",
      "lat": 40.479983,
      "lng": -3.703092
  },
  {
      "id": "212758",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�BRAVO�MURILLO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FERMIN CABALLERO",
      "addressNumber": "82",
      "addressCode": "11045647",
      "model": "",
      "address": "CALLE FERMIN CABALLERO 82, PE�A GRANDE, MADRID",
      "lat": 40.4804906,
      "lng": -3.7173786
  },
  {
      "id": "214609",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�LA�SALLE",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ISLA DE AROSA",
      "addressNumber": "47",
      "addressCode": "11045555",
      "model": "",
      "address": "CALLE ISLA DE AROSA 47, PE�A GRANDE, MADRID",
      "lat": 40.4819353,
      "lng": -3.7175616
  },
  {
      "id": "214877",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�ALHAMBRA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RAMON GOMEZ DE LA SERNA",
      "addressNumber": "103",
      "addressCode": "11077589",
      "model": "",
      "address": "CALLE RAMON GOMEZ DE LA SERNA 103, PE�A GRANDE, MADRID",
      "lat": 40.4827526,
      "lng": -3.7274043
  },
  {
      "id": "214966",
      "description": "SITUADO APARCABICICLETAS - C.P.�CARDENAL�HERRERA�ORIA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FERMIN CABALLERO",
      "addressNumber": "68",
      "addressCode": "11045737",
      "model": "",
      "address": "CALLE FERMIN CABALLERO 68, EL PILAR, MADRID",
      "lat": 40.48314130000001,
      "lng": -3.711582299999999
  },
  {
      "id": "215420",
      "description": "SITUADO APARCABICICLETAS - CASA�DE�NI�OS�ARROYOFRESNO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PICO SALVAGUARDIA",
      "addressNumber": "8",
      "addressCode": "11045499",
      "model": "",
      "address": "PLAZA PICO SALVAGUARDIA 8, PE�A GRANDE, MADRID",
      "lat": 40.4829647,
      "lng": -3.7201862
  },
  {
      "id": "215681",
      "description": "SITUADO APARCABICICLETAS - PARROQUIA�DE�SANTA�MARIA�DE�LA�ESPERANZA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GINZO DE LIMIA",
      "addressNumber": "47",
      "addressCode": "20139529",
      "model": "",
      "address": "CALLE GINZO DE LIMIA 47, EL PILAR, MADRID",
      "lat": 40.4833705,
      "lng": -3.7069041
  },
  {
      "id": "215745",
      "description": "SITUADO APARCABICICLETAS - POLIDEPORTIVO�LA�MASO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "VENTISQUERO DE LA CONDESA",
      "addressNumber": "2",
      "addressCode": "20079569",
      "model": "",
      "address": "AVENIDA VENTISQUERO DE LA CONDESA 2, MIRASIERRA, MADRID",
      "lat": 40.4838427,
      "lng": -3.7153667
  },
  {
      "id": "216205",
      "description": "SITUADO APARCABICICLETAS - BARRIO�DE�ARGENTINA,�FRENTE�A�LA�CANCHA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROSALIA DE CASTRO",
      "addressNumber": "69",
      "addressCode": "31015444",
      "model": "",
      "address": "CALLE ROSALIA DE CASTRO 69, PE�A GRANDE, MADRID",
      "lat": 40.484557,
      "lng": -3.7339528
  },
  {
      "id": "216640",
      "description": "SITUADO APARCABICICLETAS - INS.DEP/METRO�LACOMA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RISCOS DE POLANCO",
      "addressNumber": "3",
      "addressCode": "20087521",
      "model": "",
      "address": "CALLE RISCOS DE POLANCO 3, PE�A GRANDE, MADRID",
      "lat": 40.4848768,
      "lng": -3.7228139
  },
  {
      "id": "216786",
      "description": "SITUADO APARCABICICLETAS - C.E.I.P.�LORENZO�LUZURIAGA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "85",
      "neighborhoodName": "LA PAZ",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALENCIA DE DON JUAN",
      "addressNumber": "4",
      "addressCode": "11045831",
      "model": "",
      "address": "CALLE VALENCIA DE DON JUAN 4, LA PAZ, MADRID",
      "lat": 40.4847904,
      "lng": -3.7069777
  },
  {
      "id": "216969",
      "description": "SITUADO APARCABICICLETAS - C.P.�ENRIQUE�GRANADOS",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VIRGEN DE ARANZAZU",
      "addressNumber": "5",
      "addressCode": "11047353",
      "model": "",
      "address": "CALLE VIRGEN DE ARANZAZU 5, VALVERDE, MADRID",
      "lat": 40.4856265,
      "lng": -3.6909696
  },
  {
      "id": "217387",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�MIRADOR�DE�LA�REINA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CERRO MINGUETE",
      "addressNumber": "156",
      "addressCode": "31044725",
      "model": "",
      "address": "CALLE CERRO MINGUETE 156, MIRASIERRA, MADRID",
      "lat": 40.4871898,
      "lng": -3.7264922
  },
  {
      "id": "217414",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�FOMENTO�MONTEALTO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LA MASO",
      "addressNumber": "76",
      "addressCode": "11051005",
      "model": "",
      "address": "CALLE LA MASO 76, MIRASIERRA, MADRID",
      "lat": 40.4876487,
      "lng": -3.714674999999999
  },
  {
      "id": "217460",
      "description": "SITUADO APARCABICICLETAS - IES�DAMASO�ALONSO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "85",
      "neighborhoodName": "LA PAZ",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALFREDO MARQUERIE",
      "addressNumber": "21",
      "addressCode": "11045818",
      "model": "",
      "address": "CALLE ALFREDO MARQUERIE 21, LA PAZ, MADRID",
      "lat": 40.48714469999999,
      "lng": -3.700640699999999
  },
  {
      "id": "217575",
      "description": "SITUADO APARCABICICLETAS - BIBLIOTECA�PUBLICA�RAFAEL�ALBERTI",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "85",
      "neighborhoodName": "LA PAZ",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANGENJO",
      "addressNumber": "38",
      "addressCode": "20165668",
      "model": "",
      "address": "CALLE SANGENJO 38, LA PAZ, MADRID",
      "lat": 40.4879764,
      "lng": -3.698222699999999
  },
  {
      "id": "217666",
      "description": "SITUADO APARCABICICLETAS - CERCANIAS�RAMON�Y�CAJAL",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FRANCISCO SANCHA",
      "addressNumber": "44",
      "addressCode": "11047267",
      "model": "",
      "address": "CALLE FRANCISCO SANCHA 44, VALVERDE, MADRID",
      "lat": 40.4883611,
      "lng": -3.6933652
  },
  {
      "id": "217909",
      "description": "SITUADO APARCABICICLETAS - POLIGONO�INDUSTRIAL�DE�FUENCARRAL",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LABASTIDA",
      "addressNumber": "1",
      "addressCode": "20152071",
      "model": "",
      "address": "CALLE LABASTIDA 1, VALVERDE, MADRID",
      "lat": 40.488488,
      "lng": -3.689838
  },
  {
      "id": "218442",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CULTURAL�ALFREDO�KRAUS",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "PRADERA DE VAQUERIZAS",
      "addressNumber": "9",
      "addressCode": "31011398",
      "model": "",
      "address": "GLORIETA PRADERA DE VAQUERIZAS 9, MIRASIERRA, MADRID",
      "lat": 40.4908527,
      "lng": -3.721585199999999
  },
  {
      "id": "218669",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�MIRASIERRA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LA MASO",
      "addressNumber": "22",
      "addressCode": "20016656",
      "model": "",
      "address": "CALLE LA MASO 22, MIRASIERRA, MADRID",
      "lat": 40.4913742,
      "lng": -3.7140199
  },
  {
      "id": "219187",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(PARQUE ARROYO FRESNO)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SENDA DEL INFANTE",
      "addressNumber": "36",
      "addressCode": "20135684",
      "model": "",
      "address": "CALLE SENDA DEL INFANTE 36, MIRASIERRA, MADRID",
      "lat": 40.4916051,
      "lng": -3.7225636
  },
  {
      "id": "220887",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�EDUCACION�ESPECIAL�FRANCISCO�DEL�POZO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLEJON",
      "addressStreet": "DOMINE",
      "addressNumber": "6",
      "addressCode": "20016402",
      "model": "",
      "address": "CALLEJON DOMINE 6, VALVERDE, MADRID",
      "lat": 40.4942709,
      "lng": -3.6867376
  },
  {
      "id": "221789",
      "description": "SITUADO APARCABICICLETAS - ARROYOFRESNO�M-612�ACCESO�M-40",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LEONOR LOPEZ DE CORDOBA",
      "addressNumber": "2",
      "addressCode": "31049759",
      "model": "",
      "address": "CALLE LEONOR LOPEZ DE CORDOBA 2, MIRASIERRA, MADRID",
      "lat": 40.49623039999999,
      "lng": -3.7202095
  },
  {
      "id": "222575",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�VASCO�NU�EZ�DE�BALBOA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NUESTRA SE�ORA DE VALVERDE",
      "addressNumber": "149",
      "addressCode": "11049856",
      "model": "",
      "address": "CALLE NUESTRA SE�ORA DE VALVERDE 149, VALVERDE, MADRID",
      "lat": 40.4996062,
      "lng": -3.6865112
  },
  {
      "id": "223050",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�BASICA�TRES�OLIVOS",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROCINANTE",
      "addressNumber": "15",
      "addressCode": "20076943",
      "model": "",
      "address": "CALLE ROCINANTE 15, VALVERDE, MADRID",
      "lat": 40.50181509999999,
      "lng": -3.6928652
  },
  {
      "id": "223230",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(AVDA�STO�DOMINGO�DE�LA�CALZADA�1)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SANTO DOMINGO DE LA CALZADA",
      "addressNumber": "1",
      "addressCode": "31040770",
      "model": "",
      "address": "AVENIDA SANTO DOMINGO DE LA CALZADA 1, VALVERDE, MADRID",
      "lat": 40.5023401,
      "lng": -3.6620488
  },
  {
      "id": "223251",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(AVDA�STO�DOMINGO�DE�LA�CALZADA�1)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SANTO DOMINGO DE LA CALZADA",
      "addressNumber": "1",
      "addressCode": "31040770",
      "model": "",
      "address": "AVENIDA SANTO DOMINGO DE LA CALZADA 1, VALVERDE, MADRID",
      "lat": 40.5023401,
      "lng": -3.6620488
  },
  {
      "id": "223280",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(AVDA�STO�DOMINGO�DE�LA�CALZADA�1)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SANTO DOMINGO DE LA CALZADA",
      "addressNumber": "1",
      "addressCode": "31040770",
      "model": "",
      "address": "AVENIDA SANTO DOMINGO DE LA CALZADA 1, VALVERDE, MADRID",
      "lat": 40.5023401,
      "lng": -3.6620488
  },
  {
      "id": "223286",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(C/�PALAS�DEL�REY�4)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PALAS DE REY",
      "addressNumber": "4",
      "addressCode": "31030971",
      "model": "",
      "address": "CALLE PALAS DE REY 4, VALVERDE, MADRID",
      "lat": 40.5044567,
      "lng": -3.6606207
  },
  {
      "id": "223776",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(AVDA�MONASTERIO�DEL�ESCORIAL�3)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONASTERIO DE EL ESCORIAL",
      "addressNumber": "3",
      "addressCode": "31045197",
      "model": "",
      "address": "AVENIDA MONASTERIO DE EL ESCORIAL 3, EL GOLOSO, MADRID",
      "lat": 40.5035463,
      "lng": -3.7132164
  },
  {
      "id": "224147",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(C/�NTRA�SRA�DE�VALVERDE�225)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NUESTRA SE�ORA DE VALVERDE",
      "addressNumber": "225",
      "addressCode": "31053344",
      "model": "",
      "address": "CALLE NUESTRA SE�ORA DE VALVERDE 225, VALVERDE, MADRID",
      "lat": 40.5061428,
      "lng": -3.6855831
  },
  {
      "id": "224514",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA�(AUTOVIA�M-40)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AUTOVIA",
      "addressStreet": "M-40",
      "addressNumber": "5650",
      "addressCode": "31048627",
      "model": "",
      "address": "AUTOVIA M-40 5650, EL GOLOSO, MADRID",
      "lat": 40.5140843,
      "lng": -3.6962635
  },
  {
      "id": "224541",
      "description": "SITUADO APARCABICICLETAS - ANILLO�VERDE�CICLISTA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AUTOVIA",
      "addressStreet": "M-40",
      "addressNumber": "5650",
      "addressCode": "31048627",
      "model": "",
      "address": "AUTOVIA M-40 5650, EL GOLOSO, MADRID",
      "lat": 40.5140843,
      "lng": -3.6962635
  },
  {
      "id": "224772",
      "description": "SITUADO APARCABICICLETAS - CENTRO�COMERCIAL�MONTECARMELO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONASTERIO DE ARLANZA",
      "addressNumber": "20",
      "addressCode": "31018872",
      "model": "",
      "address": "CALLE MONASTERIO DE ARLANZA 20, EL GOLOSO, MADRID",
      "lat": 40.5103904,
      "lng": -3.6900581
  },
  {
      "id": "3569405",
      "description": "SITUADO APARCABICICLETAS - C/ DUQUE DE NAJERA N�3",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DUQUE DE NAJERA",
      "addressNumber": "3",
      "addressCode": "31038731",
      "model": "",
      "address": "CALLE DUQUE DE NAJERA 3, PALACIO, MADRID",
      "lat": 40.41497200000001,
      "lng": -3.711128
  },
  {
      "id": "528404",
      "description": "SITUADOS_APARCABICICLETAS - METRO CIUDAD DE LOS �NGELES",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TERTULIA",
      "addressNumber": "17",
      "addressCode": "20062065",
      "model": "",
      "address": "CALLE TERTULIA 17, LOS ROSALES, MADRID",
      "lat": 40.3595574,
      "lng": -3.692626
  },
  {
      "id": "528405",
      "description": "SITUADOS_APARCABICICLETAS -",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROMERAL",
      "addressNumber": "11",
      "addressCode": "20153740",
      "model": "",
      "address": "CALLE ROMERAL 11, LOS ANGELES, MADRID",
      "lat": 40.3491005,
      "lng": -3.696836
  },
  {
      "id": "",
      "description": "LA N@VE - CALLE DEL ROMERAL 11",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528412",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE ALCOCER 3",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCOCER",
      "addressNumber": "3",
      "addressCode": "11128273",
      "model": "",
      "address": "CALLE ALCOCER 3, LOS ANGELES, MADRID",
      "lat": 40.3505093,
      "lng": -3.6959861
  },
  {
      "id": "528413",
      "description": "SITUADOS_APARCABICICLETAS - CENTRO DEPORTIVO MUNICIPAL SAN CRIST�BAL",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "172",
      "neighborhoodName": "SAN CRISTOBAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PINAZO",
      "addressNumber": "9",
      "addressCode": "11126203",
      "model": "",
      "address": "PLAZA PINAZO 9, SAN CRISTOBAL, MADRID",
      "lat": 40.3426836,
      "lng": -3.6914402
  },
  {
      "id": "528411",
      "description": "SITUADOS_APARCABICICLETAS - PASEO DE LOS GIGANTES Y CABEZUDOS 70",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "GIGANTES Y CABEZUDOS",
      "addressNumber": "70",
      "addressCode": "31046547",
      "model": "",
      "address": "PASEO GIGANTES Y CABEZUDOS 70, LOS ANGELES, MADRID",
      "lat": 40.35301860000001,
      "lng": -3.6934751
  },
  {
      "id": "528414",
      "description": "SITUADOS_APARCABICICLETAS - PLAZA DE AGATA 11",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "AGATA",
      "addressNumber": "11",
      "addressCode": "11125157",
      "model": "",
      "address": "PLAZA AGATA 11, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.346988,
      "lng": -3.708622999999999
  },
  {
      "id": "528415",
      "description": "SITUADOS_APARCABICICLETAS - POLIDEPORTIVO PLATA Y CASTA�AR",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "PLATA Y CASTA�AR",
      "addressNumber": "7",
      "addressCode": "20177290",
      "model": "",
      "address": "PASEO PLATA Y CASTA�AR 7, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3505927,
      "lng": -3.7184477
  },
  {
      "id": "528417",
      "description": "SITUADOS_APARCABICICLETAS - PASEO DE LAS MORERAS 5",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "MORERAS",
      "addressNumber": "5",
      "addressCode": "11125164",
      "model": "",
      "address": "PASEO MORERAS 5, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3468593,
      "lng": -3.7106506
  },
  {
      "id": "528418",
      "description": "SITUADOS_APARCABICICLETAS - COLEGIO P�BLICO LOS ROSALES",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "173",
      "neighborhoodName": "BUTARQUE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "LOS ROSALES",
      "addressNumber": "131",
      "addressCode": "31004577",
      "model": "",
      "address": "AVENIDA LOS ROSALES 131, BUTARQUE, MADRID",
      "lat": 40.3517658,
      "lng": -3.6778234
  },
  {
      "id": "528420",
      "description": "SITUADOS_APARCABICICLETAS - PASEO DE ALBERTO PALACIOS 13",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "ALBERTO PALACIOS",
      "addressNumber": "16",
      "addressCode": "31001432",
      "model": "",
      "address": "PASEO ALBERTO PALACIOS 16, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3463901,
      "lng": -3.7104574
  },
  {
      "id": "528422",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA TERTULIA 3",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TERTULIA",
      "addressNumber": "1",
      "addressCode": "20062041",
      "model": "",
      "address": "CALLE TERTULIA 1, LOS ROSALES, MADRID",
      "lat": 40.3629529,
      "lng": -3.6924261
  },
  {
      "id": "528425",
      "description": "SITUADOS_APARCABICICLETAS - CONSERVATORIO PROFESIONAL DE M�SICA \"VICTORIA DE LOS �NGELES\" MADRID",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "OROVILLA",
      "addressNumber": "51",
      "addressCode": "20061915",
      "model": "",
      "address": "AVENIDA OROVILLA 51, LOS ROSALES, MADRID",
      "lat": 40.3584784,
      "lng": -3.6893018
  },
  {
      "id": "528428",
      "description": "SITUADOS_APARCABICICLETAS - COLEGIO P�BLICO EL GRECO",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "173",
      "neighborhoodName": "BUTARQUE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ESTEFANITA",
      "addressNumber": "7",
      "addressCode": "20166964",
      "model": "",
      "address": "CALLE ESTEFANITA 7, BUTARQUE, MADRID",
      "lat": 40.3454447,
      "lng": -3.6744458
  },
  {
      "id": "528430",
      "description": "SITUADOS_APARCABICICLETAS - POLIDEPORTIVO DE MARCONI",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRINCIPAL",
      "addressNumber": "1",
      "addressCode": "20174655",
      "model": "",
      "address": "CALLE PRINCIPAL 1, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.33355,
      "lng": -3.69819
  },
  {
      "id": "528435",
      "description": "SITUADOS_APARCABICICLETAS - CENTRO DEPORTIVO MUNICIPAL F�LIX RUBIO",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CIUDADANIA",
      "addressNumber": "5",
      "addressCode": "20062317",
      "model": "",
      "address": "CALLE CIUDADANIA 5, LOS ROSALES, MADRID",
      "lat": 40.3567147,
      "lng": -3.691591399999999
  },
  {
      "id": "528439",
      "description": "SITUADOS_APARCABICICLETAS - CERCAN�AS PUENTE ALCOCER",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCOCER",
      "addressNumber": "45B",
      "addressCode": "20078356",
      "model": "",
      "address": "CALLE ALCOCER 45B, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3498063,
      "lng": -3.7052303
  },
  {
      "id": "528440",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE MARIA BARRIENTOS 2",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARIA BARRIENTOS",
      "addressNumber": "2",
      "addressCode": "31002007",
      "model": "",
      "address": "CALLE MARIA BARRIENTOS 2, LOS ROSALES, MADRID",
      "lat": 40.3548984,
      "lng": -3.686462499999999
  },
  {
      "id": "528443",
      "description": "SITUADOS_APARCABICICLETAS - RESIDENCIA VILLAVERDE ALZHEIMER GESTI�N P�BLICA",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CARRETERA",
      "addressStreet": "VILLAVERDE A VALLECAS",
      "addressNumber": "38",
      "addressCode": "31002625",
      "model": "",
      "address": "CARRETERA VILLAVERDE A VALLECAS 38, LOS ROSALES, MADRID",
      "lat": 40.356344,
      "lng": -3.6861622
  },
  {
      "id": "528446",
      "description": "SITUADOS_APARCABICICLETAS - AVENIDA OROVILLA 35",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "OROVILLA",
      "addressNumber": "35",
      "addressCode": "20061891",
      "model": "",
      "address": "AVENIDA OROVILLA 35, LOS ROSALES, MADRID",
      "lat": 40.3621302,
      "lng": -3.6894307
  },
  {
      "id": "528451",
      "description": "SITUADOS_APARCABICICLETAS - METRO VILLAVERDE BAJO - CRUCE",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ANDALUCIA",
      "addressNumber": "45",
      "addressCode": "31040262",
      "model": "",
      "address": "AVENIDA ANDALUCIA 45, LOS ROSALES, MADRID",
      "lat": 40.347483,
      "lng": -3.6930809
  },
  {
      "id": "528453",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DEL BERROCAL 46",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "173",
      "neighborhoodName": "BUTARQUE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BERROCAL",
      "addressNumber": "46",
      "addressCode": "20178377",
      "model": "",
      "address": "CALLE BERROCAL 46, BUTARQUE, MADRID",
      "lat": 40.34364799999999,
      "lng": -3.6764502
  },
  {
      "id": "528457",
      "description": "SITUADOS APARCABICICLETAS - CALLE DE ROCAFORT 9",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "172",
      "neighborhoodName": "SAN CRISTOBAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROCAFORT",
      "addressNumber": "9",
      "addressCode": "31012878",
      "model": "",
      "address": "CALLE ROCAFORT 9, SAN CRISTOBAL, MADRID",
      "lat": 40.3415608,
      "lng": -3.6899947
  },
  {
      "id": "528458",
      "description": "SITUADOS APARCABICLETAS - CENTRO SOCIOCULTURAL �GATA",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR MARTIN AREVALO",
      "addressNumber": "4",
      "addressCode": "11124076",
      "model": "",
      "address": "CALLE DOCTOR MARTIN AREVALO 4, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3462324,
      "lng": -3.7083352
  },
  {
      "id": "528460",
      "description": "SITUADOS APARCABICILETAS - CERCAN�AS VILLAVERDE BAJO",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "ESTACION",
      "addressNumber": "2",
      "addressCode": "31011912",
      "model": "",
      "address": "PASEO ESTACION 2, LOS ROSALES, MADRID",
      "lat": 40.3529985,
      "lng": -3.6843294
  },
  {
      "id": "528463",
      "description": "SITUADOS_APARCABICICLETAS - BIBLIOTECA P�BLICA MAR�A MOLINER",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "OLAS",
      "addressNumber": "2",
      "addressCode": "20049365",
      "model": "",
      "address": "CALLE OLAS 2, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3496004,
      "lng": -3.7099482
  },
  {
      "id": "528464",
      "description": "SITUADOS_APARCABICICLETAS - CENTRO SOCIOCULTURAL BOHEMIOS",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LOS BOHEMIOS",
      "addressNumber": "1",
      "addressCode": "11143020",
      "model": "",
      "address": "CALLE LOS BOHEMIOS 1, LOS ANGELES, MADRID",
      "lat": 40.3564052,
      "lng": -3.6940357
  },
  {
      "id": "528467",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA CORTE DE FARAON 35",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LA CORTE DE FARAON",
      "addressNumber": "35",
      "addressCode": "11129071",
      "model": "",
      "address": "CALLE LA CORTE DE FARAON 35, LOS ANGELES, MADRID",
      "lat": 40.3617379,
      "lng": -3.6999753
  },
  {
      "id": "528524",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA CANCION DEL OLVIDO 13",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LA CANCION DEL OLVIDO",
      "addressNumber": "13",
      "addressCode": "11129032",
      "model": "",
      "address": "CALLE LA CANCION DEL OLVIDO 13, LOS ANGELES, MADRID",
      "lat": 40.3599872,
      "lng": -3.6985885
  },
  {
      "id": "528525",
      "description": "SITUADOS_APARCABICICLETAS - IES CELESTINO MUTIS",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN PE�ALVER",
      "addressNumber": "8",
      "addressCode": "11124869",
      "model": "",
      "address": "CALLE JUAN PE�ALVER 8, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3485129,
      "lng": -3.7135137
  },
  {
      "id": "528529",
      "description": "SITUADOS_APARCABICICLETAS - METRO VILLAVERDE ALTO",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALLE DE TOBALINA",
      "addressNumber": "52",
      "addressCode": "20079078",
      "model": "",
      "address": "CALLE VALLE DE TOBALINA 52, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3407176,
      "lng": -3.7133386
  },
  {
      "id": "528442",
      "description": "SITUADOS_APARCABICICLETAS -",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "172",
      "neighborhoodName": "SAN CRISTOBAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GODELLA",
      "addressNumber": "57",
      "addressCode": "11126076",
      "model": "",
      "address": "CALLE GODELLA 57, SAN CRISTOBAL, MADRID",
      "lat": 40.3430788,
      "lng": -3.6871775
  },
  {
      "id": "",
      "description": "C.E.I.P. AZOR�N",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528532",
      "description": "SITUADOS_APARCABICICLETAS - METRO VILLAVERDE ALTO",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOMINGO PARRAGA",
      "addressNumber": "15",
      "addressCode": "31016128",
      "model": "",
      "address": "CALLE DOMINGO PARRAGA 15, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.34160019999999,
      "lng": -3.7120383
  },
  {
      "id": "528533",
      "description": "SITUADOS_APARCABICICLETAS - I.E.S. CIUDAD DE LOS ANGELES",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANOETA",
      "addressNumber": "14",
      "addressCode": "31013720",
      "model": "",
      "address": "CALLE ANOETA 14, LOS ANGELES, MADRID",
      "lat": 40.352861,
      "lng": -3.6963156
  },
  {
      "id": "528534",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA RESINA 27",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "171",
      "neighborhoodName": "VILLAVERDE ALTO C.H.",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RESINA",
      "addressNumber": "27",
      "addressCode": "20118815",
      "model": "",
      "address": "CALLE RESINA 27, VILLAVERDE ALTO C.H., MADRID",
      "lat": 40.3355366,
      "lng": -3.7072978
  },
  {
      "id": "3568893",
      "description": "SITUADOS_APARCABICICLETAS -",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "175",
      "neighborhoodName": "LOS ANGELES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PIMIENTA",
      "addressNumber": "18",
      "addressCode": "20153573",
      "model": "",
      "address": "CALLE PIMIENTA 18, LOS ANGELES, MADRID",
      "lat": 40.3489767,
      "lng": -3.6958183
  },
  {
      "id": "",
      "description": "LA N@VE- CALLE DE LA PIMIENTA 18",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528276",
      "description": "SITUADO_APARCABICICLETAS - CALLE PE�A GORBEA, 13",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "132",
      "neighborhoodName": "SAN DIEGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PE�A GORBEA",
      "addressNumber": "13",
      "addressCode": "20027790",
      "model": "",
      "address": "CALLE PE�A GORBEA 13, SAN DIEGO, MADRID",
      "lat": 40.39589,
      "lng": -3.66733
  },
  {
      "id": "528275",
      "description": "SITUADO_APARCABICICLETAS - CALLE PE�A UBI�A, 6",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "132",
      "neighborhoodName": "SAN DIEGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PE�A UBI�A",
      "addressNumber": "6",
      "addressCode": "20096034",
      "model": "",
      "address": "CALLE PE�A UBI�A 6, SAN DIEGO, MADRID",
      "lat": 40.3950346,
      "lng": -3.6710775
  },
  {
      "id": "528315",
      "description": "SITUADO_APARCABICICLETAS -CALLE DE LA SIERRA CARBONERA 72",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "132",
      "neighborhoodName": "SAN DIEGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SIERRA CARBONERA",
      "addressNumber": "74",
      "addressCode": "11092753",
      "model": "",
      "address": "CALLE SIERRA CARBONERA 74, SAN DIEGO, MADRID",
      "lat": 40.3903226,
      "lng": -3.6652353
  },
  {
      "id": "528580",
      "description": "SITUADO_APARCABICICLETAS- IAM INFORMATICA DEL AYUNTAMIENTO DE MADRID",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ENTREVIAS",
      "addressNumber": "24",
      "addressCode": "20027384",
      "model": "",
      "address": "AVENIDA ENTREVIAS 24, ENTREVIAS, MADRID",
      "lat": 40.3869936,
      "lng": -3.6715554
  },
  {
      "id": "528515",
      "description": "SITUADO_APARCABICICLETAS - IES ARCIPRESTE DE HITA",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "RONDA",
      "addressStreet": "SUR",
      "addressNumber": "6",
      "addressCode": "20142134",
      "model": "",
      "address": "RONDA SUR 6, ENTREVIAS, MADRID",
      "lat": 40.3768851,
      "lng": -3.671852
  },
  {
      "id": "528578",
      "description": "SITUADO_APARCABICICLETAS - IES NUMANCIA",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "136",
      "neighborhoodName": "NUMANCIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARISMAS",
      "addressNumber": "6",
      "addressCode": "11099278",
      "model": "",
      "address": "CALLE MARISMAS 6, NUMANCIA, MADRID",
      "lat": 40.402494,
      "lng": -3.6552009
  },
  {
      "id": "528300",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO LOS TILOS",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "136",
      "neighborhoodName": "NUMANCIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EL BOSCO",
      "addressNumber": "22",
      "addressCode": "31024346",
      "model": "",
      "address": "CALLE EL BOSCO 22, NUMANCIA, MADRID",
      "lat": 40.399098,
      "lng": -3.6491944
  },
  {
      "id": "528267",
      "description": "SITUADO_APARCABICICLETAS- COLEGIO CENTRO CULTURAL PALOMERAS",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANTONIO MAIRENA",
      "addressNumber": "19B",
      "addressCode": "31028951",
      "model": "",
      "address": "CALLE ANTONIO MAIRENA 19B, PALOMERAS SURESTE, MADRID",
      "lat": 40.3925255,
      "lng": -3.640311
  },
  {
      "id": "528260",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LOS ANDALUCES 20",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANDALUCES",
      "addressNumber": "20",
      "addressCode": "11096602",
      "model": "",
      "address": "CALLE ANDALUCES 20, PALOMERAS SURESTE, MADRID",
      "lat": 40.3901678,
      "lng": -3.6380615
  },
  {
      "id": "528551",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE GERARDO DIEGO 15",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GERARDO DIEGO",
      "addressNumber": "15",
      "addressCode": "20035240",
      "model": "",
      "address": "CALLE GERARDO DIEGO 15, PALOMERAS SURESTE, MADRID",
      "lat": 40.3848993,
      "lng": -3.6431257
  },
  {
      "id": "528508",
      "description": "SITUADO_APARCABICICLETAS - BIBLIOTECA PUBLICA MIGUEL HERNANDEZ",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EXTREME�OS",
      "addressNumber": "5",
      "addressCode": "20139499",
      "model": "",
      "address": "CALLE EXTREME�OS 5, PALOMERAS SURESTE, MADRID",
      "lat": 40.3885316,
      "lng": -3.6432394
  },
  {
      "id": "461390",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE JOSE PAULETE 59",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "135",
      "neighborhoodName": "PORTAZGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE PAULETE",
      "addressNumber": "59",
      "addressCode": "31009311",
      "model": "",
      "address": "CALLE JOSE PAULETE 59, PORTAZGO, MADRID",
      "lat": 40.3925174,
      "lng": -3.6502053
  },
  {
      "id": "528308",
      "description": "SITUADO_APARCABICICLETAS - CENTRO CULTURAL ALBERTO SANCHEZ",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "136",
      "neighborhoodName": "NUMANCIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RISCO DE PELOCHE",
      "addressNumber": "15",
      "addressCode": "20037794",
      "model": "",
      "address": "CALLE RISCO DE PELOCHE 15, NUMANCIA, MADRID",
      "lat": 40.3937809,
      "lng": -3.6555413
  },
  {
      "id": "528355",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE JAVIER DE MIGUEL 33A",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JAVIER DE MIGUEL",
      "addressNumber": "33A",
      "addressCode": "20059549",
      "model": "",
      "address": "CALLE JAVIER DE MIGUEL 33A, PALOMERAS BAJAS, MADRID",
      "lat": 40.3873591,
      "lng": -3.6592137
  },
  {
      "id": "528313",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DE PABLO NERUDA 89B",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PABLO NERUDA",
      "addressNumber": "89B",
      "addressCode": "20134775",
      "model": "",
      "address": "AVENIDA PABLO NERUDA 89B, PALOMERAS BAJAS, MADRID",
      "lat": 40.3812181,
      "lng": -3.6599081
  },
  {
      "id": "528366",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DE PABLO NERUDA 93",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PABLO NERUDA",
      "addressNumber": "93",
      "addressCode": "20081667",
      "model": "",
      "address": "AVENIDA PABLO NERUDA 93, PALOMERAS BAJAS, MADRID",
      "lat": 40.3812503,
      "lng": -3.6620628
  },
  {
      "id": "528272",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LOS LEONESES 23",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LEONESES",
      "addressNumber": "23",
      "addressCode": "31046491",
      "model": "",
      "address": "CALLE LEONESES 23, PALOMERAS SURESTE, MADRID",
      "lat": 40.3813057,
      "lng": -3.647153499999999
  },
  {
      "id": "528262",
      "description": "SITUADO_APARCABICICLETAS - IES ANTONIO DOMINGUEZ ORTIZ",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MIGUEL HERNANDEZ",
      "addressNumber": "48",
      "addressCode": "20035278",
      "model": "",
      "address": "AVENIDA MIGUEL HERNANDEZ 48, PALOMERAS SURESTE, MADRID",
      "lat": 40.3884953,
      "lng": -3.6350623
  },
  {
      "id": "528365",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DE BUENOS AIRES 99",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "BUENOS AIRES",
      "addressNumber": "99",
      "addressCode": "20078119",
      "model": "",
      "address": "AVENIDA BUENOS AIRES 99, PALOMERAS SURESTE, MADRID",
      "lat": 40.3797371,
      "lng": -3.6526094
  },
  {
      "id": "528517",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE TRISTANA 10",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TRISTANA",
      "addressNumber": "10",
      "addressCode": "20081213",
      "model": "",
      "address": "CALLE TRISTANA 10, PALOMERAS BAJAS, MADRID",
      "lat": 40.3786923,
      "lng": -3.654249
  },
  {
      "id": "528358",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DEL PARQUE DE PALOMERAS BAJAS 13",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PARQUE DE PALOMERAS BAJAS",
      "addressNumber": "13",
      "addressCode": "20080217",
      "model": "",
      "address": "AVENIDA PARQUE DE PALOMERAS BAJAS 13, PALOMERAS BAJAS, MADRID",
      "lat": 40.38035660000001,
      "lng": -3.6631106
  },
  {
      "id": "528516",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE NOVECENTO 4",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VIRIDIANA",
      "addressNumber": "13F",
      "addressCode": "20081150",
      "model": "",
      "address": "CALLE VIRIDIANA 13F, PALOMERAS BAJAS, MADRID",
      "lat": 40.3775221,
      "lng": -3.6550555
  },
  {
      "id": "528514",
      "description": "SITUADO_APARCABICICLETAS - POLIDEPORTIVO PALOMERAS",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TRANVIA DE ARGANDA",
      "addressNumber": "4",
      "addressCode": "20169452",
      "model": "",
      "address": "CALLE TRANVIA DE ARGANDA 4, PALOMERAS SURESTE, MADRID",
      "lat": 40.3824049,
      "lng": -3.6343279
  },
  {
      "id": "528496",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE ENRIQUE GARCIA ALVAREZ 11",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ENRIQUE GARCIA ALVAREZ",
      "addressNumber": "11",
      "addressCode": "20142421",
      "model": "",
      "address": "CALLE ENRIQUE GARCIA ALVAREZ 11, SANTA EUGENIA, MADRID",
      "lat": 40.3822361,
      "lng": -3.615278499999999
  },
  {
      "id": "528503",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE ROMEO Y JULIETA 13",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ROMEO Y JULIETA",
      "addressNumber": "13",
      "addressCode": "20080943",
      "model": "",
      "address": "CALLE ROMEO Y JULIETA 13, PALOMERAS BAJAS, MADRID",
      "lat": 40.3788747,
      "lng": -3.6620316
  },
  {
      "id": "528549",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SEPIOLITA",
      "addressNumber": "6",
      "addressCode": "31028697",
      "model": "",
      "address": "CALLE SEPIOLITA 6, CASCO H.VICALVARO, MADRID",
      "lat": 40.4067712,
      "lng": -3.5987648
  },
  {
      "id": "",
      "description": "Punto Limpio FIjo Distrito Vic�lvaro",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528438",
      "description": "SITUADO_APARCABICICLETAS - IES VILLABLANCA",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLABLANCA",
      "addressNumber": "79",
      "addressCode": "20076408",
      "model": "",
      "address": "CALLE VILLABLANCA 79, CASCO H.VICALVARO, MADRID",
      "lat": 40.4059845,
      "lng": -3.600136
  },
  {
      "id": "528346",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO DE LA PRESENTACION DE NTRA SRA",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LARDERO",
      "addressNumber": "2",
      "addressCode": "31012361",
      "model": "",
      "address": "CALLE LARDERO 2, CASCO H.VICALVARO, MADRID",
      "lat": 40.410358,
      "lng": -3.6106812
  },
  {
      "id": "528271",
      "description": "SITUADO_APARCABICICLETAS - DOCTOR SEVERO OCHOA",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EZCARAY",
      "addressNumber": "3",
      "addressCode": "11133387",
      "model": "",
      "address": "CALLE EZCARAY 3, CASCO H.VICALVARO, MADRID",
      "lat": 40.409796,
      "lng": -3.6119515
  },
  {
      "id": "528279",
      "description": "SITUADO_APARCABICICLETAS - PASEO DE LOS ARTILLEROS 35",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "ARTILLEROS",
      "addressNumber": "35",
      "addressCode": "31021963",
      "model": "",
      "address": "PASEO ARTILLEROS 35, CASCO H.VICALVARO, MADRID",
      "lat": 40.404209,
      "lng": -3.6089454
  },
  {
      "id": "528480",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL MERCURIO 20",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MERCURIO",
      "addressNumber": "20",
      "addressCode": "11132718",
      "model": "",
      "address": "CALLE MERCURIO 20, CASCO H.VICALVARO, MADRID",
      "lat": 40.4051567,
      "lng": -3.6053817
  },
  {
      "id": "528282",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE SAN CIPRIANO 46",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN CIPRIANO",
      "addressNumber": "46",
      "addressCode": "31025058",
      "model": "",
      "address": "CALLE SAN CIPRIANO 46, CASCO H.VICALVARO, MADRID",
      "lat": 40.4038299,
      "lng": -3.6038365
  },
  {
      "id": "528281",
      "description": "SITUADO_APARCABICICLETAS - CEIP PEDRO DUQUE",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "193",
      "neighborhoodName": "VALDERRIVAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARMOLINA",
      "addressNumber": "5",
      "addressCode": "31022043",
      "model": "",
      "address": "CALLE MARMOLINA 5, VALDERRIVAS, MADRID",
      "lat": 40.39664,
      "lng": -3.60632
  },
  {
      "id": "528421",
      "description": "SITUADO_APARCABICICLETAS - BIBLIOTECA PUBLICA MUNICIPAL FRANCISCO AYALA",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "BULEVAR",
      "addressStreet": "INDALECIO PRIETO",
      "addressNumber": "21",
      "addressCode": "31025948",
      "model": "",
      "address": "BULEVAR INDALECIO PRIETO 21, VALDEBERNARDO, MADRID",
      "lat": 40.39976360000001,
      "lng": -3.6211074
  },
  {
      "id": "528351",
      "description": "SITUADO_APARCABICICLETAS - CENTRO CULTURAL VALDEBERNARDO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "BULEVAR",
      "addressStreet": "INDALECIO PRIETO",
      "addressNumber": "21",
      "addressCode": "31025948",
      "model": "",
      "address": "BULEVAR INDALECIO PRIETO 21, VALDEBERNARDO, MADRID",
      "lat": 40.39976360000001,
      "lng": -3.6211074
  },
  {
      "id": "528454",
      "description": "SITUADO_APARCABICICLETAS- COLEGIO EL VALLE VALDEBERNARDO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CORDEL DE PAVONES",
      "addressNumber": "2",
      "addressCode": "20153504",
      "model": "",
      "address": "CALLE CORDEL DE PAVONES 2, VALDEBERNARDO, MADRID",
      "lat": 40.4027584,
      "lng": -3.6177287
  },
  {
      "id": "528319",
      "description": "SITUADO_APARCABICICLETAS - ESCUELA MUNICIPAL DE MUSICA DE VICALVARO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "BULEVAR",
      "addressStreet": "INDALECIO PRIETO",
      "addressNumber": "16",
      "addressCode": "20100387",
      "model": "",
      "address": "BULEVAR INDALECIO PRIETO 16, VALDEBERNARDO, MADRID",
      "lat": 40.4019062,
      "lng": -3.6212657
  },
  {
      "id": "528499",
      "description": "SITUADO_APARCABICICLETAS - BULEVAR DE INDALECIO PRIETO 7",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "BULEVAR",
      "addressStreet": "INDALECIO PRIETO",
      "addressNumber": "7",
      "addressCode": "20137375",
      "model": "",
      "address": "BULEVAR INDALECIO PRIETO 7, VALDEBERNARDO, MADRID",
      "lat": 40.4037088,
      "lng": -3.6202458
  },
  {
      "id": "528310",
      "description": "SITUADO_APARCABICICLETAS - IES VALDEBERNARDO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "BULEVAR",
      "addressStreet": "INDALECIO PRIETO",
      "addressNumber": "3",
      "addressCode": "20169020",
      "model": "",
      "address": "BULEVAR INDALECIO PRIETO 3, VALDEBERNARDO, MADRID",
      "lat": 40.4050837,
      "lng": -3.6193058
  },
  {
      "id": "528309",
      "description": "SITUADO_APARCABICICLETAS- CENTRO DEPORTIVO MUNICIPAL DE VALDEBERNARDO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LADERA DE LOS ALMENDROS",
      "addressNumber": "2",
      "addressCode": "31023316",
      "model": "",
      "address": "CALLE LADERA DE LOS ALMENDROS 2, VALDEBERNARDO, MADRID",
      "lat": 40.4044306,
      "lng": -3.6232051
  },
  {
      "id": "528470",
      "description": "SITUADO_APARCABICICLETAS - CENTRO DEPORTIVO MUNICIPAL DE VILCALVARO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "POLIDEPORTIVO",
      "addressNumber": "3",
      "addressCode": "20064592",
      "model": "",
      "address": "PASEO POLIDEPORTIVO 3, CASCO H.VICALVARO, MADRID",
      "lat": 40.4091451,
      "lng": -3.6032474
  },
  {
      "id": "528510",
      "description": "SITUADO_APARCABICICLETAS - Colegio Zazuar",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ZAZUAR",
      "addressNumber": "17",
      "addressCode": "11131637",
      "model": "",
      "address": "CALLE ZAZUAR 17, SANTA EUGENIA, MADRID",
      "lat": 40.3814391,
      "lng": -3.60793
  },
  {
      "id": "461388",
      "description": "SITUADO_APARCABICICLETAS - IES VILLA DE VALLECAS",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLARINO DE LOS AIRES",
      "addressNumber": "14",
      "addressCode": "11131471",
      "model": "",
      "address": "CALLE VILLARINO DE LOS AIRES 14, CASCO H.VALLECAS, MADRID",
      "lat": 40.375061,
      "lng": -3.624756
  },
  {
      "id": "528465",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE FUENTIDUE�A 11",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTIDUE�A",
      "addressNumber": "11",
      "addressCode": "20072213",
      "model": "",
      "address": "CALLE FUENTIDUE�A 11, CASCO H.VALLECAS, MADRID",
      "lat": 40.3727732,
      "lng": -3.6223757
  },
  {
      "id": "528476",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTIDUE�A",
      "addressNumber": "19",
      "addressCode": "20072220",
      "model": "",
      "address": "CALLE FUENTIDUE�A 19, CASCO H.VALLECAS, MADRID",
      "lat": 40.3727732,
      "lng": -3.6223757
  },
  {
      "id": "",
      "description": "CENTRO DEPORTIVO MUNICIPAL MIGUEL GUILLEN PRIM",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528461",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE FUENTIDUE�A 41",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTIDUE�A",
      "addressNumber": "41",
      "addressCode": "20072202",
      "model": "",
      "address": "CALLE FUENTIDUE�A 41, CASCO H.VALLECAS, MADRID",
      "lat": 40.3710057,
      "lng": -3.6215652
  },
  {
      "id": "528478",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA HUELGA 6",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HUELGA",
      "addressNumber": "6",
      "addressCode": "11131349",
      "model": "",
      "address": "CALLE HUELGA 6, CASCO H.VALLECAS, MADRID",
      "lat": 40.3699288,
      "lng": -3.6231567
  },
  {
      "id": "528474",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL CONGOSTO 35",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONGOSTO",
      "addressNumber": "35",
      "addressCode": "11131198",
      "model": "",
      "address": "CALLE CONGOSTO 35, CASCO H.VALLECAS, MADRID",
      "lat": 40.3719493,
      "lng": -3.6185098
  },
  {
      "id": "529234",
      "description": "SITUADO_APARCABICICLETAS - CEIP JUAN GRIS",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASAJE",
      "addressStreet": "CONGOSTO",
      "addressNumber": "8",
      "addressCode": "31047174",
      "model": "",
      "address": "PASAJE CONGOSTO 8, CASCO H.VALLECAS, MADRID",
      "lat": 40.3755871,
      "lng": -3.6204841
  },
  {
      "id": "528479",
      "description": "SITUADO_APARCABICICLETAS - Instalaci�n Deportiva Municipal Mar Abierto",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CAMINO",
      "addressStreet": "SUERTE",
      "addressNumber": "46",
      "addressCode": "31022508",
      "model": "",
      "address": "CAMINO SUERTE 46, CASCO H.VALLECAS, MADRID",
      "lat": 40.3753545,
      "lng": -3.6166121
  },
  {
      "id": "528483",
      "description": "SITUADO_APARCABICICLETAS",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONTES DE BARBANZA",
      "addressNumber": "25",
      "addressCode": "11131316",
      "model": "",
      "address": "CALLE MONTES DE BARBANZA 25, CASCO H.VALLECAS, MADRID",
      "lat": 40.37677,
      "lng": -3.6196
  },
  {
      "id": "528475",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA REVOLTOSA 19",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LA REVOLTOSA",
      "addressNumber": "28",
      "addressCode": "11130838",
      "model": "",
      "address": "CALLE LA REVOLTOSA 28, CASCO H.VALLECAS, MADRID",
      "lat": 40.3747158,
      "lng": -3.6217868
  },
  {
      "id": "528488",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA SIERRA DE PALOMERAS 12B",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SIERRA DE PALOMERAS",
      "addressNumber": "12B",
      "addressCode": "31051361",
      "model": "",
      "address": "CALLE SIERRA DE PALOMERAS 12B, CASCO H.VALLECAS, MADRID",
      "lat": 40.378053,
      "lng": -3.6235353
  },
  {
      "id": "528468",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA PE�A VEIGA 6",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PE�A VEIGA",
      "addressNumber": "6",
      "addressCode": "20165248",
      "model": "",
      "address": "CALLE PE�A VEIGA 6, CASCO H.VALLECAS, MADRID",
      "lat": 40.3772867,
      "lng": -3.6231138
  },
  {
      "id": "528481",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL MONTE AYA 12",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONTE AYA",
      "addressNumber": "12",
      "addressCode": "11130387",
      "model": "",
      "address": "CALLE MONTE AYA 12, CASCO H.VALLECAS, MADRID",
      "lat": 40.37788,
      "lng": -3.62267
  },
  {
      "id": "528473",
      "description": "SITUADO_APARCABICICLETAS - CEIP LICEO VERSALLES",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUERTO DE SOMOSIERRA",
      "addressNumber": "9",
      "addressCode": "11130417",
      "model": "",
      "address": "CALLE PUERTO DE SOMOSIERRA 9, CASCO H.VALLECAS, MADRID",
      "lat": 40.3785891,
      "lng": -3.6223151
  },
  {
      "id": "528494",
      "description": "SITUADO_APARCABICICLETAS - Oficina de Atenci�n a la Ciudadan�a. Villa de Vallecas",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "FEDERICO GARCIA LORCA",
      "addressNumber": "12",
      "addressCode": "11130433",
      "model": "",
      "address": "PASEO FEDERICO GARCIA LORCA 12, CASCO H.VALLECAS, MADRID",
      "lat": 40.37890609999999,
      "lng": -3.6214421
  },
  {
      "id": "528472",
      "description": "SITUADO_APARCABICICLETAS - CENTRO CULTURAL FRANCISCO FATOU",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MANUEL VELEZ",
      "addressNumber": "10",
      "addressCode": "11129720",
      "model": "",
      "address": "CALLE MANUEL VELEZ 10, CASCO H.VALLECAS, MADRID",
      "lat": 40.3795902,
      "lng": -3.6231087
  },
  {
      "id": "528471",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO SAGRADO CORAZON",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN JAIME",
      "addressNumber": "46",
      "addressCode": "11129675",
      "model": "",
      "address": "CALLE SAN JAIME 46, CASCO H.VALLECAS, MADRID",
      "lat": 40.3795393,
      "lng": -3.6269996
  },
  {
      "id": "528489",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA SIERRA GADOR 42",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SIERRA GADOR",
      "addressNumber": "42",
      "addressCode": "11129690",
      "model": "",
      "address": "CALLE SIERRA GADOR 42, CASCO H.VALLECAS, MADRID",
      "lat": 40.38057,
      "lng": -3.6241123
  },
  {
      "id": "528552",
      "description": "SITUADO_APARCABICICLETAS - Estaci�n cercan�as Vallecas",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JESUS DEL PINO",
      "addressNumber": "32",
      "addressCode": "20150872",
      "model": "",
      "address": "CALLE JESUS DEL PINO 32, CASCO H.VALLECAS, MADRID",
      "lat": 40.381899,
      "lng": -3.6242706
  },
  {
      "id": "528447",
      "description": "SITUADO_APARCABICICLETAS - Vallecas C.F.",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUERTO DE REINOSA",
      "addressNumber": "2",
      "addressCode": "31012604",
      "model": "",
      "address": "CALLE PUERTO DE REINOSA 2, CASCO H.VALLECAS, MADRID",
      "lat": 40.3783613,
      "lng": -3.6171172
  },
  {
      "id": "528482",
      "description": "SITUADO_APARCABICICLETAS - CENTRO JUVENIL EL SITIO DE MI RECREO",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "REAL DE ARGANDA",
      "addressNumber": "39",
      "addressCode": "20076127",
      "model": "",
      "address": "CALLE REAL DE ARGANDA 39, SANTA EUGENIA, MADRID",
      "lat": 40.379541,
      "lng": -3.6168944
  },
  {
      "id": "528462",
      "description": "SITUADO_APARCABICICLETAS - Escuela De M�sica Creativa S L",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "AGUSTIN GARCIA MALLA",
      "addressNumber": "4",
      "addressCode": "31029535",
      "model": "",
      "address": "CALLE AGUSTIN GARCIA MALLA 4, SANTA EUGENIA, MADRID",
      "lat": 40.3819951,
      "lng": -3.6162595
  },
  {
      "id": "528318",
      "description": "SITUADO_APARCABICICLETAS - Centro Cultural PILAR MIR� Villa de Vallecas",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ENRIQUE GARCIA ALVAREZ",
      "addressNumber": "6",
      "addressCode": "31047216",
      "model": "",
      "address": "CALLE ENRIQUE GARCIA ALVAREZ 6, SANTA EUGENIA, MADRID",
      "lat": 40.3830021,
      "lng": -3.6154402
  },
  {
      "id": "528370",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ZAZUAR",
      "addressNumber": "1A",
      "addressCode": "31010463",
      "model": "",
      "address": "CALLE ZAZUAR 1A, SANTA EUGENIA, MADRID",
      "lat": 40.3825,
      "lng": -3.604809999999999
  },
  {
      "id": "",
      "description": "CENTRO JUVENIL EL ALEPH",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528505",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CERRO DE ALMODOVAR",
      "addressNumber": "5",
      "addressCode": "31040957",
      "model": "",
      "address": "CALLE CERRO DE ALMODOVAR 5, SANTA EUGENIA, MADRID",
      "lat": 40.3846004,
      "lng": -3.6034988
  },
  {
      "id": "",
      "description": "C.E.I.P. Ciudad De Valencia",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528427",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DEL ENSANCHE DE VALLECAS 14F",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "183",
      "neighborhoodName": "ENSANCHE DE VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ENSANCHE DE VALLECAS",
      "addressNumber": "14F",
      "addressCode": "31050904",
      "model": "",
      "address": "AVENIDA ENSANCHE DE VALLECAS 14F, ENSANCHE DE VALLECAS, MADRID",
      "lat": 40.3700471,
      "lng": -3.613025
  },
  {
      "id": "528423",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE PUENTEDEY 5F",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "183",
      "neighborhoodName": "ENSANCHE DE VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUENTEDEY",
      "addressNumber": "5F",
      "addressCode": "31026424",
      "model": "",
      "address": "CALLE PUENTEDEY 5F, ENSANCHE DE VALLECAS, MADRID",
      "lat": 40.3688818,
      "lng": -3.6131785
  },
  {
      "id": "528292",
      "description": "SITUADOS_APARCABICILETAS-COLEGIO LA MILAGROSA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "115",
      "neighborhoodName": "PUERTA BONITA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PARROQUIA",
      "addressNumber": "14",
      "addressCode": "11076657",
      "model": "",
      "address": "PLAZA PARROQUIA 14, PUERTA BONITA, MADRID",
      "lat": 40.3797301,
      "lng": -3.7418539
  },
  {
      "id": "528293",
      "description": "SITUADOS_APARCABICICLETAS-",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "112",
      "neighborhoodName": "OPA�EL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "GANDARA DE ORO",
      "addressNumber": "3",
      "addressCode": "11070439",
      "model": "",
      "address": "PLAZA GANDARA DE ORO 3, OPA�EL, MADRID",
      "lat": 40.3885067,
      "lng": -3.729752
  },
  {
      "id": "",
      "description": "IES EMILIO CASELAR",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528295",
      "description": "SITUADOS_APARCABICICLETAS - UNI�N FEDERAL DE POLICIA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "115",
      "neighborhoodName": "PUERTA BONITA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "CARABANCHEL",
      "addressNumber": "4",
      "addressCode": "11076403",
      "model": "",
      "address": "PLAZA CARABANCHEL 4, PUERTA BONITA, MADRID",
      "lat": 40.3807844,
      "lng": -3.7408663
  },
  {
      "id": "528296",
      "description": "SITUADOS_APARCABICICLETAS-CALLE VIA LUSITANA 3A",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "117",
      "neighborhoodName": "ABRANTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VIA LUSITANA",
      "addressNumber": "3A",
      "addressCode": "31041453",
      "model": "",
      "address": "CALLE VIA LUSITANA 3A, ABRANTES, MADRID",
      "lat": 40.37815,
      "lng": -3.722919999999999
  },
  {
      "id": "528299",
      "description": "SITUADOS_APARCABICICLETAS-COLEGIO DIOCESANO MAR�A INMACULADA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOAQUIN TURINA",
      "addressNumber": "62",
      "addressCode": "11077769",
      "model": "",
      "address": "CALLE JOAQUIN TURINA 62, BUENAVISTA, MADRID",
      "lat": 40.3721933,
      "lng": -3.758240099999999
  },
  {
      "id": "528303",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DEL CIDRO 10",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CIDRO",
      "addressNumber": "10",
      "addressCode": "20045875",
      "model": "",
      "address": "CALLE CIDRO 10, BUENAVISTA, MADRID",
      "lat": 40.3672711,
      "lng": -3.7463337
  },
  {
      "id": "528323",
      "description": "SITUADOS_APARCABICICLETAS - METRO CARABANCHEL ALTO",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLEJON",
      "addressStreet": "ROSA",
      "addressNumber": "15",
      "addressCode": "20024210",
      "model": "",
      "address": "CALLEJON ROSA 15, BUENAVISTA, MADRID",
      "lat": 40.372061,
      "lng": -3.7516148
  },
  {
      "id": "528324",
      "description": "SITUADOS_APARCABICICLETAS - PLAZA DE LA EMPERATRIZ 5",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "EMPERATRIZ",
      "addressNumber": "5",
      "addressCode": "31046099",
      "model": "",
      "address": "PLAZA EMPERATRIZ 5, BUENAVISTA, MADRID",
      "lat": 40.3734686,
      "lng": -3.7510824
  },
  {
      "id": "528325",
      "description": "SITUADOS_APARCABICILETAS - CENTRO INTEGRADO FRANCISCO DE GOYA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALFREDO ALEIX",
      "addressNumber": "16",
      "addressCode": "11078232",
      "model": "",
      "address": "CALLE ALFREDO ALEIX 16, BUENAVISTA, MADRID",
      "lat": 40.3687471,
      "lng": -3.7543974
  },
  {
      "id": "528326",
      "description": "SITUADOS_APARCABICILETAS- COLEGIO SALESIANOS CARABANCHEL",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "RONDA",
      "addressStreet": "DON BOSCO",
      "addressNumber": "3",
      "addressCode": "11077546",
      "model": "",
      "address": "RONDA DON BOSCO 3, BUENAVISTA, MADRID",
      "lat": 40.374184,
      "lng": -3.7531306
  },
  {
      "id": "528327",
      "description": "SITUADOS_APARCABICICLETAS - AVENIDA DE LOS POBLADOS 122",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "POBLADOS",
      "addressNumber": "122",
      "addressCode": "31007172",
      "model": "",
      "address": "AVENIDA POBLADOS 122, BUENAVISTA, MADRID",
      "lat": 40.37346720000001,
      "lng": -3.7223247
  },
  {
      "id": "528328",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DEL JACOBEO 1",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JACOBEO",
      "addressNumber": "1",
      "addressCode": "31015103",
      "model": "",
      "address": "CALLE JACOBEO 1, BUENAVISTA, MADRID",
      "lat": 40.3723332,
      "lng": -3.762134
  },
  {
      "id": "528330",
      "description": "SITUADOS_APARCABICICLETAS - COLEGIO �RTICA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MORALES",
      "addressNumber": "25C",
      "addressCode": "31037105",
      "model": "",
      "address": "CALLE MORALES 25C, BUENAVISTA, MADRID",
      "lat": 40.360997,
      "lng": -3.7554916
  },
  {
      "id": "528378",
      "description": "SITUADOS_APARCABICICLETAS - METRO PLAZA EL�PTICA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "112",
      "neighborhoodName": "OPA�EL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "OPORTO",
      "addressNumber": "2",
      "addressCode": "11070104",
      "model": "",
      "address": "AVENIDA OPORTO 2, OPA�EL, MADRID",
      "lat": 40.3854257,
      "lng": -3.7183681
  },
  {
      "id": "528380",
      "description": "SITUADOS_APARCABICICLETAS - COMPLEJO DEPORTIVO MUNICIPAL DE LA MINA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "114",
      "neighborhoodName": "VISTA ALEGRE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONSE�OR OSCAR ROMERO",
      "addressNumber": "41",
      "addressCode": "11075051",
      "model": "",
      "address": "CALLE MONSE�OR OSCAR ROMERO 41, VISTA ALEGRE, MADRID",
      "lat": 40.3822411,
      "lng": -3.746321
  },
  {
      "id": "528383",
      "description": "SITUADOS_APARCABICICLETAS - C.D.PUERTA BONITA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "113",
      "neighborhoodName": "SAN ISIDRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VIA CARPETANA",
      "addressNumber": "178",
      "addressCode": "11059074",
      "model": "",
      "address": "CALLE VIA CARPETANA 178, SAN ISIDRO, MADRID",
      "lat": 40.3975302,
      "lng": -3.7363897
  },
  {
      "id": "528385",
      "description": "SITUADOS_APARCABICICLETAS - AVENIDA DE LOS POBLADOS 106",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "POBLADOS",
      "addressNumber": "106",
      "addressCode": "31007160",
      "model": "",
      "address": "AVENIDA POBLADOS 106, BUENAVISTA, MADRID",
      "lat": 40.3735726,
      "lng": -3.7351712
  },
  {
      "id": "528389",
      "description": "SITUADOS_APARCABICICLETAS - METRO PLAZA EL�PTICA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "117",
      "neighborhoodName": "ABRANTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "FERNANDEZ LADREDA",
      "addressNumber": "3",
      "addressCode": "31031809",
      "model": "",
      "address": "PLAZA FERNANDEZ LADREDA 3, ABRANTES, MADRID",
      "lat": 40.3778428,
      "lng": -3.7234144
  },
  {
      "id": "528391",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LA LONJA DE LA SEDA 41",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LONJA DE LA SEDA",
      "addressNumber": "41",
      "addressCode": "31005005",
      "model": "",
      "address": "CALLE LONJA DE LA SEDA 41, BUENAVISTA, MADRID",
      "lat": 40.3655868,
      "lng": -3.7627598
  },
  {
      "id": "528393",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE LOS MORALES 33",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MORALES",
      "addressNumber": "33",
      "addressCode": "31027097",
      "model": "",
      "address": "CALLE MORALES 33, BUENAVISTA, MADRID",
      "lat": 40.36070369999999,
      "lng": -3.7647103
  },
  {
      "id": "528394",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE ATAPUERCA 5E",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "116",
      "neighborhoodName": "BUENAVISTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ATAPUERCA",
      "addressNumber": "5E",
      "addressCode": "31010237",
      "model": "",
      "address": "CALLE ATAPUERCA 5E, BUENAVISTA, MADRID",
      "lat": 40.3604849,
      "lng": -3.7606513
  },
  {
      "id": "528397",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE NUESTRA SE�ORA DE LA LUZ 11",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "114",
      "neighborhoodName": "VISTA ALEGRE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NUESTRA SE�ORA DE LA LUZ",
      "addressNumber": "11",
      "addressCode": "20168102",
      "model": "",
      "address": "CALLE NUESTRA SE�ORA DE LA LUZ 11, VISTA ALEGRE, MADRID",
      "lat": 40.3828669,
      "lng": -3.7429272
  },
  {
      "id": "528398",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE CASTROJERIZ 18",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "114",
      "neighborhoodName": "VISTA ALEGRE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CASTROJERIZ",
      "addressNumber": "18",
      "addressCode": "11075309",
      "model": "",
      "address": "CALLE CASTROJERIZ 18, VISTA ALEGRE, MADRID",
      "lat": 40.3865811,
      "lng": -3.7400377
  },
  {
      "id": "528399",
      "description": "SITUADOS_APARCABICICLETAS - CEPA VISTA ALEGRE",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "115",
      "neighborhoodName": "PUERTA BONITA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL RICARDOS",
      "addressNumber": "179B",
      "addressCode": "31009511",
      "model": "",
      "address": "CALLE GENERAL RICARDOS 179B, PUERTA BONITA, MADRID",
      "lat": 40.3817021,
      "lng": -3.7338273
  },
  {
      "id": "528400",
      "description": "SITUADOS_APARCABICICLETAS-COLEGIO SANTA RITA",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "115",
      "neighborhoodName": "PUERTA BONITA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "EUGENIA DE MONTIJO",
      "addressNumber": "53",
      "addressCode": "11076656",
      "model": "",
      "address": "CALLE EUGENIA DE MONTIJO 53, PUERTA BONITA, MADRID",
      "lat": 40.3809778,
      "lng": -3.7441834
  },
  {
      "id": "3568866",
      "description": "SITUADOS_APARCABICICLETAS-AVENIDA DE OPORTO 100",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "112",
      "neighborhoodName": "OPA�EL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "VALLE DE ORO",
      "addressNumber": "6",
      "addressCode": "11070358",
      "model": "",
      "address": "GLORIETA VALLE DE ORO 6, OPA�EL, MADRID",
      "lat": 40.3874726,
      "lng": -3.7301996
  },
  {
      "id": "3568867",
      "description": "SITUADOS_APARCABICICLETAS-AVENIDA DE OPORTO 30",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "112",
      "neighborhoodName": "OPA�EL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "OPORTO",
      "addressNumber": "32",
      "addressCode": "11070159",
      "model": "",
      "address": "AVENIDA OPORTO 32, OPA�EL, MADRID",
      "lat": 40.3855288,
      "lng": -3.7232997
  },
  {
      "id": "3568890",
      "description": "SITUADOS_APARCABICICLETAS-CALLE DE BLASA PEREZ 1",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "113",
      "neighborhoodName": "SAN ISIDRO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL RICARDOS",
      "addressNumber": "126",
      "addressCode": "31028689",
      "model": "",
      "address": "CALLE GENERAL RICARDOS 126, SAN ISIDRO, MADRID",
      "lat": 40.3917299,
      "lng": -3.727194
  },
  {
      "id": "3568892",
      "description": "SITUADOS_APARCABICICLETAS-CALLE DE SANTA LUCRECIA 11",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "112",
      "neighborhoodName": "OPA�EL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA LUCRECIA",
      "addressNumber": "11",
      "addressCode": "31000482",
      "model": "",
      "address": "CALLE SANTA LUCRECIA 11, OPA�EL, MADRID",
      "lat": 40.3864853,
      "lng": -3.7188259
  },
  {
      "id": "528402",
      "description": "SITUADOS_APARCABICICLETAS - CALLE DE NUESTRA SE�ORA DE LA ANTIGUA 52C",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "115",
      "neighborhoodName": "PUERTA BONITA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NUESTRA SE�ORA DE LA ANTIGUA",
      "addressNumber": "52C",
      "addressCode": "31052352",
      "model": "",
      "address": "CALLE NUESTRA SE�ORA DE LA ANTIGUA 52C, PUERTA BONITA, MADRID",
      "lat": 40.3764296,
      "lng": -3.74082
  },
  {
      "id": "528575",
      "description": "SITUADO_APARCABICICLETAS - Estaci�n de metro Las Suertes",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "183",
      "neighborhoodName": "ENSANCHE DE VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CA�ADA DEL SANTISIMO",
      "addressNumber": "45",
      "addressCode": "31026236",
      "model": "",
      "address": "CALLE CA�ADA DEL SANTISIMO 45, ENSANCHE DE VALLECAS, MADRID",
      "lat": 40.3630265,
      "lng": -3.5998656
  },
  {
      "id": "528332",
      "description": "SITUADOS_APARCABICICLETAS - I.E.S. VISTA ALEGRE",
      "districtId": "11",
      "districtName": "CARABANCHEL",
      "neighborhoodId": "115",
      "neighborhoodName": "PUERTA BONITA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL RICARDOS",
      "addressNumber": "177",
      "addressCode": "20149525",
      "model": "",
      "address": "CALLE GENERAL RICARDOS 177, PUERTA BONITA, MADRID",
      "lat": 40.3829505,
      "lng": -3.7348214
  },
  {
      "id": "528485",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE PUENTELARRA 28",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUENTELARRA",
      "addressNumber": "25",
      "addressCode": "11131721",
      "model": "",
      "address": "CALLE PUENTELARRA 25, SANTA EUGENIA, MADRID",
      "lat": 40.3810608,
      "lng": -3.6113362
  },
  {
      "id": "528559",
      "description": "SITUADO_APARCABICICLETAS - PABELLON MUNICIPAL DE DEPORTES CERRO ALMODOVAR",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "Calle",
      "addressStreet": "Cerro Almod�var",
      "addressNumber": "9",
      "addressCode": "31044545",
      "model": "",
      "address": "Calle Cerro Almod�var 9, SANTA EUGENIA, MADRID",
      "lat": 40.3827433,
      "lng": -3.6024504
  },
  {
      "id": "528449",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL PADRE LLANOS 5",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PADRE LLANOS",
      "addressNumber": "5",
      "addressCode": "20173638",
      "model": "",
      "address": "CALLE PADRE LLANOS 5, ENTREVIAS, MADRID",
      "lat": 40.374207,
      "lng": -3.6559988
  },
  {
      "id": "528305",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL PUERTO DE BALBARAN 127",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUERTO DE BALBARAN",
      "addressNumber": "127",
      "addressCode": "20081324",
      "model": "",
      "address": "CALLE PUERTO DE BALBARAN 127, PALOMERAS BAJAS, MADRID",
      "lat": 40.378392,
      "lng": -3.660085999999999
  },
  {
      "id": "528368",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA ENTREVIAS 92",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "131",
      "neighborhoodName": "ENTREVIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ENTREVIAS",
      "addressNumber": "92",
      "addressCode": "11088012",
      "model": "",
      "address": "AVENIDA ENTREVIAS 92, ENTREVIAS, MADRID",
      "lat": 40.3781007,
      "lng": -3.6642906
  },
  {
      "id": "528320",
      "description": "SITUADO_APARCABICICLETAS - BIBLIOTECA PUBLICA MUNICIPAL DE VICALVARO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLARDONDIEGO",
      "addressNumber": "36",
      "addressCode": "20097401",
      "model": "",
      "address": "CALLE VILLARDONDIEGO 36, CASCO H.VICALVARO, MADRID",
      "lat": 40.4052922,
      "lng": -3.6086153
  },
  {
      "id": "528356",
      "description": "SITUADO_APARCABICICLETAS - CENTRO DEPORTIVO MUNICIPAL DE VALDEBERNARDO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "192",
      "neighborhoodName": "VALDEBERNARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LADERA DE LOS ALMENDROS",
      "addressNumber": "2",
      "addressCode": "31023316",
      "model": "",
      "address": "CALLE LADERA DE LOS ALMENDROS 2, VALDEBERNARDO, MADRID",
      "lat": 40.4044306,
      "lng": -3.6232051
  },
  {
      "id": "528484",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "REAL DE ARGANDA",
      "addressNumber": "39",
      "addressCode": "20076127",
      "model": "",
      "address": "CALLE REAL DE ARGANDA 39, SANTA EUGENIA, MADRID",
      "lat": 40.379541,
      "lng": -3.6168944
  },
  {
      "id": "",
      "description": "CENTRO JUVENIL EL SITIO DE MI RECREO",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528497",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DIEGO OLIVERA VICTORIO",
      "addressNumber": "2",
      "addressCode": "20128910",
      "model": "",
      "address": "CALLE DIEGO OLIVERA VICTORIO 2, SANTA EUGENIA, MADRID",
      "lat": 40.383208,
      "lng": -3.614802
  },
  {
      "id": "",
      "description": "INSTALACIONES DEPORTIVAS LA UNION",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528348",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DE SANTA EUGENIA 2",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SANTA EUGENIA",
      "addressNumber": "2",
      "addressCode": "11131756",
      "model": "",
      "address": "AVENIDA SANTA EUGENIA 2, SANTA EUGENIA, MADRID",
      "lat": 40.3864948,
      "lng": -3.6092034
  },
  {
      "id": "528558",
      "description": "SITUADO_APARCABICICLETAS - Pabell�n Municipal de Deportes Cerro Almodovar",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CERRO DE ALMODOVAR",
      "addressNumber": "9",
      "addressCode": "31044545",
      "model": "",
      "address": "CALLE CERRO DE ALMODOVAR 9, SANTA EUGENIA, MADRID",
      "lat": 40.3827433,
      "lng": -3.6024504
  },
  {
      "id": "528554",
      "description": "SITUADO_APARCABICICLETAS - Estaci�n de metro de Valdecarros",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "183",
      "neighborhoodName": "ENSANCHE DE VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ENSANCHE DE VALLECAS",
      "addressNumber": "120F",
      "addressCode": "31025766",
      "model": "",
      "address": "AVENIDA ENSANCHE DE VALLECAS 120F, ENSANCHE DE VALLECAS, MADRID",
      "lat": 40.36000689999999,
      "lng": -3.5934935
  },
  {
      "id": "528321",
      "description": "SITUADO_APARCABICICLETAS- ESTACION DE CERCANIAS DE VICALVARO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "193",
      "neighborhoodName": "VALDERRIVAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN CIPRIANO",
      "addressNumber": "85",
      "addressCode": "20160655",
      "model": "",
      "address": "CALLE SAN CIPRIANO 85, VALDERRIVAS, MADRID",
      "lat": 40.4014065,
      "lng": -3.5958036
  },
  {
      "id": "528486",
      "description": "SITUADO_APARCABICICLETAS- Estacion de Metro la Gavia",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "183",
      "neighborhoodName": "ENSANCHE DE VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUENTEDEY",
      "addressNumber": "5D",
      "addressCode": "31026422",
      "model": "",
      "address": "CALLE PUENTEDEY 5D, ENSANCHE DE VALLECAS, MADRID",
      "lat": 40.36895,
      "lng": -3.612569999999999
  },
  {
      "id": "528361",
      "description": "SITUADO_APARCABICICLETAS - Pabellon Nueva Castilla Voley",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PICO DE LA CIERVA",
      "addressNumber": "4",
      "addressCode": "31035703",
      "model": "",
      "address": "CALLE PICO DE LA CIERVA 4, CASCO H.VALLECAS, MADRID",
      "lat": 40.37719080000001,
      "lng": -3.6178622
  },
  {
      "id": "528419",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE PUENTEDEY 5F",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "183",
      "neighborhoodName": "ENSANCHE DE VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUENTEDEY",
      "addressNumber": "5G",
      "addressCode": "31026425",
      "model": "",
      "address": "CALLE PUENTEDEY 5G, ENSANCHE DE VALLECAS, MADRID",
      "lat": 40.3688213,
      "lng": -3.6135463
  },
  {
      "id": "528550",
      "description": "SITUADO_APARCABICICLETAS - AUTOVIA M-30 1000EX",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "136",
      "neighborhoodName": "NUMANCIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AUTOVIA",
      "addressStreet": "M-30",
      "addressNumber": "1000EX",
      "addressCode": "31031347",
      "model": "",
      "address": "AUTOVIA M-30 1000EX, NUMANCIA, MADRID",
      "lat": 40.4020865,
      "lng": -3.6672036
  },
  {
      "id": "124590",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�SALESIANOS�DE�ATOCHA�(SITUADO�LINEAL)",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "26",
      "neighborhoodName": "PALOS DE MOGUER",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "RONDA",
      "addressStreet": "ATOCHA",
      "addressNumber": "27",
      "addressCode": "11010396",
      "model": "",
      "address": "RONDA ATOCHA 27, PALOS DE MOGUER, MADRID",
      "lat": 40.4062884,
      "lng": -3.6974217
  },
  {
      "id": "162466",
      "description": "SITUADO APARCABICICLETAS - ENTRADA�PARQUE�BERLIN",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "53",
      "neighborhoodName": "CIUDAD JARDIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR MARCO CORERA",
      "addressNumber": "2",
      "addressCode": "31052956",
      "model": "",
      "address": "CALLE DOCTOR MARCO CORERA 2, CIUDAD JARDIN, MADRID",
      "lat": 40.4504395,
      "lng": -3.6768745
  },
  {
      "id": "233575",
      "description": "SITUADO APARCABICICLETAS - ESTACION�METRO/CERCANIAS�ALUCHE�(C/�DE VALMOJADO 295)",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALMOJADO",
      "addressNumber": "295",
      "addressCode": "31034501",
      "model": "",
      "address": "CALLE VALMOJADO 295, ALUCHE, MADRID",
      "lat": 40.3862191,
      "lng": -3.7597031
  },
  {
      "id": "457836",
      "description": "SITUADO APARCABICICLETAS - Av. GRAN VIA DE SAN FRANCISCO N�21",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GRAN VIA DE SAN FRANCISCO",
      "addressNumber": "21",
      "addressCode": "20004154",
      "model": "",
      "address": "AVENIDA GRAN VIA DE SAN FRANCISCO 21, PALACIO, MADRID",
      "lat": 40.41044309999999,
      "lng": -3.7141145
  },
  {
      "id": "3847544",
      "description": "SITUADO APARCABICICLETAS - AVENIDA CANILLEJAS A VICALVARO 67",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "202",
      "neighborhoodName": "HELLIN",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CANILLEJAS A VICALVARO",
      "addressNumber": "67",
      "addressCode": "20096985",
      "model": "",
      "address": "AVENIDA CANILLEJAS A VICALVARO 67, HELLIN, MADRID",
      "lat": 40.4341443,
      "lng": -3.6117901
  },
  {
      "id": "3847500",
      "description": "SITUADO APARCABICICLETAS - AVENIDA ARCENTALES 28",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ARCENTALES",
      "addressNumber": "28",
      "addressCode": "31024129",
      "model": "",
      "address": "AVENIDA ARCENTALES 28, ROSAS, MADRID",
      "lat": 40.4343763,
      "lng": -3.609739
  },
  {
      "id": "3847355",
      "description": "SITUADO APARCABICICLETAS - CALLE SAN VENANCIO 34",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN VENANCIO",
      "addressNumber": "34",
      "addressCode": "31006300",
      "model": "",
      "address": "CALLE SAN VENANCIO 34, CANILLEJAS, MADRID",
      "lat": 40.4470709,
      "lng": -3.6063218
  },
  {
      "id": "3848671",
      "description": "SITUADO APARCABICICLETAS - CALLE PUERTO DE SANTA MARIA 16",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "162",
      "neighborhoodName": "PIOVERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUERTO DE SANTA MARIA",
      "addressNumber": "16",
      "addressCode": "11114817",
      "model": "",
      "address": "CALLE PUERTO DE SANTA MARIA 16, PIOVERA, MADRID",
      "lat": 40.4554857,
      "lng": -3.6504967
  },
  {
      "id": "529261",
      "description": "SITUADO APARCABICICLETAS - AVENIDA ANDES 50",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "161",
      "neighborhoodName": "PALOMAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ANDES",
      "addressNumber": "50",
      "addressCode": "20107470",
      "model": "",
      "address": "AVENIDA ANDES 50, PALOMAS, MADRID",
      "lat": 40.4564336,
      "lng": -3.6178227
  },
  {
      "id": "4741045",
      "description": "SITUADO APARCABICICLETAS - EN�LA�ENTRADA�DEL�AYUNTAMIENTO�DE�MADRID",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONTALBAN",
      "addressNumber": "3",
      "addressCode": "11012789",
      "model": "",
      "address": "CALLE MONTALBAN 3, LOS JERONIMOS, MADRID",
      "lat": 40.4182794,
      "lng": -3.6910379
  },
  {
      "id": "457516",
      "description": "SITUADO APARCABICICLETAS - Pz. ANGEL CARBAJO N�8",
      "districtId": "6",
      "districtName": "TETUAN",
      "neighborhoodId": "63",
      "neighborhoodName": "CASTILLEJOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "ANGEL CARBAJO",
      "addressNumber": "8",
      "addressCode": "20011572",
      "model": "",
      "address": "PLAZA ANGEL CARBAJO 8, CASTILLEJOS, MADRID",
      "lat": 40.4617011,
      "lng": -3.6946625
  },
  {
      "id": "528576",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE GUILLERMO PINGARRON 2",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "135",
      "neighborhoodName": "PORTAZGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GUILLERMO PINGARRON",
      "addressNumber": "2",
      "addressCode": "11097873",
      "model": "",
      "address": "CALLE GUILLERMO PINGARRON 2, PORTAZGO, MADRID",
      "lat": 40.3876718,
      "lng": -3.648767399999999
  },
  {
      "id": "528504",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE BENJAMIN PALENCIA 2",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "136",
      "neighborhoodName": "NUMANCIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BENJAMIN PALENCIA",
      "addressNumber": "2",
      "addressCode": "20002299",
      "model": "",
      "address": "CALLE BENJAMIN PALENCIA 2, NUMANCIA, MADRID",
      "lat": 40.3962904,
      "lng": -3.6550569
  },
  {
      "id": "457818",
      "description": "SITUADO APARCABICICLETAS - Pz. ISABEL II N�8",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "ISABEL II",
      "addressNumber": "8",
      "addressCode": "20003836",
      "model": "",
      "address": "PLAZA ISABEL II 8, PALACIO, MADRID",
      "lat": 40.4179811,
      "lng": -3.7089656
  },
  {
      "id": "528298",
      "description": "SITUADO_APARCABICICLETAS - CALLE DEL PUERTO DE CANENCIA 9",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "136",
      "neighborhoodName": "NUMANCIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PUERTO DE CANENCIA",
      "addressNumber": "9",
      "addressCode": "11099384",
      "model": "",
      "address": "CALLE PUERTO DE CANENCIA 9, NUMANCIA, MADRID",
      "lat": 40.4004981,
      "lng": -3.6583951
  },
  {
      "id": "528270",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE CARLOS SOLE 13,",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "135",
      "neighborhoodName": "PORTAZGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CARLOS SOLE",
      "addressNumber": "13",
      "addressCode": "31044663",
      "model": "",
      "address": "CALLE CARLOS SOLE 13, PORTAZGO, MADRID",
      "lat": 40.3919827,
      "lng": -3.6487103
  },
  {
      "id": "528311",
      "description": "SITUADO_APARCABICICLETAS - CENTRO DEPORTIVO MUNICIPAL PUENTE DE VALLECAS",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PAYASO FOFO",
      "addressNumber": "20",
      "addressCode": "20081901",
      "model": "",
      "address": "CALLE PAYASO FOFO 20, PALOMERAS BAJAS, MADRID",
      "lat": 40.3915089,
      "lng": -3.6602989
  },
  {
      "id": "528268",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA SIERRA MOLINA 1",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "132",
      "neighborhoodName": "SAN DIEGO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SIERRA MOLINA",
      "addressNumber": "1",
      "addressCode": "31041718",
      "model": "",
      "address": "CALLE SIERRA MOLINA 1, SAN DIEGO, MADRID",
      "lat": 40.3887101,
      "lng": -3.6667845
  },
  {
      "id": "528265",
      "description": "SITUADO_APARCABICICLETAS - CENTRO DE SALUD FEDERICA MONTSENY",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "ALBUFERA",
      "addressNumber": "287",
      "addressCode": "20083674",
      "model": "",
      "address": "AVENIDA ALBUFERA 287, PALOMERAS SURESTE, MADRID",
      "lat": 40.3870006,
      "lng": -3.6388101
  },
  {
      "id": "3568902",
      "description": "SITUADO_ APARCABICICLETAS- PLAZA DE LA PE�A GUDINA 1",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "133",
      "neighborhoodName": "PALOMERAS BAJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PE�A GUDINA",
      "addressNumber": "1",
      "addressCode": "31045145",
      "model": "",
      "address": "PLAZA PE�A GUDINA 1, PALOMERAS BAJAS, MADRID",
      "lat": 40.385898,
      "lng": -3.6636406
  },
  {
      "id": "528495",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "DON ANTONIO DE ANDRES",
      "addressNumber": "17",
      "addressCode": "11132388",
      "model": "",
      "address": "PLAZA DON ANTONIO DE ANDRES 17, CASCO H.VICALVARO, MADRID",
      "lat": 40.4022853,
      "lng": -3.606
  },
  {
      "id": "",
      "description": "Oficina de Atenci�n a la Ciudadan�a. L�nea Madrid. Distrito de Vic�lvaro",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528502",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE MINERVA 161",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "193",
      "neighborhoodName": "VALDERRIVAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MINERVA",
      "addressNumber": "161",
      "addressCode": "20180448",
      "model": "",
      "address": "CALLE MINERVA 161, VALDERRIVAS, MADRID",
      "lat": 40.39664,
      "lng": -3.60632
  },
  {
      "id": "457631",
      "description": "SITUADO APARCABICICLETAS - C/ FERNANDO EL CATOLICO N�77",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "71",
      "neighborhoodName": "GAZTAMBIDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FERNANDO EL CATOLICO",
      "addressNumber": "77",
      "addressCode": "11038665",
      "model": "",
      "address": "CALLE FERNANDO EL CATOLICO 77, GAZTAMBIDE, MADRID",
      "lat": 40.4342402,
      "lng": -3.7174938
  },
  {
      "id": "457639",
      "description": "SITUADO APARCABICICLETAS - C/ VALLEHERMOSO N�15",
      "districtId": "7",
      "districtName": "CHAMBERI",
      "neighborhoodId": "72",
      "neighborhoodName": "ARAPILES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALLEHERMOSO",
      "addressNumber": "15",
      "addressCode": "11039765",
      "model": "",
      "address": "CALLE VALLEHERMOSO 15, ARAPILES, MADRID",
      "lat": 40.4314892,
      "lng": -3.7092035
  },
  {
      "id": "528620",
      "description": "SITUADO_APARCABICICLETAS - Colegio Santo Angel",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "145",
      "neighborhoodName": "FONTARRON",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARROYO FONTARRON",
      "addressNumber": "201",
      "addressCode": "11102598",
      "model": "",
      "address": "CALLE ARROYO FONTARRON 201, FONTARRON, MADRID",
      "lat": 40.3982363,
      "lng": -3.644071
  },
  {
      "id": "528625",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA ENCOMIENDA DE PALACIOS 239",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ENCOMIENDA DE PALACIOS",
      "addressNumber": "239",
      "addressCode": "31043389",
      "model": "",
      "address": "CALLE ENCOMIENDA DE PALACIOS 239, PAVONES, MADRID",
      "lat": 40.3962432,
      "lng": -3.6326726
  },
  {
      "id": "528616",
      "description": "SITUADO_APARCABICICLETAS - Centro Sociocultural Moratalaz",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTE CARRANTONA",
      "addressNumber": "10C",
      "addressCode": "20098143",
      "model": "",
      "address": "CALLE FUENTE CARRANTONA 10C, PAVONES, MADRID",
      "lat": 40.39921,
      "lng": -3.6366434
  },
  {
      "id": "528605",
      "description": "SITUADO_APARCABICICLETAS - Colegio P�blico Manuel S�inz de Vicu�a",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "143",
      "neighborhoodName": "MARROQUINA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAMINO DE LOS VINATEROS",
      "addressNumber": "104",
      "addressCode": "11101655",
      "model": "",
      "address": "CALLE CAMINO DE LOS VINATEROS 104, MARROQUINA, MADRID",
      "lat": 40.4094444,
      "lng": -3.6499737
  },
  {
      "id": "528606",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA HACIENDA DE PAVONES 107",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "146",
      "neighborhoodName": "VINATEROS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HACIENDA DE PAVONES",
      "addressNumber": "105",
      "addressCode": "11103041",
      "model": "",
      "address": "CALLE HACIENDA DE PAVONES 105, VINATEROS, MADRID",
      "lat": 40.4038917,
      "lng": -3.6435724
  },
  {
      "id": "528611",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA FUENTE CARRANTONA 61",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "142",
      "neighborhoodName": "HORCAJO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTE CARRANTONA",
      "addressNumber": "61",
      "addressCode": "31041857",
      "model": "",
      "address": "CALLE FUENTE CARRANTONA 61, HORCAJO, MADRID",
      "lat": 40.4103322,
      "lng": -3.6301015
  },
  {
      "id": "528600",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE MOLINA DE SEGURA 6",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "143",
      "neighborhoodName": "MARROQUINA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MOLINA DE SEGURA",
      "addressNumber": "6",
      "addressCode": "20158922",
      "model": "",
      "address": "CALLE MOLINA DE SEGURA 6, MARROQUINA, MADRID",
      "lat": 40.4068581,
      "lng": -3.638385999999999
  },
  {
      "id": "528601",
      "description": "SITUADO_APARCABICICLETAS - AVENIDA DEL DOCTOR GARCIA TAPIA 47",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "143",
      "neighborhoodName": "MARROQUINA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "DOCTOR GARCIA TAPIA",
      "addressNumber": "114",
      "addressCode": "11101516",
      "model": "",
      "address": "AVENIDA DOCTOR GARCIA TAPIA 114, MARROQUINA, MADRID",
      "lat": 40.4121331,
      "lng": -3.6486866
  },
  {
      "id": "528587",
      "description": "SITUADO_APARCABICICLETAS -",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "144",
      "neighborhoodName": "MEDIA LEGUA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LITUANIA",
      "addressNumber": "7",
      "addressCode": "20107374",
      "model": "",
      "address": "CALLE LITUANIA 7, MEDIA LEGUA, MADRID",
      "lat": 40.409565,
      "lng": -3.6616145
  },
  {
      "id": "",
      "description": "Colegio siglo XXI",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "528589",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE VALDEBERNARDO 2",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALDEBERNARDO",
      "addressNumber": "2",
      "addressCode": "11103385",
      "model": "",
      "address": "CALLE VALDEBERNARDO 2, PAVONES, MADRID",
      "lat": 40.3984386,
      "lng": -3.6323654
  },
  {
      "id": "528274",
      "description": "SITUADO_APARCABICICLETAS - ESCUELA OFICIAL DE IDIOMAS MORATALAZ",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALDEBERNARDO",
      "addressNumber": "3",
      "addressCode": "11101288",
      "model": "",
      "address": "CALLE VALDEBERNARDO 3, PAVONES, MADRID",
      "lat": 40.3985867,
      "lng": -3.6332986
  },
  {
      "id": "528491",
      "description": "SITUADO_APARCABICICLETAS - COLEGIO PUBLICO PIO BAROJA",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "146",
      "neighborhoodName": "VINATEROS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LUIS DE HOYOS SAINZ",
      "addressNumber": "86",
      "addressCode": "11102882",
      "model": "",
      "address": "CALLE LUIS DE HOYOS SAINZ 86, VINATEROS, MADRID",
      "lat": 40.40360099999999,
      "lng": -3.6363667
  },
  {
      "id": "528466",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE DIEGO OLIVERA VICTORIO 2",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DIEGO OLIVERA VICTORIO",
      "addressNumber": "2",
      "addressCode": "20128910",
      "model": "",
      "address": "CALLE DIEGO OLIVERA VICTORIO 2, SANTA EUGENIA, MADRID",
      "lat": 40.383208,
      "lng": -3.614802
  },
  {
      "id": "528501",
      "description": "SITUADO_APARCABICICLETAS - Centro Cultural Zazuar",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ZAZUAR",
      "addressNumber": "4A",
      "addressCode": "31011654",
      "model": "",
      "address": "CALLE ZAZUAR 4A, SANTA EUGENIA, MADRID",
      "lat": 40.3829416,
      "lng": -3.6057042
  },
  {
      "id": "528507",
      "description": "SITUADO_APARCABICICLETAS - Colegio P�blico Ciudad de Valencia",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "182",
      "neighborhoodName": "SANTA EUGENIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CERRO DE ALMODOVAR",
      "addressNumber": "3",
      "addressCode": "31040956",
      "model": "",
      "address": "CALLE CERRO DE ALMODOVAR 3, SANTA EUGENIA, MADRID",
      "lat": 40.3856252,
      "lng": -3.604294
  },
  {
      "id": "4742736",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 61",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "61",
      "addressCode": "31039618",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 61, VALDEFUENTES, MADRID",
      "lat": 40.4956259,
      "lng": -3.6183017
  },
  {
      "id": "4742779",
      "description": "SITUADO APARCABICICLETAS - AVENIDA SECUNDINO ZUAZO 18",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "SECUNDINO ZUAZO",
      "addressNumber": "18",
      "addressCode": "31046533",
      "model": "",
      "address": "AVENIDA SECUNDINO ZUAZO 18, VALDEFUENTES, MADRID",
      "lat": 40.4952643,
      "lng": -3.6224322
  },
  {
      "id": "457877",
      "description": "SITUADO APARCABICICLETAS - RONDA DE VALENCIA N�2",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "12",
      "neighborhoodName": "EMBAJADORES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "RONDA",
      "addressStreet": "VALENCIA",
      "addressNumber": "2",
      "addressCode": "20004606",
      "model": "",
      "address": "RONDA VALENCIA 2, EMBAJADORES, MADRID",
      "lat": 40.4060622,
      "lng": -3.6999668
  },
  {
      "id": "3569263",
      "description": "SITUADO APARCABICICLETAS - PARQUE�INFANTIL�NORE�A�GOIZUETA",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TENIENTE CORONEL NORE�A",
      "addressNumber": "2",
      "addressCode": "11009226",
      "model": "",
      "address": "CALLE TENIENTE CORONEL NORE�A 2, LEGAZPI, MADRID",
      "lat": 40.3894089,
      "lng": -3.6946659
  },
  {
      "id": "3554534",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�MURILLO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RUIZ DE ALARCON",
      "addressNumber": "27",
      "addressCode": "11012952",
      "model": "",
      "address": "CALLE RUIZ DE ALARCON 27, LOS JERONIMOS, MADRID",
      "lat": 40.4130594,
      "lng": -3.6913271
  },
  {
      "id": "3543373",
      "description": "SITUADO APARCABICICLETAS - PANTEON�DE�HOMBRES�ILUSTRES",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "REINA CRISTINA",
      "addressNumber": "4",
      "addressCode": "20107314",
      "model": "",
      "address": "PASEO REINA CRISTINA 4, PACIFICO, MADRID",
      "lat": 40.4064461,
      "lng": -3.6841593
  },
  {
      "id": "138086",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DE�NEGOCIOS�VELAZQUEZ",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VELAZQUEZ",
      "addressNumber": "56",
      "addressCode": "11014344",
      "model": "",
      "address": "CALLE VELAZQUEZ 56, RECOLETOS, MADRID",
      "lat": 40.4276129,
      "lng": -3.6836779
  },
  {
      "id": "3538012",
      "description": "SITUADO APARCABICICLETAS - CAMPUS�REPSOL",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MENDEZ ALVARO",
      "addressNumber": "55",
      "addressCode": "11010555",
      "model": "",
      "address": "CALLE MENDEZ ALVARO 55, DELICIAS, MADRID",
      "lat": 40.3984178,
      "lng": -3.6830679
  },
  {
      "id": "3562553",
      "description": "SITUADO APARCABICICLETAS - CALLE�NU�EZ�DE�BALBOA�16",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "NU�EZ DE BALBOA",
      "addressNumber": "16",
      "addressCode": "11014560",
      "model": "",
      "address": "CALLE NU�EZ DE BALBOA 16, RECOLETOS, MADRID",
      "lat": 40.4234089,
      "lng": -3.6827467
  },
  {
      "id": "162739",
      "description": "SITUADO APARCABICICLETAS - C/V�CALLE�ARIEL",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MENESES",
      "addressNumber": "4",
      "addressCode": "31022245",
      "model": "",
      "address": "CALLE MENESES 4, LEGAZPI, MADRID",
      "lat": 40.39003650000001,
      "lng": -3.6838434
  },
  {
      "id": "3569265",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�PELAYO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "31",
      "neighborhoodName": "PACIFICO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CIUDAD DE BARCELONA",
      "addressNumber": "25",
      "addressCode": "11010748",
      "model": "",
      "address": "AVENIDA CIUDAD DE BARCELONA 25, PACIFICO, MADRID",
      "lat": 40.4042755,
      "lng": -3.6805123
  },
  {
      "id": "3568894",
      "description": "SITUADOS_APARCABICICLETAS-",
      "districtId": "17",
      "districtName": "VILLAVERDE",
      "neighborhoodId": "174",
      "neighborhoodName": "LOS ROSALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARIA MARTINEZ OVIOL",
      "addressNumber": "12",
      "addressCode": "20182859",
      "model": "",
      "address": "CALLE MARIA MARTINEZ OVIOL 12, LOS ROSALES, MADRID",
      "lat": 40.3508942,
      "lng": -3.6915966
  },
  {
      "id": "",
      "description": "CENTRO CULTURAL SANTA PETRONILA",
      "districtId": "",
      "districtName": "",
      "neighborhoodId": "",
      "neighborhoodName": "",
      "state": "",
      "installDate": "",
      "addressType": "",
      "addressStreet": "",
      "addressNumber": "",
      "addressCode": "",
      "model": "",
      "address": "  , , MADRID",
      "lat": 40.4167754,
      "lng": -3.7037902
  },
  {
      "id": "4745105",
      "description": "SITUADOS_APARCABICICLETAS - METRO SAN FERM�N - ORCASUR",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "122",
      "neighborhoodName": "ORCASUR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PERLA",
      "addressNumber": "35",
      "addressCode": "20129665",
      "model": "",
      "address": "AVENIDA PERLA 35, ORCASUR, MADRID",
      "lat": 40.3699543,
      "lng": -3.6937975
  },
  {
      "id": "4745113",
      "description": "SITUADO APARCABICICLETAS - C/ FUENCARRAL N�46",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "15",
      "neighborhoodName": "UNIVERSIDAD",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENCARRAL",
      "addressNumber": "46",
      "addressCode": "11004423",
      "model": "",
      "address": "CALLE FUENCARRAL 46, UNIVERSIDAD, MADRID",
      "lat": 40.4231819,
      "lng": -3.7004821
  },
  {
      "id": "3544532",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�REINA�VICTORIA",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "46",
      "neighborhoodName": "CASTELLANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PRINCIPE DE VERGARA",
      "addressNumber": "61",
      "addressCode": "11019915",
      "model": "",
      "address": "CALLE PRINCIPE DE VERGARA 61, CASTELLANA, MADRID",
      "lat": 40.4336069,
      "lng": -3.679663399999999
  },
  {
      "id": "3569264",
      "description": "SITUADO APARCABICICLETAS - BANCO�ESPA�A-DUERO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "24",
      "neighborhoodName": "LEGAZPI",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TITAN",
      "addressNumber": "8",
      "addressCode": "31022353",
      "model": "",
      "address": "CALLE TITAN 8, LEGAZPI, MADRID",
      "lat": 40.3919388,
      "lng": -3.678883299999999
  },
  {
      "id": "3565249",
      "description": "SITUADO APARCABICICLETAS - CALLE�O�DONNEL�36",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "34",
      "neighborhoodName": "IBIZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "O'DONNELL",
      "addressNumber": "36",
      "addressCode": "11012609",
      "model": "",
      "address": "CALLE O'DONNELL 36, IBIZA, MADRID",
      "lat": 40.421223,
      "lng": -3.6742198
  },
  {
      "id": "3551763",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�ESCUELAS�AGUIRRE",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "36",
      "neighborhoodName": "NI�O JESUS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PIO BAROJA",
      "addressNumber": "4",
      "addressCode": "11013570",
      "model": "",
      "address": "CALLE PIO BAROJA 4, NI�O JESUS, MADRID",
      "lat": 40.414589,
      "lng": -3.674669
  },
  {
      "id": "128679",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�SAINZ�DE�BARANDA",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR ESQUERDO",
      "addressNumber": "107",
      "addressCode": "11012040",
      "model": "",
      "address": "CALLE DOCTOR ESQUERDO 107, ESTRELLA, MADRID",
      "lat": 40.4147353,
      "lng": -3.669281599999999
  },
  {
      "id": "201810",
      "description": "SITUADO APARCABICICLETAS - ESTACION�CERCANIAS�EL�BARRIAL",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "96",
      "neighborhoodName": "EL PLANTIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RAFAEL BOTI",
      "addressNumber": "64",
      "addressCode": "31023498",
      "model": "",
      "address": "CALLE RAFAEL BOTI 64, EL PLANTIO, MADRID",
      "lat": 40.4650977,
      "lng": -3.808038299999999
  },
  {
      "id": "230124",
      "description": "SITUADO APARCABICICLETAS - METRO�CUATRO�VIENTOS",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "105",
      "neighborhoodName": "CAMPAMENTO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "HUSARES",
      "addressNumber": "48",
      "addressCode": "31040048",
      "model": "",
      "address": "PASEO HUSARES 48, CAMPAMENTO, MADRID",
      "lat": 40.3786992,
      "lng": -3.792400799999999
  },
  {
      "id": "3538354",
      "description": "SITUADO APARCABICICLETAS - MC�DONALDS",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONTECARMELO",
      "addressNumber": "1",
      "addressCode": "31044754",
      "model": "",
      "address": "AVENIDA MONTECARMELO 1, MIRASIERRA, MADRID",
      "lat": 40.50146,
      "lng": -3.6983
  },
  {
      "id": "3535270",
      "description": "SITUADO APARCABICICLETAS - METRO�TRES�OLIVOS",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CAMPO DE CALATRAVA",
      "addressNumber": "1",
      "addressCode": "20058653",
      "model": "",
      "address": "AVENIDA CAMPO DE CALATRAVA 1, VALVERDE, MADRID",
      "lat": 40.5004453,
      "lng": -3.6936298
  },
  {
      "id": "3542043",
      "description": "SITUADO APARCABICICLETAS - AZULEJOS�PE�A",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "LLANO CASTELLANO",
      "addressNumber": "15",
      "addressCode": "20016134",
      "model": "",
      "address": "AVENIDA LLANO CASTELLANO 15, VALVERDE, MADRID",
      "lat": 40.4865275,
      "lng": -3.6846999
  },
  {
      "id": "3536003",
      "description": "SITUADO APARCABICICLETAS - C.E.I.P.�INFANTA�LEONOR",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONASTERIO DE EL PAULAR",
      "addressNumber": "140",
      "addressCode": "31025800",
      "model": "",
      "address": "CALLE MONASTERIO DE EL PAULAR 140, MIRASIERRA, MADRID",
      "lat": 40.5013,
      "lng": -3.7000065
  },
  {
      "id": "3562711",
      "description": "SITUADO APARCABICICLETAS - CALLE�DE�PADILLA�1",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "46",
      "neighborhoodName": "CASTELLANA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PADILLA",
      "addressNumber": "1",
      "addressCode": "11019862",
      "model": "",
      "address": "CALLE PADILLA 1, CASTELLANA, MADRID",
      "lat": 40.431784,
      "lng": -3.686473599999999
  },
  {
      "id": "3564397",
      "description": "SITUADO APARCABICICLETAS - C/BRUJULA�(ANILLO CICLISTA)�PARQUE ARROYO POZUELO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ESCULTOR PERESEJO",
      "addressNumber": "66",
      "addressCode": "20063938",
      "model": "",
      "address": "CALLE ESCULTOR PERESEJO 66, ARAVACA, MADRID",
      "lat": 40.4537705,
      "lng": -3.7894613
  },
  {
      "id": "120312",
      "description": "SITUADO APARCABICICLETAS - ESPACIO DE IGUALDAD RETIRO (ELENA ARNEDO SORIANO)",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "32",
      "neighborhoodName": "ADELFAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALDERRIBAS",
      "addressNumber": "99",
      "addressCode": "20045674",
      "model": "",
      "address": "CALLE VALDERRIBAS 99, ADELFAS, MADRID",
      "lat": 40.401717,
      "lng": -3.668311
  },
  {
      "id": "3563602",
      "description": "SITUADO APARCABICICLETAS - PARROQUIA�DE�SANTA�CRISTINA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "102",
      "neighborhoodName": "PUERTA DEL ANGEL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "PUERTA DEL ANGEL",
      "addressNumber": "4",
      "addressCode": "11068830",
      "model": "",
      "address": "PLAZA PUERTA DEL ANGEL 4, PUERTA DEL ANGEL, MADRID",
      "lat": 40.41390519999999,
      "lng": -3.728197599999999
  },
  {
      "id": "126081",
      "description": "SITUADO APARCABICICLETAS - AL�LADO�DE�CARRIL�BICI",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JEMENU�O",
      "addressNumber": "2:00 AM",
      "addressCode": "20105044",
      "model": "",
      "address": "CALLE JEMENU�O 2:00 AM, IMPERIAL, MADRID",
      "lat": 40.4068452,
      "lng": -3.7200879
  },
  {
      "id": "170579",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�MIGUEL�DE�UNAMUNO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALICANTE",
      "addressNumber": "7",
      "addressCode": "11009480",
      "model": "",
      "address": "CALLE ALICANTE 7, DELICIAS, MADRID",
      "lat": 40.3942716,
      "lng": -3.6931146
  },
  {
      "id": "162728",
      "description": "SITUADO APARCABICICLETAS - CENTRO�COMERCIAL�MENDEZ�ALVARO",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "27",
      "neighborhoodName": "ATOCHA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RETAMA",
      "addressNumber": "4",
      "addressCode": "20111962",
      "model": "",
      "address": "CALLE RETAMA 4, ATOCHA, MADRID",
      "lat": 40.3952522,
      "lng": -3.6748218
  },
  {
      "id": "3531472",
      "description": "SITUADO APARCABICICLETAS - CALLE�DE�MARTINEZ�IZQUIERDO�43",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARTINEZ IZQUIERDO",
      "addressNumber": "43",
      "addressCode": "11018436",
      "model": "",
      "address": "CALLE MARTINEZ IZQUIERDO 43, GUINDALERA, MADRID",
      "lat": 40.4346427,
      "lng": -3.667391
  },
  {
      "id": "173555",
      "description": "SITUADO APARCABICICLETAS - ARCHIVO�REGIONAL�DE�LA�COMUNIDAD�DE�MADRID",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL LACY",
      "addressNumber": "25",
      "addressCode": "11009552",
      "model": "",
      "address": "CALLE GENERAL LACY 25, DELICIAS, MADRID",
      "lat": 40.40021,
      "lng": -3.690808099999999
  },
  {
      "id": "3564399",
      "description": "SITUADO APARCABICICLETAS - PARQUE�ARROYO�POZUELO�(C/ ESCULTOR PERESEJO 62)",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ESCULTOR PERESEJO",
      "addressNumber": "62",
      "addressCode": "20138022",
      "model": "",
      "address": "CALLE ESCULTOR PERESEJO 62, ARAVACA, MADRID",
      "lat": 40.4539593,
      "lng": -3.7886606
  },
  {
      "id": "3566709",
      "description": "SITUADO APARCABICICLETAS - ESTADIO�SANTIAGO�BERNABEU",
      "districtId": "5",
      "districtName": "CHAMARTIN",
      "neighborhoodId": "54",
      "neighborhoodName": "HISPANOAMERICA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CONCHA ESPINA",
      "addressNumber": "2",
      "addressCode": "11020909",
      "model": "",
      "address": "AVENIDA CONCHA ESPINA 2, HISPANOAMERICA, MADRID",
      "lat": 40.4518928,
      "lng": -3.6897452
  },
  {
      "id": "190946",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�CERCANIAS�ARAVACA�(�C/�DE LA GOLONDRINA 104)",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GOLONDRINA",
      "addressNumber": "104",
      "addressCode": "31044466",
      "model": "",
      "address": "CALLE GOLONDRINA 104, ARAVACA, MADRID",
      "lat": 40.4493045,
      "lng": -3.785254
  },
  {
      "id": "3564085",
      "description": "SITUADO APARCABICICLETAS - AVENIDA�DE�LA�GALAXIA�2",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GALAXIA",
      "addressNumber": "2",
      "addressCode": "11056924",
      "model": "",
      "address": "AVENIDA GALAXIA 2, ARAVACA, MADRID",
      "lat": 40.4590154,
      "lng": -3.7855999
  },
  {
      "id": "232105",
      "description": "SITUADO APARCABICICLETAS - CENTRO�JACINTO�VERDAGUER",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTE DE LIMA",
      "addressNumber": "22",
      "addressCode": "11067134",
      "model": "",
      "address": "CALLE FUENTE DE LIMA 22, LAS AGUILAS, MADRID",
      "lat": 40.382532,
      "lng": -3.782548
  },
  {
      "id": "191467",
      "description": "Situado Aparcabicicletas ESTACI�N DE CERCAN�AS ARAVACA ( C/ de la golondrina cv C/Rosas de Aravaca)",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GOLONDRINA",
      "addressNumber": "104",
      "addressCode": "31044466",
      "model": "",
      "address": "CALLE GOLONDRINA 104, ARAVACA, MADRID",
      "lat": 40.4493045,
      "lng": -3.785254
  },
  {
      "id": "3565138",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�ALFREDO�GOYENECHE",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HUMERA",
      "addressNumber": "40",
      "addressCode": "31011019",
      "model": "",
      "address": "CALLE HUMERA 40, ARAVACA, MADRID",
      "lat": 40.452133,
      "lng": -3.7824678
  },
  {
      "id": "232207",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CULTURAL�ALMIRANTE�CHURRUCA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTE DEL TIRO",
      "addressNumber": "72",
      "addressCode": "20134686",
      "model": "",
      "address": "CALLE FUENTE DEL TIRO 72, LAS AGUILAS, MADRID",
      "lat": 40.3829085,
      "lng": -3.7802991
  },
  {
      "id": "227269",
      "description": "SITUADO APARCABICICLETAS - CENTRO�SOCIOCULTURAL�ALFONSO�XII",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "81",
      "neighborhoodName": "EL PARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MIRA EL RIO",
      "addressNumber": "4",
      "addressCode": "31006789",
      "model": "",
      "address": "CALLE MIRA EL RIO 4, EL PARDO, MADRID",
      "lat": 40.519746,
      "lng": -3.7778377
  },
  {
      "id": "230112",
      "description": "SITUADO APARCABICICLETAS - INSTITUCION�LA�SALLE",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "BLAS CABRERA",
      "addressNumber": "50",
      "addressCode": "11067883",
      "model": "",
      "address": "CALLE BLAS CABRERA 50, LAS AGUILAS, MADRID",
      "lat": 40.3783684,
      "lng": -3.773793
  },
  {
      "id": "3564575",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�BASICA�PARQUE�ARROYO�POZUELO�I",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARROYO DE POZUELO",
      "addressNumber": "10",
      "addressCode": "31045365",
      "model": "",
      "address": "CALLE ARROYO DE POZUELO 10, ARAVACA, MADRID",
      "lat": 40.4513633,
      "lng": -3.7693722
  },
  {
      "id": "3564463",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�BASICA�C.F.�NUESTRA�SE�ORA�DEL�BUEN�CAMINO",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "GLORIETA",
      "addressStreet": "RIO ZANCARA",
      "addressNumber": "1",
      "addressCode": "31020483",
      "model": "",
      "address": "GLORIETA RIO ZANCARA 1, ARAVACA, MADRID",
      "lat": 40.452637,
      "lng": -3.7797234
  },
  {
      "id": "177370",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CULTURAL�SARA�MONTIEL",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "105",
      "neighborhoodName": "CAMPAMENTO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CARRETERA",
      "addressStreet": "BOADILLA DEL MONTE",
      "addressNumber": "40",
      "addressCode": "31012355",
      "model": "",
      "address": "CARRETERA BOADILLA DEL MONTE 40, CAMPAMENTO, MADRID",
      "lat": 40.39954720000001,
      "lng": -3.7750368
  },
  {
      "id": "3558124",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�BARTOLOME�COSIO",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE DE CADALSO",
      "addressNumber": "19",
      "addressCode": "31025319",
      "model": "",
      "address": "CALLE JOSE DE CADALSO 19, LAS AGUILAS, MADRID",
      "lat": 40.3814889,
      "lng": -3.771367
  },
  {
      "id": "232134",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�ALUCHE�(AVENIDA DE LAS AGUILAS)",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE DE CADALSO",
      "addressNumber": "1",
      "addressCode": "11068677",
      "model": "",
      "address": "CALLE JOSE DE CADALSO 1, LAS AGUILAS, MADRID",
      "lat": 40.38259679999999,
      "lng": -3.7717106
  },
  {
      "id": "228434",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�BASICA�GENERAL�SALIQUET",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SOLEDAD CAZORLA",
      "addressNumber": "22",
      "addressCode": "20020648",
      "model": "",
      "address": "CALLE SOLEDAD CAZORLA 22, LAS AGUILAS, MADRID",
      "lat": 40.3769608,
      "lng": -3.7748206
  },
  {
      "id": "3536280",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�O�DONNEL",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "42",
      "neighborhoodName": "GOYA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "DOCTOR ESQUERDO",
      "addressNumber": "38",
      "addressCode": "31050016",
      "model": "",
      "address": "CALLE DOCTOR ESQUERDO 38, GOYA, MADRID",
      "lat": 40.422404,
      "lng": -3.6691617
  },
  {
      "id": "4745556",
      "description": "SITUADO APARCABICICLETAS - CENTRO�CIVICO�LA�VAGUADA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONFORTE DE LEMOS",
      "addressNumber": "38",
      "addressCode": "11045935",
      "model": "",
      "address": "AVENIDA MONFORTE DE LEMOS 38, EL PILAR, MADRID",
      "lat": 40.478613,
      "lng": -3.7094293
  },
  {
      "id": "3569261",
      "description": "SITUADO APARCABICICLETAS - JUNTA�MUNICIPAL�DE�LATINA�/�AUDITORIO�PACO�DE�LUCIA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "LAS AGUILAS",
      "addressNumber": "2:00 AM",
      "addressCode": "31003920",
      "model": "",
      "address": "AVENIDA LAS AGUILAS 2:00 AM, LAS AGUILAS, MADRID",
      "lat": 40.3845979,
      "lng": -3.765056
  },
  {
      "id": "139444",
      "description": "SITUADO APARCABICICLETAS - MOVIDO�A�AVENIDA�DE�LOS�TOREROS�(CLUB�DEPORTIVO�CALDEIRO)",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "TOREROS",
      "addressNumber": "45",
      "addressCode": "11017571",
      "model": "",
      "address": "AVENIDA TOREROS 45, GUINDALERA, MADRID",
      "lat": 40.4324703,
      "lng": -3.6665029
  },
  {
      "id": "4748551",
      "description": "SITUADO APARCABICICLETAS - MERCADO�DE�VALDEZARZA",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "94",
      "neighborhoodName": "VALDEZARZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANTONIO MACHADO",
      "addressNumber": "14 A",
      "addressCode": "20104474",
      "model": "",
      "address": "CALLE ANTONIO MACHADO 14 A, VALDEZARZA, MADRID",
      "lat": 40.4660823,
      "lng": -3.719088199999999
  },
  {
      "id": "226835",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�MONTE�EL�PARDO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "81",
      "neighborhoodName": "EL PARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GUARDIA",
      "addressNumber": "19",
      "addressCode": "31022099",
      "model": "",
      "address": "AVENIDA GUARDIA 19, EL PARDO, MADRID",
      "lat": 40.5171182,
      "lng": -3.774326499999999
  },
  {
      "id": "4749615",
      "description": "SITUADO APARCABICICLETAS - M-86�CENTRO DEPORTIVO M86",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JOSE MARTINEZ DE VELASCO",
      "addressNumber": "18",
      "addressCode": "20066362",
      "model": "",
      "address": "CALLE JOSE MARTINEZ DE VELASCO 18, ESTRELLA, MADRID",
      "lat": 40.41494429999999,
      "lng": -3.6675986
  },
  {
      "id": "4749630",
      "description": "SITUADO APARCABICICLETAS - REAL�CANOA�NATACION�CLUB",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEZ VOLADOR",
      "addressNumber": "30",
      "addressCode": "11011966",
      "model": "",
      "address": "CALLE PEZ VOLADOR 30, ESTRELLA, MADRID",
      "lat": 40.41360359999999,
      "lng": -3.6647203
  },
  {
      "id": "4749631",
      "description": "SITUADO APARCABICICLETAS - REAL�CANOA�NATACION�CLUB",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEZ VOLADOR",
      "addressNumber": "30",
      "addressCode": "11011966",
      "model": "",
      "address": "CALLE PEZ VOLADOR 30, ESTRELLA, MADRID",
      "lat": 40.41360359999999,
      "lng": -3.6647203
  },
  {
      "id": "4749633",
      "description": "SITUADO APARCABICICLETAS - REAL�CANOA�NATACION�CLUB",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEZ VOLADOR",
      "addressNumber": "30",
      "addressCode": "11011966",
      "model": "",
      "address": "CALLE PEZ VOLADOR 30, ESTRELLA, MADRID",
      "lat": 40.41360359999999,
      "lng": -3.6647203
  },
  {
      "id": "4740793",
      "description": "SITUADO APARCABICICLETAS - FRENTE�AL�AYUNTAMIENTO�DE�MADRID",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "35",
      "neighborhoodName": "LOS JERONIMOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONTALBAN",
      "addressNumber": "4",
      "addressCode": "11012783",
      "model": "",
      "address": "CALLE MONTALBAN 4, LOS JERONIMOS, MADRID",
      "lat": 40.4179644,
      "lng": -3.6912048
  },
  {
      "id": "4740917",
      "description": "SITUADO APARCABICICLETAS - CENTRO RUSO DE CIENCIA Y CULTURA. FRENTE AL AYUNTAMIENTO",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "41",
      "neighborhoodName": "RECOLETOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "59",
      "addressCode": "11013652",
      "model": "",
      "address": "CALLE ALCALA 59, RECOLETOS, MADRID",
      "lat": 40.4198947,
      "lng": -3.6913659
  },
  {
      "id": "528833",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 89",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "203",
      "neighborhoodName": "AMPOSTA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "89",
      "addressCode": "11135505",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 89, AMPOSTA, MADRID",
      "lat": 40.4234717,
      "lng": -3.6271078
  },
  {
      "id": "528843",
      "description": "SITUADO APARCABICICLETAS - CALLE MANZANAR 12",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "163",
      "neighborhoodName": "CANILLAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MANZANAR",
      "addressNumber": "12",
      "addressCode": "31037956",
      "model": "",
      "address": "CALLE MANZANAR 12, CANILLAS, MADRID",
      "lat": 40.4653993,
      "lng": -3.648228
  },
  {
      "id": "528842",
      "description": "SITUADO APARCABICICLETAS - AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 14",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "INSTITUCION LIBRE DE ENSE�ANZA",
      "addressNumber": "14",
      "addressCode": "31025053",
      "model": "",
      "address": "AVENIDA INSTITUCION LIBRE DE ENSE�ANZA 14, PUEBLO NUEVO, MADRID",
      "lat": 40.4367323,
      "lng": -3.6376091
  },
  {
      "id": "222596",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�MUNICIPAL�BASICA�MONTECARMELO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONASTERIO DE EL PAULAR",
      "addressNumber": "45",
      "addressCode": "31029294",
      "model": "",
      "address": "CALLE MONASTERIO DE EL PAULAR 45, MIRASIERRA, MADRID",
      "lat": 40.5001681,
      "lng": -3.7011695
  },
  {
      "id": "194451",
      "description": "SITUADO APARCABICICLETAS - C.P�ANDRES�MANJON",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MARIA AUXILIADORA",
      "addressNumber": "2",
      "addressCode": "31012992",
      "model": "",
      "address": "CALLE MARIA AUXILIADORA 2, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.457996,
      "lng": -3.7156396
  },
  {
      "id": "205615",
      "description": "SITUADO APARCABICICLETAS - INSTALACION�DEPORTIVA�LA�CABRERA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LA CABRERA",
      "addressNumber": "33",
      "addressCode": "31044378",
      "model": "",
      "address": "CALLE LA CABRERA 33, PE�A GRANDE, MADRID",
      "lat": 40.4718326,
      "lng": -3.7171637
  },
  {
      "id": "205792",
      "description": "SITUADO APARCABICICLETAS - C.P�FEDERICO�GARCIA�LORCA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "RIBADAVIA",
      "addressNumber": "20",
      "addressCode": "11046264",
      "model": "",
      "address": "CALLE RIBADAVIA 20, EL PILAR, MADRID",
      "lat": 40.4724723,
      "lng": -3.7049219
  },
  {
      "id": "207485",
      "description": "SITUADO APARCABICICLETAS - C.P�LUIS�DE�GONGORA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SARRIA",
      "addressNumber": "49",
      "addressCode": "31054399",
      "model": "",
      "address": "CALLE SARRIA 49, EL PILAR, MADRID",
      "lat": 40.4747662,
      "lng": -3.7151508
  },
  {
      "id": "4749632",
      "description": "SITUADO APARCABICICLETAS - REAL�CANOA�NATACION�CLUB",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEZ VOLADOR",
      "addressNumber": "30",
      "addressCode": "11011966",
      "model": "",
      "address": "CALLE PEZ VOLADOR 30, ESTRELLA, MADRID",
      "lat": 40.41360359999999,
      "lng": -3.6647203
  },
  {
      "id": "3534863",
      "description": "SITUADO APARCABICICLETAS - INST.�DEPORTIVA�BASICA�ARJONA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "103",
      "neighborhoodName": "LUCERO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CONCEJAL FRANCISCO JOSE JIMENEZ MARTIN",
      "addressNumber": "146",
      "addressCode": "20086557",
      "model": "",
      "address": "CALLE CONCEJAL FRANCISCO JOSE JIMENEZ MARTIN 146, LUCERO, MADRID",
      "lat": 40.4001563,
      "lng": -3.7497284
  },
  {
      "id": "3536124",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DE�LA�PRADERA�DEL�SALCERAL",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "VENTISQUERO DE LA CONDESA",
      "addressNumber": "50",
      "addressCode": "20088287",
      "model": "",
      "address": "AVENIDA VENTISQUERO DE LA CONDESA 50, MIRASIERRA, MADRID",
      "lat": 40.4955371,
      "lng": -3.718798799999999
  },
  {
      "id": "227733",
      "description": "SITUADO APARCABICICLETAS - C.E.I.P.�PRINCIPE�DE�ASTURIAS",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "88",
      "neighborhoodName": "EL GOLOSO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "IVAN PAVLOV",
      "addressNumber": "2",
      "addressCode": "31021306",
      "model": "",
      "address": "CALLE IVAN PAVLOV 2, EL GOLOSO, MADRID",
      "lat": 40.5429089,
      "lng": -3.6955338
  },
  {
      "id": "3538068",
      "description": "SITUADO APARCABICICLETAS - ESTACION�DE�METRO�LAS�TABLAS",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TOQUES",
      "addressNumber": "11",
      "addressCode": "31009168",
      "model": "",
      "address": "CALLE TOQUES 11, VALVERDE, MADRID",
      "lat": 40.50931,
      "lng": -3.66806
  },
  {
      "id": "3538189",
      "description": "SITUADO APARCABICICLETAS - PLAZA�DEL�CRISTO�REY",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "CRISTO REY",
      "addressNumber": "5",
      "addressCode": "31011243",
      "model": "",
      "address": "PLAZA CRISTO REY 5, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.4394314,
      "lng": -3.7174932
  },
  {
      "id": "232493",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�LAS�CRUCES",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "POBLADOS",
      "addressNumber": "64",
      "addressCode": "20092567",
      "model": "",
      "address": "AVENIDA POBLADOS 64, LAS AGUILAS, MADRID",
      "lat": 40.3848629,
      "lng": -3.7609414
  },
  {
      "id": "4748550",
      "description": "SITUADO APARCABICICLETAS - CENTRO�DEPORTIVO�MUNICIPAL�CIUDAD�DE�LOS�POETAS",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "94",
      "neighborhoodName": "VALDEZARZA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANTONIO MACHADO",
      "addressNumber": "14 A",
      "addressCode": "20104474",
      "model": "",
      "address": "CALLE ANTONIO MACHADO 14 A, VALDEZARZA, MADRID",
      "lat": 40.4660823,
      "lng": -3.719088199999999
  },
  {
      "id": "3538347",
      "description": "SITUADO APARCABICICLETAS - AECID�AGENCIA�ESPA�OLA�DE�COOPERACION",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "REYES CATOLICOS",
      "addressNumber": "4",
      "addressCode": "11053056",
      "model": "",
      "address": "AVENIDA REYES CATOLICOS 4, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.4381029,
      "lng": -3.7204841
  },
  {
      "id": "233419",
      "description": "SITUADO APARCABICICLETAS - ESTACION�METRO/CERCANIAS�ALUCHE�(AV/ DE LOS POBLADOS 41)",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "POBLADOS",
      "addressNumber": "41",
      "addressCode": "31031861",
      "model": "",
      "address": "AVENIDA POBLADOS 41, ALUCHE, MADRID",
      "lat": 40.38552019999999,
      "lng": -3.7608835
  },
  {
      "id": "3536170",
      "description": "SITUADO APARCABICICLETAS - CIUDAD�DE�LA�RAQUETA",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "87",
      "neighborhoodName": "MIRASIERRA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MONASTERIO DE EL PAULAR",
      "addressNumber": "2",
      "addressCode": "31041092",
      "model": "",
      "address": "CALLE MONASTERIO DE EL PAULAR 2, MIRASIERRA, MADRID",
      "lat": 40.5002217,
      "lng": -3.713992499999999
  },
  {
      "id": "227813",
      "description": "SITUADO APARCABICICLETAS - FRENTE�A�LA�PERGOLA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "106",
      "neighborhoodName": "CUATRO VIENTOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TROMBONES",
      "addressNumber": "13",
      "addressCode": "31036380",
      "model": "",
      "address": "CALLE TROMBONES 13, CUATRO VIENTOS, MADRID",
      "lat": 40.37051840000001,
      "lng": -3.763831599999999
  },
  {
      "id": "233967",
      "description": "SITUADO APARCABICICLETAS - I.E.S.�BLAS�DE�OTERO",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MAQUEDA",
      "addressNumber": "117",
      "addressCode": "11065473",
      "model": "",
      "address": "CALLE MAQUEDA 117, ALUCHE, MADRID",
      "lat": 40.3890014,
      "lng": -3.7617485
  },
  {
      "id": "234642",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�GAMO�DIANA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TEMBLEQUE",
      "addressNumber": "104",
      "addressCode": "11065563",
      "model": "",
      "address": "CALLE TEMBLEQUE 104, ALUCHE, MADRID",
      "lat": 40.3923018,
      "lng": -3.762377
  },
  {
      "id": "234830",
      "description": "SITUADO APARCABICICLETAS - AUDITORIO�PARQUE�ALUCHE",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ILLESCAS",
      "addressNumber": "151",
      "addressCode": "31050623",
      "model": "",
      "address": "CALLE ILLESCAS 151, ALUCHE, MADRID",
      "lat": 40.3918158,
      "lng": -3.7545662
  },
  {
      "id": "3544153",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�PUBLICO�LEOPOLDO�CALVO�SOTELO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "86",
      "neighborhoodName": "VALVERDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "CAMINO DE SANTIAGO",
      "addressNumber": "25",
      "addressCode": "31049645",
      "model": "",
      "address": "AVENIDA CAMINO DE SANTIAGO 25, VALVERDE, MADRID",
      "lat": 40.502617,
      "lng": -3.674842
  },
  {
      "id": "3545445",
      "description": "SITUADO APARCABICICLETAS - URBANIZACION�LA�BUGANVILLA",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "97",
      "neighborhoodName": "ARAVACA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "RONDA",
      "addressStreet": "BUGANVILLA DEL REY",
      "addressNumber": "1",
      "addressCode": "20090795",
      "model": "",
      "address": "RONDA BUGANVILLA DEL REY 1, ARAVACA, MADRID",
      "lat": 40.454732,
      "lng": -3.7660443
  },
  {
      "id": "237510",
      "description": "SITUADO APARCABICICLETAS - C.P.�AMADEO�VIVES",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "104",
      "neighborhoodName": "ALUCHE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALMOJADO",
      "addressNumber": "61",
      "addressCode": "11065308",
      "model": "",
      "address": "CALLE VALMOJADO 61, ALUCHE, MADRID",
      "lat": 40.396899,
      "lng": -3.756387999999999
  },
  {
      "id": "3553305",
      "description": "SITUADO APARCABICICLETAS - PUNTO�LIMPIO�LATINA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "101",
      "neighborhoodName": "LOS CARMENES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GALLUR",
      "addressNumber": "2",
      "addressCode": "20019104",
      "model": "",
      "address": "CALLE GALLUR 2, LOS CARMENES, MADRID",
      "lat": 40.3988833,
      "lng": -3.735337
  },
  {
      "id": "457815",
      "description": "SITUADO APARCABICICLETAS - Pz. ISABEL II N�6",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "ISABEL II",
      "addressNumber": "8",
      "addressCode": "20003836",
      "model": "",
      "address": "PLAZA ISABEL II 8, PALACIO, MADRID",
      "lat": 40.4179811,
      "lng": -3.7089656
  },
  {
      "id": "3541340",
      "description": "SITUADO APARCABICICLETAS - COLONIA�DE�LA�FUENTE�DE�LA�TEJA",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA POLA",
      "addressNumber": "13",
      "addressCode": "11052007",
      "model": "",
      "address": "CALLE SANTA POLA 13, CASA DE CAMPO, MADRID",
      "lat": 40.4318999,
      "lng": -3.7366755
  },
  {
      "id": "3551688",
      "description": "SITUADO APARCABICICLETAS - JUNTA�MUNICIPAL�FUENCARRAL�EL�PARDO",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "MONFORTE DE LEMOS",
      "addressNumber": "40",
      "addressCode": "11045934",
      "model": "",
      "address": "AVENIDA MONFORTE DE LEMOS 40, EL PILAR, MADRID",
      "lat": 40.4785929,
      "lng": -3.7097586
  },
  {
      "id": "236539",
      "description": "SITUADO APARCABICICLETAS - C.P.�LOS�CARMENES",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "101",
      "neighborhoodName": "LOS CARMENES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALHAMBRA",
      "addressNumber": "102",
      "addressCode": "11063760",
      "model": "",
      "address": "CALLE ALHAMBRA 102, LOS CARMENES, MADRID",
      "lat": 40.39577,
      "lng": -3.74398
  },
  {
      "id": "3544389",
      "description": "SITUADO APARCABICICLETAS - COLONIA�DE�LA�FUENTE�DE�LA�TEJA",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA POLA",
      "addressNumber": "22",
      "addressCode": "11051996",
      "model": "",
      "address": "CALLE SANTA POLA 22, CASA DE CAMPO, MADRID",
      "lat": 40.4323438,
      "lng": -3.7370427
  },
  {
      "id": "3541341",
      "description": "SITUADO APARCABICICLETAS - COLONIA�DE�LA�FUENTE�DE�LA�TEJA",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SANTA POLA",
      "addressNumber": "20",
      "addressCode": "11051978",
      "model": "",
      "address": "CALLE SANTA POLA 20, CASA DE CAMPO, MADRID",
      "lat": 40.4320245,
      "lng": -3.7364675
  },
  {
      "id": "186568",
      "description": "SITUADO APARCABICICLETAS - C.P.�FERNANDEZ�MORATIN",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "VALLADOLID",
      "addressNumber": "2",
      "addressCode": "20016844",
      "model": "",
      "address": "AVENIDA VALLADOLID 2, CASA DE CAMPO, MADRID",
      "lat": 40.426741,
      "lng": -3.727295
  },
  {
      "id": "193973",
      "description": "SITUADO APARCABICICLETAS - GLORIETA�DE�ROCIO�DURCAL",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "93",
      "neighborhoodName": "CIUDAD UNIVERSITARIA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "PABLO IGLESIAS",
      "addressNumber": "93",
      "addressCode": "11053471",
      "model": "",
      "address": "AVENIDA PABLO IGLESIAS 93, CIUDAD UNIVERSITARIA, MADRID",
      "lat": 40.4572998,
      "lng": -3.712296
  },
  {
      "id": "232054",
      "description": "SITUADO APARCABICICLETAS - CANCHAS�DE�FUTBOL�SALA",
      "districtId": "10",
      "districtName": "LATINA",
      "neighborhoodId": "107",
      "neighborhoodName": "LAS AGUILAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VIDAUBA",
      "addressNumber": "3",
      "addressCode": "31045344",
      "model": "",
      "address": "CALLE VIDAUBA 3, LAS AGUILAS, MADRID",
      "lat": 40.3836013,
      "lng": -3.7638877
  },
  {
      "id": "4782833",
      "description": "SITUADO_APARCABICICLETAS",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "207",
      "neighborhoodName": "CANILLEJAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CIRCE",
      "addressNumber": "50",
      "addressCode": "20043156",
      "model": "MU-51",
      "address": "CALLE CIRCE 50, CANILLEJAS, MADRID",
      "lat": 40.4418514,
      "lng": -3.6105904
  },
  {
      "id": "4741983",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE CASALARREINA 29B",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CASALARREINA",
      "addressNumber": "29B",
      "addressCode": "31047333",
      "model": "",
      "address": "CALLE CASALARREINA 29B, CASCO H.VICALVARO, MADRID",
      "lat": 40.4080534,
      "lng": -3.6145644
  },
  {
      "id": "528273",
      "description": "SITUADO_APARCABICICLETAS - IES JOAQUIN RODRIGO",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CASALARREINA",
      "addressNumber": "28",
      "addressCode": "31028954",
      "model": "",
      "address": "CALLE CASALARREINA 28, CASCO H.VICALVARO, MADRID",
      "lat": 40.4090571,
      "lng": -3.6141415
  },
  {
      "id": "4784681",
      "description": "SITUADO APARCABICICLETAS - PLAZA ALSACIA 1",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "204",
      "neighborhoodName": "ARCOS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PLAZA",
      "addressStreet": "ALSACIA",
      "addressNumber": "1",
      "addressCode": "31051797",
      "model": "",
      "address": "PLAZA ALSACIA 1, ARCOS, MADRID",
      "lat": 40.4167772,
      "lng": -3.6231705
  },
  {
      "id": "4745561",
      "description": "SITUADO APARCABICICLETAS - TEATRO�DE�MADRID",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "84",
      "neighborhoodName": "EL PILAR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "VAGUADA",
      "addressNumber": "11",
      "addressCode": "20095215",
      "model": "",
      "address": "PASEO VAGUADA 11, EL PILAR, MADRID",
      "lat": 40.4801368,
      "lng": -3.709565
  },
  {
      "id": "4795850",
      "description": "SITUADO APARCABICICLETAS - COLEGIO PUREZA DE MARIA",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "LIRA",
      "addressNumber": "10",
      "addressCode": "11011826",
      "model": "",
      "address": "CALLE LIRA 10, ESTRELLA, MADRID",
      "lat": 40.4081108,
      "lng": -3.6681706
  },
  {
      "id": "528367",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA SEPIOLITA 1",
      "districtId": "19",
      "districtName": "VICALVARO",
      "neighborhoodId": "191",
      "neighborhoodName": "CASCO H.VICALVARO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VILLABLANCA",
      "addressNumber": "81",
      "addressCode": "20108070",
      "model": "",
      "address": "CALLE VILLABLANCA 81, CASCO H.VICALVARO, MADRID",
      "lat": 40.4056728,
      "lng": -3.5992855
  },
  {
      "id": "4988193",
      "description": "SITUADO APARCABICICLETAS - PARQUE�ANA�TUTOR�(C/V�AVDA.�DEL�CARDENAL�HERRERA�ORIA)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "VENTISQUERO DE LA CONDESA",
      "addressNumber": "2",
      "addressCode": "20079569",
      "model": "",
      "address": "AVENIDA VENTISQUERO DE LA CONDESA 2, PE�A GRANDE, MADRID",
      "lat": 40.4838427,
      "lng": -3.7153667
  },
  {
      "id": "4988195",
      "description": "SITUADO APARCABICICLETAS - PARQUE�ANA�TUTOR�(C/V�C.�PINARES�LLANOS)",
      "districtId": "8",
      "districtName": "FUENCARRAL-EL PARDO",
      "neighborhoodId": "83",
      "neighborhoodName": "PE�A GRANDE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "VALLE DE PINARES LLANOS",
      "addressNumber": "1",
      "addressCode": "20075089",
      "model": "",
      "address": "CALLE VALLE DE PINARES LLANOS 1, PE�A GRANDE, MADRID",
      "lat": 40.4855924,
      "lng": -3.7173942
  },
  {
      "id": "528612",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA FUENTE CARRANTONA 61",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "142",
      "neighborhoodName": "HORCAJO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FUENTE CARRANTONA",
      "addressNumber": "61",
      "addressCode": "31041857",
      "model": "",
      "address": "CALLE FUENTE CARRANTONA 61, HORCAJO, MADRID",
      "lat": 40.4103322,
      "lng": -3.6301015
  },
  {
      "id": "4990352",
      "description": "SITUADO APARCABICICLETAS - C/ CAVA ALTA N� 6",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "11",
      "neighborhoodName": "PALACIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAVA ALTA",
      "addressNumber": "6",
      "addressCode": "20003940",
      "model": "",
      "address": "CALLE CAVA ALTA 6, PALACIO, MADRID",
      "lat": 40.41255049999999,
      "lng": -3.708573
  },
  {
      "id": "528266",
      "description": "SITUADO_APARCABICICLETAS- CAMPO FUTBOL ARAGON",
      "districtId": "13",
      "districtName": "PUENTE DE VALLECAS",
      "neighborhoodId": "134",
      "neighborhoodName": "PALOMERAS SURESTE",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ANTONIO MAIRENA",
      "addressNumber": "23",
      "addressCode": "31025964",
      "model": "",
      "address": "CALLE ANTONIO MAIRENA 23, PALOMERAS SURESTE, MADRID",
      "lat": 40.3920623,
      "lng": -3.6386559
  },
  {
      "id": "5068930",
      "description": "SITUADO_APARCABICICLETAS - IES JUAN DE VILLANUEVA. CAMINO VIEJO VILLAVERDE",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "CAMINO VIEJO DE VILLAVERDE",
      "addressNumber": "28",
      "addressCode": "11080808",
      "model": "",
      "address": "CALLE CAMINO VIEJO DE VILLAVERDE 28, ORCASITAS, MADRID",
      "lat": 40.3692614,
      "lng": -3.7113841
  },
  {
      "id": "5068925",
      "description": "SITUADOS_APARCABICICLETAS - IES PIO BAROJA. CALLE TOLOSA",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "121",
      "neighborhoodName": "ORCASITAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "TOLOSA",
      "addressNumber": "2",
      "addressCode": "11079512",
      "model": "",
      "address": "CALLE TOLOSA 2, ORCASITAS, MADRID",
      "lat": 40.3729779,
      "lng": -3.7189619
  },
  {
      "id": "5068993",
      "description": "SITUADOS_APARCABICICLETAS - CEIP CIUDAD DE JAEN",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "122",
      "neighborhoodName": "ORCASUR",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "Calle",
      "addressStreet": "Camino R�o",
      "addressNumber": "3",
      "addressCode": "31048238",
      "model": "",
      "address": "Calle Camino R�o 3, ORCASUR, MADRID",
      "lat": 40.3703425,
      "lng": -3.7003625
  },
  {
      "id": "5069903",
      "description": "SITUADOS_APARCABICICLETAS - CALLE GENERAL MARVA I.E.S PEDRO SALINAS",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "GENERAL MARVA",
      "addressNumber": "26",
      "addressCode": "11084285",
      "model": "",
      "address": "CALLE GENERAL MARVA 26, MOSCARDO, MADRID",
      "lat": 40.3888397,
      "lng": -3.7090167
  },
  {
      "id": "5072932",
      "description": "SITUADO APARCABICICLETAS - C/�JUAN�DUQUE�46",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN DUQUE",
      "addressNumber": "46",
      "addressCode": "11008104",
      "model": "",
      "address": "CALLE JUAN DUQUE 46, IMPERIAL, MADRID",
      "lat": 40.4085734,
      "lng": -3.719770999999999
  },
  {
      "id": "5090608",
      "description": "SITUADO APARCABICICLETAS - AVENIDA NICETO ALCALA ZAMORA 48",
      "districtId": "16",
      "districtName": "HORTALEZA",
      "neighborhoodId": "166",
      "neighborhoodName": "VALDEFUENTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "NICETO ALCALA ZAMORA",
      "addressNumber": "48",
      "addressCode": "31040776",
      "model": "",
      "address": "AVENIDA NICETO ALCALA ZAMORA 48, VALDEFUENTES, MADRID",
      "lat": 40.4992084,
      "lng": -3.6567154
  },
  {
      "id": "5104672",
      "description": "SITUADO APARCABICICLETAS - COLEGIO�AGUSTINIANO",
      "districtId": "3",
      "districtName": "RETIRO",
      "neighborhoodId": "33",
      "neighborhoodName": "ESTRELLA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PEZ AUSTRAL",
      "addressNumber": "13",
      "addressCode": "11011967",
      "model": "",
      "address": "CALLE PEZ AUSTRAL 13, ESTRELLA, MADRID",
      "lat": 40.4122751,
      "lng": -3.6657098
  },
  {
      "id": "5105345",
      "description": "SITUADO_APARCABICICLETAS - CALLE PICO MAMPODRE 21",
      "districtId": "18",
      "districtName": "VILLA DE VALLECAS",
      "neighborhoodId": "181",
      "neighborhoodName": "CASCO H.VALLECAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "Calle",
      "addressStreet": "Pico Mampodre",
      "addressNumber": "10",
      "addressCode": "20151619",
      "model": "",
      "address": "Calle Pico Mampodre 10, CASCO H.VALLECAS, MADRID",
      "lat": 40.37758280000001,
      "lng": -3.620534299999999
  },
  {
      "id": "5166918",
      "description": "SITUADOS_APARCABICICLETAS - CEIP MARCELO USERA",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "124",
      "neighborhoodName": "ALMENDRALES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "FELIPE DIAZ",
      "addressNumber": "2",
      "addressCode": "11083517",
      "model": "",
      "address": "CALLE FELIPE DIAZ 2, ALMENDRALES, MADRID",
      "lat": 40.387433,
      "lng": -3.6998166
  },
  {
      "id": "5166053",
      "description": "SITUADOS_APARCABICICLETAS - CEIP PRADOLONGO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "126",
      "neighborhoodName": "ZOFIO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PARQUE DE LA PALOMA",
      "addressNumber": "44",
      "addressCode": "11085751",
      "model": "",
      "address": "CALLE PARQUE DE LA PALOMA 44, ZOFIO, MADRID",
      "lat": 40.3791708,
      "lng": -3.7156464
  },
  {
      "id": "5173954",
      "description": "SITUADOS_APARCABICICLETAS - CEIP JUAN SEBASTI�N ELCANO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "MIRASIERRA",
      "addressNumber": "29",
      "addressCode": "11084162",
      "model": "",
      "address": "CALLE MIRASIERRA 29, MOSCARDO, MADRID",
      "lat": 40.3890664,
      "lng": -3.7047431
  },
  {
      "id": "5174286",
      "description": "SITUADOS_APARCABICICLETAS - CSS SAN FILIBERTO",
      "districtId": "12",
      "districtName": "USERA",
      "neighborhoodId": "125",
      "neighborhoodName": "MOSCARDO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "SAN FILIBERTO",
      "addressNumber": "7",
      "addressCode": "11085115",
      "model": "",
      "address": "CALLE SAN FILIBERTO 7, MOSCARDO, MADRID",
      "lat": 40.3858219,
      "lng": -3.7151587
  },
  {
      "id": "5182687",
      "description": "SITUADO APARCABICICLETAS - AVENIDA DAROCA 91",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "DAROCA",
      "addressNumber": "91",
      "addressCode": "20117835",
      "model": "",
      "address": "AVENIDA DAROCA 91, PUEBLO NUEVO, MADRID",
      "lat": 40.4258999,
      "lng": -3.6450195
  },
  {
      "id": "5182888",
      "description": "SITUADO APARCABICICLETAS - CALLE ARRIAGA 73",
      "districtId": "15",
      "districtName": "CIUDAD LINEAL",
      "neighborhoodId": "152",
      "neighborhoodName": "PUEBLO NUEVO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ARRIAGA",
      "addressNumber": "73",
      "addressCode": "11107027",
      "model": "",
      "address": "CALLE ARRIAGA 73, PUEBLO NUEVO, MADRID",
      "lat": 40.4242234,
      "lng": -3.6393359
  },
  {
      "id": "528493",
      "description": "SITUADO_APARCABICICLETAS - CALLE DE LA HACIENDA DE PAVONES 340",
      "districtId": "14",
      "districtName": "MORATALAZ",
      "neighborhoodId": "141",
      "neighborhoodName": "PAVONES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "HACIENDA DE PAVONES",
      "addressNumber": "342",
      "addressCode": "20098170",
      "model": "",
      "address": "CALLE HACIENDA DE PAVONES 342, PAVONES, MADRID",
      "lat": 40.4001689,
      "lng": -3.6348425
  },
  {
      "id": "5214364",
      "description": "SITUADO APARCABICICLETAS - C/�DE�JUAN�DE�MARIANA�17",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "25",
      "neighborhoodName": "DELICIAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN DE MARIANA",
      "addressNumber": "17",
      "addressCode": "20135925",
      "model": "",
      "address": "CALLE JUAN DE MARIANA 17, DELICIAS, MADRID",
      "lat": 40.39847,
      "lng": -3.68611
  },
  {
      "id": "7124145",
      "description": "SITUADO APARCABICICLETAS - PSO.�DE�LA�FLORIDA�10",
      "districtId": "9",
      "districtName": "MONCLOA-ARAVACA",
      "neighborhoodId": "91",
      "neighborhoodName": "CASA DE CAMPO",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "FLORIDA",
      "addressNumber": "10",
      "addressCode": "20106563",
      "model": "",
      "address": "PASEO FLORIDA 10, CASA DE CAMPO, MADRID",
      "lat": 40.4225382,
      "lng": -3.7223443
  },
  {
      "id": "7180032",
      "description": "SITUADO APARCABICICLETAS - AVENIDA GUADALAJARA 126",
      "districtId": "20",
      "districtName": "SAN BLAS-CANILLEJAS",
      "neighborhoodId": "205",
      "neighborhoodName": "ROSAS",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "AVENIDA",
      "addressStreet": "GUADALAJARA",
      "addressNumber": "126",
      "addressCode": "31051637",
      "model": "",
      "address": "AVENIDA GUADALAJARA 126, ROSAS, MADRID",
      "lat": 40.4235956,
      "lng": -3.6072988
  },
  {
      "id": "7182450",
      "description": "SITUADO APARCABICICLETAS - C/ DE JUAN DUQUE 1",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "JUAN DUQUE",
      "addressNumber": "1",
      "addressCode": "20007142",
      "model": "",
      "address": "CALLE JUAN DUQUE 1, IMPERIAL, MADRID",
      "lat": 40.411985,
      "lng": -3.7183914
  },
  {
      "id": "7182455",
      "description": "SITUADO APARCABICICLETAS - PSO/ DE LA VIRGEN DEL PUERTO 35",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "VIRGEN DEL PUERTO",
      "addressNumber": "35",
      "addressCode": "11008053",
      "model": "",
      "address": "PASEO VIRGEN DEL PUERTO 35, IMPERIAL, MADRID",
      "lat": 40.4108054,
      "lng": -3.7211027
  },
  {
      "id": "7182461",
      "description": "SITUADO APARCABICICLETAS - PSO/ DE LA VIRGEN DEL PUERTO 43",
      "districtId": "2",
      "districtName": "ARGANZUELA",
      "neighborhoodId": "21",
      "neighborhoodName": "IMPERIAL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "PASEO",
      "addressStreet": "VIRGEN DEL PUERTO",
      "addressNumber": "43",
      "addressCode": "11008057",
      "model": "",
      "address": "PASEO VIRGEN DEL PUERTO 43, IMPERIAL, MADRID",
      "lat": 40.40983019999999,
      "lng": -3.721055
  },
  {
      "id": "7182653",
      "description": "SITUADO APARCABICICLETAS - C/ PILAR DE ZARAGOZA 57",
      "districtId": "4",
      "districtName": "SALAMANCA",
      "neighborhoodId": "44",
      "neighborhoodName": "GUINDALERA",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "PILAR DE ZARAGOZA",
      "addressNumber": "57",
      "addressCode": "11018571",
      "model": "",
      "address": "CALLE PILAR DE ZARAGOZA 57, GUINDALERA, MADRID",
      "lat": 40.4356031,
      "lng": -3.6713994
  },
  {
      "id": "7182738",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCALA N� 13",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "16",
      "neighborhoodName": "SOL",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "13",
      "addressCode": "11007440",
      "model": "",
      "address": "CALLE ALCALA 13, SOL, MADRID",
      "lat": 40.4179651,
      "lng": -3.7007596
  },
  {
      "id": "7182744",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCALA N� 16",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "16",
      "addressCode": "11004000",
      "model": "",
      "address": "CALLE ALCALA 16, CORTES, MADRID",
      "lat": 40.4175362,
      "lng": -3.6994699
  },
  {
      "id": "7182745",
      "description": "SITUADO APARCABICICLETAS - CALLE ALCALA N� 24",
      "districtId": "1",
      "districtName": "CENTRO",
      "neighborhoodId": "13",
      "neighborhoodName": "CORTES",
      "state": "OPERATIVO",
      "installDate": "",
      "addressType": "CALLE",
      "addressStreet": "ALCALA",
      "addressNumber": "24",
      "addressCode": "11004004",
      "model": "",
      "address": "CALLE ALCALA 24, CORTES, MADRID",
      "lat": 40.4178962,
      "lng": -3.698749
  }
];

module.exports = {
  bikes,
};
