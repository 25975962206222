<template>
  <div>
    <div class="my-search">
      <input
        class="my-search-control"
        type="text"
        id="pac-address"
        placeholder="Buscar"
      />
    </div>
    <div class="my-position" @click="location">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="30"
        viewBox="0 0 24 24"
        width="30"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          fill="#020873"
          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
        />
      </svg>
    </div>
    <gmap-map
      ref="mapRef"
      :options="{
        gestureHandling: 'greedy',
        mapTypeControl: false,
        fullscreenControl: false,
      }"
      :center="center"
      :zoom="mapp.zoom"
      map-type-id="terrain"
      style="width: 100vw; height: calc(100vh - 70px)"
    >
      <gmap-marker
        v-if="showMarkerCenter"
        :position="markerCenter"
        :clickable="false"
        :draggable="false"
        :icon="'https://maps.google.com/mapfiles/ms/icons/green-dot.png'"
      />

      <gmap-marker
        ref="markerRef"
        :key="index"
        v-for="(m, index) in markers"
        :position="{ lat: m.lat, lng: m.lng }"
        :clickable="true"
        :draggable="false"
        :options="{ _ref: m }"
        @click="toggleInfoWindow(m, index)"
      />
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>

<script>
import bikes from "../data/madbikes-3";
//import * as VueGoogleMaps from 'vue2-google-maps';
import { gmapApi } from "vue2-google-maps";
export default {
  name: "BikesMap",
  data() {
    return {
      gmap: {},
      radius: 2000,
      mapp: { radius: 2000, zoom: 15 },
      geoCircle: null,
      geoCircle2: null,
      markers: [],
      places: {},
      center: { lat: 40.4255386, lng: -3.6992025 },
      markerCenter: { lat: 40.4255386, lng: -3.6992025 },
      showMarkerCenter: false,
      map: null,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    //set bounds of the map
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.gmap = map;
      // this.infoWindow = new google.maps.InfoWindow({
      //   //content: document.getElementById("info-content"),
      //   content: document.getElementById("infowindow-content"),
      // });

      const input = document.getElementById("pac-address");
      const autocomplete = new this.google.maps.places.Autocomplete(input);
      autocomplete.bindTo("bounds", map);

      map.addListener("center_changed", () => this.searchDebounce());
      map.addListener("zoom_changed", () => this.searchDebounce());

      this.searchDebounce();

      this.places = new this.google.maps.places.PlacesService(map);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        } // If the place has a geometry, then present it on a map.

        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17); // Why 17? Because it looks good.
        }

        this.showMarkerCenter = true;
        this.markerCenter = place.geometry.location;

        let query = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          radius: this.mapp.radius,
        };

        this.search(query);
      });
    });
  },
  methods: {
    searchDebounce() {
      clearTimeout(this.mapUpdater);

      //this.markerCenter = this.gmap.getCenter();

      this.mapUpdater = setTimeout(() => {
        let query = {
          lat: this.gmap.getCenter().lat(),
          lng: this.gmap.getCenter().lng(),
          radius: this.mapp.radius,
        };
        this.mapp.zoom = this.gmap.getZoom();
        if (!this.openingInfoWindow) {
          this.search(query);

          //this.markers = bikes.bikes.filter(m => m.state === "OPERATIVO").filter(m => m.state === "OPERATIVO")

          this.drawCircle(this.gmap);
        }
      }, 150);
    },
    drawCircle(map) {
      let radius = parseInt(this.mapp.radius);
      if (this.geoCircle) {
        //If the circle is already created and visible on map.
        this.geoCircle.setRadius(radius);
        this.geoCircle.setCenter(map.getCenter());
      } else {
        //Circle not initalized yet, so initialize and display.
        var circleOptions = {
          strokeColor: "#888",
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: "#888",
          fillOpacity: 0.15,
          map: map, //pass the map object to show on the map.
          center: map.getCenter(), //or you can pass a google.maps.LatLng object
          radius: radius * 1.6, //radius of the circle in metres
        };
        this.geoCircle = new this.google.maps.Circle(circleOptions);
      }

      // if (this.geoCircle2) {
      //   //If the circle is already created and visible on map.
      //   this.geoCircle2.setRadius(5);
      //   this.geoCircle2.setCenter(map.getCenter());
      // } else {
      //   //Circle not initalized yet, so initialize and display.
      //   var circleOptions2 = {
      //     strokeColor: "#f30",
      //     strokeOpacity: 1,
      //     strokeWeight: 1,
      //     fillColor: "#f30",
      //     fillOpacity: 1,
      //     map: map, //pass the map object to show on the map.
      //     center: map.getCenter(), //or you can pass a google.maps.LatLng object
      //     radius: 5, //radius of the circle in metres
      //   };
      //   this.geoCircle2 = new this.google.maps.Circle(circleOptions2);
      // }
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = { lat: marker.lat, lng: marker.lng };
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    search: function (query) {
      console.log("search", query);
      this.markers = bikes.bikes
        .filter((m) => m.state === "OPERATIVO")
        .filter(
          (m) => this.distance(query.lat, query.lng, m.lat, m.lng) < this.radius
        );
    },
    distance: function (lat1, lon1, lat2, lon2) {
      var R = 6371e3; // metres
      var φ1 = this.toRadians(lat1);
      var φ2 = this.toRadians(lat2);
      var Δφ = this.toRadians(lat2 - lat1);
      var Δλ = this.toRadians(lon2 - lon1);
      var a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    },
    toRadians(num) {
      return (num * Math.PI) / 180;
    },

    getInfoWindowContent: function (marker) {
      return `<div class="card">  
  <div class="card-content">
    <div class="media">
      <div class="media-content">
        <p class="title is-4">${this.titleCase(marker.addressType)} ${this.titleCase(marker.addressStreet)}, ${marker.addressNumber}</p>
        <p class="title is-4">${this.titleCase(marker.neighborhoodName)} (${this.titleCase(marker.districtName)})</p>
      </div>
    </div>    
  </div>
</div>`;
    },
    location() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            //infoWindow.setPosition(pos);
            //infoWindow.setContent("Location found.");
            //infoWindow.open(map);
            this.gmap.setCenter(pos);
            this.gmap.setZoom(17);

            this.showMarkerCenter = true;
            this.markerCenter = pos;
          },
          () => {
            //handleLocationError(true, infoWindow, map.getCenter());
            alert("Error en geoposicionamiento");
          }
        );
      } else {
        // Browser doesn't support Geolocation
        //handleLocationError(false, infoWindow, map.getCenter());
        alert("Geoposicionamiento no disponible");
      }
    },
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
  // filters: {
  //   titleCase(str) {
  //     var splitStr = str.toLowerCase().split(" ");
  //     for (var i = 0; i < splitStr.length; i++) {
  //       // You do not need to check if i is larger than splitStr length, as your for does that for you
  //       // Assign it back to the array
  //       splitStr[i] =
  //         splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  //     }
  //     // Directly return the joined string
  //     return splitStr.join(" ");
  //   },
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.gm-style .gm-style-iw-c{
  min-width: 200px!important;
  padding: 0!important;
}
.gm-style .gm-style-iw-c button {
  right: 4px !important;
}
.gm-style .gm-style-iw-c img {
  width: 24px !important;
  height: 24px !important;
}
.card-content {
  margin: 25px;
}
</style>
<style scoped>
.my-search {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 250px;
  height: 40px;
  background-color: #fff;
  z-index: 10;
  line-height: 40px;
  padding: 0;
  border-radius: 2px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
.my-position {
  position: absolute;
  top: 10px;
  left: 270px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  z-index: 10;
  line-height: 40px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
@media (max-width: 767px) {
  .my-position {
    right: 10px;
    left: auto;
  }
}
.my-position svg {
  margin-top: 4px;
}
.my-search-control {
  border: 0;
  height: 38px;
  padding: 0 6px;
  outline: none;
  font-size: 16px;
  max-width: 240px;
}
</style>
